import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type OtifPurchaseOrdersTotalsReportQueryVariables = Types.Exact<{
  filters: Types.ExecutiveReportingFilters;
}>;


export type OtifPurchaseOrdersTotalsReportQuery = { __typename?: 'RootQueryType', otifPurchaseOrdersTotalsReport?: { __typename?: 'OtifTotalsReport', inFull: any, onTime: any, otif: any, acceptedFillRate: any, submittedFillRate: any, rsvValue: any, approximateLostOpportunity: number } | null };


export const OtifPurchaseOrdersTotalsReportDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"otifPurchaseOrdersTotalsReport"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExecutiveReportingFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"otifPurchaseOrdersTotalsReport"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"inFull"}},{"kind":"Field","name":{"kind":"Name","value":"onTime"}},{"kind":"Field","name":{"kind":"Name","value":"otif"}},{"kind":"Field","name":{"kind":"Name","value":"acceptedFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"submittedFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"rsvValue"}},{"kind":"Field","name":{"kind":"Name","value":"approximateLostOpportunity"}}]}}]}}]} as unknown as DocumentNode<OtifPurchaseOrdersTotalsReportQuery, OtifPurchaseOrdersTotalsReportQueryVariables>;