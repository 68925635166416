import React, { Fragment, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import s from './ExportItemsModal.module.scss';
import { useQuery } from '@apollo/client';
import { GetPurchaseOrdersForExportDocument } from './gql/__generated__/getPurchaseOrdersForExport.query';
import { PoExportItem } from 'graphql/__generated__/types';
import ExportItemsTable from 'pages/OrdersPage/components/ExportItemsTable/ExportItemsTable';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';

export type ExportType = 'FULL' | 'INVALID' | 'DUMMY' | '';
export type ToggleExportModal = (type: ExportType) => void;

interface ExportItemsModalProps {
  visibility: boolean;
  toggleModal: ToggleExportModal;
  selectedPurchaseOrderIds: string[];
  type: ExportType;
}

const newInvalidHeaders: { label: string; key: keyof PoExportItem }[] = [
  { label: 'Product External ID', key: 'externalId' },
  { label: 'SAP Mat #', key: 'sapMaterialId' },
  { label: 'UPC', key: 'upc' },
  { label: 'Ordered Qty', key: 'quantityOrdered' },
  { label: 'PO', key: 'customerPo' },
  { label: 'Order Date', key: 'orderDate' },
  { label: 'Description', key: 'description' },
  { label: 'Ship-To', key: 'shipTo' }
];

const newItemHeaders: { label: string; key: keyof PoExportItem }[] = [
  { label: 'Product External ID', key: 'externalId' },
  { label: 'Sold To #', key: 'soldTo' },
  { label: 'RDD', key: 'rdd' },
  { label: 'SAP Mat #', key: 'sapMaterialId' },
  { label: 'UPC', key: 'upc' },
  { label: 'Original Order Qty', key: 'quantityOriginal' },
  { label: 'Ordered Qty', key: 'quantityOrdered' },
  { label: 'PO', key: 'customerPo' },
  { label: 'Description', key: 'description' },
  { label: 'Ship-To', key: 'shipTo' },
  { label: 'DC', key: 'distributionCenters' },
  { label: 'Trading Partner', key: 'tp' },
  { label: 'Price', key: 'price' },
  { label: 'Currency', key: 'currency' }
];

const ExportItemsModal = ({
  visibility,
  toggleModal,
  selectedPurchaseOrderIds,
  type
}: ExportItemsModalProps) => {
  const [showIndividualItems, setShowIndividualItems] = useState(false);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const {
    error: exportError,
    loading: exportLoading,
    data: exportData
  } = useQuery(GetPurchaseOrdersForExportDocument, {
    variables: {
      purchaseOrderIds: selectedPurchaseOrderIds
    },
    skip: !type || !visibility
  });

  const newCsvData: PoExportItem[] = useMemo(() => {
    if (exportData) {
      let newMappedData: PoExportItem[] = [];

      exportData.poExportItems?.items.forEach((poToExport) => {
        if (
          (type === 'INVALID' && poToExport.errors && poToExport.errors.length > 0) ||
          type !== 'INVALID'
        ) {
          //prevents duplicates of the items
          let existingRow = newMappedData.find((po) => po.externalId === poToExport.externalId);

          if (existingRow && type === 'INVALID' && !showIndividualItems) {
            if (
              poToExport.customerPo &&
              !existingRow.customerPo?.includes(poToExport.customerPo) &&
              !showIndividualItems
            ) {
              existingRow = {
                ...existingRow,
                quantityOrdered:
                  (existingRow.quantityOrdered || 0) + (poToExport.quantityOrdered || 0),
                customerPo: `${existingRow.customerPo}, ${poToExport.customerPo}`
              };
            }
          } else {
            newMappedData.push(poToExport);
          }
        }
      });

      newMappedData = newMappedData.sort((a, b) =>
        (a.customerPo || '').localeCompare(b.customerPo || '')
      );

      return newMappedData;
    } else {
      return [];
    }
  }, [showIndividualItems, exportData, type]);

  const controls = [
    <AlloyButton key="cancel" data-testid="cancel-button" onClick={() => toggleModal('')}>
      Cancel
    </AlloyButton>,
    <Fragment key="export-fragment">
      <AlloyButton
        key="export"
        type="primary"
        data-testid="download-button"
        onClick={() => {
          csvLinkRef.current?.link.click();
          toggleModal('');
        }}
        disabled={newCsvData.length === 0}
      >
        Download csv
      </AlloyButton>
      {type === 'FULL' && (
        <CSVLink
          key="items-quantity"
          filename={'items-quantity.csv'}
          headers={newItemHeaders}
          data={newCsvData}
          ref={csvLinkRef}
        />
      )}

      {type === 'INVALID' && (
        <CSVLink
          key="invalid-items-quantity"
          filename={'invalid-items-quantity.csv'}
          headers={newInvalidHeaders}
          data={newCsvData}
          ref={csvLinkRef}
        />
      )}
    </Fragment>
  ];

  return (
    <AlloyModal
      className={s.export_items_modal}
      data-testid="export-items-modal"
      title={
        <>
          {`${type === 'FULL' ? 'Export items' : 'Export invalid items'}`}
          <div className={s.controls}>{controls}</div>
        </>
      }
      open={visibility}
      onCancel={() => toggleModal('')}
      width={1500}
      footer={controls}
    >
      <ExportItemsTable
        showIndividualItems={showIndividualItems}
        setShowIndividualItems={setShowIndividualItems}
        type={type}
        newCsvData={newCsvData}
        exportLoading={exportLoading}
        exportError={exportError}
      />
    </AlloyModal>
  );
};

export default ExportItemsModal;
