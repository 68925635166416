import React, { useState, useContext, useEffect, useMemo } from 'react';
import s from './OrdersTable.module.scss';
import { DashOutlined, ExclamationCircleFilled, FieldTimeOutlined } from '@ant-design/icons';
import {
  PO_ERROR_MAP,
  PO_STATUS_NEW,
  PO_STATUS_EXPORTED,
  PO_STATUS_SCRUBBED,
  PO_STATUS_ACKNOWLEDGED,
  PO_STATUS_SUBMITTED,
  DeliveryType,
  MeasurementSystem
} from 'common/constants';
import { dateFormat } from 'common/helpers/date';
import { SortingType } from 'common/types';
import Copy from 'components/Common/Copy';
import { EditPoDestination } from '../BulkProcessSelect/components/EditPoDestination/EditPoDestination';
import PurchaseOrderStatus from 'components/PurchaseOrders/PurchaseOrderStatus/PurchaseOrderStatus';
import moment from 'moment';
import questionIcon from 'assets/icons/question_icon.svg';
import { useMutation, useSubscription } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { PoHistoryDrawer } from 'components/PurchaseOrders/PoHistoryDrawer';
import { UserContext } from 'context/userContext';
import { RevertPurchaseOrderDocument } from './gql/__generated__/revertPurchaseOrder.mutation';
import { UnsubmitPurchaseOrderDocument } from './gql/__generated__/unsubmitPurchaseOrder.mutation';
import { PurchaseOrder, PurchaseOrderEvent } from 'pages/OrdersPage/types';
import { notEmpty } from 'common/helpers/notEmpty';
import { PurchaseOrderSubscriptionDocument } from 'pages/OrdersPage/gql/__generated__/purchaseOrderSubscription.subscription';
import clsx from 'clsx';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloyTable, ColumnsType, SorterResult } from 'components/ui/AlloyTable/AlloyTable';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { App } from 'ant5';
import { AlloyAlert } from 'components/ui/AlloyAlert/AlloyAlert';

interface OrdersTableProps {
  getSortOrder: (columnKey: string) => SortingType | undefined;
  handleTableSorting: (columnKey: string, order: string) => void;
  selectedOrders: PurchaseOrder[];
  purchaseOrders: PurchaseOrder[];
  onSelectionAction: (selectedRows: PurchaseOrder[]) => void;
  loading: boolean;
}

interface PurchaseOrderIdCellProps {
  poId: string;
  record: PurchaseOrder;
}

export interface PurchaseOrderHistoryDrawerState {
  visible: boolean;
  poHistory: PurchaseOrderEvent[];
  poNumber: string;
}

const PurchaseOrderIdCell = ({ poId, record }: PurchaseOrderIdCellProps) => {
  useSubscription(PurchaseOrderSubscriptionDocument, {
    variables: { id: record?.id }
  });

  const routerLocation = useLocation();
  const poScrollTo = useMemo(() => routerLocation.state?.poId, [routerLocation]);

  useEffect(() => {
    if (record.id === poScrollTo) {
      const elementToScroll = document.getElementById(`po-id-${record.id}`);
      if (elementToScroll) {
        elementToScroll?.scrollIntoView({ block: 'center' });
      }
    }
  }, [poScrollTo, record.id]);

  return (
    <div data-testid="po-id-cell-container" className={s.po_id_cell_container}>
      <div data-testid={`po-id-${poId}`} id={`po-id-${record.id}`}>
        <Copy>{poId}</Copy>
      </div>
    </div>
  );
};

export const OrdersTable = ({
  purchaseOrders,
  getSortOrder,
  handleTableSorting,
  selectedOrders,
  onSelectionAction,
  loading
}: OrdersTableProps) => {
  const { message, modal: antModal } = App.useApp();
  const { user } = useContext(UserContext);
  const routerLocation = useLocation();
  const [poHistoryDrawerState, setPoHistoryDrawerState] = useState<PurchaseOrderHistoryDrawerState>(
    {
      visible: false,
      poHistory: [],
      poNumber: ''
    }
  );

  const togglePOHistoryDrawer = () => {
    setPoHistoryDrawerState({
      ...poHistoryDrawerState,
      visible: !poHistoryDrawerState.visible
    });
  };

  const [unsubmitPurchaseOrder] = useMutation(UnsubmitPurchaseOrderDocument, {
    onCompleted: (data) => {
      message.success(
        `PO #: ${data?.unsubmitPurchaseOrder?.purchaseOrder?.customerPo} has returned to earlier state`
      );
    },
    onError: (error) => {
      console.error(error.message);
      message.error(`Error on reverting state for PO: ${error.message}`);
    },
    refetchQueries: ['purchaseOrders', 'purchaseOrderCounts']
  });

  const [revertPurchaseOrder] = useMutation(RevertPurchaseOrderDocument, {
    onCompleted: (data) => {
      message.success(
        `PO #: ${data?.revertPurchaseOrder?.purchaseOrder?.customerPo} has returned to earlier state`
      );
    },
    onError: (error) => {
      console.error(error.message);
      message.error(`Error on reverting state for PO: ${error.message}`);
    },
    refetchQueries: ['purchaseOrders', 'purchaseOrderCounts']
  });

  const history = useHistory();

  const routeChange = (id: string) => {
    history.replace({
      ...routerLocation,
      state: { poId: id }
    });
    history.push({
      pathname: `/orders/${id}`,
      state: parse(history.location.search)
    });
  };

  const columns: ColumnsType<PurchaseOrder> = [
    {
      width: '24px',
      fixed: 'left' as const,
      render: (_, record) => {
        let errors: string[] = [];
        if (record.purchaseOrderErrors && record.purchaseOrderErrors.length) {
          errors = record.purchaseOrderErrors.map((error) => {
            if (PO_ERROR_MAP[error]) return PO_ERROR_MAP[error];
            else return error;
          });
        }

        const actionStatuses = ['FAILURE'];
        const confirmationAction =
          actionStatuses.includes(record.latestAcknowledgementStatus || '') ||
          actionStatuses.includes(record.latestDownConfirmationStatus || '');

        if (confirmationAction) {
          errors.push(PO_ERROR_MAP['FAILED_CONFIRMATION']);
        }

        if ((record?.missingOrderStatusSlaAudits?.length || 0) > 0)
          errors = (record.missingOrderStatusSlaAudits || []).filter(notEmpty).concat(errors);

        let warnings: string[] = [];
        if (record.purchaseOrderWarnings && record.purchaseOrderWarnings.length) {
          warnings = record.purchaseOrderWarnings.map((warning) => {
            if (PO_ERROR_MAP[warning]) return PO_ERROR_MAP[warning];
            else return warning;
          });
        }

        const errorsAndWarnings = [...errors.concat(warnings)];

        return errorsAndWarnings.length ? (
          <AlloyTooltip
            data-testid="errors-and-warnings-tooltip"
            title={errorsAndWarnings.map((message, i) => (
              <div data-testid="errors-and-warnings-message" key={i}>
                {message}
              </div>
            ))}
          >
            <span data-testid="errors-and-warnings-exclamation-span">
              <ExclamationCircleFilled
                data-testid="errors-and-warnings-exclamation-circle"
                className={s.exclamation_circle}
              />
            </span>
          </AlloyTooltip>
        ) : null;
      }
    },
    AlloyTable.SELECTION_COLUMN,
    {
      title: 'Purchase Order',
      dataIndex: 'customerPo',
      key: 'CUSTOMER_PO',
      fixed: 'left' as const,
      width: '160px',
      sortOrder: getSortOrder('CUSTOMER_PO'),
      sorter: true,
      className: s.po_id,
      render: (_, record) => <PurchaseOrderIdCell poId={record?.customerPo || ''} record={record} />
    },
    {
      title: 'Trading Partner',
      dataIndex: 'tradingPartnerCode',
      width: '200px',
      key: 'TRADING_PARTNER_CODE',
      sortOrder: getSortOrder('TRADING_PARTNER_CODE'),
      sorter: true,
      render: (_, record) => {
        return (
          <div
            data-testid={`po-tp-${record.customerPo}`}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {record.tradingPartnerCode}
            {((record?.statuses?.primary || '').toUpperCase() === PO_STATUS_SUBMITTED ||
              (record?.statuses?.primary || '').toUpperCase() === PO_STATUS_ACKNOWLEDGED ||
              (record?.statuses?.primary || '').toUpperCase() === PO_STATUS_SCRUBBED) &&
              record.tradingPartner?.deliveryType === DeliveryType.WHD && (
                <div
                  data-testid="ant-dropdown-link"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <AlloyDropdown
                    placement="bottomRight"
                    trigger={['click']}
                    menu={{
                      items: [
                        ...((record.statuses?.primary || '').toUpperCase() ===
                          PO_STATUS_SUBMITTED ||
                        (record.statuses?.primary || '').toUpperCase() === PO_STATUS_ACKNOWLEDGED
                          ? [
                              {
                                label: 'Revert state',
                                key: '0',
                                //@ts-ignore
                                'data-testid': 'po-revert-state',
                                onClick: () =>
                                  antModal.confirm({
                                    className: s.confirm_wrapper,
                                    title: 'Return to earlier state',
                                    content: (
                                      <>
                                        <AlloyAlert
                                          description={
                                            <>
                                              <b>Note: </b>
                                              <span>
                                                This order will need to be cancelled in SAP to avoid
                                                duplication.
                                              </span>
                                            </>
                                          }
                                          type="warning"
                                          showIcon
                                        />
                                        <AlloyTable
                                          style={{ marginTop: '12px' }}
                                          rowKey="id"
                                          columns={[
                                            { title: 'Purchase Order', dataIndex: 'customerPo' },
                                            {
                                              title: 'Trading Partner',
                                              dataIndex: 'tradingPartnerCode'
                                            }
                                          ]}
                                          dataSource={[
                                            {
                                              id: record.id,
                                              customerPo: record.customerPo,
                                              tradingPartnerCode: record.tradingPartnerCode
                                            }
                                          ]}
                                          pagination={false}
                                        />
                                      </>
                                    ),
                                    okText: 'Confirm',
                                    okType: 'danger',
                                    okButtonProps: {
                                      //@ts-ignore
                                      'data-testid': 'confirm-po-revert-state'
                                    },
                                    cancelText: 'Cancel',
                                    onOk: () => {
                                      unsubmitPurchaseOrder({
                                        variables: {
                                          input: {
                                            id: record.id
                                          }
                                        }
                                      });
                                    }
                                  })
                              }
                            ]
                          : []),
                        ...((record?.statuses?.primary || '').toUpperCase() === PO_STATUS_SCRUBBED
                          ? [
                              {
                                label: 'Revert state',
                                //@ts-ignore
                                'data-testid': 'po-revert-state',
                                key: '0',
                                onClick: () => {
                                  antModal.confirm({
                                    className: s.confirm_wrapper,
                                    title: 'Return to earlier state',
                                    content: (
                                      <>
                                        <AlloyAlert
                                          description={
                                            <>
                                              <b>Note: </b>
                                              <span>
                                                This order will need to be cancelled in SAP to avoid
                                                duplication.
                                              </span>
                                            </>
                                          }
                                          type="warning"
                                          showIcon
                                        />
                                        <AlloyTable
                                          style={{ marginTop: '12px' }}
                                          rowKey="id"
                                          columns={[
                                            { title: 'Purchase Order', dataIndex: 'customerPo' },
                                            {
                                              title: 'Trading Partner',
                                              dataIndex: 'tradingPartnerCode'
                                            }
                                          ]}
                                          dataSource={[
                                            {
                                              id: record.id,
                                              customerPo: record.customerPo,
                                              tradingPartnerCode: record.tradingPartnerCode
                                            }
                                          ]}
                                          pagination={false}
                                        />
                                      </>
                                    ),
                                    okText: 'Confirm',
                                    okType: 'danger',
                                    okButtonProps: {
                                      //@ts-ignore
                                      'data-testid': 'confirm-po-revert-state'
                                    },
                                    cancelText: 'Cancel',
                                    onOk: () => {
                                      revertPurchaseOrder({
                                        variables: {
                                          input: {
                                            id: record.id
                                          }
                                        }
                                      });
                                    }
                                  });
                                }
                              }
                            ]
                          : [])
                      ]
                    }}
                  >
                    <DashOutlined
                      data-testid={`po-state-dropdown-${record.customerPo}`}
                      rotate={90}
                      className={s.dash_icon}
                    />
                  </AlloyDropdown>
                </div>
              )}
          </div>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'statuses',
      key: 'STATUS',
      width: '230px',
      sortOrder: getSortOrder('STATUS'),
      sorter: true,
      render: (_, record) => {
        const actionStatuses = ['GENERATED', 'FAILURE'];
        const confirmationAction =
          actionStatuses.includes(record.latestAcknowledgementStatus || '') ||
          actionStatuses.includes(record.latestDownConfirmationStatus || '');
        return (
          <div className={s.status_content} data-testid={`po-status-${record.customerPo}`}>
            <a href={`/orders/${record.id}`}>
              <div className={s.right_click} />
            </a>
            <PurchaseOrderStatus
              status={record?.statuses?.primary || ''}
              secondaryStatuses={record?.statuses?.secondary}
              setPoHistoryDrawerState={setPoHistoryDrawerState}
              poHistoryDrawerState={poHistoryDrawerState}
              poId={record.id}
              poNumber={record.customerPo || ''}
            />
            {confirmationAction && (
              <AlloyTooltip title="Confirmation requires user action">
                <FieldTimeOutlined className={s.confirmation_flag} />
              </AlloyTooltip>
            )}
          </div>
        );
      }
    },
    {
      title: 'Customer',
      width: '140px',
      key: 'VENDOR_MARKET',
      sorter: true,
      sortOrder: getSortOrder('VENDOR_MARKET'),
      render: (_, record) => {
        return (
          <>
            <a href={`/orders/${record.id}`}>
              <div className={s.right_click}></div>
            </a>
            {record.tradingPartner?.vendorMarket?.name}
          </>
        );
      }
    },
    {
      title: 'Ship-To',
      key: 'RETAILER_ADDRESS',
      width: '300px',
      sortOrder: getSortOrder('RETAILER_ADDRESS'),
      render: (_, record) => {
        const twelveHoursAgo = moment().subtract(12, 'hours');
        const recentDeliveryDestinationUpdate = record.history.find(
          (item) =>
            item.action === 'UPDATE_DESTINATION' && moment(item.actionTime).isAfter(twelveHoursAgo)
        );

        return (
          <>
            <a href={`/orders/${record.id}`}>
              <div className={s.right_click}></div>
            </a>
            {record.tradingPartner?.deliveryType === 'WHD' &&
              [
                PO_STATUS_NEW,
                PO_STATUS_EXPORTED,
                PO_STATUS_SCRUBBED,
                PO_STATUS_ACKNOWLEDGED
              ].includes((record?.statuses?.primary || '').toUpperCase()) &&
              user?.availableActions.includes('EDIT_SHIP_TO') && (
                <div className={s.ship_to_dropdown}>
                  <EditPoDestination
                    bordered={false}
                    className={clsx(
                      s.po_table_edit_ship_to,
                      `${
                        !record.retailerDeliveryDestination
                          ? s.ship_to_not_found
                          : record.retailerDeliveryDestination &&
                            recentDeliveryDestinationUpdate &&
                            recentDeliveryDestinationUpdate?.user
                          ? s.ship_to_recently_updated
                          : record.retailerDeliveryDestination && recentDeliveryDestinationUpdate
                          ? s.ship_to_recently_updated_api
                          : ''
                      }`
                    )}
                    placeholder={
                      record.retailerDeliveryDestination
                        ? `${record.retailerDeliveryDestination.name} - ${record.retailerDeliveryDestination.externalId}`
                        : record.retailerDeliveryDestinationExternalId || 'Not Found'
                    }
                    purchaseOrders={{
                      id: record.id,
                      customerPo: record.customerPo || '',
                      deliveryType: record.tradingPartner.deliveryType,
                      primaryStatus: (record.statuses?.primary || '').toUpperCase()
                    }}
                  />

                  {record.retailerDeliveryDestination && (
                    <div data-testid={`select-editShipTo-address-${record.customerPo}`}>{`${
                      record.retailerDeliveryDestination.address || ''
                    } ${record.retailerDeliveryDestination.addressCity || ''} ${
                      record.retailerDeliveryDestination.addressState || ''
                    }`}</div>
                  )}
                </div>
              )}

            {record.retailerDeliveryDestination &&
              (![
                PO_STATUS_NEW,
                PO_STATUS_EXPORTED,
                PO_STATUS_SCRUBBED,
                PO_STATUS_ACKNOWLEDGED
              ].includes((record?.statuses?.primary || '').toUpperCase()) ||
                record.tradingPartner?.deliveryType !== 'WHD' ||
                !user?.availableActions.includes('EDIT_SHIP_TO')) && (
                <div>
                  <div>
                    {record.retailerDeliveryDestination.name} -{' '}
                    {record.retailerDeliveryDestination.externalId}
                  </div>
                  <div>{`${record.retailerDeliveryDestination.address || ''} ${
                    record.retailerDeliveryDestination.addressCity || ''
                  } ${record.retailerDeliveryDestination.addressState || ''}`}</div>
                </div>
              )}
          </>
        );
      },
      sorter: true
    },
    {
      title: 'Total Ordered Qty',
      align: 'center' as const,
      key: 'ORDER_QTY',
      width: '135px',
      render: (_, record) => {
        return (
          <div data-testid="order-qty">
            <a href={`/orders/${record.id}`}>
              <div className={s.right_click}></div>
            </a>
            {record.totalOrderedQuantity}
          </div>
        );
      }
    },
    {
      title: 'Ordered',
      width: '100px',
      align: 'center' as const,
      key: 'ORDER_DATE',
      dataIndex: 'orderDate',
      sortOrder: getSortOrder('ORDER_DATE'),
      sorter: true,
      render: (_, record) => {
        return (
          <>
            <a href={`/orders/${record.id}`}>
              <div className={s.right_click}></div>
            </a>
            {dateFormat(record.orderDate)}
          </>
        );
      }
    },
    {
      title: (
        <div>
          MABD
          <AlloyTooltip title="Must Arrive By Date" placement="top">
            <img alt="subtract icon" className={s.tooltip_question_icon} src={questionIcon} />
          </AlloyTooltip>
        </div>
      ),
      width: '118px',
      align: 'center' as const,
      key: 'DELIVERY_WINDOW_END',
      dataIndex: 'deliveryWindowEnd',
      sortOrder: getSortOrder('DELIVERY_WINDOW_END'),
      render: (_, record) => {
        return (
          <>
            <a href={`/orders/${record.id}`}>
              <div className={s.right_click}></div>
            </a>
            {dateFormat(record.deliveryWindowEnd) || 'TBD'}
          </>
        );
      },
      sorter: true
    },
    {
      title: 'BU',
      width: '90px',
      align: 'center' as const,
      key: 'BUSINESS_UNIT',
      sortOrder: getSortOrder('BUSINESS_UNIT'),
      sorter: true,
      render: (_, record) => {
        // TODO: tooltip is not displayed, but not sure it needs
        return (
          <>
            <a href={`/orders/${record.id}`}>
              <div className={s.right_click}></div>
            </a>
            <AlloyTooltip placement="left" title={record?.businessUnit?.name}>
              <div>{record?.businessUnit?.code}</div>
            </AlloyTooltip>
          </>
        );
      }
    },
    {
      title: 'Total Weight',
      key: 'TOTAL_WEIGHT',
      align: 'center' as const,
      width: '150px',
      render: (_, record) =>
        record.tradingPartner?.measurementSystem === MeasurementSystem.METRIC ? (
          <AlloyTooltip
            overlayStyle={{ whiteSpace: 'pre-line' }}
            title={`Total products weight ${Math.ceil(
              (record.totalWeightInLbs || 0) * 0.45359237
            )} kg
              Total pallets weight ${Math.ceil(
                (record.totalPalletsWeightInLbs || 0) * 0.45359237
              )} kg `}
          >
            {Math.ceil(
              (record.totalWeightInLbs || 0) * 0.45359237 +
                (record.totalPalletsWeightInLbs || 0) * 0.45359237
            )}{' '}
            kg
          </AlloyTooltip>
        ) : (
          <AlloyTooltip
            overlayStyle={{ whiteSpace: 'pre-line' }}
            title={`Total products weight ${Math.ceil(record.totalWeightInLbs || 0)} lbs
              Total pallets weight ${Math.ceil(record.totalPalletsWeightInLbs || 0)} lbs `}
          >
            {Math.ceil((record.totalWeightInLbs || 0) + (record.totalPalletsWeightInLbs || 0))} lbs
          </AlloyTooltip>
        )
    },
    {
      title: 'Total pallets',
      key: 'TOTAL_PALLETS',
      align: 'center' as const,
      width: '88px',
      render: (_, record) => {
        return (
          <>
            <a href={`/orders/${record.id}`}>
              <div className={s.right_click}></div>
            </a>
            {!!record.totalPallets ? record.totalPallets : '-'}
          </>
        );
      }
    },
    {
      title: 'Total shipments',
      key: 'TOTAL_SHIPMENTS',
      align: 'center' as const,
      width: '104px',
      render: (_, record) => {
        return (
          <>
            <a href={`/orders/${record.id}`}>
              <div className={s.right_click}></div>
            </a>
            {record.totalShipments}
          </>
        );
      }
    },
    {
      title: 'Updated',
      key: 'UPDATED_AT',
      className: s.history_column,
      sortOrder: getSortOrder('UPDATED_AT'),
      sorter: true,
      align: 'center' as const,
      width: '130px',
      render: (_, record) => {
        return record.lastUpdatedAt ? (
          <>{moment(record.lastUpdatedAt).startOf('minute').fromNow()}</>
        ) : (
          ''
        );
      }
    }
    // hiding notes column for now
    // {
    //   title: '',
    //   width: '55px',
    //   align: 'center' as const,
    //   className: 'note_cell',
    //   render: (_, { notes, id }) => (
    //     <div
    //       className="note_cell_content"
    //       data-testid="note_cell_content"
    //       onClick={(e) => e.stopPropagation()}
    //     >
    //       {notes ? (
    //         <Tooltip
    //           placement="leftBottom"
    //           overlayClassName="table_tooltip_body"
    //           title={
    //             <OrderNoteTooltip
    //               content={notes}
    //               orderId={id}
    //               updateOrder={updatePurchaseOrderNote}
    //             />
    //           }
    //         >
    //           <img width="55px" alt="note icon" src={noteIcon} />
    //         </Tooltip>
    //       ) : (
    //         <Tooltip
    //           placement="leftBottom"
    //           overlayClassName="table_tooltip_body"
    //           trigger={'click'}
    //           title={
    //             <OrderNoteTooltip
    //               content={notes}
    //               orderId={id}
    //               createNoteMode
    //               updateOrder={updatePurchaseOrderNote}
    //             />
    //           }
    //         >
    //           <img width="55px" alt="add note icon" src={addNoteIcon} />
    //         </Tooltip>
    //       )}
    //     </div>
    //   )
    // }
  ];

  return (
    <>
      <AlloyTable
        loading={loading}
        data-testid="orders_table"
        className={s.orders_table}
        scroll={{ x: '100%' }}
        sticky={{
          offsetScroll: 6
        }}
        bordered="outer-only"
        rowClassName={(po) => {
          const actionStatuses = ['FAILURE'];
          const confirmationAction =
            actionStatuses.includes(po.latestAcknowledgementStatus || '') ||
            actionStatuses.includes(po.latestDownConfirmationStatus || '');

          return clsx(s[`row_${(po.statuses?.primary || '')?.toLowerCase()}`], {
            [s.row_errored]:
              po.purchaseOrderErrors.length ||
              po.purchaseOrderWarnings.length ||
              confirmationAction ||
              (po?.missingOrderStatusSlaAudits || [])?.length > 0 ||
              (!po.totalShipments &&
                po.tradingPartner?.deliveryType === 'WHD' &&
                (po?.statuses?.primary || '').toUpperCase() === PO_STATUS_NEW),
            [s.highlited_row]: routerLocation.state && po.id === routerLocation.state.poId
          });
        }}
        dataSource={purchaseOrders}
        columns={columns}
        pagination={false}
        rowKey={(record) => record.id}
        rowSelection={{
          onChange: (_selectedRowKeys, selectedRows) => onSelectionAction(selectedRows),
          type: 'checkbox',
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedOrders.map((row) => row.id),
          // @ts-ignore
          getCheckboxProps(record) {
            return { 'data-testid': record.customerPo };
          }
        }}
        sortDirections={[
          'ascend' as SortingType,
          'descend' as SortingType,
          'ascend' as SortingType
        ]}
        onChange={(_, __, sorter: SorterResult<PurchaseOrder> | SorterResult<PurchaseOrder>[]) => {
          const columnKey =
            String((sorter as SorterResult<PurchaseOrder>).columnKey) || 'ORDER_DATE';
          const order = (sorter as SorterResult<PurchaseOrder>).order === 'ascend' ? 'ASC' : 'DESC';
          handleTableSorting(columnKey, order);
        }}
        onRow={({ id, customerPo }: PurchaseOrder) => {
          return {
            onClick: (e) => {
              e.preventDefault();
              if (!(e.target as HTMLElement).classList.contains('ant-table-selection-column')) {
                routeChange(id);
              }
            },
            'data-testid': `po-row-${customerPo}`
          };
        }}
        showSorterTooltip={false}
      />

      <PoHistoryDrawer {...poHistoryDrawerState} toggleDrawer={togglePOHistoryDrawer} />
    </>
  );
};
