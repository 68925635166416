import React from 'react';
import { ParsedQuery } from 'query-string';
import { useFeatureFlag } from 'common/useFeatureFlags/useFeatureFlags';
import LoaderSpinner from 'components/LoaderSpinner';
import OrdersPage from './OrdersPage';
import { OrdersPageWithNewSearch } from './OrdersPageWithNewSearch';
import { PO_PAGE_NEW_SEARCH_FF } from 'common/useFeatureFlags/featureFlags';

interface SortConfig {
  columnKey: string;
  order: string;
  searchText: string | null;
  limit: string | null;
}

export const getSortConfig = (location: ParsedQuery): SortConfig => {
  return Object.assign(
    {
      columnKey: 'ORDER_DATE',
      order: 'DESC',
      searchText: null,
      limit: null
    },
    location
  );
};

// This is a temporary wrapper so as not to make big changes to the Orders Page file
// Will be deleted along with the "schip_po_page_new_search" feature flag
export const OrdersPageWrapper = () => {
  const { enabled: isUseNewSearchFeatureFlag, loading: isUseNewSearchLoading } = useFeatureFlag({
    name: PO_PAGE_NEW_SEARCH_FF
  });

  if (isUseNewSearchLoading) return <LoaderSpinner />;

  return isUseNewSearchFeatureFlag ? <OrdersPageWithNewSearch /> : <OrdersPage />;
};
