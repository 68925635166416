import React, { useMemo } from 'react';
import { useState } from 'react';
import edit from 'assets/icons/password/edit.svg';
import remove from 'assets/icons/password/remove.svg';
import save from 'assets/icons/password/save.svg';
import cancel from 'assets/icons/password/cancel.svg';
import s from './PasswordField.module.scss';
import { Field, useForm } from 'react-final-form';
import clsx from 'clsx';
import { FieldValidator } from 'final-form';
import { ValidationMessage } from 'components/Common/fields/ValidationMessage/ValidationMessage';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';

type State = 'EDIT' | 'VIEW' | 'REMOVE';

const getHiddenValue = (value: string) =>
  value.length < 10 ? '*'.repeat(10) : `${'*'.repeat(6)}${value.substring(value.length - 4)}`;

export const PasswordField = ({
  title,
  fieldName,
  editFieldName,
  upserting,
  defaultValue,
  required,
  validate
}: {
  title: string;
  fieldName: string;
  editFieldName: string;
  upserting: boolean;
  defaultValue?: string;
  required?: boolean;
  validate?: FieldValidator<string | string[]>;
}) => {
  const [state, setState] = useState<State>('VIEW');

  const [tempValue, setTempValue] = useState<string>('');

  const form = useForm();

  const handleEdit = () => {
    setTempValue(form.getState().values[editFieldName]);
    setState('EDIT');
  };

  const handleCancel = () => {
    setTempValue('');
    setState('VIEW');
  };

  const handleRemove = async () => {
    form.mutators.setSecretValue(null, editFieldName);
    form.mutators.setSecretValue(null, fieldName);
    setState('VIEW');
  };

  const isTempValueValid = useMemo(() => {
    return !validate || !validate(tempValue, []);
  }, [validate, tempValue]);

  const handleSave = async () => {
    if (isTempValueValid) {
      form.mutators.setSecretValue(tempValue, editFieldName);
      form.mutators.setSecretValue(getHiddenValue(tempValue), fieldName);
      setState('VIEW');
    }
  };

  return (
    <div className={s.password_field}>
      {required && (
        <AlloyTooltip overlay="Required" className={s.required}>
          <span data-testid="final-form-input-required">* </span>
        </AlloyTooltip>
      )}
      <span className={s.title}>{title}</span>
      <div className={s.container}>
        {state === 'EDIT' && (
          <Field
            name={fieldName}
            validate={() => (state === 'EDIT' ? 'Needs to finish filling' : null)}
            style={{ height: 0, overflow: 'hidden' }}
          >
            {({ meta }) => {
              return (
                <div className={s.field_container}>
                  <AlloyInput
                    value={tempValue}
                    onChange={(event) => setTempValue(event.currentTarget.value)}
                    data-testid={`final-form-input-field`}
                    autoComplete="off"
                    autoFocus
                  />
                  {validate && (
                    <ValidationMessage
                      meta={
                        validate(tempValue, [])
                          ? { error: validate(tempValue, []), touched: true }
                          : meta
                      }
                    />
                  )}
                </div>
              );
            }}
          </Field>
        )}

        {state !== 'EDIT' && (
          <Field name={fieldName} validate={validate}>
            {({ input }) => {
              return (
                <AlloySpin spinning={upserting}>
                  <span
                    className={clsx(s.value, {
                      [s.default]: (!input.value || input.value.length === 0) && !!defaultValue
                    })}
                  >
                    {input.value || defaultValue || 'NONE'}
                  </span>
                  {validate && (
                    <ValidationMessage
                      meta={{
                        error: validate(input.value, form.getState().values),
                        touched: state !== 'REMOVE'
                      }}
                    />
                  )}
                </AlloySpin>
              );
            }}
          </Field>
        )}

        <div className={s.buttons}>
          {state === 'VIEW' && (
            <>
              <AlloyButton
                title="Edit"
                icon={<img src={edit} alt="" width={14} />}
                onClick={handleEdit}
                type="text"
                className={clsx(s.button, { [s.margin_bottom]: validate })}
              />
              <Field name={fieldName}>
                {({ input }) =>
                  input.value && (
                    <AlloyButton
                      title="Remove"
                      icon={<img src={remove} alt="" width={18} />}
                      onClick={() => setState('REMOVE')}
                      type="text"
                      className={clsx(s.button, { [s.margin_bottom]: validate })}
                    />
                  )
                }
              </Field>
            </>
          )}
          {state === 'EDIT' && (
            <>
              <AlloyButton
                title="Save"
                icon={<img src={save} alt="" width={14} />}
                onClick={handleSave}
                type="text"
                disabled={!isTempValueValid}
                className={clsx(s.button, { [s.margin_bottom]: validate })}
              />
              <AlloyButton
                title="Cancel"
                icon={<img src={cancel} alt="" width={12} />}
                onClick={handleCancel}
                type="text"
                className={clsx(s.button, { [s.margin_bottom]: validate })}
              />
            </>
          )}
          {state === 'REMOVE' && (
            <>
              <AlloyButton
                onClick={handleRemove}
                type="primary"
                danger
                className={clsx({ [s.margin_bottom]: validate })}
              >
                Delete password
              </AlloyButton>
              <AlloyButton onClick={handleCancel} className={clsx({ [s.margin_bottom]: validate })}>
                Cancel
              </AlloyButton>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
