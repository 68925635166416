import React from 'react';
import { useState } from 'react';
import { validateRequired } from 'components/Common/fields/Utils';
import { PasswordField } from 'components/PasswordField/PasswordField';
import { useForm } from 'react-final-form';
import s from './IntegrationsForm.module.scss';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { AlloySwitch } from 'components/ui/AlloySwitch/AlloySwitch';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';

interface IntegrationsFormProps {
  setUseConfig: (integrationName: string | undefined, value: boolean) => void;
  upserting: boolean;
}

const columns: ColumnsType<{
  title: string;
  name: string;
}> = [
  {
    title: 'Type',
    render: (_, record) => <span className={s.title_row}>{record.title}</span>
  },
  AlloyTable.EXPAND_COLUMN
];

export const IntegrationsForm = ({ setUseConfig, upserting }: IntegrationsFormProps) => {
  const form = useForm();

  const [integrations, setIntegration] = useState<{
    [name: string]: {
      description: string;
      show: boolean;
      children?: { [name: string]: { description: string; show: boolean } };
    };
  }>({
    sftpConfig: {
      description: 'SFTP Configuration',
      show: !!form.getState().values.useSftpConfig
    },
    s3Config: {
      description: 'S3 Configuration',
      show: !!form.getState().values.useS3Config
    }
  });

  const validateIfSelected = (integrationName: string, validator: any) => {
    return integrations[integrationName]?.show && !upserting ? validator : undefined;
  };

  const sftpConfigForm = (
    <AlloyRow gutter={8}>
      <AlloyCol span={24}>
        <AlloyFormField
          component="input"
          name="sftpConfigHost"
          fieldProps={{
            title: 'Host'
          }}
          required
          validate={validateIfSelected('sftpConfig', validateRequired)}
        />
        <AlloyFormField
          component="inputNumber"
          name="sftpConfigPort"
          fieldProps={{
            title: 'Port'
          }}
          required
          validate={validateIfSelected('sftpConfig', validateRequired)}
        />
        <AlloyFormField
          component="input"
          name="sftpConfigUser"
          fieldProps={{
            title: 'User'
          }}
          required
          validate={validateIfSelected('sftpConfig', validateRequired)}
        />
        <PasswordField
          title="Password"
          fieldName="sftpConfigPassword"
          editFieldName="sftpConfigEditPassword"
          upserting={upserting}
          required
          validate={validateIfSelected('sftpConfig', validateRequired)}
        />
        <AlloyFormField
          component="input"
          name="sftpConfigCustomizationPath"
          fieldProps={{ title: 'Customization Path' }}
        />
        <AlloyFormField
          component="input"
          name="sftpConfigCustomizationImagePath"
          fieldProps={{ title: 'Customization Image Path' }}
        />
        <AlloyFormField
          component="input"
          name="sftpConfigInventoryPath"
          fieldProps={{ title: 'Inventory Path' }}
        />
      </AlloyCol>
    </AlloyRow>
  );

  const s3ConfigForm = (
    <AlloyRow gutter={8}>
      <AlloyCol span={24}>
        <PasswordField
          title="AWS Key ID"
          data-testid="s3ConfigAwsKeyId"
          fieldName="s3ConfigAwsKeyId"
          editFieldName="s3ConfigEditAwsKeyId"
          upserting={upserting}
          required
          validate={validateIfSelected('s3Config', validateRequired)}
        />
        <PasswordField
          title="AWS Secret"
          data-testid="s3ConfigAwsSecret"
          fieldName="s3ConfigAwsSecret"
          editFieldName="s3ConfigEditAwsSecret"
          upserting={upserting}
          required
          validate={validateIfSelected('s3Config', validateRequired)}
        />
        <AlloyFormField
          component="input"
          name="s3ConfigS3BucketName"
          data-testid="s3ConfigS3BucketName"
          fieldProps={{
            title: 'S3 Bucket Name'
          }}
          required
          validate={validateIfSelected('s3Config', validateRequired)}
        />
        <AlloyFormField
          component="input"
          name="s3ConfigS3Region"
          data-testid="s3ConfigS3Region"
          fieldProps={{
            title: 'S3 Region'
          }}
          required
          validate={validateIfSelected('s3Config', validateRequired)}
        />
        <AlloyFormField
          component="input"
          name="s3ConfigCustomizationPath"
          data-testid="s3ConfigCustomizationPath"
          fieldProps={{
            title: 'Customization Path'
          }}
        />
        <AlloyFormField
          component="input"
          name="s3ConfigCustomizationImagePath"
          data-testid="s3ConfigCustomizationImagePath"
          fieldProps={{
            title: 'Customization Image Path'
          }}
        />
      </AlloyCol>
    </AlloyRow>
  );

  return (
    <>
      <AlloyTable
        showHeader={false}
        rowKey={(row) => row.name}
        columns={columns}
        dataSource={Object.keys(integrations).map((integration) => ({
          title: integrations[integration].description,
          name: integration
        }))}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => {
            switch (record.name) {
              case 'sftpConfig':
                return integrations['sftpConfig'].show ? sftpConfigForm : null;
              case 's3Config':
                return integrations['s3Config'].show ? s3ConfigForm : null;
              default:
                return null;
            }
          },
          expandedRowKeys: Object.keys(integrations).reduce<string[]>((result, integration) => {
            if (integrations[integration].show) result.push(integration);
            return result;
          }, []),
          expandIcon: ({ expanded, record }) => (
            <AlloySwitch
              checked={expanded}
              onChange={(checked) => {
                setIntegration({
                  ...integrations,
                  [record.name]: { ...integrations[record.name], show: checked }
                });
                setUseConfig(record.name, checked);
              }}
            />
          )
        }}
      />
    </>
  );
};
