import { ViewField } from 'components/Common/ViewMode/ViewField/ViewField';
import { ViewRow } from 'components/Common/ViewMode/ViewRow/ViewRow';
import React from 'react';
import s from './ViewRetailerChannel.module.scss';
import { VendorMarketFullData } from '../RetailerChannelViewDrawer';

export const ViewRetailerChannel = ({
  vendorMarket
}: {
  vendorMarket: VendorMarketFullData | null | undefined;
}) => (
  <div>
    <ViewRow
      title="General setting"
      bordered={false}
      content={
        <>
          <ViewField
            title="External ID"
            type="INPUT"
            value={vendorMarket?.externalId}
            data-testid="retailer-channel-view-external-id"
          />
          <ViewField
            title="Name"
            type="INPUT"
            value={vendorMarket?.name}
            data-testid="retailer-channel-view-name"
          />
          <ViewField
            title="Country Code"
            type="INPUT"
            value={vendorMarket?.countryCode}
            data-testid="retailer-channel-view-country-code"
          />
          <ViewField
            title="Currency Code"
            type="INPUT"
            value={vendorMarket?.currencyCode}
            data-testid="retailer-channel-view-currency-code"
          />
          <ViewField
            title="Retailer"
            type="INPUT"
            value={vendorMarket?.vendor?.name}
            data-testid="retailer-channel-view-retailer"
          />
          <ViewField
            title="Parent Retailer Channel"
            type="INPUT"
            value={vendorMarket?.parentMarket?.name}
            data-testid="retailer-channel-view-parent-retailer-channel"
          />
        </>
      }
    />
  </div>
);
