import { Moment } from 'moment';

const EMAIL_MATCHER =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const composeValidators =
  (validators: any[]) => (value: string | number | bigint | readonly any[]) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

export const composeDateValidators = (validators: any[]) => (value: Moment | null | undefined) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const validateRequired = (value: string | readonly any[] | number | bigint) =>
  typeof value === 'boolean' ||
  (value !== undefined &&
    value !== null &&
    (((typeof value === 'number' || typeof value === 'bigint') && String(value).length > 0) ||
      (typeof value !== 'number' && typeof value !== 'bigint' && value.length > 0)))
    ? null
    : 'Should not be empty';

export const validateRequiredDate = (value: string | Moment) =>
  (typeof value === 'string' && value.length > 0) || value ? null : 'Should not be empty';

export const validateEmails = (values: string | string[]) =>
  (typeof values === 'string' ? [values] : values).find(
    (value) => !value.toLowerCase().match(EMAIL_MATCHER)
  )
    ? 'Should be a list of valid emails'
    : null;

export const validateEmail = (value: string) =>
  !value.toLowerCase().match(EMAIL_MATCHER) ? 'Should be a valid email' : null;

export const getMaxLengthValidation = (maxLength: number) => (value: string | string[]) =>
  !value || value.length <= maxLength ? null : `Should not be more than ${maxLength} characters`;

export const getLengthValidation = (length: number) => (value: string) =>
  !value || value.length === length ? null : `Should be ${length} characters`;

export const getMatchRegExpValidation = (regExp: string, message?: string) => (value: string) =>
  value && String(value).match(regExp) ? null : message || 'Not valid field';

export const parseIntValue = (value: string | undefined) =>
  value ? value.replaceAll(/[^0-9]/g, '') : '';

export const getTestRegexpValidationWithCustomMessage =
  (regExp: RegExp, message = 'Invalid value') =>
  (value: string) =>
    !value || regExp.test(value) ? null : message;

export const getCoordinatesValidation = (regExp: RegExp) => (value: string) =>
  !value || regExp.test(value) ? null : 'Invalid value';

//TODO: add validation for shipping SLA for TPs? what kind of validation, if it picks from options list?
