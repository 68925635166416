import { Button, Select, Spin } from 'antd';
import DatePicker from 'components/DatePicker/DatePicker';
import React, { useMemo } from 'react';
import selectIcon from 'assets/icons/select_icon.svg';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { cloneDeep } from 'lodash-es';
import {
  DateRange,
  Filter,
  FilterData,
  FilterName,
  NewFilterName,
  NumberRange,
  ShipToFilterName
} from 'common/interfaces';
import s from './AdvancedFilterComponent.module.scss';
import { AlloyInputNumber } from 'components/ui/AlloyInputNumber/AlloyInputNumber';
import { useWindowSize } from '@reactuses/core';

const { Option } = Select;
const { RangePicker } = DatePicker;

interface FilterBuilderProps<T extends FilterName | NewFilterName | ShipToFilterName> {
  filters: Filter<T>[];
  setFilters: (filters: Filter<T>[], manualReload?: Filter<T>[]) => void;
  supportingFilters: FilterData<T>[];
  onFocus: (fieldName: T, currentFilters: Filter<T>[]) => void;
  loading: string[];
  searchFieldName?: string;
}

export const FilterBuilder = <T extends FilterName | NewFilterName | ShipToFilterName>({
  filters,
  setFilters,
  supportingFilters,
  onFocus,
  loading,
  searchFieldName
}: FilterBuilderProps<T>) => {
  const { height } = useWindowSize();
  const dropdownSize = useMemo(
    () => Math.min(supportingFilters.length * 32, height),
    [height, supportingFilters.length]
  );

  //check for presence of new filter here by console logging both filter and supportingFilter
  const getFilterItem = (filter: Filter<T>, filterIndex: number) => {
    const supportingFilter = supportingFilters.find(
      (supportingFilter) => supportingFilter.name === filter.name
    );

    return (
      <div
        key={filterIndex}
        className={s.filter_builder_item}
        data-testid={`advanced-filters-filter-name-${filterIndex}`}
      >
        <div>
          <Select<T>
            optionFilterProp="children"
            data-testid="advanced-filters-filter-name"
            placeholder="Select"
            allowClear
            listHeight={dropdownSize}
            suffixIcon={<img alt="" width="18px" src={selectIcon} />}
            style={{ minWidth: '130px' }}
            dropdownMatchSelectWidth={false}
            onClear={() => {
              const newFilters = cloneDeep(filters);
              newFilters[filterIndex].name = undefined;
              newFilters[filterIndex].option = undefined;
              newFilters[filterIndex].value = undefined;
              setFilters(newFilters);
            }}
            value={filter.name}
            onChange={(value) => {
              const newFilters = cloneDeep(filters);
              newFilters[filterIndex].name = value as any;
              newFilters[filterIndex].option = undefined;
              newFilters[filterIndex].value = undefined;
              onFocus(value as any, filters);
              setFilters(newFilters);
            }}
          >
            {supportingFilters.map((supportingFilter) => (
              <Option key={supportingFilter.name} value={supportingFilter.name}>
                {supportingFilter.title}
              </Option>
            ))}
          </Select>
          {supportingFilter && supportingFilter.additionalOptions && (
            <Select
              optionFilterProp="children"
              data-testid="advanced-filters-filter-option"
              placeholder="Option"
              allowClear
              dropdownMatchSelectWidth={false}
              suffixIcon={<img alt="" width="18px" src={selectIcon} />}
              style={{ minWidth: '100px' }}
              onClear={() => {
                const newFilters = cloneDeep(filters);
                newFilters[filterIndex].option = undefined;
                setFilters(newFilters);
              }}
              value={filter.option}
              onChange={(value: string) => {
                const newFilters = cloneDeep(filters);
                newFilters[filterIndex].option = value;
                setFilters(newFilters);
              }}
            >
              {supportingFilter.additionalOptions.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          )}
          {supportingFilter && supportingFilter.type === 'string' && (
            <Select
              optionFilterProp="children"
              data-testid="advanced-filters-filter-value"
              loading={supportingFilter.lazyLoading && loading.includes(supportingFilter.name)}
              placeholder={supportingFilter.title}
              allowClear
              dropdownMatchSelectWidth={false}
              maxTagCount={2}
              style={{ minWidth: '150px' }}
              mode={supportingFilter.multiple ? 'multiple' : undefined}
              suffixIcon={
                loading.includes(supportingFilter.name) ? undefined : (
                  <img alt="" width="18px" src={selectIcon} />
                )
              }
              notFoundContent={
                loading.includes(supportingFilter.name) ? <Spin size="small" /> : null
              }
              onFocus={() => {
                if (!supportingFilter.values?.length && supportingFilter.lazyLoading) {
                  setFilters(filters, filters);
                }
              }}
              onClear={() => {
                const newFilters = cloneDeep(filters);
                newFilters[filterIndex].value = undefined;
                setFilters(newFilters);
              }}
              value={
                loading.includes(supportingFilter.name) && supportingFilter.lazyLoading
                  ? []
                  : typeof filter.value === 'string'
                  ? [filter.value]
                  : (filter.value as string[])
              }
              filterSort={(optionA: any, optionB: any) =>
                optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())
              }
              onChange={(value: string | string[]) => {
                const newFilters = cloneDeep(filters);
                newFilters[filterIndex].value = value;
                setFilters(newFilters);
              }}
            >
              {supportingFilter.values?.map((value) => (
                <Option key={`${value.name}-${value.id}`} value={value.name || ''}>
                  {value.title}
                </Option>
              ))}
            </Select>
          )}
          {supportingFilter && supportingFilter.type === 'dateRange' && (
            <RangePicker
              allowClear
              style={{ height: '32px' }}
              value={
                filter.value
                  ? [
                      moment((filter.value as DateRange).start),
                      moment((filter.value as DateRange).end)
                    ]
                  : null
              }
              placeholder={[`${supportingFilter.title} from`, 'to']}
              onChange={(_, dateStrings: string[]) => {
                const newFilters = cloneDeep(filters);
                newFilters[filterIndex].value =
                  dateStrings[0].length > 0 && dateStrings[1].length > 0
                    ? { start: dateStrings[0], end: dateStrings[1] }
                    : undefined;
                setFilters(newFilters);
              }}
            />
          )}
          {supportingFilter && supportingFilter.type === 'numberRange' && (
            <div className={s.number_range_input}>
              <AlloyInputNumber
                min={0}
                precision={0}
                data-testid="advanced-filters-range-min"
                placeholder="Min"
                value={(filter.value as NumberRange)?.min}
                onChange={(value) => {
                  const newFilters = cloneDeep(filters);
                  const filterValue = newFilters[filterIndex].value as NumberRange | null;

                  if (filterValue) {
                    filterValue.min = value;
                    // Update max to be not smaller than min
                    if (filterValue.max !== null && value !== null) {
                      filterValue.max = Math.max(filterValue.max, value);
                    }
                  } else {
                    newFilters[filterIndex].value = { max: null, min: value };
                  }

                  setFilters(newFilters);
                }}
              />
              <AlloyInputNumber
                // Don't allow max to be smaller than min
                min={0}
                precision={0}
                placeholder="Max"
                data-testid="advanced-filters-range-max"
                value={(filter.value as NumberRange)?.max}
                onChange={(value) => {
                  const newFilters = cloneDeep(filters);
                  const filterValue = newFilters[filterIndex].value as NumberRange | null;

                  if (filterValue) {
                    filterValue.max = value;
                    if (filterValue.min !== null && value !== null) {
                      filterValue.min = Math.min(filterValue.min, value);
                    }
                  } else {
                    newFilters[filterIndex].value = { max: value, min: null };
                  }

                  setFilters(newFilters);
                }}
              />
            </div>
          )}
        </div>
        <div>
          <Button
            data-testid="advanced-filters-remove-filter-button"
            disabled={filters.filter((filter) => filter.name !== searchFieldName).length <= 1}
            icon={<MinusOutlined />}
            onClick={() => setFilters(filters.filter((value, index) => index !== filterIndex))}
          />
          <Button
            data-testid="advanced-filters-add-filter-button"
            icon={<PlusOutlined />}
            onClick={() => {
              const newFilters = cloneDeep(filters);
              newFilters.splice(filterIndex + 1, 0, { name: undefined, value: undefined });
              setFilters(newFilters);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={s.filter_builder}>
      {filters.map((filter, index) =>
        !searchFieldName || filter.name !== searchFieldName ? getFilterItem(filter, index) : null
      )}
    </div>
  );
};
