import { useQuery } from '@apollo/client';
import {
  AlloyTable,
  ColumnType,
  ColumnsType,
  SorterResult
} from 'components/ui/AlloyTable/AlloyTable';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyDropdown } from 'components/ui/AlloyDropdown/AlloyDropdown';
import { Paginator } from 'components/Paginator/Paginator';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './DistributionCentersPage.module.scss';
import { HighlightedText } from 'components/Common/HighlightedText/HighlightedText';
import { SearchField } from 'components/Common/SearchField/SearchField';
import { DEFAULT_PAGE_SIZE, EMPTY } from 'common/constants';
import {
  DistributionCentersDocument,
  DistributionCentersQuery
} from './gql/__generated__/distributionCenters.query';
import { InferNodeType } from 'common/helpers/mappingHelper';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { ChangeHistory } from 'components/ChangeHistory/ChangeHistory';
import { BulkUpsertDistributionCentersModal } from './components/BulkUpsertDistributionCentersModal/BulkUpsertDistributionCentersModal';
import { DownOutlined } from '@ant-design/icons';
import { EditDistributionCenterModal } from './components/EditDistributionCenterModal/EditDistributionCenterModal';
import {
  DistributionCenterFullData,
  DistributionCenterViewDrawer
} from './components/DistributionCenterViewDrawer/DistributionCenterViewDrawer';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { DistributionCenterSortColumn, SortOrderDirection } from 'graphql/__generated__/types';
import { getColumnSortOrder } from 'common/helpers/sorting';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import { UpdatedAt } from 'components/ui/UpdatedAt/UpdatedAt';
import { useFeatureFlag } from 'common/useFeatureFlags/useFeatureFlags';
import { DC_FACILITY_MANAGEMENT_FF } from 'common/useFeatureFlags/featureFlags';

export type DistributionCenter = InferNodeType<DistributionCentersQuery, 'distributionCenters'>;

export const DistributionCentersPage = () => {
  const { enabled: isDCFacilityManagementEnabled } = useFeatureFlag({
    name: DC_FACILITY_MANAGEMENT_FF
  });

  const [distributionCenterId, setDistributionCenterId] = useQueryParam('dc', StringParam);
  const [pageSize, setPageSize] = useQueryParam('limit', NumberParam);
  const [sortColumn, setSortColumn] = useQueryParam('column', StringParam);
  const [sortOrder, setSortOrder] = useQueryParam('order', StringParam);
  const [search, setSearch] = useQueryParam('search', StringParam);
  const [after, setAfter] = useQueryParam('after', StringParam);
  const [before, setBefore] = useQueryParam('before', StringParam);

  const [showBulkUpsertModal, setShowBulkUpsertModal] = useState(false);

  const [editingDistributionCenter, setEditingDistributionCenter] =
    useState<DistributionCenterFullData>();
  const [viewHistoryDistributionCenter, setViewHistoryDistributionCenter] = useState<
    DistributionCenter | undefined
  >();

  const { data, loading, error } = useQuery(DistributionCentersDocument, {
    variables: {
      first: after || !before ? pageSize : null,
      last: before ? pageSize : null,
      sort: {
        column: sortColumn as DistributionCenterSortColumn,
        direction: sortOrder as SortOrderDirection
      },
      after,
      before,
      searchLike: search
    },

    skip: !pageSize || !sortColumn || !sortOrder
  });

  const distributionCentersList = useMemo(
    () => getNodesFromEdges(data?.distributionCenters),
    [data]
  );

  useEffect(() => {
    if (!pageSize || !sortColumn || !sortOrder) {
      setPageSize(DEFAULT_PAGE_SIZE);
      setSortColumn('UPDATED_AT');
      setSortOrder('DESC');
    }
  }, [pageSize, setPageSize, setSortColumn, setSortOrder, sortColumn, sortOrder]);

  useEffect(() => {
    if (distributionCenterId) {
      setShowBulkUpsertModal(false);
      setViewHistoryDistributionCenter(undefined);
    }
  }, [distributionCenterId]);

  const handleSearch = useCallback(
    (value: string) => {
      if (search !== (value || undefined)) {
        setSearch(value || undefined);
        setAfter(undefined);
        setBefore(undefined);
      }
    },
    [search, setAfter, setBefore, setSearch]
  );

  const columns: ColumnsType<DistributionCenter> = useMemo(
    () => [
      {
        title: 'Code',
        key: 'CODE',
        width: 210,
        render: (_, record) => <HighlightedText text={record.code} highlight={search ?? ''} />,
        // temporarily continue to show code
        // render: (_, record) => <ListOfItemsColumn items={record.codes.map(code => code.code)} splitToLines />,
        sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'CODE'),
        sorter: true
      },
      {
        title: 'Name',
        key: 'NAME',
        width: 200,
        render: (_, record) => <HighlightedText text={record.name} highlight={search ?? ''} />,
        sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'NAME'),
        sorter: true
      },
      {
        title: 'Address',
        render: (_, record) => `${record.address || ''} ${record.city || ''} ${record.state || ''}`
      },
      {
        title: 'Last updated',
        key: 'UPDATED_AT',
        width: 160,
        render: (_, record) => (
          <UpdatedAt
            onClick={(e) => {
              setViewHistoryDistributionCenter(record);
              e.stopPropagation();
            }}
            updatedAt={record.updatedAt}
          />
        ),
        sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'UPDATED_AT'),
        sorter: true
      }
    ],
    [search, sortColumn, sortOrder]
  );

  const updatedColumns = useMemo(() => {
    if (isDCFacilityManagementEnabled) {
      const sourceColumn: ColumnType<DistributionCenter> = {
        title: 'Source',
        key: 'SOURCE',
        width: 200,
        render: (_, record) => record.source || EMPTY,
        sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'SOURCE'),
        sorter: true
      };

      const facilityColumn: ColumnType<DistributionCenter> = {
        title: 'Facility',
        key: 'FACILITY_NAME',
        width: 200,
        render: (_, record) => record.distributionCenterFacility?.name || EMPTY,
        sortOrder: getColumnSortOrder(sortColumn, sortOrder, 'FACILITY_NAME'),
        sorter: true
      };

      const [firstColumn, secondColumn, ...restColumns] = columns;

      return [firstColumn, sourceColumn, secondColumn, facilityColumn, ...restColumns];
    }

    return columns;
  }, [columns, isDCFacilityManagementEnabled, sortColumn, sortOrder]);

  if (error) return <p>Error</p>;

  const nextPage = async () => {
    setAfter(data?.distributionCenters?.pageInfo?.endCursor);
    setBefore(undefined);
  };

  const prevPage = async () => {
    setAfter(undefined);
    setBefore(data?.distributionCenters?.pageInfo?.startCursor);
  };

  const handlePageSizeChange = async (value: number) => {
    setPageSize(value);
    setAfter(undefined);
    setBefore(undefined);
  };

  const handleTableSorting = (column: string, order: string) => {
    setSortColumn(column);
    setSortOrder(order);
    setAfter(undefined);
    setBefore(undefined);
  };

  const addMenu = [
    {
      label: 'Single add',
      key: '0',
      onClick: () => {
        setEditingDistributionCenter({} as DistributionCenterFullData);
      },
      'data-testid': 'add-single-dc'
    },
    {
      label: 'Bulk add (CSV)',
      key: '1',
      onClick: () => {
        setShowBulkUpsertModal(true);
      },
      'data-testid': 'bulk-add-dc'
    }
  ];

  return (
    <div className={s.page}>
      <div className={s.header}>
        <PageHeader className={s.title}>Distribution Centers</PageHeader>
        <div>
          <AlloyDropdown menu={{ items: addMenu }} trigger={['click']} placement="bottomLeft">
            <AlloyButton
              type="primary"
              className={s.add_new_button}
              data-testid="add-dc-to-dropdown"
            >
              Add new Distribution Center
              <DownOutlined />
            </AlloyButton>
          </AlloyDropdown>
        </div>
      </div>
      <SearchField handleSearch={handleSearch} loading={loading} value={search || ''} />
      <div className={s.pagination_container}>
        <QuantityDisplay
          count={data?.distributionCenters?.totalCount}
          titleSingular="Distribution Center"
          titleMultiple="Distribution Centers"
        />
        <Paginator
          hasNextPage={!!data?.distributionCenters?.pageInfo?.hasNextPage}
          hasPreviousPage={!!data?.distributionCenters?.pageInfo?.hasPreviousPage}
          nextPage={nextPage}
          prevPage={prevPage}
          pageSize={pageSize || DEFAULT_PAGE_SIZE}
          handlePageSizeChange={handlePageSizeChange}
          onlyButtons={false}
        />
      </div>
      <AlloyTable
        loading={loading}
        sticky
        tableLayout="auto"
        className={s.table}
        columns={updatedColumns}
        dataSource={distributionCentersList}
        rowKey={(record) => record.id}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () => {
              setDistributionCenterId(record.id);
            }
          };
        }}
        onChange={(_pagination, _filters, sorter) => {
          const column = String((sorter as SorterResult<DistributionCenter>).columnKey) || 'NAME';
          const order =
            (sorter as SorterResult<DistributionCenter>).order === 'ascend' ||
            !(sorter as SorterResult<DistributionCenter>).order
              ? 'ASC'
              : 'DESC';
          handleTableSorting(column, order);
        }}
      />
      <div className={s.pagination_container_end}>
        <Paginator
          hasNextPage={!!data?.distributionCenters?.pageInfo?.hasNextPage}
          hasPreviousPage={!!data?.distributionCenters?.pageInfo?.hasPreviousPage}
          nextPage={nextPage}
          prevPage={prevPage}
          pageSize={pageSize || DEFAULT_PAGE_SIZE}
          handlePageSizeChange={handlePageSizeChange}
          onlyButtons={true}
        />
      </div>
      <EditDistributionCenterModal
        onClose={() => {
          setEditingDistributionCenter(undefined);
          setDistributionCenterId(undefined);
        }}
        distributionCenter={editingDistributionCenter}
        isDCFacilityManagementEnabled={isDCFacilityManagementEnabled}
      />
      <DistributionCenterViewDrawer
        onClose={() => setDistributionCenterId(undefined)}
        distributionCenterId={
          editingDistributionCenter || !distributionCenterId ? undefined : distributionCenterId
        }
        startEditing={(distributionCenter) => {
          setEditingDistributionCenter({ ...distributionCenter });
        }}
        isDCFacilityManagementEnabled={isDCFacilityManagementEnabled}
      />
      <ChangeHistory
        entity={
          viewHistoryDistributionCenter
            ? {
                id: viewHistoryDistributionCenter.id || '',
                name: viewHistoryDistributionCenter.name || '',
                type: 'Distribution Center'
              }
            : undefined
        }
        onClose={() => setViewHistoryDistributionCenter(undefined)}
      />
      <BulkUpsertDistributionCentersModal
        visible={showBulkUpsertModal}
        onClose={() => {
          setShowBulkUpsertModal(false);
        }}
      />
    </div>
  );
};
