import { useWindowSize } from '@reactuses/core';
import { setItem } from 'common/services/persistentStorageServices';
import { AlloyTour } from 'components/ui/AlloyTour/AlloyTour';
import { UserContext } from 'context/userContext';
import React, { useContext, useMemo } from 'react';

export const NEW_PO_SEARCH_TOUR_LOCAL_STORAGE_KEY = 'poPageNewSearchTourWasShown';

export const SearchTour = ({
  searchFieldRef,
  open,
  setClosedByUser
}: {
  searchFieldRef: React.RefObject<HTMLDivElement>;
  open: boolean;
  setClosedByUser: (closed: boolean) => void;
}) => {
  const { width } = useWindowSize();
  const placement = useMemo(() => (width < 1130 ? 'bottom' : 'right'), [width]);

  const { user } = useContext(UserContext);

  const onCloseTour = () => {
    setClosedByUser(true);
    setItem(`${NEW_PO_SEARCH_TOUR_LOCAL_STORAGE_KEY}[${user?.name}]`, true);
  };

  return (
    <>
      <AlloyTour
        open={open}
        onClose={onCloseTour}
        placement={placement}
        steps={[
          {
            title: (
              <span style={{ textAlign: 'center' }}>
                New in the Search!
                <span style={{ fontSize: '18px', marginLeft: '8px' }}>&#127881;</span>
              </span>
            ),
            description:
              'You can now search by one or more PO Number, SAP order number, or by product UPC, GTIN, Customer Product ID (ASIN, Doordash ID, etc.)',
            target: () => searchFieldRef.current as HTMLDivElement,
            nextButtonProps: {
              style: { height: '32px', paddingLeft: '16px', paddingRight: '16px', fontWeight: 600 }
            },
            placement: placement
          }
        ]}
      />
    </>
  );
};
