import React from 'react';
import { ViewField } from 'components/Common/ViewMode/ViewField/ViewField';
import { ViewRow } from 'components/Common/ViewMode/ViewRow/ViewRow';
import s from './IntegrationsViewTab.module.scss';
import { DistributionCenterFullData } from '../../DistributionCenterViewDrawer';
import { DisabledIntegration } from 'components/Common/ViewMode/DisabledIntegration/DisabledIntegration';

export const IntegrationsViewTab = ({
  distributionCenter
}: {
  distributionCenter: DistributionCenterFullData;
}) => {
  const sftpConfig = (
    <>
      <div className={s.row}>
        <ViewField type="INPUT" title="Host" value={distributionCenter?.sftpConfig?.host} />
        <ViewField type="INPUT" title="Port" value={distributionCenter?.sftpConfig?.port} />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="User" value={distributionCenter?.sftpConfig?.user} />
        <ViewField type="INPUT" title="Password" value={distributionCenter?.sftpConfig?.password} />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Customization Path"
          value={distributionCenter?.sftpConfig?.customizationPath}
        />
        <ViewField
          type="INPUT"
          title="Customization Image Path"
          value={distributionCenter?.sftpConfig?.customizationImagePath}
        />
      </div>
      <ViewField
        type="INPUT"
        title="Inventory Path"
        value={distributionCenter?.sftpConfig?.inventoryPath}
      />
    </>
  );

  const s3Config = (
    <>
      <div className={s.row}>
        <ViewField type="INPUT" title="AWS Key ID" value={distributionCenter?.s3Config?.awsId} />
        <ViewField
          type="INPUT"
          title="AWS Secret"
          value={distributionCenter?.s3Config?.awsSecret}
        />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="S3 Bucket Name"
          value={distributionCenter?.s3Config?.s3Bucket}
        />
        <ViewField type="INPUT" title="S3 Region" value={distributionCenter?.s3Config?.region} />
      </div>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Customization Path"
          value={distributionCenter?.s3Config?.customizationPath}
        />
        <ViewField
          type="INPUT"
          title="Customization Image Path"
          value={distributionCenter?.s3Config?.customizationImagePath}
        />
      </div>
    </>
  );

  const integrations = [
    {
      title: 'SFTP Configuration',
      show: !!distributionCenter?.sftpConfig,
      content: sftpConfig
    },
    {
      title: 'S3 Configuration',
      show: !!distributionCenter?.s3Config,
      content: s3Config
    }
  ];

  return (
    <>
      {integrations
        .sort((int1, int2) => (int1.show === int2.show ? 0 : int1.show ? -1 : 1))
        .map((integration, number) => (
          <ViewRow
            key={integration.title}
            title={integration.title}
            content={integration.show ? integration.content : <DisabledIntegration />}
            bordered={number !== integrations.length - 1 || !integration.show}
          />
        ))}
    </>
  );
};
