import React, { useMemo } from 'react';
import s from './CutsTable.module.scss';
import { EMPTY } from 'common/constants';
import { CutOrdersFilters, CutOrdersSortColumn } from 'graphql/__generated__/types';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { JsonParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import { CutOrdersDocument, CutOrdersQuery } from './gql/__generated__/cutOrders.query';
import { useQuery } from '@apollo/client';
import { Paginator } from 'components/Paginator/Paginator';
import {
  usePaginationFilters,
  usePaginatorProps,
  usePaginatorStateInQueryParams
} from 'components/Paginator/usePaginator';
import { QuantityDisplay } from 'components/ui/QuantityDisplay/QuantityDisplay';
import { SortingType } from 'common/types';
import { useDeepCompareEffect } from 'use-deep-compare';
import {
  CUT_CATEGORIES,
  CUTS_BY_REASON_CATEGORY,
  DEFAULT_CATEGORY_TAB
} from 'pages/OnTimeInFull/types';
import { capitalize } from 'lodash-es';

type CutOrder = InferNodeType<CutOrdersQuery, 'cutOrders'>;

const getRowKey = (item: CutOrder) => `${item.upc}-${item.productName}-${item.cutReason}`;

const formatPercentage = (value: any) => {
  const parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue)) return EMPTY;
  return `${(parsedValue * 100).toFixed(2)}%`;
};

const CATEGORIES = CUT_CATEGORIES.map((x) => ({ value: x, label: capitalize(x) }));

export const CutsTable = ({ filters }: { filters: CutOrdersFilters }) => {
  const { after, setAfter, before, setBefore, pageLimit, setPageLimit } =
    usePaginatorStateInQueryParams();
  const [sort, setSort] = useQueryParam(
    'sort',
    withDefault(JsonParam, { columnKey: 'CUT_UNITS', direction: 'DESC' })
  );

  const getSortOrder = (columnKey: CutOrdersSortColumn) =>
    sort.columnKey === columnKey
      ? ((sort.order === 'ASC' ? 'ascend' : 'descend') as SortingType)
      : undefined;

  const columns: ColumnsType<CutOrder> = [
    {
      key: 'UPC',
      title: 'UPC',
      render: (_, { upc }) => <span className={s.number}>{upc}</span>,
      sortOrder: getSortOrder('UPC'),
      sorter: true,
      width: '160px'
    },
    {
      title: 'Product Name',
      dataIndex: 'productName'
    },
    {
      key: 'CUT_REASON',
      title: 'Cut Reason',
      dataIndex: 'cutReason',
      sortOrder: getSortOrder('CUT_REASON'),
      sorter: true,
      width: '240px'
    },
    {
      key: 'CUT_UNITS',
      title: 'Cut Units',
      align: 'right',
      render: (_, { cutUnits }) => <span className={s.number}>{cutUnits}</span>,
      sortOrder: getSortOrder('CUT_UNITS'),
      sorter: true,
      width: '120px'
    },
    {
      title: 'Percentage',
      align: 'right',
      render: (_, { percentage }) => (
        <span className={s.number}>{formatPercentage(percentage)}</span>
      ),
      width: '80px'
    }
  ];

  const paginationFilters = usePaginationFilters({ before, after, pageLimit });

  const [category, setCategory] = useQueryParam(
    CUTS_BY_REASON_CATEGORY,
    withDefault(StringParam, DEFAULT_CATEGORY_TAB)
  );

  const { loading, data } = useQuery(CutOrdersDocument, {
    variables: {
      filters: {
        fiscalCalendarWeeks: filters.fiscalCalendarWeeks,
        categories: [category]
      },
      ...paginationFilters,
      sort: { column: sort.columnKey || 'CUT_UNITS', direction: sort.order || 'DESC' }
    }
  });

  // Reseting pagination after any change, just in case. We useDeepCompare so sort doesn't break it.
  // I don't like useEffect, but then – who does?
  useDeepCompareEffect(() => {
    setBefore(null);
    setAfter(null);
  }, [category, setAfter, setBefore, pageLimit, sort, filters.fiscalCalendarWeeks]);

  const cuts = useMemo(() => getNodesFromEdges(data?.cutOrders), [data?.cutOrders]);
  const pageInfo = useMemo(() => data?.cutOrders?.pageInfo, [data?.cutOrders]);

  const paginatorProps = usePaginatorProps({
    pageInfo,
    setAfter,
    setBefore,
    pageLimit,
    setPageLimit
  });

  return (
    <div className={s.wrapper}>
      <div className={s.mode_selector}>
        <AlloySegmented
          options={CATEGORIES}
          value={category}
          onChange={(value) => setCategory(value)}
        />
      </div>
      <div className={s.top_section}>
        <QuantityDisplay
          titleMultiple="Cuts"
          titleSingular="Cut"
          count={data?.cutOrders?.totalCount}
          className={s.title}
        />
        <Paginator {...paginatorProps} onlyButtons={false} />
      </div>
      <AlloyTable
        dataSource={cuts}
        loading={loading}
        pagination={false}
        columns={columns}
        rowKey={getRowKey}
        className={s.table}
        onChange={(_, __, sorter) => {
          const firstItem = Array.isArray(sorter) ? sorter[0] : sorter;
          const columnKey = firstItem.columnKey || 'CUT_UNITS';
          const order = firstItem.order === 'ascend' ? 'ASC' : 'DESC';
          setSort({ columnKey, order });
        }}
        sortDirections={[
          'ascend' as SortingType,
          'descend' as SortingType,
          'ascend' as SortingType
        ]}
      />
    </div>
  );
};
