import React from 'react';
import { Form } from 'react-final-form';
import { useMutation } from '@apollo/client';
import { UpsertVendorDocument } from '../../gql/__generated__/upsertVendor.mutation';
import { UpsertVendorInput } from 'graphql/__generated__/types';
import { FullScreenEditingModal } from 'components/Common/FullScreenEditingModal/FullScreenEditingModal';
import { App } from 'ant5';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';

interface EditVendorModalProps {
  vendor?: UpsertVendorInput;
  onClose: (id?: string) => void;
}

export const EditRetailerModal = ({ onClose, vendor }: EditVendorModalProps) => {
  const { message } = App.useApp();

  const [upsertVendor] = useMutation(UpsertVendorDocument, {
    refetchQueries: ['vendors'],
    onCompleted: () => {
      message.success(`Retailer successfully ${vendor?.id ? 'updated' : 'added'}.`);
      onClose();
    },
    onError: (error) => {
      console.error(error.message);
      message.error(`Error happened during Retailer saving: ${error.message}`);
    }
  });

  const submitForm = async (values: UpsertVendorInput) => {
    await upsertVendor({
      variables: {
        input: {
          id: values.id,
          name: values.name ?? '',
          code: values.code ?? '',
          description: values.description ?? ''
        }
      }
    });
  };

  return (
    <FullScreenEditingModal
      title={`${vendor?.id ? 'Edit' : 'Add'} Retailer`}
      open={!!vendor}
      onClose={onClose}
      buttons={
        <>
          <AlloyButton onClick={() => onClose()} type="secondary" size="large">
            Cancel
          </AlloyButton>
          <AlloyButton
            htmlType="submit"
            type="primary"
            data-testid="edit-retailer-modal-submit-button"
            size="large"
            onClick={() => {
              document
                ?.getElementById('edit-retailer-modal-form')
                ?.dispatchEvent(new Event('submit', { cancelable: true }));
            }}
          >
            {vendor?.id ? 'Save' : 'Add'}
          </AlloyButton>
        </>
      }
    >
      <Form<UpsertVendorInput>
        initialValues={{
          id: vendor?.id,
          name: vendor?.name || '',
          code: vendor?.code || '',
          description: vendor?.description || ''
        }}
        onSubmit={submitForm}
        render={({ handleSubmit, form }) => (
          <form
            id="edit-retailer-modal-form"
            onSubmit={(event) => {
              const promise = handleSubmit(event);
              promise &&
                promise.then(() => {
                  form.reset();
                  form.resetFieldState('name');
                  form.resetFieldState('code');
                });
              return promise;
            }}
          >
            <AlloyFormField
              component="input"
              name="code"
              required
              fieldProps={{
                title: 'Code',
                disabled: !!vendor?.id
              }}
            />
            <AlloyFormField
              component="input"
              name="name"
              required
              fieldProps={{
                title: 'Name'
              }}
            />
            <AlloyFormField
              component="input"
              name="description"
              fieldProps={{
                title: 'Description'
              }}
            />
          </form>
        )}
      />
    </FullScreenEditingModal>
  );
};
