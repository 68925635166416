import React from 'react';
import s from './ProductCard.module.scss';
import clsx from 'clsx';

interface ProductCardProps {
  upc: string;
  asin: string;
  description: string;
  isSelected: boolean;
  setSelected: (asin: string) => void;
}

export const ProductCard = ({
  upc,
  asin,
  description,
  isSelected,
  setSelected
}: ProductCardProps) => {
  return (
    <button
      className={clsx(s.wrapper, { [s.selected]: isSelected })}
      onClick={() => setSelected(asin)}
    >
      <div className={s.top}>
        <div className={s.upc}>
          <div>UPC</div>
          <div className={clsx(s.bold, s.value)}>{upc}</div>
        </div>
        <div className={s.asin}>
          <div>ASIN</div>
          <div className={clsx(s.bold, s.value)}>{asin}</div>
        </div>
      </div>
      <div className={clsx(s.description, s.value)}>{description}</div>
    </button>
  );
};
