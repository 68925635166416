import React, { useEffect, useMemo, useState } from 'react';
import s from './FulfillTable.module.scss';
import { InventoryRepackFilters, RepackOptimizerStage } from 'graphql/__generated__/types';
import { AlloyTable } from 'components/ui/AlloyTable/AlloyTable';
import { safeLocaleCompare, safeNumberComparator } from 'common/helpers/comparators';
import clsx from 'clsx';
import { ChangeHistory } from 'components/ChangeHistory/ChangeHistory';
import { ArrayParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { notEmpty } from 'common/helpers/notEmpty';
import { SearchOutlined, SwapOutlined } from '@ant-design/icons';
import { MultipleValuesInput } from 'components/MultipleValuesInput/MultipleValuesInput';
import { useQuery } from '@apollo/client';
import {
  InventoryRepackFulfillReportDocument,
  InventoryRepackFulfillReportQuery
} from './gql/__generated__/inventoryRepackFulfillReport.query';
import { DEFAULT_PAGE_SIZE_OPTIONS, NOT_AVAILABLE } from 'common/constants';
import {
  FULFIL_FILTERS_QUERY_PARAM,
  FULFILL_SEARCH_QUERY_PARAM,
  repackPlanningTableFilters,
  TYPE_CONST
} from 'pages/RepackPlanning/constants';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import clipboardIcon from 'assets/icons/clipboard_icon.svg';
import scissorsIcon from 'assets/icons/scissors_icon.svg';
import { ColumnsWithExportRender } from 'common/helpers/tableExportAlloy';
import { RepackOptimizerStatus } from '../RepackOptimizerStatus/RepackOptimizerStatus';
import { CuttingModal } from '../CuttingModal/CuttingModal';
import { ChangeTooltip } from '../ChangeTooltip/ChangeTooltip';
import { TableFilters } from 'components/TableFilters';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { useRepackOptimizerStatus } from '../RepackOptimizerStatus/useRepackOptimiserStatus';

export type FulfillItem = NonNullable<
  NonNullable<InventoryRepackFulfillReportQuery['inventoryRepackFulfillReport']>['metrics']
>[number];

function displayNumber(number: number | null | undefined) {
  return number ? number.toLocaleString() : 0;
}

export const getFulfillColumns = (isExportMode = false): ColumnsWithExportRender<FulfillItem> => {
  const columns: ColumnsWithExportRender<FulfillItem> = [
    AlloyTable.SELECTION_COLUMN,
    {
      title: 'Item ID',
      render: (_, { itemId }) => <span className={s.number}>{itemId}</span>,
      sorter: (a, b) => safeLocaleCompare(a.itemId, b.itemId),
      width: 125,
      exportConfig: {
        render: ({ itemId }) => itemId
      }
    },
    {
      title: '',
      render: (_, { lastChangeInfo, isMoved }) => (
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          {isMoved && (
            <AlloyTooltip title="Was moved">
              <div className={s.was_moved}>
                <SwapOutlined />
              </div>
            </AlloyTooltip>
          )}
          {lastChangeInfo && (
            <ChangeTooltip lastChangeInfo={lastChangeInfo}>
              <div>
                <object
                  type="image/svg+xml"
                  data={clipboardIcon}
                  aria-label="Uncut"
                  className={s.clipboard_icon}
                />
              </div>
            </ChangeTooltip>
          )}
        </div>
      ),
      width: 52,
      onCell: () => ({ style: { padding: '4px' } })
    },
    {
      title: 'Product Title',
      dataIndex: 'productTitle',
      sorter: (a, b) => safeLocaleCompare(a.productTitle, b.productTitle),
      exportConfig: {
        render: ({ productTitle }) => productTitle || ''
      }
    },
    {
      title: 'TYPE',
      render: (_, { type }) => TYPE_CONST[type || NOT_AVAILABLE],
      sorter: (a, b) => safeLocaleCompare(a.type, b.type),
      width: 125,
      className: s.capitalize,
      exportConfig: {
        title: 'Type',
        render: ({ type }) => TYPE_CONST[type || NOT_AVAILABLE]
      }
    },
    {
      title: 'ASIN',
      dataIndex: 'asin',
      sorter: (a, b) => safeLocaleCompare(a.asin, b.asin),
      width: 125,
      exportConfig: {
        render: ({ asin }) => asin || ''
      }
    },
    {
      title: 'Req Production',
      render: (_, { totalNeedQty, shortfallQty }) => (
        <span className={clsx(s.number, { [s.bold]: (shortfallQty || 0) > 0 })}>
          {displayNumber(totalNeedQty)}
        </span>
      ),
      sorter: (a, b) => safeNumberComparator(a.totalNeedQty, b.totalNeedQty),
      width: 120,
      defaultSortOrder: 'descend',
      exportConfig: {
        render: ({ totalNeedQty }) => totalNeedQty ?? 0,
        xlsxConfig: {
          t: 'n',
          z: '#,##0.000'
        }
      }
    },
    {
      title: 'Memphis Fulfill Qty',
      render: (_, { memphisFulfillQty }) => (
        <span className={clsx(s.number)}>{displayNumber(memphisFulfillQty)}</span>
      ),
      sorter: (a, b) => safeNumberComparator(a.memphisFulfillQty, b.memphisFulfillQty),
      width: 120,
      exportConfig: {
        render: ({ memphisFulfillQty }) => memphisFulfillQty ?? 0,
        xlsxConfig: {
          t: 'n',
          z: '#,##0.000'
        }
      }
    },
    {
      title: 'GP Fulfill Qty',
      render: (_, { gpFulfillQty }) => (
        <span className={clsx(s.number)}>{displayNumber(gpFulfillQty)}</span>
      ),
      sorter: (a, b) => safeNumberComparator(a.gpFulfillQty, b.gpFulfillQty),
      width: 120,
      exportConfig: {
        render: ({ gpFulfillQty }) => gpFulfillQty ?? 0,
        xlsxConfig: {
          t: 'n',
          z: '#,##0.000'
        }
      }
    },
    {
      title: 'Hamburg Fulfill Qty',
      render: (_, { hamburgFulfillQty }) => (
        <span className={clsx(s.number)}>{displayNumber(hamburgFulfillQty)}</span>
      ),
      sorter: (a, b) => safeNumberComparator(a.hamburgFulfillQty, b.hamburgFulfillQty),
      width: 120,
      exportConfig: {
        render: ({ hamburgFulfillQty }) => hamburgFulfillQty ?? 0,
        xlsxConfig: {
          t: 'n',
          z: '#,##0.000'
        }
      }
    },
    {
      title: 'Shortfall',
      key: 'Shortfall',
      render: (_, { shortfallQty }) => (
        <span className={clsx(s.number, { [s.isShort]: (shortfallQty || 0) > 0 })}>
          {displayNumber(shortfallQty)}
        </span>
      ),
      sorter: (a, b) => safeNumberComparator(a.shortfallQty, b.shortfallQty),
      width: 120,
      defaultSortOrder: 'descend',
      exportConfig: {
        render: ({ shortfallQty }) => shortfallQty ?? 0,
        xlsxConfig: {
          t: 'n',
          z: '#,##0.000'
        }
      }
    },
    {
      exportConfig: {
        title: 'Was moved',
        render: ({ isMoved }) => (isMoved ? 'Yes' : '')
      }
    },
    {
      exportConfig: {
        title: 'Last moved reason',
        render: ({ lastChangeInfo }) => lastChangeInfo?.reason || ''
      }
    },
    {
      exportConfig: {
        title: 'Last moved comment',
        render: ({ lastChangeInfo }) => lastChangeInfo?.comment || ''
      }
    }
    // No UpdatedAt = no fun
    // {
    //   title: 'Last Updated',
    //   width: 160,
    //   render: (_, record) => {
    //     return (
    //       <UpdatedAt
    //         allowForNonAdminUsers
    //         onClick={(e) => {
    //           setOpenAsin(record.asin);
    //           e.stopPropagation();
    //         }}
    //         updatedAt={record.updatedAt}
    //       />
    //     );
    //   },
    //   sorter: (a, b) => moment(a.updatedAt).diff(b.updatedAt)
    // }
  ];
  if (isExportMode) return columns;
  return columns.filter((x) => x.render || x.dataIndex);
};

const getKey = (item: FulfillItem) => item.id;

export const FulfillTable = ({
  filters,
  stage
}: {
  filters: InventoryRepackFilters;
  stage: RepackOptimizerStage;
}) => {
  const columns = getFulfillColumns();

  const [searchTerm, setSearchTerm] = useQueryParam(
    FULFILL_SEARCH_QUERY_PARAM,
    withDefault(ArrayParam, [])
  );
  const nonEmptySearch = searchTerm.filter(notEmpty) as string[];

  const [isCuttingVisible, setIsCuttingVisible] = useState(false);
  const fulfill = useQuery(InventoryRepackFulfillReportDocument, {
    variables: { filters }
  });

  const { status, shouldShowTable } = useRepackOptimizerStatus({ stage, refetch: fulfill.refetch });

  const metrics = useMemo(
    () => fulfill.data?.inventoryRepackFulfillReport?.metrics || [],
    [fulfill.data?.inventoryRepackFulfillReport?.metrics]
  );

  const [openAsin, setOpenAsin] = useQueryParam('fulfillAudit', withDefault(StringParam, ''));
  const [selectedRows, setSelectedRows] = useState<FulfillItem[]>([]);

  // If stage changes, reset selection.
  useEffect(() => {
    setSelectedRows([]);
  }, [stage]);

  return (
    <div className={s.wrapper}>
      <div className={s.quantity}>
        Production Items ({fulfill.loading || !shouldShowTable ? '...' : metrics.length})
        {selectedRows.length > 0 && (
          <span>
            , {selectedRows.length} selected
            <AlloyButton style={{ marginLeft: '12px' }} onClick={() => setSelectedRows([])}>
              Clear selection
            </AlloyButton>
          </span>
        )}
      </div>
      <div className={s.topRow}>
        <div className={s.actions}>
          <MultipleValuesInput
            placeholder="Search by Item ID or ASIN"
            value={
              nonEmptySearch
                ? typeof nonEmptySearch === 'string'
                  ? [nonEmptySearch]
                  : nonEmptySearch
                : []
            }
            onChange={setSearchTerm}
            allowClear={true}
            prefix={<SearchOutlined width="14px" height="14px" />}
            splitInputValue={/[^0-9a-zA-Z-]+/g}
          />
          <TableFilters
            filters={repackPlanningTableFilters}
            queryParamName={FULFIL_FILTERS_QUERY_PARAM}
            loading={false}
          />
        </div>

        {selectedRows.length > 0 ? (
          <AlloyButton
            type="secondary"
            onClick={() => setIsCuttingVisible(true)}
            icon={<img src={scissorsIcon} alt="" />}
          >
            Cutting
          </AlloyButton>
        ) : (
          <></>
        )}
      </div>
      {shouldShowTable ? (
        <AlloyTable
          className={s.table}
          columns={columns}
          dataSource={metrics}
          loading={fulfill.loading}
          rowKey={getKey}
          pagination={{
            hideOnSinglePage: true,
            size: 'small',
            pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
            showSizeChanger: true,
            defaultPageSize: DEFAULT_PAGE_SIZE_OPTIONS[0]
          }}
          rowSelection={{
            preserveSelectedRowKeys: true,
            onChange: (_keys, rows) => {
              setSelectedRows(rows);
            },
            type: 'checkbox',
            selectedRowKeys: selectedRows.map(getKey),
            //@ts-ignore
            getCheckboxProps(record) {
              return { 'data-testid': getKey(record) };
            }
          }}
        />
      ) : (
        <RepackOptimizerStatus
          status={status.data?.repackOptimizerStatus?.status}
          loading={status.loading}
        />
      )}
      <CuttingModal
        items={selectedRows}
        isModalVisible={isCuttingVisible}
        setIsModalVisible={setIsCuttingVisible}
        resetSelection={() => setSelectedRows([])}
        stage={stage}
      />
      <ChangeHistory
        entity={
          openAsin
            ? { id: openAsin, name: 'fulfillItem', type: 'unknown-use-for-mocks-only' }
            : undefined
        }
        onClose={() => setOpenAsin('')}
        title={
          <span>
            <b>ASIN: </b>
            {openAsin}
          </span>
        }
      />
    </div>
  );
};
