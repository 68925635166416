import { useMutation } from '@apollo/client';
import { cloneDeep } from 'lodash-es';
import { FormApi } from 'final-form';
import { Moment } from 'moment';
import React from 'react';
import { Form } from 'react-final-form';
import moment from 'moment';
import clsx from 'clsx';
import arrayMutators from 'final-form-arrays';
import s from './DistributionCenterForm.module.scss';
import { UpsertDistributionCenterDocument } from 'pages/DistributionCentersPage/gql/__generated__/upsertDistributionCenter.mutation';
import { DcSourceSystemParamsDocument } from 'pages/DistributionCentersPage/gql/__generated__/dcSourceSystemParams.query';
import { DistributionCenterFullData } from '../../EditDistributionCenterModal/EditDistributionCenterModal';
import { DistributionCenterCode } from 'graphql/__generated__/types';
import { App } from 'ant5';

interface DistributionCenterFormProps {
  distributionCenter: Partial<DistributionCenterFullData> | null | undefined;
  onClose: (id?: string) => void;
  children: (upserting: boolean) => any;
}

export const DistributionCenterForm = ({
  onClose,
  distributionCenter,
  children
}: DistributionCenterFormProps) => {
  const { message } = App.useApp();

  const [upsertDistributionCenter, { loading: upserting }] = useMutation(
    UpsertDistributionCenterDocument,
    {
      refetchQueries: [
        'distributionCenters',
        {
          query: DcSourceSystemParamsDocument,
          variables: {
            code: distributionCenter?.code,
            source: distributionCenter?.source
          }
        }
      ]
    }
  );

  const submitForm = async (values: any, form: FormApi<any, any>) => {
    // const submitForm = async (values: DistributionCenter, form: FormApi<any, any>) => { //TODO: we should fix this any type! Using DistrubutionCenter breaks the const below - fix this when implementing
    const formValues = cloneDeep(values);

    try {
      const {
        truckCapacityPallets,
        truckCapacityWeight,
        useWmsConfig,
        wmsConfigExternalId,
        useSftpConfig,
        sftpConfigEditPassword,
        sftpConfigUser,
        sftpConfigHost,
        sftpConfigPort,
        sftpConfigCustomizationPath,
        sftpConfigCustomizationImagePath,
        sftpConfigInventoryPath,
        useS3Config,
        s3ConfigEditAwsKeyId,
        s3ConfigEditAwsSecret,
        s3ConfigS3BucketName,
        s3ConfigS3Region,
        s3ConfigCustomizationPath,
        s3ConfigCustomizationImagePath,
        currentPalletCapacity,
        maxPalletCapacity,
        monthlyHoldingCostPerPalletPosition,
        monthlyLeaseCost,
        operatingCostPerHour,
        operatingCostPerCase
      } = values;

      delete values.truckCapacityPallets;
      delete values.truckCapacityWeight;
      delete values.useWmsConfig;
      delete values.wmsConfigExternalId;
      delete values.useSftpConfig;
      delete values.sftpConfigPassword;
      delete values.sftpConfigUser;
      delete values.sftpConfigHost;
      delete values.sftpConfigPort;
      delete values.sftpConfigCustomizationPath;
      delete values.sftpConfigCustomizationImagePath;
      delete values.sftpConfigInventoryPath;
      delete values.sftpConfigEditPassword;
      delete values.useS3Config;
      delete values.s3ConfigAwsKeyId;
      delete values.s3ConfigAwsSecret;
      delete values.s3ConfigS3BucketName;
      delete values.s3ConfigS3Region;
      delete values.s3ConfigCustomizationPath;
      delete values.s3ConfigCustomizationImagePath;
      delete values.s3ConfigEditAwsKeyId;
      delete values.s3ConfigEditAwsSecret;
      delete values.currentPalletCapacity;
      delete values.maxPalletCapacity;
      delete values.monthlyHoldingCostPerPalletPosition;
      delete values.monthlyLeaseCost;
      delete values.operatingCostPerHour;
      delete values.operatingCostPerCase;
      delete values.siblings;

      const result = await upsertDistributionCenter({
        variables: {
          input: {
            ...values,
            address: values.address?.trim() || null,
            zip: values.zip?.trim() || null,
            state: values.state?.trim() || null,
            city: values.city?.trim() || null,
            code: values.code?.trim() || null,
            codes:
              values.codes?.map((code: DistributionCenterCode) => ({
                code: code.code,
                source: code.source,
                dummySapSoldTo: code.dummySapSoldTo
              })) || [], //TODO: delete this after schip_dc_facility_management FF removed
            name: values.name?.trim() || null,
            lat: values.lat ? Number.parseFloat(values.lat).toFixed(6) : null,
            lng: values.lng ? Number.parseFloat(values.lng).toFixed(6) : null,
            phone: values.phone?.trim() || null,
            transaverRef: values.transaverRef?.trim() || null,
            careOf: values.careOf?.trim() || null,
            distributionCenterFacilityId: values.distributionCenterFacilityId || null,
            dummySapSoldTo: values.dummySapSoldTo?.trim() || null, //TODO: delete this after schip_dc_facility_management FF is removed
            costAndCapacity: {
              currentPalletCapacity: Number(currentPalletCapacity) || null,
              maxPalletCapacity: Number(maxPalletCapacity) || null,
              monthlyHoldingCostPerPalletPosition:
                parseFloat(monthlyHoldingCostPerPalletPosition) || null,
              monthlyLeaseCost: parseFloat(monthlyLeaseCost) || null,
              operatingCostPerCase: parseFloat(operatingCostPerCase) || null,
              operatingCostPerHour: parseFloat(operatingCostPerHour) || null
            },
            wmsConfig: useWmsConfig
              ? {
                  externalId: wmsConfigExternalId
                }
              : null,
            truckCapacity: {
              pallets: Number(truckCapacityPallets),
              weight: Number(truckCapacityWeight)
            },
            blackouts: values.blackouts.map((blackout: { date: Moment; dayOfWeek: string }) => ({
              date: blackout.date ? blackout.date.format('YYYY-MM-DD') : null,
              dayOfWeek: blackout.dayOfWeek
            })),
            sftpConfig: useSftpConfig
              ? {
                  port: sftpConfigPort ? Number(sftpConfigPort) : null,
                  host: sftpConfigHost?.trim(),
                  user: sftpConfigUser?.trim(),
                  password:
                    sftpConfigEditPassword !== undefined
                      ? sftpConfigEditPassword?.trim() || null
                      : undefined,
                  customizationPath: sftpConfigCustomizationPath?.trim(),
                  customizationImagePath: sftpConfigCustomizationImagePath?.trim(),
                  inventoryPath: sftpConfigInventoryPath?.trim()
                }
              : null,
            s3Config: useS3Config
              ? {
                  awsId:
                    s3ConfigEditAwsKeyId !== undefined
                      ? s3ConfigEditAwsKeyId?.trim() || null
                      : undefined,
                  awsSecret:
                    s3ConfigEditAwsSecret !== undefined
                      ? s3ConfigEditAwsSecret?.trim() || null
                      : undefined,
                  s3Bucket: s3ConfigS3BucketName?.trim(),
                  region: s3ConfigS3Region,
                  customizationPath: s3ConfigCustomizationPath?.trim(),
                  customizationImagePath: s3ConfigCustomizationImagePath?.trim()
                }
              : null
          }
        }
      });
      // TODO: reset it later, so it doesn't flash old values when the form is being closed
      form.reset();
      const distributionCenter = result.data?.upsertDistributionCenter?.distributionCenter;
      message.success(
        <>Distribution Center successfully {distributionCenter?.id ? 'updated' : 'added'}.</>,
        2
      );
      onClose();
    } catch (e) {
      form.initialize(formValues);
      message.error(`Error happened during Distribution Center saving. ${e.message}`);
    }
  };

  if (!distributionCenter) return null;

  return (
    <Form
      initialValues={{
        id: distributionCenter?.id,
        code: distributionCenter?.code || '',
        codes: distributionCenter?.codes || [], //TODO: delete this after schip_dc_facility_management FF removed
        name: distributionCenter?.name || '',
        address: distributionCenter?.address || '',
        careOf: distributionCenter?.careOf || '',
        city: distributionCenter?.city || '',
        customizationHeaders: (distributionCenter?.customizationHeaders || []).map((header) => ({
          title: header?.title || '',
          field: header?.field || '',
          fieldType: header?.fieldType || 'TEXT'
        })),
        distributionCenterFacilityId: distributionCenter?.distributionCenterFacility?.id || null,
        dummySapSoldTo: distributionCenter?.dummySapSoldTo || '', //TODO: delete this after schip_dc_facility_management FF removed
        siblings: distributionCenter?.siblings || '',
        phone: distributionCenter?.phone || '',
        transaverRef: distributionCenter?.transaverRef || '',
        state: distributionCenter?.state || '',
        lat: distributionCenter?.lat || '',
        lng: distributionCenter?.lng || '',
        zip: distributionCenter?.zip || '',
        source: distributionCenter?.source,
        inventorySource: distributionCenter?.inventorySource,
        emails: distributionCenter?.emails || [],
        blackouts: (distributionCenter?.blackouts || []).map((blackout) => ({
          date: blackout?.date ? moment(blackout.date) : null,
          dayOfWeek: blackout?.dayOfWeek
        })),
        truckCapacityPallets: distributionCenter?.truckCapacity?.pallets || 30,
        truckCapacityWeight: distributionCenter?.truckCapacity?.weight || 44500,
        // Cost & Capacity
        currentPalletCapacity: distributionCenter?.costAndCapacity?.currentPalletCapacity || 0,
        maxPalletCapacity: distributionCenter?.costAndCapacity?.maxPalletCapacity || 0,
        monthlyHoldingCostPerPalletPosition:
          parseFloat(distributionCenter.costAndCapacity?.monthlyHoldingCostPerPalletPosition) || 0,
        monthlyLeaseCost: parseFloat(distributionCenter.costAndCapacity?.monthlyLeaseCost) || 0,
        operatingCostPerHour:
          parseFloat(distributionCenter.costAndCapacity?.operatingCostPerHour) || 0,
        operatingCostPerCase:
          parseFloat(distributionCenter.costAndCapacity?.operatingCostPerCase) || 0,
        // Wms Config
        useWmsConfig: !!distributionCenter?.wmsConfig,
        wmsConfigExternalId: distributionCenter?.wmsConfig?.externalId,
        // SFTP Config
        useSftpConfig: !!distributionCenter.sftpConfig,
        sftpConfigUser: distributionCenter.sftpConfig?.user,
        sftpConfigPort: distributionCenter.sftpConfig?.port,
        sftpConfigHost: distributionCenter.sftpConfig?.host,
        sftpConfigPassword: distributionCenter.sftpConfig?.password,
        sftpConfigCustomizationPath: distributionCenter.sftpConfig?.customizationPath,
        sftpConfigCustomizationImagePath: distributionCenter.sftpConfig?.customizationImagePath,
        sftpConfigInventoryPath: distributionCenter.sftpConfig?.inventoryPath,
        // S3 Config
        useS3Config: !!distributionCenter.s3Config,
        s3ConfigAwsKeyId: distributionCenter.s3Config?.awsId,
        s3ConfigAwsSecret: distributionCenter.s3Config?.awsSecret,
        s3ConfigS3BucketName: distributionCenter.s3Config?.s3Bucket,
        s3ConfigS3Region: distributionCenter.s3Config?.region,
        s3ConfigCustomizationPath: distributionCenter.s3Config?.customizationPath,
        s3ConfigCustomizationImagePath: distributionCenter.s3Config?.customizationImagePath
      }}
      mutators={{
        ...arrayMutators,
        useSftpConfig: (args, state, utils) => {
          utils.changeValue(state, 'useSftpConfig', () => args[0]);
        },
        useS3Config: (args, state, utils) => {
          utils.changeValue(state, 'useS3Config', () => args[0]);
        },
        setSecretValue: (args, state, utils) => {
          utils.changeValue(state, args[1], () => args[0]);
        },
        setDistributionCenterFacilityId: (args, state, utils) => {
          utils.changeValue(state, 'distributionCenterFacilityId', () => args[0]);
        }
      }}
      onSubmit={submitForm}
      render={({ handleSubmit, form }) => (
        <form
          id="edit-distribution-center-modal-form"
          className={clsx(s.form)}
          onSubmit={(event) => {
            const { errors } = form.getState();
            if (distributionCenter.id && errors && !!Object.keys(errors).length) {
              if (
                //TODO: add siblings here if necessary -- not needed since siblings are not editable?
                errors['code'] ||
                errors['source'] ||
                errors['name'] ||
                errors['emails'] ||
                errors['truckCapacityPallets'] ||
                errors['truckCapacityWeight'] ||
                errors['wmsConfigExternalId'] ||
                errors['costAndCapacity']
              ) {
                message.warning(
                  <span data-testid="message_edit-dc-general-tab-required-fields">
                    Please fill all required fields and check errors on the General Settings tab
                  </span>
                );
              } else if (
                errors['sftpConfigHost'] ||
                errors['sftpConfigPassword'] ||
                errors['sftpConfigPort'] ||
                errors['sftpConfigUser'] ||
                errors['s3ConfigAwsSecret'] ||
                errors['s3ConfigS3BucketName'] ||
                errors['s3ConfigS3Region'] ||
                errors['s3ConfigAwsKeyId']
              ) {
                message.warning(
                  <span data-testid="message_edit-dc-integrations-tab-required-fields">
                    Please fill all required fields and check errors on the Integrations tab
                  </span>
                );
              } else if (errors['customizationHeaders']) {
                message.warning(
                  <span data-testid="message_edit-dc-customization-tab-required">
                    Please fill all required fields and check errors on the Customization tab
                  </span>
                );
              }
            }
            const promise = handleSubmit(event);
            promise &&
              promise.then(() => {
                // do nothing
              });
            return promise;
          }}
        >
          {children(upserting)}
        </form>
      )}
    />
  );
};
