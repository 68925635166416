import { useMutation } from '@apollo/client';
import { Modal, Row, Spin } from 'antd'; //TODO: convert to Alloy components
import React from 'react';
import { useState } from 'react';
import { TradingPartnerAssortment } from '../../AssortmentPage';
import { BulkUpdateVendorProductDtcInventoryReserveDocument } from './gql/__generated__/bulkUpdateVendorProductDtcInventoryReserve.mutation';
import { InputNumber } from 'components/alloy/InputNumber/InputNumber';
import { TradingPartnerAssortmentsDocument } from 'pages/AssortmentPage/gql/__generated__/tradingPartnerAssortments.query';
import { App } from 'ant5';

interface ReserveBulkUpdateModalProps {
  selectedRows: TradingPartnerAssortment[];
  visible: boolean;
  close: (clearSelection: boolean) => void;
  tradingPartnerId: string;
  vendorMarketId: string;
}

export const ReserveBulkUpdateModal = ({
  selectedRows,
  visible,
  close,
  vendorMarketId,
  tradingPartnerId
}: ReserveBulkUpdateModalProps) => {
  const [reserve, setReserve] = useState(0);

  const { message } = App.useApp();

  const [bulkUpdateInventoryReserve, { loading }] = useMutation(
    BulkUpdateVendorProductDtcInventoryReserveDocument,
    {
      refetchQueries: [TradingPartnerAssortmentsDocument]
    }
  );

  return (
    <Modal
      open={visible}
      title="Reserve changing"
      okText="Ok"
      cancelText="Cancel"
      onCancel={() => close(false)}
      onOk={async () => {
        const result = await bulkUpdateInventoryReserve({
          variables: {
            input: {
              tradingPartnerId: tradingPartnerId,
              vendorMarketId: vendorMarketId,
              vendorProductDtcInventoryReserve: reserve,
              vendorProductIds: selectedRows.map((row) => row.retailerProductId)
            }
          }
        });
        if (result.data?.bulkUpdateVendorProductDtcInventoryReserve?.ok) {
          message.success('Reserve was successfully changed');
        } else {
          // console.error(message.error); // go to parent to console log error
          message.error("Reserve wasn't changed");
        }
        close(true);
        setReserve(0);
      }}
    >
      <Spin spinning={loading}>
        <Row style={{ alignItems: 'baseline' }}>
          Change To:
          <InputNumber
            style={{ marginLeft: '16px' }}
            min={0}
            value={reserve}
            onChange={(value) => setReserve(Math.round(value ?? 0))}
          />
        </Row>
      </Spin>
    </Modal>
  );
};
