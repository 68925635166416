import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import type * as Enums from 'graphql/__generated__/enums';

type AllEnums = typeof Enums;

const ENUM_VALUES = gql`
  query ($enumName: String!) {
    __type(name: $enumName) {
      enumValues {
        name
      }
    }
  }
`;

const createMultipleEnumsQuery = <EnumKey extends keyof AllEnums>(enumNames: EnumKey[]) => {
  // Validate input - Ensure enumNames is an array of strings
  if (!Array.isArray(enumNames) || !enumNames.every((name) => typeof name === 'string')) {
    throw new Error('Invalid enum names array.');
  }

  // Generate fields for each enum type
  const fields = enumNames
    .map(
      (name) => `
    ${name}: __type(name: "${name}") {
      enumValues {
        name
      }
    }
  `
    )
    .join('\n');

  return gql`
    query {
      ${fields}
    }
  `;
};

/**
 * Custom hook to fetch enum values.
 * In theory, we don't even need to fetch that value from backend. But actually we do, since BE can change.
 */
export const useEnumValue = <EnumKey extends keyof AllEnums>(
  enumName: EnumKey
): { loading: boolean; enumValues: Array<keyof AllEnums[EnumKey]> } => {
  const { loading, data } = useQuery(ENUM_VALUES, {
    variables: {
      enumName
    }
  });

  const enumValues = useMemo(() => {
    if (data)
      return (data.__type?.enumValues || [])?.map(
        (value: { name: string }) => value?.name
      ) as Array<keyof AllEnums[EnumKey]>;
    return [];
  }, [data]);

  return { loading, enumValues };
};

/**
 * Custom hook to fetch multiple enum values in one go.
 * Created to avoid sending 20+ requests for one simple page
 */
export const useEnumValues = <EnumKey extends keyof AllEnums>(
  enumNames: EnumKey[]
): {
  loading: boolean;
  enumValues: {
    [K in EnumKey]: Array<keyof AllEnums[K]>;
  };
} => {
  // Generate query dynamically based on enum names
  const query = useMemo(() => createMultipleEnumsQuery(enumNames), [enumNames]);

  // Use the query with the correct response type
  const { loading, data } = useQuery(query);

  const enumValues = useMemo(() => {
    const result = {} as {
      [K in EnumKey]: Array<keyof AllEnums[K]>;
    };
    enumNames.forEach((enumName) => {
      result[enumName] = (data?.[enumName]?.enumValues || []).map(
        (value: { name: string }) => value?.name
      );
    });

    return result as {
      [K in EnumKey]: Array<keyof AllEnums[K]>;
    };
  }, [data, enumNames]);

  return { loading, enumValues };
};
