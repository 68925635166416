import React, { useState } from 'react';
import s from './EventTracker.module.scss';
import { NoEvents } from './components/NoEvents/NoEvents';
import { EventsTable } from './components/EventsTable/EventsTable';
import { AlloyInputForEventTracker } from 'components/ui/AlloyInputForEventTracker/AlloyInputForEventTracker';
import { SearchOutlined } from '@ant-design/icons';
import { useUrlParams } from 'common/helpers/urlParams';
import { NewEventModal } from './components/NewEventModal/NewEventModal';

export const EventTrackerPage = () => {
  const [urlParams, setUrlParams] = useUrlParams();
  const [showNewEventModal, setShowNewEventModal] = useState(false);

  return (
    <div className={s.events_tracker}>
      <div className={s.events_tracker_title}>Event Tracker</div>
      {/* <NoEvents /> */}
      <div className={s.search}>
        <AlloyInputForEventTracker
          prefix={<SearchOutlined />}
          size="large"
          placeholder="Search"
          onPressEnter={(event) =>
            setUrlParams({ search: (event.target as HTMLInputElement).value })
          }
        />
      </div>
      <EventsTable newEvent={setShowNewEventModal} />
      <NewEventModal open={showNewEventModal} close={() => setShowNewEventModal(false)} />
    </div>
  );
};
