import { isEqual, sortBy } from 'lodash-es';
import { parsePeriodWeekString } from './fiscalCalendar';

export const safeLocaleCompare = (a?: string | null, b?: string | null) =>
  (a || '').localeCompare(b || '', 'en', { sensitivity: 'base' });

// This function will put nulls and undefineds BEFORE 0 and negative numbers
export const safeNumberComparator = (
  x: number | null | undefined,
  y: number | null | undefined
) => {
  if (x === null || y === null || x === undefined || y === undefined) {
    if ((x === null || x === undefined) && (y === null || y === undefined)) {
      return 0;
    } else if (x === null || x === undefined) {
      return -1;
    } else {
      return 1;
    }
  } else {
    return x - y; // Compare the values numerically for non-null cases
  }
};

export const safeDatesComparator = (x: Date | null | undefined, y: Date | null | undefined) => {
  if (x === null || y === null || x === undefined || y === undefined) {
    if ((x === null || x === undefined) && (y === null || y === undefined)) {
      return 0;
    } else if (x === null || x === undefined) {
      return -1;
    } else {
      return 1;
    }
  } else {
    return x.getTime() - y.getTime();
  }
};

/**
 * Compare 2 period-week strings, like P1W1 and P13W3
 * @param first first value, like P1W1
 * @param second second value, like P2W1
 * @returns comparator value
 */
export function periodWeekComparator(x: string | null | undefined, y: string | null | undefined) {
  if (x === null || y === null || x === undefined || y === undefined) {
    if ((x === null || x === undefined) && (y === null || y === undefined)) {
      return 0;
    } else if (x === null || x === undefined) {
      return -1;
    } else {
      return 1;
    }
  }

  const a = parsePeriodWeekString(x);
  const b = parsePeriodWeekString(y);

  if (a.period !== b.period) {
    return a.period - b.period;
  }
  return a.periodWeek - b.periodWeek;
}
