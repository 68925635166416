import React from 'react';
import { DatePicker as AntDatePicker } from 'ant5';
import type { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { PickerProps } from 'ant5/lib/date-picker/generatePicker';

const MomentDatePicker = AntDatePicker.generatePicker<Moment>(momentGenerateConfig);

interface DatePickerProps extends PickerProps<Moment> {}

export const AlloyDatePicker = React.forwardRef<any, DatePickerProps>((props, ref) => (
  <MomentDatePicker {...props} ref={ref} />
));

const { RangePicker: MomentRangePicker } = MomentDatePicker;
export const AlloyRangePicker = MomentRangePicker;

// Re-exporting types
export type { DatePickerProps };
