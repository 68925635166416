import React, { useState } from 'react';
import s from './EditEventDetails.module.scss';
import { AlloyInputForEventTracker } from 'components/ui/AlloyInputForEventTracker/AlloyInputForEventTracker';
import { FinalFormCheckbox } from 'components/FinalFormFields/FinalFormCheckbox/FinalFormCheckbox';
import { FinalFormSelect } from 'components/FinalFormFields/FinalFormSelect/FinalFormSelect';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloyRangePicker } from 'components/ui/AlloyDatePicker/AlloyDatePicker';
import { InfoCircleFilled } from '@ant-design/icons';
import moment from 'moment';

interface EditEventDetailsProps {
  event: any;
}

export const EditEventDetails = ({ event }: EditEventDetailsProps) => {
  const [details, setDetails] = useState(event);
  if (!event) return null;

  return (
    <div className={s.event_details}>
      <div className={s.input_group}>
        <AlloyInputForEventTracker
          placeholder="Choose a unique name (eg. Prime day 2024)"
          label="Event name"
          value={details.name}
        />
        <div className={s.sub_option}>
          <FinalFormCheckbox name="private" checked={details.private} />
          <div className={s.label}>Make event private</div>
          <AlloyTooltip title="Private events are only visible to you. Public events are visible to any individual with access to the same Trading Partners in Schip.">
            <InfoCircleFilled className={s.icon} />
          </AlloyTooltip>
        </div>
      </div>
      <div className={s.input_group}>
        <div className={s.small_details}>Date range of event hypercare</div>
        <AlloyRangePicker
          className={s.date_picker}
          defaultValue={[moment('2015/01/01', 'YYYY/MM/DD'), moment('2015/01/01', 'YYYY/MM/DD')]}
          format={'YYYY/MM/DD'}
        />
      </div>
      <div className={s.input_group}>
        <div>Customer</div>
        <FinalFormSelect
          placeholder="Choose a customer"
          className={s.select}
          name="customer"
          options={[]}
        />
      </div>
      <div className={s.input_group}>
        <div>BU</div>
        <FinalFormSelect placeholder="Choose a BU" className={s.select} name="bu" options={[]} />
      </div>
    </div>
  );
};
