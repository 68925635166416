import React, { useState, useEffect, CSSProperties } from 'react';
import './styles.scss';
import { PurchaseOrdersTpRddDocument } from './gql/__generated__/EditPoDestination.query';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  PO_STATUS_NEW,
  PO_STATUS_EXPORTED,
  PO_STATUS_SCRUBBED,
  PO_STATUS_ACKNOWLEDGED
} from 'common/constants';
import { InfoCircleFilled, SearchOutlined } from '@ant-design/icons';
import { RetailerDeliveryDestination } from 'graphql/__generated__/types';
import { getNodesFromEdges } from 'common/helpers/mappingHelper';
import { UpdatePurchaseOrdersDestinationDocument } from './gql/__generated__/updatePurchaseOrdersDestination.mutation';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { App } from 'ant5';
import { AlloyMenuItem, AlloySubMenu } from 'components/ui/AlloyMenu/AlloyMenu';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
const { Option } = AlloySelect;

interface PoData {
  id: string;
  customerPo: string;
  primaryStatus: string;
  deliveryType: string;
}

interface EditPoDestinationProps {
  purchaseOrders: PoData | PoData[];
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  bordered?: boolean;
  style?: CSSProperties;
  asSubMenu?: boolean;
  key?: React.Key | null | undefined;
}

interface ShipToErrors {
  new?: boolean;
  type?: boolean;
}

export const EditPoDestination = ({
  purchaseOrders,
  placeholder,
  className,
  disabled,
  bordered = true,
  style,
  asSubMenu,
  key
}: EditPoDestinationProps) => {
  const { notification } = App.useApp();
  const [allowedDeliveryDestinations, setAllowedDeliveryDestinations] = useState<
    Partial<RetailerDeliveryDestination>[]
  >([]);
  const [value, setValue] = useState(placeholder || 'Edit Ship-To');
  const [updatePoDeliveryDestination] = useMutation(UpdatePurchaseOrdersDestinationDocument, {
    refetchQueries: ['purchaseOrders']
  });
  const [po, setPo] = useState<PoData[]>([]);
  const [tpRddsLoaded, setTpRddsLoaded] = useState<boolean>(false);
  const [errors, setErrors] = useState<ShipToErrors>({});

  const [search, setSearch] = useState('');

  const [loadTpRdd, { loading: loadingTpRdds }] = useLazyQuery(PurchaseOrdersTpRddDocument, {
    onCompleted: (data) => {
      const poTpRdd = getNodesFromEdges(data.purchaseOrders);

      //return an array of delivery destinations, which are valid for ALL of the selected purchase order

      let validLocations: RetailerDeliveryDestination[] = [];
      for (const [index, purchaseOrder] of poTpRdd.entries()) {
        const tradingPartnerRdds =
          purchaseOrder?.tradingPartner?.tradingPartnerRdds
            ?.filter((tpRdd) => tpRdd?.retailerDeliveryDestination?.active)
            .map((tpRdd) => tpRdd?.retailerDeliveryDestination) || [];

        const poAllowedDc =
          purchaseOrder?.retailerDeliveryDestination?.allowedDistributionCenters?.map(
            (allowedDc) => allowedDc?.distributionCenter?.id
          );

        const dcRdds = tradingPartnerRdds.filter((tpRdd) => {
          //if this purchase order does not have a valid RDD defined, then there are no valid DCs to choose from. So all RDD for the trading partner could be valid.
          if (!poAllowedDc || purchaseOrder.statuses?.primary === PO_STATUS_NEW) return true;

          const tpRddAllowedDc =
            tpRdd?.allowedDistributionCenters?.map(
              (allowedDc) => allowedDc?.distributionCenter?.id
            ) || [];

          //if one of the trading partner rdds has a DC overlap then it's valid
          for (const allowedDc of tpRddAllowedDc) {
            if (poAllowedDc.includes(allowedDc)) {
              return true;
            }
          }

          return false;
        });

        if (index === 0) {
          validLocations = dcRdds as RetailerDeliveryDestination[];
        } else {
          //rdd validation is subtractive. Only RDD that are valid for all purchase orders will be displayed, so any RDD that did not exist in the valid array previously should be ignored, and those that are in the valid array, but are not valid for this purchase order should be removed as invalid.
          const dcRddsById = dcRdds.map((rdd) => rdd?.id);
          validLocations = validLocations.filter((rdd) => dcRddsById.includes(rdd?.id));
        }
      }

      validLocations.sort((a, b) => a.externalId?.localeCompare(b.externalId || '') || 0);
      setAllowedDeliveryDestinations(
        validLocations.map((rdd) => ({
          id: rdd.id,
          externalId: rdd.externalId,
          addressCity: rdd.addressCity,
          addressState: rdd.addressState
        }))
      );

      setTpRddsLoaded(true);
    }
  });

  const getTpRdds = () => {
    if (!po.length) return;

    loadTpRdd({
      variables: {
        ids: po.map((po) => po.id)
      }
    });
  };

  useEffect(() => {
    if (!placeholder) return;

    setValue(placeholder);
  }, [placeholder]);

  useEffect(() => {
    let filteredPo: PoData[] = [];
    const newErrors: ShipToErrors = {};
    //edit ship-to is not allowed for submitted POs or DSD orders
    if (Array.isArray(purchaseOrders)) {
      filteredPo = filteredPo.concat(
        purchaseOrders.filter((po) => {
          if (
            ![
              PO_STATUS_NEW,
              PO_STATUS_EXPORTED,
              PO_STATUS_SCRUBBED,
              PO_STATUS_ACKNOWLEDGED
            ].includes(po.primaryStatus)
          )
            newErrors.new = true;
          if (po.deliveryType !== 'WHD') newErrors.type = true;

          return (
            [
              PO_STATUS_NEW,
              PO_STATUS_EXPORTED,
              PO_STATUS_SCRUBBED,
              PO_STATUS_ACKNOWLEDGED
            ].includes(po.primaryStatus) && po.deliveryType === 'WHD'
          );
        })
      );
    } else if (
      [PO_STATUS_NEW, PO_STATUS_EXPORTED, PO_STATUS_SCRUBBED, PO_STATUS_ACKNOWLEDGED].includes(
        purchaseOrders.primaryStatus
      ) &&
      purchaseOrders.deliveryType === 'WHD'
    ) {
      filteredPo.push(purchaseOrders);
    } else {
      if (purchaseOrders?.primaryStatus !== PO_STATUS_NEW) newErrors.new = true;
      if (purchaseOrders.deliveryType !== 'WHD') newErrors.type = true;
    }

    setErrors(newErrors);
    setPo(filteredPo);
  }, [purchaseOrders]);

  useEffect(() => {}, [po]);

  const onSelectShipTo = (destinationId: string) => {
    updatePoDeliveryDestination({
      variables: {
        input: { purchaseOrderIds: po.map((purchaseOrder) => purchaseOrder.id), destinationId }
      }
    })
      .then(() => {
        const message =
          po.length > 1
            ? `${po.length} Ship-to locations updated successfully`
            : `Ship-to location updated successfully`;
        notification.success({
          message,
          duration: 4.5,
          props: {
            'data-testid': 'edit-ship-to-success-notification'
          }
        });
      })
      .catch(() => {
        notification.error({ message: 'Something went wrong! Please try again' });
      });
  };

  const getInfoMessage = () => {
    let msg = '';
    if (errors.new && errors.type)
      msg = 'Only WHD orders with a status of NEW, EXPORTED, or SCRUBBED will be updated';
    else if (errors.new)
      msg = 'Only purchase orders with a status of NEW, EXPORTED, or SCRUBBED will be updated.';
    else if (errors.type) msg = 'Only WHD orders will be updated';
    return msg;
  };

  return !asSubMenu ? (
    <AlloySelect
      data-testid={`select-editShipTo${po.length === 1 ? `-${po[0].customerPo}` : '-multiple'}`}
      style={style}
      className={`filled_grey_btn edit_ship_to ${className || ''}`}
      disabled={disabled === true}
      onClick={(event) => {
        event.stopPropagation();
        getTpRdds();
        const msg = getInfoMessage();
        if (msg) notification.info({ message: msg });
      }}
      value={value}
      loading={loadingTpRdds}
      onDropdownVisibleChange={(open) => open && setSearch('')}
      dropdownRender={
        tpRddsLoaded
          ? (menu) => (
              <div className="edit_ship_to_dropdown_container">
                <AlloyInput
                  className="edit_ship_to_search_input"
                  size="large"
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  allowClear
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="menu">{menu}</div>
              </div>
            )
          : () => (
              <div className="edit-rdd-spinner">
                <AlloySpin />
              </div>
            )
      }
      variant={!bordered ? 'borderless' : undefined}
      onSelect={onSelectShipTo}
      placeholder={placeholder || 'Edit Ship-To'}
    >
      {allowedDeliveryDestinations.map((destination) => {
        const description = `${destination.externalId} - ${destination.addressCity ?? ''}${
          destination.addressCity && destination.addressState ? ', ' : ''
        }${destination.addressState ?? ''}`;
        return description.toLowerCase().includes(search.toLowerCase()) ? (
          <Option
            key={destination.externalId}
            value={destination.id}
            data-testid={`ship_to_option_${destination.externalId}`}
          >
            {description}
          </Option>
        ) : null;
      })}
    </AlloySelect>
  ) : (
    <AlloySubMenu
      data-testid={`bulk-process-select-editShipTo`}
      title="Edit Ship-To"
      onTitleClick={() => getTpRdds()}
      onTitleMouseEnter={() => {
        if (po.length) getTpRdds();
      }}
      key={key}
      className={className}
      disabled={/*!allowedDeliveryDestinations || allowedDeliveryDestinations.length === 0 */ false}
      icon={
        (errors.new || errors.type) && (
          <AlloyTooltip title={getInfoMessage()}>
            <InfoCircleFilled style={{ color: 'GrayText' }} />
          </AlloyTooltip>
        )
      }
    >
      {/*TODO Add a loading menu item here while query is loading*/}
      {!tpRddsLoaded && (
        <AlloyMenuItem key="spinner" className="edit-rdd-menu-item-spinner">
          <div>
            <AlloySpin />
          </div>
        </AlloyMenuItem>
      )}
      {tpRddsLoaded &&
        allowedDeliveryDestinations.map((destination) => {
          return (
            <AlloyMenuItem
              data-testid={`edit-ship-to-${destination.externalId}`}
              key={destination.externalId}
              onClick={() => onSelectShipTo(destination.id || '')}
              className="edit_ship_to_submenu_item"
            >
              {destination.externalId} - {destination.addressCity}
              {destination.addressCity && destination.addressState ? ',' : ''}
              {destination.addressState}
            </AlloyMenuItem>
          );
        })}
      {tpRddsLoaded && !allowedDeliveryDestinations.length && (
        <AlloyMenuItem key="empty">
          <>
            <div>No common ship-to location</div>
            <div>available for selected orders</div>
          </>
        </AlloyMenuItem>
      )}
    </AlloySubMenu>
  );
};
