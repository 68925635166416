import React, { Dispatch, ReactNode, useState } from 'react';

type ProviderProps = {
  children: ReactNode;
};

interface Errors {
  [index: string]: string;
}

interface EventTrackerContextData {
  errors?: Errors;
  setErrors?: Dispatch<React.SetStateAction<Errors>>;
}

export const EventTrackerContext = React.createContext({} as EventTrackerContextData);

export const EventTrackerProvider = (props: ProviderProps) => {
  const [errors, setErrors] = useState<Errors>({});

  return (
    <EventTrackerContext.Provider value={{ errors, setErrors }}>
      {props.children}
    </EventTrackerContext.Provider>
  );
};
