import React from 'react';
import s from './ProductDetails.module.scss';
import { Product } from '../../ForecastPlanning';
import clsx from 'clsx';
import { EMPTY } from 'common/constants';

const formatPercentage = (value: any) => {
  const parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue)) return EMPTY;
  return `${(parsedValue * 100).toFixed(0)}%`;
};

const formatNumber = (value: any) => {
  const parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue)) return EMPTY;
  return parsedValue.toLocaleString();
};

interface ProductDetailsInterface {
  product: Product;
}

export const ProductDetails = ({ product }: ProductDetailsInterface) => {
  return (
    <div>
      <div className={s.top}>
        <div className={s.info}>
          <div>UPC: {product.upc}</div>
          <div>ASIN: {product.asin}</div>
          <div>Description: {product.description}</div>
        </div>
        <div className={s.week_stats_wrapper}>
          <div className={s.week_stats}>
            <h2 className={s.week_stats_title}>Order Trend / FCST</h2>
            <div className={s.wrapper}>
              <div className={s.colored_stat}>
                <div className={s.title}>4WK</div>
                <div>{formatPercentage(product.orderTrendForecast4weeks)}</div>
              </div>
              <div className={s.colored_stat}>
                <div className={s.title}>8WK</div>
                <div>{formatPercentage(product.orderTrendForecast8weeks)}</div>
              </div>
            </div>
          </div>
          <div className={s.week_stats}>
            <h2 className={s.week_stats_title}>Scan Trend / FCST</h2>
            <div className={s.wrapper}>
              <div className={s.colored_stat}>
                <div className={s.title}>4WK</div>
                <div>{formatPercentage(product.scanTrendForecast4weeks)}</div>
              </div>
              <div className={s.colored_stat}>
                <div className={s.title}>8WK</div>
                <div>{formatPercentage(product.scanTrendForecast8weeks)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.stats}>
        <div className={s.stat}>
          <div className={s.title}>5wk Fill Rate Trend</div>
          <div className={s.value}>{formatPercentage(product.fillRateTrend5week)}</div>
        </div>
        <div className={s.stat}>
          <div className={s.title}>BW Inv</div>
          <div className={s.value}>{formatNumber(product.bwInv)}</div>
        </div>
        <div className={s.stat}>
          <div className={s.title}>EW Inv</div>
          <div className={s.value}>{formatNumber(product.ewInv)}</div>
        </div>
        <div className={s.stat}>
          <div className={s.title}>Ending WOC</div>
          <div
            className={s.value}
            style={{
              background: '#D5E9CA',
              fontWeight: 'bold',
              color: 'black'
            }}
          >
            {formatNumber(product.endingWoc)}
          </div>
        </div>
        <div className={s.stat}>
          <div className={s.title}>Order Trend</div>
          <div className={s.value}>{formatNumber(product.orderTrend)}</div>
        </div>
        <div className={s.stat}>
          <div className={s.title}>Scan Trend</div>
          <div className={s.value}>{formatNumber(product.scanTrend)}</div>
        </div>
        {
          // Hidden for now
          /* <div className={s.stat}>
          <div className={s.title}>Sales FCST</div>
          <div className={s.value}>{formatPercentage(product.salesForecast)}</div>
        </div> */
        }
      </div>
    </div>
  );
};
