import React from 'react';
import { useState } from 'react';
import s from '../EditDistributionCenterModal.module.scss';
import { CustomizationForm } from '../../forms/CustomizationForm/CustomizationForm';
import { useForm } from 'react-final-form';
import { GeneralSettingsForm } from '../../forms/GeneralSettingsForm/GeneralSettingsForm';
import { IntegrationsForm } from '../../forms/IntegrationsForm/IntegrationsForm';
import { FullScreenEditingModal } from 'components/Common/FullScreenEditingModal/FullScreenEditingModal';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloySteps } from 'components/ui/AlloySteps/AlloySteps';

interface EditingDistributionCenterStepperProps {
  handleClose: () => void;
  upserting: boolean;
  visible: boolean;
  isDCFacilityManagementEnabled: boolean | undefined;
}

export const AddingDistributionCenterStepper = ({
  handleClose,
  upserting,
  visible,
  isDCFacilityManagementEnabled
}: EditingDistributionCenterStepperProps) => {
  const [activeStep, setActiveStep] = useState(0);

  const form = useForm();

  const onClose = () => {
    handleClose();
    setActiveStep(0);
  };

  const steps = [
    {
      title: 'General Settings',
      content: (
        <GeneralSettingsForm
          upserting={upserting}
          isDCFacilityManagementEnabled={isDCFacilityManagementEnabled}
        />
      )
    },
    {
      title: 'Integrations',
      content: (
        <IntegrationsForm
          upserting={upserting}
          setUseConfig={(integrationName: string | undefined, value: boolean) => {
            switch (integrationName) {
              case 'sftpConfig':
                form.mutators.useSftpConfig(value);
                break;
              case 's3Config':
                form.mutators.useS3Config(value);
                break;
            }
          }}
        />
      )
    },
    {
      title: 'Customization',
      content: <CustomizationForm upserting={upserting} />
    }
  ];

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <AlloySpin spinning={upserting}>
      <FullScreenEditingModal
        title={`${form.getState().values['id'] ? 'Edit' : 'Add'} Distribution Center`}
        open={visible}
        onClose={onClose}
        buttons={
          <>
            <AlloyButton
              className={s.steps_button}
              onClick={onClose}
              data-testid="adding-dc-stepper-cancel-button"
            >
              Cancel
            </AlloyButton>
            {activeStep > 0 && (
              <AlloyButton
                className={s.steps_button}
                onClick={() => prevStep()}
                data-testid="adding-dc-stepper-back-button"
              >
                Back
              </AlloyButton>
            )}
            {activeStep < steps.length - 1 && (
              <AlloyButton
                className={s.steps_button}
                type="primary"
                onClick={() => {
                  const { errors } = form.getState();
                  if (
                    errors &&
                    ((activeStep === 0 &&
                      (errors['code'] ||
                        errors['name'] ||
                        errors['source'] ||
                        errors['truckCapacityPallets'] ||
                        errors['truckCapacityWeight'] ||
                        errors['wmsConfigExternalId'] ||
                        errors[''])) ||
                      (activeStep === 1 &&
                        (errors['sftpConfigHost'] ||
                          errors['sftpConfigPassword'] ||
                          errors['sftpConfigPort'] ||
                          errors['sftpConfigUser'] ||
                          errors['s3ConfigAwsSecret'] ||
                          errors['s3ConfigS3BucketName'] ||
                          errors['s3ConfigS3Region'] ||
                          errors['s3ConfigAwsKeyId'])) ||
                      (activeStep === 2 && errors['customizationHeaders']))
                  ) {
                    form.submit();
                  } else {
                    nextStep();
                  }
                }}
                data-testid="adding-dc-stepper-next-button"
              >
                Next
              </AlloyButton>
            )}
            {(activeStep === steps.length - 1 || form.getState().values['id']) && (
              <AlloyButton
                className={s.steps_button}
                type="primary"
                onClick={() => {
                  form.submit()?.finally(() => setActiveStep(0));
                }}
                data-testid="adding-dc-stepper-add-button"
              >
                {form.getState().values['id'] ? 'Save' : 'Add'}
              </AlloyButton>
            )}
          </>
        }
        steps={
          <AlloySteps
            current={activeStep}
            size="small"
            style={{ width: '684px', alignSelf: 'center' }}
            items={steps}
          />
        }
      >
        {steps[activeStep].content}
      </FullScreenEditingModal>
    </AlloySpin>
  );
};
