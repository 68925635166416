import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { getItem, setItem } from 'common/services/persistentStorageServices';
import { NEW_SEARCH_LOCAL_STORAGE_FILTERS_NAME } from '../AdvancedFilters/AdvancedFiltersForNewSearch';
import { LOCAL_STORAGE_FILTERS_NAME } from '../AdvancedFilters/AdvancedFilters';
import { AdvancedFilter, Filter, FilterName, NewFilterName } from 'common/interfaces';
import { getNodesFromEdges, InferNodeType } from 'common/helpers/mappingHelper';
import { notEmpty } from 'common/helpers/notEmpty';
import {
  ResaveFiltersDataDocument,
  ResaveFiltersDataQuery
} from './gql/__generated__/resaveFiltersData.query';

type TradingPartner = InferNodeType<ResaveFiltersDataQuery, 'tradingPartners'>;
type BusinessUnit = InferNodeType<ResaveFiltersDataQuery, 'businessUnits'>;
type RetailerChannel = InferNodeType<ResaveFiltersDataQuery, 'vendorMarkets'>;
type DistributionCenter = InferNodeType<ResaveFiltersDataQuery, 'distributionCenters'>;
type ShipTo = InferNodeType<ResaveFiltersDataQuery, 'retailerDeliveryDestinations'>;

export const useResaveFilters = (): boolean => {
  const [resavingFinished, setResavingFinished] = useState(false);

  const [loadResavingData, { data }] = useLazyQuery(ResaveFiltersDataDocument, {
    fetchPolicy: 'network-only'
  });
  const retailerChannels = useMemo(
    () => (data ? getNodesFromEdges(data?.vendorMarkets) : undefined),
    [data]
  );
  const tradingPartners = useMemo(
    () => (data ? getNodesFromEdges(data?.tradingPartners) : undefined),
    [data]
  );
  const distributionCenters = useMemo(
    () => (data ? getNodesFromEdges(data?.distributionCenters) : undefined),
    [data]
  );
  const shipTos = useMemo(
    () => (data ? getNodesFromEdges(data?.retailerDeliveryDestinations) : undefined),
    [data]
  );
  const businessUnits = useMemo(
    () => (data ? getNodesFromEdges(data?.businessUnits).filter(notEmpty) : undefined),
    [data]
  );

  // we need to re-save filters in local storage for new search query
  // they will have different names and data types, but we don't want to loose them
  useEffect(() => {
    const newFilters = getItem(NEW_SEARCH_LOCAL_STORAGE_FILTERS_NAME);
    if (!newFilters) {
      const oldFilters = getItem(LOCAL_STORAGE_FILTERS_NAME) as AdvancedFilter<FilterName>[];
      if (oldFilters) {
        // load all of them because we need to filter them by each other
        loadResavingData();
      } else {
        setResavingFinished(true);
      }
    } else {
      setResavingFinished(true);
    }
  }, [loadResavingData]);

  useEffect(() => {
    const newFilters = getItem(NEW_SEARCH_LOCAL_STORAGE_FILTERS_NAME);
    if (!newFilters) {
      const oldFilters = getItem(LOCAL_STORAGE_FILTERS_NAME) as
        | AdvancedFilter<FilterName>[]
        | undefined;
      // if data was loaded
      if (businessUnits && distributionCenters && shipTos && tradingPartners && retailerChannels) {
        const newFilters = convertOldFiltersToNewSearchFilters(
          oldFilters,
          tradingPartners,
          retailerChannels,
          distributionCenters,
          shipTos,
          businessUnits
        );
        setItem(NEW_SEARCH_LOCAL_STORAGE_FILTERS_NAME, newFilters);
        setResavingFinished(true);
      }
    }
  }, [businessUnits, distributionCenters, shipTos, tradingPartners, retailerChannels]);

  return resavingFinished;
};

const convertOldFiltersToNewSearchFilters = (
  oldAdvancedFilters: AdvancedFilter<FilterName>[] | undefined,
  tradingPartners?: TradingPartner[],
  retailerChannels?: RetailerChannel[],
  distributionCenters?: DistributionCenter[],
  shipTos?: ShipTo[],
  businessUnits?: BusinessUnit[]
): AdvancedFilter<NewFilterName>[] => {
  return (oldAdvancedFilters || []).map(({ name, description, filters: oldFilters }) => {
    const filteredDependsItemsByEachOther = filterDependsItemsByEachOther(
      oldFilters,
      tradingPartners ?? [],
      retailerChannels ?? [],
      businessUnits ?? [],
      shipTos ?? [],
      distributionCenters ?? []
    );
    return {
      name,
      description,
      filters: oldFilters.map((oldFilter): Filter<NewFilterName> => {
        switch (oldFilter.name) {
          case 'tradingPartners':
            return {
              name: 'tradingPartnerIds',
              value: filteredDependsItemsByEachOther.tradingPartnerIds
            };
          case 'businessUnits':
            return {
              name: 'businessUnitIds',
              value: filteredDependsItemsByEachOther.businessUnitIds
            };
          case 'customers':
            return {
              name: 'retailerChannelIds',
              value: filteredDependsItemsByEachOther.retailerChannelIds
            };
          case 'distributionCenters':
            return {
              name: 'distributionCenterIds',
              value: filteredDependsItemsByEachOther.distributionCenterIds
            };
          case 'deliveryDestinations':
            return {
              name: 'deliveryDestinationIds',
              value: filteredDependsItemsByEachOther.shipToIds,
              option: oldFilter.option
            };
          case 'customerPos':
            return { name: 'searchIds', value: oldFilter.value };
          case 'labels':
          case 'dateReceived':
          case 'deliveryWindow':
          case 'mustArriveByDate':
          case 'shipmentsRange':
          case 'salesOrderMustArriveByDate':
          case 'withCuts':
          case 'purchaseOrderConfirmation':
          case 'purchaseOrderErrors':
          case 'deliveryType':
          default:
            return {
              name: oldFilter.name,
              value: oldFilter.value,
              option: oldFilter.option
            };
        }
      })
    } as AdvancedFilter<NewFilterName>;
  });
};

const filterDependsItemsByEachOther = (
  oldFilters: Filter<FilterName>[],
  tradingPartners: TradingPartner[],
  retailerChannels: RetailerChannel[],
  businessUnits: BusinessUnit[],
  shipTos: ShipTo[],
  distributionCenters: DistributionCenter[]
) => {
  const tradingPartnerExternalIds = oldFilters.find((f) => f.name === 'tradingPartners')?.value as
    | string[]
    | undefined;
  const retailerChannelExternalIds = oldFilters.find((f) => f.name === 'customers')?.value as
    | string[]
    | undefined;
  const deliveryTypes = oldFilters.find((f) => f.name === 'deliveryType')?.value as
    | string[]
    | undefined;
  const businessUnitExternalIds = oldFilters.find((f) => f.name === 'businessUnits')?.value as
    | string[]
    | undefined;
  const shipToExternalIds = oldFilters.find((f) => f.name === 'deliveryDestinations')?.value as
    | string[]
    | undefined;
  const distributionCenterExternalIds = oldFilters.find((f) => f.name === 'distributionCenters')
    ?.value as string[] | undefined;

  const tradingPartnersByExternalIds = tradingPartnerExternalIds?.flatMap((externalId) =>
    (tradingPartners || []).filter((tp) => tp.externalId === externalId)
  );
  const retailerChannelsByExternalIds = retailerChannelExternalIds?.flatMap((externalId) =>
    (retailerChannels || []).filter((rc) => rc.name === externalId)
  );
  const businessUnitsByExternalIds = businessUnitExternalIds?.flatMap((externalId) =>
    (businessUnits || []).filter((bu) => bu.code === externalId)
  );
  const shipTosByExternalIds = shipToExternalIds?.flatMap((externalId) =>
    (shipTos || []).filter((st) => st.externalId === externalId)
  );
  const distributionCentersByExternalIds = distributionCenterExternalIds?.flatMap((externalId) =>
    (distributionCenters || []).filter((dc) => dc.externalId === externalId)
  );

  // since deliveryType, RC and BU provides for TP, we should use only TPs which were filtered by this fields
  const filteredTps = (tradingPartnersByExternalIds ?? tradingPartners).filter(
    (tp) =>
      (!deliveryTypes || deliveryTypes.includes(tp.deliveryType ?? '')) &&
      (!businessUnitsByExternalIds ||
        businessUnitsByExternalIds.some((bu) => bu.id === tp.businessUnit?.id)) &&
      (!retailerChannelsByExternalIds ||
        retailerChannelsByExternalIds.some((rc) => rc.id === tp.vendorMarket.id))
  );

  // filter ShipTos because we have items with the same external IDs
  const filteredShipTos = shipTosByExternalIds?.filter((st) => {
    const tpIdsFromShipTo = st.tradingPartnerRetailerDeliveryDestinations.map(
      (tpRdd) => tpRdd.tradingPartner?.id || ''
    );
    return !!tpIdsFromShipTo.some((id) => filteredTps.map((tp) => tp.id).includes(id));
  });

  return {
    tradingPartnerIds: tradingPartnersByExternalIds?.map((tp) => tp.id),
    businessUnitIds: businessUnitsByExternalIds?.map((bu) => bu.id),
    retailerChannelIds: retailerChannelsByExternalIds?.map((rc) => rc.id),
    distributionCenterIds: distributionCentersByExternalIds?.map((dc) => dc.id),
    shipToIds: filteredShipTos?.map((st) => st.id)
  };
};
