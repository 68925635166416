import * as Types from '../../../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ExecutiveIntelligenceServiceOtifPurchaseOrdersReportsByPeriodQueryVariables = Types.Exact<{
  filters: Types.ExecutiveReportingFilters;
}>;


export type ExecutiveIntelligenceServiceOtifPurchaseOrdersReportsByPeriodQuery = { __typename?: 'RootQueryType', periodReport: Array<{ __typename?: 'OtifReportByPeriod', acceptedFillRate: any, approximateLostOpportunity: number, inFull: any, onTime: any, orderCount: number, otif: any, submittedFillRate: any, rsvValue: any, fiscalCalendarWeek: { __typename?: 'FiscalCalendarWeek', period: number, week: number, year: number } }> };


export const ExecutiveIntelligenceServiceOtifPurchaseOrdersReportsByPeriodDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"executiveIntelligenceServiceOtifPurchaseOrdersReportsByPeriod"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"filters"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ExecutiveReportingFilters"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"periodReport"},"name":{"kind":"Name","value":"otifPurchaseOrdersReportsByPeriod"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filters"},"value":{"kind":"Variable","name":{"kind":"Name","value":"filters"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"acceptedFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"approximateLostOpportunity"}},{"kind":"Field","name":{"kind":"Name","value":"fiscalCalendarWeek"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"period"}},{"kind":"Field","name":{"kind":"Name","value":"week"}},{"kind":"Field","name":{"kind":"Name","value":"year"}}]}},{"kind":"Field","name":{"kind":"Name","value":"inFull"}},{"kind":"Field","name":{"kind":"Name","value":"onTime"}},{"kind":"Field","name":{"kind":"Name","value":"orderCount"}},{"kind":"Field","name":{"kind":"Name","value":"otif"}},{"kind":"Field","name":{"kind":"Name","value":"submittedFillRate"}},{"kind":"Field","name":{"kind":"Name","value":"rsvValue"}}]}}]}}]} as unknown as DocumentNode<ExecutiveIntelligenceServiceOtifPurchaseOrdersReportsByPeriodQuery, ExecutiveIntelligenceServiceOtifPurchaseOrdersReportsByPeriodQueryVariables>;