import { StatChangeIndicatorProps } from 'components/ui/StatChangeIndicator/StatChangeIndicator';

export function calculateChange(
  actualPeriodValue: number,
  comparisonPeriodValue: number,
  shouldShowChange: boolean,
  isMoreBetter: boolean,
  valueFormatter: (string: string) => string
): StatChangeIndicatorProps | undefined {
  const diff = actualPeriodValue - comparisonPeriodValue;

  if (
    actualPeriodValue !== undefined &&
    comparisonPeriodValue !== undefined &&
    shouldShowChange &&
    !Number.isNaN(diff)
  ) {
    const isPositive = diff > 0 ? isMoreBetter : diff < 0 ? !isMoreBetter : undefined;

    return {
      value: valueFormatter(Math.abs(diff).toString()),
      change: diff > 0 ? ('increase' as const) : diff < 0 ? ('decrease' as const) : undefined,
      isPositive: isPositive
    };
  }

  // Return undefined if conditions are not met
  return undefined;
}
