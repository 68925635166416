import { Button, Select } from 'antd';
import moment from 'moment';
import { OrdersTable } from './components/OrdersTable/OrdersTable';
import React, { useContext, useMemo, useState } from 'react';
import {
  ArrayParam,
  JsonParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault
} from 'use-query-params';
import s from './Visibility.module.scss';
import { AvailableFillRateReportFiltersDocument } from './gql/__generated__/availableFilters.query';
import { useQuery } from '@apollo/client';
import { Stats } from './components/Stats/Stats';
import {
  FillRateReportFiscalCalendarWeekInput,
  AppliedFillRateReportFilters
} from 'graphql/__generated__/types';
import { RoundedRadioButton } from 'components/RoundedRadioButton';
import { DisplayMode, MetricItemWithArrayIndex } from './types';
import { safeLocaleCompare } from 'common/helpers/comparators';
import { upperFirst, isEqual, range } from 'lodash-es';
import { PeriodCalendar } from 'components/PeriodCalendar/PeriodCalendar';
import { CalendarValue, Mode } from 'components/PeriodCalendar/types';
import { FiscalCalendarWeek } from 'common/interfaces';
import { getFinancialInfo, isSundayToday } from 'common/helpers/fiscalCalendar';
import { UserContext } from 'context/userContext';

import { ExecutiveMetrics } from './components/ExecutiveMetrics/ExecutiveMetrics';
import {
  backendDateFormat,
  getCurrentPeriod,
  transformCalendarValueToFiscalWeeks
} from 'components/PeriodCalendar/helpers';
import annotationPlugin from 'chartjs-plugin-annotation';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Legend,
  Tooltip as ChartTooltip,
  LineController
} from 'chart.js';
import { TopOpportunities } from './components/TopOpportunities/TopOpportunities';
import { ProductsViewTable } from './components/ProductsViewTable/ProductsViewTable';
import { PrimeDayProduct } from './components/ProductsViewTable/mapper';
import { LastUpdated } from './components/LastUpdated/LastUpdated';
import { getValueWithAll, setArrayWithAll } from 'common/helpers/selectAll';
import { OnTimeShipments } from './components/OnTimeShipments/OnTimeShipments';
import { useDeepCompareMemo } from 'use-deep-compare';
import ProductViewContextProvider from './components/ProductsViewTable/ProductViewContext';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTooltip,
  Legend,
  LineController,
  annotationPlugin
);

const CURRENT_YEAR = new Date().getFullYear();
const { period: CURRENT_PERIOD } = getFinancialInfo(new Date());

const { Option } = Select;

const getDefaultPeriod = () => {
  const { week } = getCurrentPeriod(true);
  const end = week;
  const start = Math.max(1, week - 3);
  return { start, end };
};

function getUpcomingPeriodFilter(daysFromToday: number): FillRateReportFiscalCalendarWeekInput[] {
  const startDate = moment();
  const endDate = moment().add(daysFromToday, 'd');
  return [
    {
      period: 1,
      week: 1,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    }
  ];
}

function getPastDuePeriodFilter(
  periods: FiscalCalendarWeek[]
): FillRateReportFiscalCalendarWeekInput[] {
  const now = moment();

  const onlyPeriodsFromPast = periods.filter((x) => moment(x.startDate).isBefore(now));

  const currentPeriod = onlyPeriodsFromPast.find((x) =>
    now.isBetween(moment(x.startDate, backendDateFormat), moment(x.endDate, backendDateFormat))
  );

  if (currentPeriod) {
    // If current day is Saturday, then past-dues will not include current period, so remove it.
    // This should not happen usually, but we need to account for that :)
    if (isSundayToday()) {
      return onlyPeriodsFromPast.filter((x) => x !== currentPeriod);
    } else {
      // Modify "current" period to include only up to today
      currentPeriod.endDate = moment().subtract(1, 'd').format(backendDateFormat);
    }
  }
  return onlyPeriodsFromPast;
}

function getPeriodFilter(
  daysFromToday: number,
  mode: DisplayMode,
  period: CalendarValue
): FillRateReportFiscalCalendarWeekInput[] {
  if (mode === 'upcoming') return getUpcomingPeriodFilter(daysFromToday);
  // past treated differently since we don't want to include a whole weeek, we want to include dates up to (but not including) today
  if (mode === 'past') return getPastDuePeriodFilter(transformCalendarValueToFiscalWeeks(period));
  return transformCalendarValueToFiscalWeeks(period);
}

export const Visibility = () => {
  const { isAdmin } = useContext(UserContext);
  const [selectedRows, setSelectedRows] = useState<MetricItemWithArrayIndex[]>([]);
  const resetSelectedRows = () => setSelectedRows([]);
  const [selectedProductRows, setSelectedProductRows] = useState<PrimeDayProduct[]>([]);
  const resetSelectedProductRows = () => setSelectedProductRows([]);

  const [exportVisible, setExportVisible] = useState(false);
  const [exportProductsVisible, setExportProductsVisible] = useState(false);

  const [customers, setCustomers] = useQueryParam('customers', withDefault(ArrayParam, ['all']));
  const [businessUnits, setBusinessUnits] = useQueryParam('bu', withDefault(ArrayParam, ['all']));

  const DEFAULT_TAB: DisplayMode = 'products';
  const [mode, setMode] = useQueryParam('mode', withDefault(StringParam, DEFAULT_TAB));
  const [days, setDays] = useQueryParam('days', withDefault(NumberParam, 0));
  const [pastPeriod, setPastPeriod] = useQueryParam<CalendarValue>(
    'past_period',
    withDefault(JsonParam, {
      mode: Mode.PERIOD_WEEK,
      isRange: true,
      years: [CURRENT_YEAR],
      ...getDefaultPeriod()
    })
  );

  const [summaryPeriod, setSummaryPeriod] = useQueryParam<CalendarValue>(
    'summary_period',
    withDefault(JsonParam, {
      mode: Mode.PERIOD,
      years: [CURRENT_YEAR],
      periods: range(1, CURRENT_PERIOD + 1)
    })
  );

  const { data: filterData, loading: filterLoading } = useQuery(
    AvailableFillRateReportFiltersDocument
  );

  const customersOptions = useMemo(
    () =>
      [...(filterData?.availableFillRateReportFilters?.vendorMarkets || [])].sort((a, b) =>
        safeLocaleCompare(a?.name, b?.name)
      ),
    [filterData]
  );
  const businessUnitsOptions = useMemo(
    () =>
      [...(filterData?.availableFillRateReportFilters?.subBusinessUnits || [])].sort((a, b) =>
        safeLocaleCompare(a?.name, b?.name)
      ),
    [filterData]
  );

  const businessUnitIds = getValueWithAll(
    businessUnits as string[],
    businessUnitsOptions.map((x) => x.id)
  );

  const vendorMarketIds = getValueWithAll(
    customers as string[],
    customersOptions.map((x) => x.id)
  );

  // Usual useMemo is not enough because values returned by useQueryParam are not referentially stable.
  const filters: AppliedFillRateReportFilters = useDeepCompareMemo(
    () => ({
      subBusinessUnitIds: businessUnitIds,
      vendorMarketIds,
      fiscalCalendarWeeks: getPeriodFilter(days, mode as DisplayMode, pastPeriod),
      referenceDateType: 'DELIVERY_DATE'
    }),
    [businessUnitIds, days, mode, pastPeriod, vendorMarketIds]
  );

  const productViewFilter = useDeepCompareMemo(
    () => ({
      subBusinessUnitIds: filters.subBusinessUnitIds,
      vendorMarketIds: filters.vendorMarketIds,
      fiscalCalendarWeeks: filters.fiscalCalendarWeeks
    }),
    [filters.fiscalCalendarWeeks, filters.subBusinessUnitIds, filters.vendorMarketIds]
  );

  // TODO: rewrite as routes later?
  const allModes: { key: DisplayMode; title: string }[] = [
    { key: 'summary', title: 'Executive Summary' },
    { key: 'upcoming', title: 'Upcoming Shipments' },
    { key: 'past', title: 'Past Due Shipments' },
    { key: 'products', title: 'Product View' }
  ];

  const availableModes = isAdmin()
    ? allModes
    : allModes.filter((x) => !['summary'].includes(x.key));

  return (
    <>
      <div className={s.header}>
        <div className={s.page_title}>
          <span className={s.title}>Order Visibility</span>
          <LastUpdated />
        </div>
        <div className={s.page_filters}>
          <Select
            listItemHeight={32}
            loading={filterLoading}
            className={s.select}
            value={customers}
            mode="multiple"
            showSearch={false}
            showArrow
            onChange={(value) => {
              if (!isEqual(value, customers) && value?.length > 0) {
                resetSelectedRows();
                resetSelectedProductRows();
              }
              setArrayWithAll(value as string[], customers as string[], setCustomers);
            }}
            dropdownMatchSelectWidth={300}
            maxTagCount="responsive"
          >
            <Option value="all">All Customers</Option>
            {(customersOptions || []).map((customer) => (
              <Option key={customer.id} value={customer.id}>
                {upperFirst(customer.name)}
              </Option>
            ))}
          </Select>
          <Select
            listItemHeight={32}
            loading={filterLoading}
            className={s.select}
            value={businessUnits}
            mode="multiple"
            showSearch={false}
            showArrow
            onChange={(value) => {
              if (!isEqual(value, businessUnits) && value?.length > 0) {
                resetSelectedRows();
                resetSelectedProductRows();
              }
              setArrayWithAll(value as string[], businessUnits as string[], setBusinessUnits);
            }}
            maxTagCount="responsive"
          >
            <Option value="all">All BUs</Option>
            {(businessUnitsOptions || []).map((bu) => (
              <Option key={bu.id} value={bu.id}>
                {bu.name}
              </Option>
            ))}
          </Select>
          {mode === 'past' || mode === 'products' ? (
            <PeriodCalendar
              value={pastPeriod}
              onChange={setPastPeriod}
              years={range(CURRENT_YEAR - 5, CURRENT_YEAR + 1)}
            />
          ) : null}
          {mode === 'summary' ? (
            <PeriodCalendar
              years={range(2016, CURRENT_YEAR + 1)}
              value={summaryPeriod}
              onChange={setSummaryPeriod}
            />
          ) : null}
          {(mode === 'past' || mode === 'upcoming') && (
            <Button
              data-testid="export-button"
              onClick={() => setExportVisible(true)}
              size="large"
              style={{ minWidth: '180px' }}
              className="filled_grey_btn_no_border"
            >
              Export{' '}
              {selectedRows?.length > 0
                ? `${selectedRows.length} PO${selectedRows.length === 1 ? '' : 's'}`
                : 'view'}
            </Button>
          )}
          {mode === 'products' && (
            <Button
              data-testid="export-products-button"
              onClick={() => setExportProductsVisible(true)}
              size="large"
              style={{ minWidth: '180px' }}
              className="filled_grey_btn_no_border"
            >
              Export{' '}
              {selectedProductRows?.length > 0
                ? `${selectedProductRows.length} product${
                    selectedProductRows.length === 1 ? '' : 's'
                  }`
                : 'view'}
            </Button>
          )}
        </div>
      </div>
      <div className={s.modeSelector}>
        <RoundedRadioButton
          buttons={availableModes}
          value={mode}
          onChange={(key) => {
            resetSelectedRows();
            resetSelectedProductRows();
            setMode(key);
          }}
        />
      </div>
      {mode === 'products' && (
        <div>
          <ProductViewContextProvider>
            <ProductsViewTable
              filters={productViewFilter}
              selectedRows={selectedProductRows}
              setSelectedRows={setSelectedProductRows}
              exportModalVisible={exportProductsVisible}
              setExportModalVisible={setExportProductsVisible}
              resetSelectedRows={resetSelectedProductRows}
            />
          </ProductViewContextProvider>
        </div>
      )}
      {mode === 'summary' && (
        <div>
          <ExecutiveMetrics />
          <OnTimeShipments
            period={summaryPeriod}
            businessUnitIds={businessUnitIds}
            vendorMarketIds={vendorMarketIds}
          />
          <TopOpportunities
            period={summaryPeriod}
            businessUnitIds={businessUnitIds}
            vendorMarketIds={vendorMarketIds}
          />
        </div>
      )}
      {['upcoming', 'past'].includes(mode) && (
        <>
          <Stats filters={filters} mode={mode as DisplayMode} />
          <div className={s.table_header}>
            {mode === 'upcoming' ? (
              <>
                <h2 className={s.title}>Upcoming Shipment</h2>
                <span className={s.due}>Due </span>
                <Select
                  value={days}
                  showArrow
                  onChange={(value) => {
                    if (!isEqual(value, days)) {
                      resetSelectedRows();
                    }
                    setDays(value as number);
                  }}
                  bordered={false}
                  dropdownStyle={{ minWidth: '120px', fontWeight: 'bold' }}
                >
                  <Option value={0}>Today</Option>
                  {[1, 2, 3, 4, 5, 6, 7].map((days) => (
                    <Option key={days} value={days}>
                      Today +{days} Day{days === 1 ? '' : 's'}
                    </Option>
                  ))}
                </Select>
              </>
            ) : (
              <h2 className={s.title}>Past Due Shipment</h2>
            )}
          </div>
          <OrdersTable
            filters={filters}
            mode={mode as DisplayMode}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            exportModalVisible={exportVisible}
            setExportModalVisible={setExportVisible}
            resetSelectedRows={resetSelectedRows}
          />
        </>
      )}
    </>
  );
};
