import React from 'react';
import s from './FieldInfo.module.scss';

interface FieldInfoProps {
  error?: string;
  info?: string;
  disabled?: boolean;
}

export const FieldInfo = ({ error, info, disabled, ...rest }: FieldInfoProps) => {
  return (
    <p className={s.info} aria-disabled={disabled} data-haserror={!!error} {...rest}>
      {error || info || ''}
    </p>
  );
};
