import React, { useMemo } from 'react';
import s from './Stats.module.scss';
import { StatsButton, StatsTile } from 'components/StatsTile/StatsTile';
import { InventoryReconciliationFilters } from 'graphql/__generated__/types';
import { useQuery } from '@apollo/client';
import { InventoryReconciliationTotalsDocument } from './gql/__generated__/inventoryReconciliationDetails.query';
import { EMPTY, NOT_AVAILABLE } from 'common/constants';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { useFeatureFlag } from 'common/useFeatureFlags/useFeatureFlags';
import { isEmpty } from 'lodash-es';
import { RECONCILIATION_FF } from 'common/useFeatureFlags/featureFlags';
export const PERFECT_SWAP = 'perfect-swap';

export const Stats = ({ filters }: { filters: InventoryReconciliationFilters }) => {
  const { enabled: isReconciliationPhaseTwoEnabled } = useFeatureFlag({ name: RECONCILIATION_FF });

  const areSignificantFiltersEmpty = useMemo(
    () =>
      isEmpty(filters.smartSearchTerms) &&
      isEmpty(filters.unitsOfMeasure) &&
      !filters.perfectSwapBatchesOnly &&
      typeof filters.variance === 'undefined',
    [filters]
  );

  const [displayFilter, setDisplayFilter] = useQueryParam(
    'display-filter',
    withDefault(StringParam, undefined)
  );

  const { data, loading, error } = useQuery(InventoryReconciliationTotalsDocument, {
    variables: {
      filters
    }
  });

  return (
    <div className={s.stats}>
      <StatsTile
        title="Total Product Batches"
        count={data?.inventoryReconciliationTotals?.totalProducts ?? NOT_AVAILABLE}
        loading={loading}
        error={!!error}
      />
      <StatsTile
        title="Batches with Discrepancy"
        count={data?.inventoryReconciliationTotals?.productsWithDiscrepancy ?? NOT_AVAILABLE}
        loading={loading}
        error={!!error}
      />
      {areSignificantFiltersEmpty && (
        <StatsTile
          title="Inventory Accuracy"
          count={parseFloat(data?.inventoryReconciliationTotals?.inventoryAccuracy)}
          loading={loading}
          error={!!error}
          percentage
          maximumFractionDigits={2}
        />
      )}
      {!isReconciliationPhaseTwoEnabled && (
        <StatsButton
          title="Perfect Batch Swaps"
          count={
            data?.inventoryReconciliationTotals?.perfectBatchSwapCount
              ? `${data?.inventoryReconciliationTotals?.perfectBatchSwapCount} Swaps`
              : EMPTY
          }
          loading={loading}
          error={!!error}
          isActive={displayFilter === PERFECT_SWAP}
          onClick={() => {
            displayFilter === PERFECT_SWAP
              ? setDisplayFilter(undefined)
              : setDisplayFilter(PERFECT_SWAP);
          }}
        />
      )}
    </div>
  );
};
