import { acronymsToUpperCase, camelCaseToSpaces } from 'common/helpers/stringsConverter';
import {
  ChangeHistoryEntityType,
  ChangeHistoryItem,
  fieldNameToString,
  getEventNameByAction
} from './baseHelper';

export const tpIntegrationConfigs = [
  'channelAdvisorConfig',
  'ediConfigExternal',
  'ediConfigInternal',
  'emailConfigSalesOrderExport',
  'emailConfigSalesOrderRelease',
  'ediMessageConfiguration',
  'purchaseOrderCustomization850',
  'purchaseOrderAckCustomization855',
  'purchaseOrderCustomization856',
  'purchaseOrderCustomization810',
  'ediSelfServiceConfig',
  'internalAlertConfig',
  'internalAlertEmailConfig',
  'ochConfig',
  'pepdirectConfig',
  'shippingConfig',
  'amazonSellingPartnerConfig',
  'samsClubConfig',
  'invoiceConfig'
];

export const tpGeneralSettingConfigs = [
  'processingFlow',
  'storeConfig',
  'poValidationConfig',
  'validationConfig',
  'sourcingRules'
];
export const tpIntegrationSubconfigs = [
  'edi850Config',
  'edi855Config',
  'edi856Config',
  'edi810Config'
];

export const getTradingPartnerEventName = (
  path: string,
  simpleChanges: ChangeHistoryItem[],
  type: ChangeHistoryEntityType,
  eventType: string
) => {
  const pathFirstPart = path.split('.')[1];
  const changedConfig = [
    ...tpIntegrationConfigs,
    ...tpGeneralSettingConfigs,
    'assortmentConfig'
  ].includes(pathFirstPart);
  if (changedConfig) {
    const configName = fieldNameToString(pathFirstPart, type, path);
    if (pathFirstPart === 'pepdirectConfig' && path.includes('customizationsHeaders')) {
      return getEventNameByAction(simpleChanges, `${configName} Customization Header`);
    }
    if (pathFirstPart === 'assortmentConfig' && path.includes('fields')) {
      const fieldName = path.split('.')[3];
      if (path.includes('validation')) {
        return getEventNameByAction(
          simpleChanges,
          `${configName} ${acronymsToUpperCase(camelCaseToSpaces(fieldName))} field validation`
        );
      }
      return getEventNameByAction(
        simpleChanges,
        `${configName} ${acronymsToUpperCase(camelCaseToSpaces(fieldName))} field`,
        { before: false, value: 'turned on' },
        { before: false, value: 'turned off' }
      );
    }
    if (pathFirstPart === 'sourcingRules') {
      return getEventNameByAction(
        simpleChanges,
        'Realese Rule',
        { before: false, value: ' was added' },
        { before: false, value: ' was removed' }
      );
    }
    if (pathFirstPart === 'ediSelfServiceConfig') {
      // skip first part
      const pathParts = path.split('.');
      if (path.length > 2) {
        if (pathParts[2] === 'ediMessageConfig') {
          if (pathParts.length > 4) {
            // custom fields
            return getEventNameByAction(
              simpleChanges,
              fieldNameToString(pathParts[pathParts.length - 2], type, path),
              { before: false, value: 'added' },
              { before: false, value: 'removed' }
            );
          }
          // show subconfig
          return getEventNameByAction(
            simpleChanges,
            fieldNameToString(pathParts[3], type, path),
            { before: false, value: 'turned on' },
            { before: false, value: 'turned off' }
          );
        } else {
          // global settings
          return getEventNameByAction(
            simpleChanges,
            `EDI message config`,
            { before: false, value: 'turned on' },
            { before: false, value: 'turned off' }
          );
        }
      }
    }
    return getEventNameByAction(
      simpleChanges,
      configName,
      { before: false, value: 'turned on' },
      { before: false, value: 'turned off' }
    );
  }
  return `${eventType} ${type}`;
};
