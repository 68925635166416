import React, { useState, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import s from './Event.module.scss';
import { PeriodCalendar } from 'components/PeriodCalendar/PeriodCalendar';
import { upperFirst, range } from 'lodash-es';
import { getFinancialInfo, isSundayToday } from 'common/helpers/fiscalCalendar';
import { ArrayParam, JsonParam, NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { CalendarValue, Mode } from 'components/PeriodCalendar/types';
import { MockData } from '../EventsTable/mockData';
import { EditEventDrawer } from '../EventsTable/components/EditEventDrawer/EditEventDrawer';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { useQuery } from '@apollo/client';
import { AvailableFillRateReportFiltersDocument } from 'pages/Visibility/gql/__generated__/availableFilters.query';
import { DisplayMode } from 'pages/Visibility/types';
import { getValueWithAll, setArrayWithAll } from 'common/helpers/selectAll';
import { safeLocaleCompare } from 'common/helpers/comparators';
import {
  FillRateReportFiscalCalendarWeekInput,
  AppliedFillRateReportFilters
} from 'graphql/__generated__/types';
import { useDeepCompareMemo } from 'use-deep-compare';
import moment from 'moment';
import {
  backendDateFormat,
  transformCalendarValueToFiscalWeeks
} from 'components/PeriodCalendar/helpers';
import { FiscalCalendarWeek } from 'common/interfaces';
import ProductViewContextProvider from 'pages/Visibility/components/ProductsViewTable/ProductViewContext';
import { ProductsViewTable } from 'pages/Visibility/components/ProductsViewTable/ProductsViewTable';
import { getCurrentReferenceDate, getCurrentPeriod } from 'components/PeriodCalendar/helpers';

const { Option } = AlloySelect;

const CURRENT_YEAR = new Date().getFullYear();
const CURRENT_PERIOD = getCurrentPeriod();

function getPeriodFilter(
  daysFromToday: number,
  mode: DisplayMode,
  period: CalendarValue
): FillRateReportFiscalCalendarWeekInput[] {
  if (mode === 'upcoming') return getUpcomingPeriodFilter(daysFromToday);
  // past treated differently since we don't want to include a whole weeek, we want to include dates up to (but not including) today
  if (mode === 'past') return getPastDuePeriodFilter(transformCalendarValueToFiscalWeeks(period));
  return transformCalendarValueToFiscalWeeks(period);
}

function getUpcomingPeriodFilter(daysFromToday: number): FillRateReportFiscalCalendarWeekInput[] {
  const startDate = moment();
  const endDate = moment().add(daysFromToday, 'd');
  return [
    {
      period: 1,
      week: 1,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD')
    }
  ];
}

function getPastDuePeriodFilter(
  periods: FiscalCalendarWeek[]
): FillRateReportFiscalCalendarWeekInput[] {
  const now = moment();

  const onlyPeriodsFromPast = periods.filter((x) => moment(x.startDate).isBefore(now));

  const currentPeriod = onlyPeriodsFromPast.find((x) =>
    now.isBetween(moment(x.startDate, backendDateFormat), moment(x.endDate, backendDateFormat))
  );

  if (currentPeriod) {
    // If current day is Saturday, then past-dues will not include current period, so remove it.
    // This should not happen usually, but we need to account for that :)
    if (isSundayToday()) {
      return onlyPeriodsFromPast.filter((x) => x !== currentPeriod);
    } else {
      // Modify "current" period to include only up to today
      currentPeriod.endDate = moment().subtract(1, 'd').format(backendDateFormat);
    }
  }
  return onlyPeriodsFromPast;
}

const getDefaultPeriod = () => {
  // If past-mode only, we need to set previous day's period, since otherwise it will be empty.
  // This is a hacky way for past-due, we might rewrite it in future
  const referenceDate = getCurrentReferenceDate();

  const { week } = getFinancialInfo(referenceDate);
  const end = week;
  const start = Math.max(1, week - 3);
  return { start, end };
};

export const Event = ({ match }: RouteComponentProps<{ id: string }>) => {
  const id = match.params.id;
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const event = MockData.find((event) => event.id === id);

  const [customers, setCustomers] = useQueryParam('customers', withDefault(ArrayParam, ['all']));
  const [businessUnits, setBusinessUnits] = useQueryParam('bu', withDefault(ArrayParam, ['all']));
  const [days, setDays] = useQueryParam('days', withDefault(NumberParam, 0));

  const { data: filterData, loading: filterLoading } = useQuery(
    AvailableFillRateReportFiltersDocument
  );

  if (!event) return <div>Event not found</div>;

  const [pastPeriod, setPastPeriod] = useQueryParam<CalendarValue>(
    'past_period',
    withDefault(JsonParam, {
      mode: Mode.PERIOD_WEEK,
      isRange: true,
      years: [CURRENT_YEAR],
      ...getDefaultPeriod()
    })
  );

  const customersOptions = useMemo(
    () =>
      [...(filterData?.availableFillRateReportFilters?.vendorMarkets || [])].sort((a, b) =>
        safeLocaleCompare(a?.name, b?.name)
      ),
    [filterData]
  );

  const businessUnitsOptions = useMemo(
    () =>
      [...(filterData?.availableFillRateReportFilters?.subBusinessUnits || [])].sort((a, b) =>
        safeLocaleCompare(a?.name, b?.name)
      ),
    [filterData]
  );

  const businessUnitIds = getValueWithAll(
    businessUnits as string[],
    businessUnitsOptions.map((x) => x.id)
  );

  const vendorMarketIds = getValueWithAll(
    customers as string[],
    customersOptions.map((x) => x.id)
  );

  // Usual useMemo is not enough because values returned by useQueryParam are not referentially stable.
  const filters: AppliedFillRateReportFilters = useDeepCompareMemo(
    () => ({
      subBusinessUnitIds: businessUnitIds,
      vendorMarketIds,
      fiscalCalendarWeeks: getPeriodFilter(days, 'products', pastPeriod),
      referenceDateType: 'DELIVERY_DATE'
    }),
    [businessUnitIds, days, 'products', pastPeriod, vendorMarketIds]
  );

  const productViewFilter = useDeepCompareMemo(
    () => ({
      subBusinessUnitIds: filters.subBusinessUnitIds,
      vendorMarketIds: filters.vendorMarketIds,
      fiscalCalendarWeeks: filters.fiscalCalendarWeeks
    }),
    [filters.fiscalCalendarWeeks, filters.subBusinessUnitIds, filters.vendorMarketIds]
  );

  //Make sure the user has access to the requested ID
  return (
    <>
      <div className={s.header}>
        <div className={s.page_title}>
          <span className={s.title}>{event.name}</span>
          <a className={s.edit_link} onClick={() => setShowEditDrawer(true)}>
            Edit
          </a>
        </div>
        <div className={s.page_filters}>
          <AlloySelect
            listItemHeight={32}
            loading={filterLoading}
            className={s.select}
            value={customers}
            mode="multiple"
            showSearch={false}
            showArrow
            onChange={(value) => {
              setArrayWithAll(value as string[], customers as string[], setCustomers);
            }}
            dropdownMatchSelectWidth={300}
            maxTagCount="responsive"
          >
            <Option value="all">All Customers</Option>
            {(customersOptions || []).map((customer) => (
              <Option key={customer.id} value={customer.id}>
                {upperFirst(customer.name)}
              </Option>
            ))}
          </AlloySelect>
          <AlloySelect
            listItemHeight={32}
            loading={filterLoading}
            className={s.select}
            value={businessUnits}
            mode="multiple"
            showSearch={false}
            showArrow
            onChange={(value) => {
              setArrayWithAll(value as string[], businessUnits as string[], setBusinessUnits);
            }}
            maxTagCount="responsive"
          >
            <Option value="all">All BUs</Option>
            {(businessUnitsOptions || []).map((bu) => (
              <Option key={bu.id} value={bu.id}>
                {bu.name}
              </Option>
            ))}
          </AlloySelect>
          <PeriodCalendar
            value={pastPeriod}
            onChange={setPastPeriod}
            years={range(CURRENT_YEAR - 5, CURRENT_YEAR + 1)}
          />
        </div>
      </div>
      <ProductViewContextProvider>
        <ProductsViewTable
          filters={productViewFilter}
          selectedRows={[]}
          setSelectedRows={() => {}}
          exportModalVisible={false}
          setExportModalVisible={() => {}}
          resetSelectedRows={() => {}}
        />
      </ProductViewContextProvider>
      <EditEventDrawer
        event={event}
        show={showEditDrawer}
        onClose={() => setShowEditDrawer(false)}
      />
    </>
  );
};
