import React, { useCallback, useMemo } from 'react';
import s from './EditTradingPartnerAssortment.module.scss';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { AlloyTabs } from 'components/ui/AlloyTabs/AlloyTabs';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { concat, merge, uniq, upperCase } from 'lodash-es';
import { ModalAction } from 'common/constants';
import { FormApi } from 'final-form';
import { FormFieldComponent } from './components/FormFieldComponent/FormFieldComponent'; //TODO: find out if I need to do something here now that we have AlloyFormField?
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';
import { validateRequired } from 'components/Common/fields/Utils';
import {
  DEFAULT_FORM_FIELDS_ORDER_ON_FORM,
  SPECIAL_ASSORTMENT_FORM_FIELD,
  sorterBySlugs
} from 'pages/AssortmentPage/fieldsHelper';
import { ShipToField } from './components/ShipToField/ShipToField';
import { SettingOutlined } from '@ant-design/icons';
import {
  AssortmentConfig,
  DistributionCenter,
  RetailerDeliveryDestination
} from 'pages/AssortmentPage/AssortmentPage';
import {
  ProductType,
  TradingPartnerActiveAssortmentMoqUnitOfMeasure,
  TradingPartnerActiveAssortmentRequiredOrderUnitOfMeasure
} from 'graphql/__generated__/types';
import { UpsertTradingPartnerActiveAssortmentMutationVariables } from 'pages/AssortmentPage/gql/__generated__/upsertTradingPartnerActiveAssortment.mutation';
import { Product } from './components/UpcField';
import { PrioritizedList } from 'components/PrioritizedList/PrioritizedList';
import { v4 as uuidv4 } from 'uuid';
import { RetailerProductExternalIdType } from 'graphql/__generated__/types';
import { TradingPartnerAssortmentFullData } from '../ProductDetailsModal/ProductDetailsDrawer';
import { FullScreenEditingModal } from 'components/Common/FullScreenEditingModal/FullScreenEditingModal';
import { VendorProduct } from './components/ExternalIdField';
import { ActiveAssortmentProductType } from 'graphql/__generated__/enums';
import { CatalogProduct } from './components/CatalogProductUpcField';

const DELIVERY_TYPE_MAP: {
  [index: string]: string;
} = {
  DSD: 'DIRECT_STORE_DELIVERY',
  ECOMM: 'ECOMM_DELIVERY',
  WHD: 'WAREHOUSE_DELIVERY'
};

interface EditFormValues {
  vendorFlex: boolean;
  distributionCenters: string[];
  vendorFlexLocations: string[];
  externalId: string;
  name: string;
  upc: string;
  toCaseQuantity: string;
  grossWeight: string;
  sapMaterialId: string;
  width: string;
  height: string;
  depth: string;
  palletCount: string;
  productMoqUnitOfMeasure: TradingPartnerActiveAssortmentMoqUnitOfMeasure;
  productPrice: string;
  productMoqMinimum: string;
  productRequiredOrderUnitOfMeasure: TradingPartnerActiveAssortmentRequiredOrderUnitOfMeasure;
  productCurrency: string;
  productOracleInvenId: string;
  productCasesPerLayer: string;
  productProductType: ProductType;
  productLayersPerPallet: string;
  shipsInOwnContainer: boolean;
  inventoryReserve: string;
  tradingPartner: string;
  shipTo: string[];
  retailerDeliveryDestinationIds: string[];
  multipleUpc: EditFormSubstitution[];
}

interface EditFormSubstitution {
  depth?: number;
  grossWeight?: number;
  height?: number;
  palletCount?: number;
  sapMaterialId?: string;
  upc?: string;
  width?: number;
  productLayersPerPallet?: number;
  productCasesPerLayer?: number;
  shipsInOwnContainer?: boolean;
  productOracleInvenId?: string;
  productProductType?: ProductType;
  key: string;
}

export const spaceRemover = (value: string) => value.trim();

const getModalAction = (retailerProductId: string | undefined) =>
  retailerProductId ? ModalAction.EDIT : ModalAction.ADD;

const getBaseItem = (
  itemInfo: TradingPartnerAssortmentFullData | undefined,
  predefinedValues: Partial<TradingPartnerAssortmentFullData> | undefined
): TradingPartnerAssortmentFullData => {
  return merge(
    merge({ retailerDeliveryDestinationIds: [], substitutions: [{ inventory: [] }] }, itemInfo),
    predefinedValues
  );
};

const isForMultipleUpc = (slug?: string) =>
  slug &&
  [
    'UPC',
    'SAP_MATERIAL_ID',
    'CASES_PER_PALLET',
    'GROSS_WEIGHT',
    'WIDTH',
    'HEIGHT',
    'DEPTH',
    'ORACLE_INVEN_ID',
    'CASES_PER_LAYER',
    'LAYERS_PER_PALLET',
    'SHIPS_IN_OWN_CONTAINER',
    'PRODUCT_TYPE'
  ].includes(slug);

interface EditTradingPartnerAssortmentByConfigProps {
  isNewAssortmentEnabled?: boolean;
  visible: boolean;
  onCancel: () => void;
  retailerProduct: TradingPartnerAssortmentFullData | undefined;
  upsertTradingPartnerAssortment: (
    action: ModalAction,
    params: UpsertTradingPartnerActiveAssortmentMutationVariables['input']
  ) => void;
  tradingPartnerDistributionCenters: DistributionCenter[];
  defaultShipTo?: string[];
  predefinedValues?: Partial<TradingPartnerAssortmentFullData>;
  vendorId: string | undefined;
  tradingPartner: {
    id: string;
    name?: string | null;
    deliveryType?: string | null;
    retailerDeliveryDestinations: RetailerDeliveryDestination[];
  };
  assortmentConfig: AssortmentConfig;
  vendorMarketId: string;
  retailerProductExternalIdType?: RetailerProductExternalIdType;
  changeAddingFlowToUpdate: (retailerProduct: TradingPartnerAssortmentFullData) => void;
}

export interface DeliveryDestination {
  id: string;
  name: string | null | undefined;
  externalId: string | null | undefined;
  specialAssortment: boolean;
}

export const EditTradingPartnerAssortmentByConfig = ({
  isNewAssortmentEnabled,
  visible,
  onCancel,
  retailerProduct,
  tradingPartner,
  tradingPartnerDistributionCenters,
  upsertTradingPartnerAssortment,
  predefinedValues,
  defaultShipTo,
  assortmentConfig,
  vendorId,
  vendorMarketId,
  retailerProductExternalIdType,
  changeAddingFlowToUpdate
}: EditTradingPartnerAssortmentByConfigProps) => {
  const formFields = (
    assortmentConfig.specialAssortment
      ? [...assortmentConfig.fields, SPECIAL_ASSORTMENT_FORM_FIELD]
      : [...assortmentConfig.fields]
  ).sort(sorterBySlugs(DEFAULT_FORM_FIELDS_ORDER_ON_FORM));

  const editingItem = useMemo<TradingPartnerAssortmentFullData | undefined>(
    () => getBaseItem(retailerProduct, predefinedValues),
    [predefinedValues, retailerProduct]
  );

  const action = useMemo(
    () => getModalAction(editingItem?.retailerProductId),
    [editingItem?.retailerProductId]
  );

  const collectedRdds = useMemo(
    () =>
      (tradingPartner.retailerDeliveryDestinations || [])
        .filter((rdd) => rdd?.active)
        .map((rdd) => ({
          id: rdd?.id,
          name: rdd?.name,
          externalId: rdd?.externalId,
          specialAssortment: rdd?.specialAssortment
        })),
    [tradingPartner.retailerDeliveryDestinations]
  );

  const changeToUpdate = useCallback(
    (item: VendorProduct) => {
      changeAddingFlowToUpdate(
        getBaseItem(
          {
            rddIds: [],
            retailerProductId: item.id,
            retailerProductExternalId: item.externalId,
            substitutions: item.substitutionProducts?.map((substitution) => {
              return {
                inventory: [],
                casesPerLayer: substitution.product.casesPerLayer,
                casesPerPallet: substitution.product.quantityPerPallet,
                currency: item.tradingPartnerVendorProducts?.find(
                  (tpvp) => tpvp?.tradingPartnerId === tradingPartner.id
                )?.currency,
                depth: substitution.product.depth,
                grossWeight: substitution.product.weightInPounds,
                grossWeightInKg: substitution.product.weightInKilograms,
                height: substitution.product.height,
                inventoryReserve: item.tradingPartnerVendorProducts?.find(
                  (tpvp) => tpvp?.tradingPartnerId === tradingPartner.id
                )?.inventoryReserve,
                layersPerPallet: substitution.product.layersPerPallet,
                moqMinimum: item.tradingPartnerVendorProducts?.find(
                  (tpvp) => tpvp?.tradingPartnerId === tradingPartner.id
                )?.moqMinimum,
                moqUnitOfMeasure: item.tradingPartnerVendorProducts?.find(
                  (tpvp) => tpvp?.tradingPartnerId === tradingPartner.id
                )?.moqUnitOfMeasure,
                name: substitution.product.name,
                oracleInvenId: substitution.product.oracleInvenId,
                price: item.tradingPartnerVendorProducts?.find(
                  (tpvp) => tpvp?.tradingPartnerId === tradingPartner.id
                )?.price,
                requiredOrderUnitOfMeasure: item.tradingPartnerVendorProducts?.find(
                  (tpvp) => tpvp?.tradingPartnerId === tradingPartner.id
                )?.requiredOrderUnitOfMeasure,
                sapMaterialId: substitution.product.sapMaterialId,
                shipsInOwnContainer: substitution.product.shipsInOwnContainer,
                toCaseQuantity: item.toCaseQuantity,
                upc: substitution.product.upc,
                width: substitution.product.width,
                productType: substitution.product.productType
                  ? (upperCase(substitution.product.productType) as ProductType)
                  : undefined,
                activeDcIds: []
              };
            }),
            updatedAt: undefined
          },
          undefined
        )
      );
    },
    [changeAddingFlowToUpdate, tradingPartner.id]
  );

  const submitForm = (values: EditFormValues) => {
    const {
      productMoqUnitOfMeasure,
      productPrice,
      productMoqMinimum,
      productRequiredOrderUnitOfMeasure,
      productCurrency,
      productCasesPerLayer,
      productLayersPerPallet,
      productOracleInvenId
    } = values;

    const multipleUpcUsed =
      !!assortmentConfig.multipleUpc || !!assortmentConfig.useMultipleSubstitutions;

    const mapSubstitutionFromForm = (multipleValue: any, index: number) => ({
      productCasesPerPallet:
        multipleUpcUsed || index !== 0
          ? parseInt(multipleValue?.palletCount)
          : parseInt(values?.palletCount),
      productDepth:
        multipleUpcUsed || index !== 0
          ? parseFloat(multipleValue?.depth)
          : parseFloat(values?.depth),
      productGrossWeight:
        multipleUpcUsed || index !== 0
          ? parseFloat(multipleValue?.grossWeight)
          : parseFloat(values?.grossWeight),
      productHeight:
        multipleUpcUsed || index !== 0
          ? parseFloat(multipleValue?.height)
          : parseFloat(values?.height),
      productName: values.name,
      productSapMaterialId:
        multipleUpcUsed || index !== 0 ? multipleValue?.sapMaterialId : values?.sapMaterialId,
      productUpc: multipleUpcUsed || index !== 0 ? multipleValue?.upc : values?.upc,
      productWidth:
        multipleUpcUsed || index !== 0
          ? parseFloat(multipleValue?.width)
          : parseFloat(values?.width),
      productShipsInOwnContainer:
        multipleUpcUsed || index !== 0
          ? multipleValue?.shipsInOwnContainer
          : values?.shipsInOwnContainer,
      productMoqUnitOfMeasure: productMoqUnitOfMeasure,
      productPrice: productPrice,
      productProductType:
        multipleUpcUsed || index !== 0
          ? multipleValue?.productProductType
          : values?.productProductType,
      productMoqMinimum: parseInt(productMoqMinimum),
      productRequiredOrderUnitOfMeasure: productRequiredOrderUnitOfMeasure,
      productCurrency: productCurrency,
      productCasesPerLayer:
        multipleUpcUsed || index !== 0
          ? parseInt(multipleValue?.productCasesPerLayer)
          : parseInt(productCasesPerLayer),
      productLayersPerPallet:
        multipleUpcUsed || index !== 0
          ? parseInt(multipleValue?.productLayersPerPallet)
          : parseInt(productLayersPerPallet),
      productOracleInvenId:
        multipleUpcUsed || index !== 0 ? multipleValue?.productOracleInvenId : productOracleInvenId,
      productInventoryReserve: isNaN(parseInt(values.inventoryReserve))
        ? undefined
        : parseInt(values.inventoryReserve)
    });
    // cause of DESC-11037 we should save only 1 upc if multiple_upc or use_multiple_substitutions not true
    const preparedSubstitutions = multipleUpcUsed
      ? values.multipleUpc?.map(mapSubstitutionFromForm)
      : values.multipleUpc?.slice(0, 1).map(mapSubstitutionFromForm);

    const inputVariables = {
      distributionCenterIds: values.distributionCenters,
      reprocessImpactedPurchaseOrders: true,
      type: DELIVERY_TYPE_MAP[tradingPartner?.deliveryType ?? ''] as ActiveAssortmentProductType,
      vendorProductExternalId: assortmentConfig.fields.find((field) => field.slug === 'EXTERNAL_ID')
        ? values.externalId
        : values.upc ?? (multipleUpcUsed ? values.multipleUpc[0].upc : ''),
      retailerDeliveryDestinationIds:
        values.vendorFlex && values.vendorFlexLocations.length > 0
          ? values.shipTo.includes('all')
            ? concat(
                collectedRdds?.filter((rdd) => !rdd.specialAssortment)?.map(({ id }) => id),
                values.vendorFlexLocations
              )
            : concat(values.shipTo, values.vendorFlexLocations)
          : values.shipTo.includes('all')
            ? collectedRdds?.filter((rdd) => !rdd.specialAssortment)?.map(({ id }) => id)
            : values.shipTo,
      substitutions: preparedSubstitutions,
      vendorProductId: action === ModalAction.ADD ? null : editingItem?.retailerProductId,
      tradingPartnerId: values.tradingPartner,
      vendorProductToCaseQuantity: parseInt(values?.toCaseQuantity),
      vendorProductDtcInventoryReserve: isNaN(parseInt(values.inventoryReserve))
        ? undefined
        : parseInt(values.inventoryReserve),
      vendorMarketId: vendorMarketId
    };

    upsertTradingPartnerAssortment(action, inputVariables);
    onCancel();
  };

  const fillFormWithProduct = (product: Product, form: FormApi, upcTab?: number) => {
    const tabPrefix = upcTab === undefined ? '' : `multipleUpc[${upcTab}].`;
    form.getFieldState(`${tabPrefix}productCasesPerLayer`)?.change(product.casesPerLayer);
    form.getFieldState(`${tabPrefix}palletCount`)?.change(product.quantityPerPallet);
    form.getFieldState(`${tabPrefix}depth`)?.change(product.depth);
    form.getFieldState(`${tabPrefix}grossWeight`)?.change(product.weightInPounds);
    form.getFieldState(`${tabPrefix}height`)?.change(product.height);
    form.getFieldState(`${tabPrefix}productLayersPerPallet`)?.change(product.layersPerPallet);
    form.getFieldState(`${tabPrefix}productOracleInvenId`)?.change(product.oracleInvenId);
    form.getFieldState(`${tabPrefix}sapMaterialId`)?.change(product.sapMaterialId);
    form.getFieldState(`${tabPrefix}shipsInOwnContainer`)?.change(product.shipsInOwnContainer);
    form.getFieldState(`${tabPrefix}width`)?.change(product.width);
    form.getFieldState(`${tabPrefix}name`)?.change(product.name);
    form
      .getFieldState(`${tabPrefix}productProductType`)
      ?.change(product.productType ? upperCase(product.productType) : undefined);

    form.mutators.setUpcByFillingForm(product.upc, `${tabPrefix}upc`);
  };

  const fillFormWithCatalogProduct = (product: CatalogProduct, form: FormApi, upcTab?: number) => {
    const tabPrefix = upcTab === undefined ? '' : `multipleUpc[${upcTab}].`;
    form.getFieldState(`${tabPrefix}productCasesPerLayer`)?.change(product.casesPerLayer);
    form.getFieldState(`${tabPrefix}palletCount`)?.change(product.casesPerPallet);
    form.getFieldState(`${tabPrefix}depth`)?.change(product.depth);
    form.getFieldState(`${tabPrefix}grossWeight`)?.change(product.grossWeight);
    form.getFieldState(`${tabPrefix}height`)?.change(product.height);
    form.getFieldState(`${tabPrefix}productLayersPerPallet`)?.change(product.layersPerPallet);
    form.getFieldState(`${tabPrefix}productOracleInvenId`)?.change(product.oracleInvenId);
    form.getFieldState(`${tabPrefix}sapMaterialId`)?.change(product.sapMaterialId);
    form.getFieldState(`${tabPrefix}shipsInOwnContainer`)?.change(product.shipsInOwnContainer);
    form.getFieldState(`${tabPrefix}width`)?.change(product.width);
    form.getFieldState(`${tabPrefix}name`)?.change(product.name);
    form
      .getFieldState(`${tabPrefix}productProductType`)
      ?.change(product.productType ? upperCase(product.productType) : undefined);

    form.mutators.setUpcByFillingForm(product.gtin12, `${tabPrefix}upc`);
  };

  const fields = (form: FormApi<any>) => {
    if (assortmentConfig.multipleUpc || assortmentConfig.useMultipleSubstitutions) {
      // 2 columns, second is for multiple upc
      return (
        <>
          <AlloyCol span={12} className={s.group}>
            <TradingPartnerField tradingPartner={tradingPartner} />
            <ShipToField deliveryDestinations={collectedRdds} />
            {formFields.map(
              (formField) =>
                !isForMultipleUpc(formField.slug || undefined) && (
                  <FormFieldComponent
                    isNewAssortmentEnabled={isNewAssortmentEnabled}
                    key={formField.slug}
                    vendorId={vendorId || ''}
                    action={action}
                    changeToUpdate={changeToUpdate}
                    formField={formField}
                    assortmentConfig={assortmentConfig}
                    distributionCenters={tradingPartnerDistributionCenters}
                    deliveryDestinations={collectedRdds}
                    fillFormWithProduct={fillFormWithProduct}
                    fillFormWithCatalogProduct={fillFormWithCatalogProduct}
                    retailerProductExternalIdType={retailerProductExternalIdType}
                  />
                )
            )}
          </AlloyCol>
          <AlloyCol span={12} className={s.group}>
            <FieldArray name="multipleUpc">
              {({ fields }) => {
                const items = fields.map((name, index) => ({
                  label: (
                    <span data-testid={`aa-edit-modal-upc-tab-${index + 1}`}>UPC{index + 1}</span>
                  ),
                  key: index.toString(),
                  closable: false,
                  children: (
                    <>
                      {formFields.map(
                        (formField) =>
                          isForMultipleUpc(formField.slug || '') && (
                            <FormFieldComponent
                              isNewAssortmentEnabled={isNewAssortmentEnabled}
                              vendorId={vendorId || ''}
                              action={action}
                              changeToUpdate={changeToUpdate}
                              key={`${name}_${formField.slug}`}
                              formField={formField}
                              assortmentConfig={assortmentConfig}
                              distributionCenters={tradingPartnerDistributionCenters}
                              deliveryDestinations={collectedRdds}
                              tabKey={name}
                              fillFormWithProduct={(product, form) =>
                                fillFormWithProduct(product, form, index)
                              }
                              fillFormWithCatalogProduct={(product, form) =>
                                fillFormWithCatalogProduct(product, form, index)
                              }
                              retailerProductExternalIdType={retailerProductExternalIdType}
                            />
                          )
                      )}
                    </>
                  )
                }));

                items.push({
                  label: (
                    <SettingOutlined
                      data-testid={`aa-edit-modal-upc-tab-settings`}
                      style={{ marginRight: 0 }}
                    />
                  ),
                  key: 'settings',
                  closable: false,
                  children: (
                    <FieldArray name="multipleUpc">
                      {({ fields }) => (
                        <div className={s.upc_settings_container}>
                          <p className={s.subtitle}>UPC settings list</p>
                          <PrioritizedList<
                            EditFormSubstitution & { priority: number; active: boolean }
                          >
                            selected={fields.value.map((value, index) => ({
                              ...value,
                              priority: index + 1,
                              active: true
                            }))}
                            onChange={(items) => form.mutators.updateMultipleUpcs(items)}
                            data-testid="edit-aa-multiple-upcs-priority-list"
                            itemBodyRender={(item) => (
                              <div className={s.card_content}>
                                <div>
                                  <div>UPC {item.priority}</div>
                                  <div>{item.upc}</div>
                                </div>
                                {/* here should be an active/inactive switch, when this ability will be implemented on BE */}
                              </div>
                            )}
                            itemKey={(item) => item.key}
                            preventRemovalLastElement
                          />
                        </div>
                      )}
                    </FieldArray>
                  )
                });

                return (
                  <AlloyTabs
                    type="editable-card"
                    onEdit={() => fields.push({ key: uuidv4() })}
                    className={s.tabs}
                    items={items}
                  />
                );
              }}
            </FieldArray>
          </AlloyCol>
        </>
      );
    } else if (formFields.length > 10) {
      // 2 columns
      return (
        <>
          <AlloyCol span={12} className={s.group}>
            <TradingPartnerField tradingPartner={tradingPartner} />
            <ShipToField deliveryDestinations={collectedRdds} />
            {formFields.map((formField, index) =>
              index < formFields.length / 2 ? (
                <FormFieldComponent
                  isNewAssortmentEnabled={isNewAssortmentEnabled}
                  key={formField.slug}
                  vendorId={vendorId || ''}
                  action={action}
                  changeToUpdate={changeToUpdate}
                  formField={formField}
                  assortmentConfig={assortmentConfig}
                  distributionCenters={tradingPartnerDistributionCenters}
                  deliveryDestinations={collectedRdds}
                  fillFormWithProduct={fillFormWithProduct}
                  fillFormWithCatalogProduct={fillFormWithCatalogProduct}
                  retailerProductExternalIdType={retailerProductExternalIdType}
                />
              ) : null
            )}
          </AlloyCol>
          <AlloyCol span={12} className={s.group}>
            {formFields.map((formField, index) =>
              index >= formFields.length / 2 ? (
                <FormFieldComponent
                  isNewAssortmentEnabled={isNewAssortmentEnabled}
                  key={formField.slug}
                  vendorId={vendorId || ''}
                  action={action}
                  changeToUpdate={changeToUpdate}
                  formField={formField}
                  assortmentConfig={assortmentConfig}
                  distributionCenters={tradingPartnerDistributionCenters}
                  deliveryDestinations={collectedRdds}
                  fillFormWithProduct={fillFormWithProduct}
                  fillFormWithCatalogProduct={fillFormWithCatalogProduct}
                  retailerProductExternalIdType={retailerProductExternalIdType}
                />
              ) : null
            )}
          </AlloyCol>
        </>
      );
    } else {
      // 1 column
      return (
        <AlloyCol span={24} className={s.group}>
          <TradingPartnerField tradingPartner={tradingPartner} />
          <ShipToField deliveryDestinations={collectedRdds} />
          {formFields.map((formField) => (
            <FormFieldComponent
              isNewAssortmentEnabled={isNewAssortmentEnabled}
              key={formField.slug}
              vendorId={vendorId || ''}
              action={action}
              changeToUpdate={changeToUpdate}
              formField={formField}
              assortmentConfig={assortmentConfig}
              distributionCenters={tradingPartnerDistributionCenters}
              deliveryDestinations={collectedRdds}
              fillFormWithProduct={fillFormWithProduct}
              fillFormWithCatalogProduct={fillFormWithCatalogProduct}
              retailerProductExternalIdType={retailerProductExternalIdType}
            />
          ))}
        </AlloyCol>
      );
    }
  };

  return (
    <FullScreenEditingModal
      data-testid={`aa-edit-modal-${editingItem?.retailerProductId || 'new'}`}
      title={
        action === ModalAction.ADD ? (
          <span data-testid={`aa-edit-modal-add-title`}>Add Product to Assortment</span>
        ) : (
          <span data-testid={`aa-edit-modal-edit-title`}>Edit active assortment item</span>
        )
      }
      containerClassName={s.editing_area}
      open={visible}
      onClose={onCancel}
      buttons={
        <>
          <AlloyButton
            data-testid="aa-edit-modal-cancel-button"
            type="secondary"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </AlloyButton>
          <AlloyButton
            type="primary"
            data-testid={`aa-edit-modal-${
              action === ModalAction.ADD ? 'add-product' : 'update'
            }-button`}
            onClick={() => {
              document
                ?.getElementById('edit-retailer-product-modal-form')
                ?.dispatchEvent(new Event('submit', { cancelable: true }));
            }}
          >
            {action === ModalAction.ADD ? 'Add Product' : 'Update'}
          </AlloyButton>
        </>
      }
    >
      <Form<EditFormValues>
        initialValues={{
          vendorFlex:
            tradingPartner.retailerDeliveryDestinations.filter((rdd) => {
              return editingItem?.rddIds?.includes(rdd.id) && rdd.specialAssortment;
            }).length > 0,
          distributionCenters: uniq(
            (editingItem?.substitutions[0]?.inventory || []).reduce(
              (result, inv) => (inv.active ? [...result, inv.distributionCenterId] : result),
              [] as string[]
            )
          ),
          vendorFlexLocations: tradingPartner.retailerDeliveryDestinations
            .filter((rdd) => editingItem?.rddIds?.includes(rdd.id) && rdd.specialAssortment)
            .map((location) => location.id),
          externalId: editingItem?.retailerProductExternalId,
          name: editingItem?.substitutions[0]?.name,
          upc: editingItem?.substitutions[0]?.upc || undefined,
          toCaseQuantity: `${editingItem?.substitutions[0]?.toCaseQuantity || ''}`,
          grossWeight: `${editingItem?.substitutions[0]?.grossWeight || ''}`,
          sapMaterialId: editingItem?.substitutions[0]?.sapMaterialId || undefined,
          width: `${editingItem?.substitutions[0]?.width || ''}`,
          height: `${editingItem?.substitutions[0]?.height || ''}`,
          depth: `${editingItem?.substitutions[0]?.depth || ''}`,
          palletCount: `${editingItem?.substitutions[0]?.casesPerPallet || ''}`,
          productMoqUnitOfMeasure: editingItem?.substitutions[0]?.moqUnitOfMeasure || undefined,
          productPrice: editingItem?.substitutions[0]?.price,
          productMoqMinimum: `${editingItem?.substitutions[0]?.moqMinimum || ''}`,
          productRequiredOrderUnitOfMeasure:
            editingItem?.substitutions[0]?.requiredOrderUnitOfMeasure || undefined,
          productCurrency: editingItem?.substitutions[0]?.currency || undefined,
          productOracleInvenId: editingItem?.substitutions[0]?.oracleInvenId || undefined,
          productCasesPerLayer: `${editingItem?.substitutions[0]?.casesPerLayer || ''}`,
          productLayersPerPallet: `${editingItem?.substitutions[0]?.layersPerPallet || ''}`,
          productProductType: editingItem?.substitutions[0]?.productType || undefined,
          shipsInOwnContainer: editingItem?.substitutions[0]?.shipsInOwnContainer || undefined,
          inventoryReserve: `${editingItem?.substitutions[0]?.inventoryReserve || ''}`,
          tradingPartner: tradingPartner.id || undefined,
          shipTo: defaultShipTo
            ? defaultShipTo
            : tradingPartner.retailerDeliveryDestinations
                ?.filter(
                  (rdd) =>
                    editingItem?.rddIds?.includes(rdd.id) && !rdd.specialAssortment && rdd.active
                )
                ?.sort((rdd1, rdd2) => (rdd1.name || '').localeCompare(rdd2.name || ''))
                ?.map(({ id }) => id) || [],
          multipleUpc: editingItem?.substitutions.map((product) => {
            return {
              depth: product?.depth || undefined,
              grossWeight: product?.grossWeight || undefined,
              height: product?.height || undefined,
              palletCount: product?.casesPerPallet || undefined,
              sapMaterialId: product?.sapMaterialId || undefined,
              upc: product?.upc || undefined,
              width: product.width || undefined,
              productLayersPerPallet: product.layersPerPallet || undefined,
              productCasesPerLayer: product.casesPerLayer || undefined,
              shipsInOwnContainer: product.shipsInOwnContainer || undefined,
              productOracleInvenId: product.oracleInvenId || undefined,
              productProductType: product.productType || undefined,
              key: uuidv4()
            };
          }) || [{ key: uuidv4() } as EditFormSubstitution]
        }}
        onSubmit={submitForm}
        mutators={{
          ...arrayMutators,
          setShipTos: (args, state, utils) => {
            utils.changeValue(state, 'shipTo', () => args[0]);
          },
          setUpcByFillingForm: (args, state, utils) => {
            const fieldName = args[1];
            utils.changeValue(state, fieldName, () => args[0]);
            utils.resetFieldState(fieldName);
          },
          updateMultipleUpcs: (args, state, utils) => {
            utils.changeValue(state, 'multipleUpc', () => args[0]);
          }
        }}
        render={({
          handleSubmit,
          form,
          form: {
            mutators: { push, pop }
          }
        }) => (
          <form
            id="edit-retailer-product-modal-form"
            onSubmit={(event) => {
              const promise = handleSubmit(event);
              promise &&
                promise.then(() => {
                  form.reset();
                  form.resetFieldState('externalId');
                  form.resetFieldState('upc');
                  form.resetFieldState('sapMaterialId');
                });
              return promise;
            }}
          >
            <AlloyRow>{fields(form)}</AlloyRow>
          </form>
        )}
      />
    </FullScreenEditingModal>
  );
};

const TradingPartnerField = ({
  tradingPartner
}: {
  tradingPartner: {
    id: string;
    name?: string | null;
    deliveryType?: string | null;
    retailerDeliveryDestinations: RetailerDeliveryDestination[];
  };
}) => (
  <AlloyFormField
    data-testid="aa-edit-modal-trading-partner-select"
    component="select"
    name="tradingPartner"
    validate={validateRequired}
    fieldProps={{
      title: 'Trading Partner',
      options: [{ value: tradingPartner.id, label: tradingPartner.name || '' }],
      disabled: true
    }}
  />
);
