import { Button, Space, Table, Tooltip } from 'antd';
import React from 'react';
import applyFilterIcon from 'assets/icons/apply_icon.svg';
import deleteFilterIcon from 'assets/icons/delete_icon.svg';
import viewFilterIcon from 'assets/icons/view_icon.svg';
import { AdvancedFilter, FilterName, NewFilterName, ShipToFilterName } from 'common/interfaces';
import s from './AdvancedFilterComponent.module.scss';
import { ColumnsType } from 'antd/lib/table';

interface FiltersTableProps<T extends FilterName | NewFilterName | ShipToFilterName> {
  filters: AdvancedFilter<T>[];
  onApplyFilter: (filter: AdvancedFilter<T>) => void;
  onViewFilterClick: (filter: AdvancedFilter<T>) => void;
  onDeleteFilterClick: (filter: AdvancedFilter<T>) => void;
}

export const FiltersTable = <T extends FilterName | NewFilterName | ShipToFilterName>({
  filters,
  onApplyFilter,
  onViewFilterClick,
  onDeleteFilterClick
}: FiltersTableProps<T>) => {
  const columns: ColumnsType<AdvancedFilter<T>> = [
    {
      key: 'name',
      render: (_, record) => <b data-testid={`name-${record.name}`}>{record.name}</b>
    },
    {
      key: 'description',
      render: (_, record) => record.description
    },
    {
      key: 'actions',
      render: (_, record) => (
        <Space size="small">
          <Tooltip placement="bottomLeft" title="Apply">
            <Button
              data-testid={`apply-${record.name}`}
              icon={<img alt="" width="14px" src={applyFilterIcon} />}
              type="text"
              onClick={() => onApplyFilter(record)}
            />
          </Tooltip>
          <Tooltip placement="bottomLeft" title="View">
            <Button
              data-testid={`view-${record.name}`}
              icon={<img alt="" width="14px" src={viewFilterIcon} />}
              type="text"
              onClick={() => onViewFilterClick(record)}
            />
          </Tooltip>
          <Tooltip placement="bottomLeft" title="Delete">
            <Button
              data-testid={`delete-${record.name}`}
              icon={<img alt="" width="14px" src={deleteFilterIcon} />}
              type="text"
              onClick={() => onDeleteFilterClick(record)}
            />
          </Tooltip>
        </Space>
      )
    }
  ];

  return (
    <Table
      rowKey={(record) => record.name}
      columns={columns}
      dataSource={filters}
      showHeader={false}
      pagination={false}
      className={s.filters_table}
    />
  );
};
