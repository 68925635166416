import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { FeFeatureFlagsDocument } from './gql/__generated__/feFeatureFlags.query';
import { FeFeatureFlag, FeFeatureFlagInput } from 'graphql/__generated__/types';
import {
  getFeatureFlagOverridedInLocalStorageValue,
  getFeatureFlagOverridedInQueryParamsValue
} from './featureFlagOverrides';
import { isE2ETestSession } from 'common/helpers/identificateEnvironment';
import { BooleanParam, QueryParamConfig, useQueryParams } from 'use-query-params';

export const useFeatureFlag = (
  flag: FeFeatureFlagInput
): { loading: boolean; enabled: boolean | undefined } => {
  // We use useFeatureFlags for an implementation, just limit it to 1 parameter.
  const { loading, featureFlags } = useFeatureFlags([flag]);

  return {
    loading,
    enabled: featureFlags[0].enabled
  };
};

export const useFeatureFlags = (
  flags: FeFeatureFlagInput[]
): { loading: boolean; featureFlags: FeFeatureFlag[] } => {
  const { loading, data } = useQuery(FeFeatureFlagsDocument, {
    variables: {
      flags: flags
    }
  });

  const [featureFlagQueryParams] = useQueryParams(
    flags.reduce((result, flag) => {
      result[flag.name] = BooleanParam;
      return result;
    }, {} as { [flag: string]: QueryParamConfig<boolean | null | undefined> })
  );

  const featureFlags = useMemo(() => {
    if (data) return data.feFeatureFlags;
    // While loading, return all values as false, since otherwise destructuring will fail
    return flags.map((x) => ({ name: x.name, enabled: false }));
  }, [data, flags]);

  // Local storage overrides work only for Cypress tests.
  // Query parameter overrides can be used for manual testing
  // The query parameter should match the feature flag name and have a boolean value (0 or 1).
  const featureFlagsWithOverrides = useMemo(() => {
    if (!isE2ETestSession())
      return featureFlags.map(({ name, enabled }) => ({
        name,
        enabled: getFeatureFlagOverridedInQueryParamsValue(name, featureFlagQueryParams) ?? enabled
      }));
    // Notice that FF overrides could DISABLE feature flags too.
    return featureFlags.map(({ name, enabled }) => ({
      name,
      enabled:
        getFeatureFlagOverridedInQueryParamsValue(name, featureFlagQueryParams) ??
        getFeatureFlagOverridedInLocalStorageValue(name) ??
        enabled
    }));
  }, [featureFlagQueryParams, featureFlags]);

  return { loading, featureFlags: featureFlagsWithOverrides };
};
