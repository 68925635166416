import React from 'react';
import { useQuery } from '@apollo/client';
import { ChargebackTotalsReportDocument } from './gql/__generated__/chargebackTotalsReport.query';
import {
  FiscalCalendarWeekInputWithWeekOnly,
  getCurrentPeriod
} from 'components/PeriodCalendar/helpers';
import { convertToMM } from 'common/helpers/convertToMM';
import { TotalWithGraph } from '../TotalWithGraph/TotalWithGraph';
import {
  ChargebackReportsByPeriodDocument,
  ChargebackReportsByPeriodQuery
} from './gql/__generated__/chargebackReportsByPeriod.query';
import { convertWeekToPeriodWeekString, getFinancialInfo } from 'common/helpers/fiscalCalendar';
import { isContinuous } from 'common/helpers/graph';
import { getPreviousWeekAndYear, yearWeekSorter } from 'common/helpers/periodHelper';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { calculateChange } from 'pages/ExecutiveIntelligence/helpers';

type ChargebackByPeriodData = ChargebackReportsByPeriodQuery['chargebackReportsByPeriod'][number];

// Backend name
const TOTAL = '__TOTAL__';

const currentPeriod = getCurrentPeriod(true);

// We want to compare PREVIOUS week to PREVIOUS PREVIOUS week, since CURRENT week has no data
const actualPeriod = getPreviousWeekAndYear(currentPeriod.week, currentPeriod.year);
const comparisonPeriod = getPreviousWeekAndYear(actualPeriod.week, actualPeriod.year);

const shouldShowChange = actualPeriod.week !== comparisonPeriod.week;

// TODO: create a generic function for that?
export const mapDataWithPeriods = (
  data: ChargebackByPeriodData[],
  periods: FiscalCalendarWeekInputWithWeekOnly[]
) => {
  const sortedData = [...periods].sort((a, b) => yearWeekSorter(a, b));

  const dataWithFilledHoles = (sortedData || []).map((fiscalCalendarWeek) => {
    const found = data.find(
      (x) =>
        x.fiscalCalendarWeek.week === fiscalCalendarWeek.week &&
        fiscalCalendarWeek.year === x.fiscalCalendarWeek.year
    );
    return {
      fiscalCalendarWeek,
      ...found
    };
  });

  return { data: dataWithFilledHoles };
};

export const Chargebacks = ({
  fiscalCalendarWeeks
}: {
  fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[];
}) => {
  const totals = useQuery(ChargebackTotalsReportDocument, {
    variables: {
      filters: {
        countryCode: 'US',
        fiscalCalendarWeeks: fiscalCalendarWeeks
      },
      dimension: 'BUSINESS_UNIT'
    }
  });

  const period = useQuery(ChargebackReportsByPeriodDocument, {
    variables: {
      filters: {
        countryCode: 'US',
        fiscalCalendarWeeks: fiscalCalendarWeeks
      },
      dimension: 'BUSINESS_UNIT'
    }
  });

  if (totals.error)
    return (
      <div style={{ gridColumn: 'span 12' }}>
        Could not load chargebacks data due to a server error
      </div>
    );
  if (totals.loading && !totals.data)
    return (
      <div
        style={{
          height: '360px',
          gridColumn: 'span 12',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <AlloySpin />
      </div>
    );

  return (
    <>
      {(totals.data?.chargebackTotalsReport || []).map(({ dimensionValue, financialCharge }) => {
        const relatedData = mapDataWithPeriods(
          (period.data?.chargebackReportsByPeriod || []).filter(
            (x) => x.dimensionValue === dimensionValue
          ),
          fiscalCalendarWeeks
        ).data;
        const isContinious = isContinuous(relatedData.map((x) => x.fiscalCalendarWeek.week));

        const actualPeriodValue = relatedData.find(
          (x) =>
            x.fiscalCalendarWeek.week === actualPeriod.week &&
            x.fiscalCalendarWeek.year === actualPeriod.year
        )?.financialCharge;
        const comparisonPeriodValue = relatedData.find(
          (x) =>
            x.fiscalCalendarWeek.week === comparisonPeriod.week &&
            x.fiscalCalendarWeek.year === comparisonPeriod.year
        )?.financialCharge;

        const change = calculateChange(
          actualPeriodValue,
          comparisonPeriodValue,
          shouldShowChange,
          false,
          (value) => convertToMM(value)
        );

        return (
          <TotalWithGraph
            key={dimensionValue}
            change={change}
            changeText="than prev. period"
            changeTooltip={`In ${convertWeekToPeriodWeekString(
              actualPeriod.week
            )} compared to ${convertWeekToPeriodWeekString(comparisonPeriod.week)}`}
            title={dimensionValue === TOTAL ? 'Chargebacks' : dimensionValue || ''}
            loading={totals.loading}
            total={convertToMM(financialCharge)}
            totalPosition={dimensionValue === TOTAL ? 'left' : 'top'}
            width={dimensionValue === TOTAL ? 12 : 3}
            graph={{
              loading: period.loading,
              data: relatedData.map((x) => ({
                value: isNaN(parseFloat(x.financialCharge))
                  ? undefined
                  : parseFloat(x.financialCharge),
                label: convertWeekToPeriodWeekString(x.fiscalCalendarWeek.week)
              })),
              isContinious,
              valueTickFormatter: (value) => convertToMM(value),
              valueTooltipFormatter: (value) => `$${value.toLocaleString('en-US')}`
            }}
          />
        );
      })}
    </>
  );
};
