import React, { useEffect, useMemo, useRef, useState } from 'react';
import s from './CutsByReasonGraph.module.scss';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { CutOrdersFilters } from 'graphql/__generated__/types';
import { useQuery } from '@apollo/client';
import { EMPTY } from 'common/constants';
import { safeNumberComparator } from 'common/helpers/comparators';
import { Cell, Pie, PieChart, PieLabelRenderProps, ResponsiveContainer } from 'recharts';
import { CutReasonsByCategoryDocument } from './gql/__generated__/cutReasonsByCategory.query';
import { useQueryParam, withDefault, StringParam } from 'use-query-params';
import {
  CUT_CATEGORIES,
  CUTS_BY_REASON_CATEGORY,
  DEFAULT_CATEGORY_TAB
} from 'pages/OnTimeInFull/types';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { capitalize, truncate } from 'lodash-es';
import clsx from 'clsx';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloyPopover } from 'components/ui/AlloyPopover/AlloyPopover';
import { DownOutlined } from '@ant-design/icons';
import { getColor } from 'common/helpers/palette';

const safeFormatPercentage = (value: number | undefined) => {
  if (Number.isNaN(value) || value === undefined) return EMPTY;
  // For now. so we don't show 100%.
  const fixedAmount = 2;
  const formattedValue = value.toFixed(fixedAmount);
  const isNotExact =
    (formattedValue === '100.00' && value < 100) || (formattedValue === '0.00' && value > 0);
  return `${isNotExact ? '~' : ''}${value.toFixed(fixedAmount)}%`;
};

const printNumberWithoutScientificNotation = (number: number) => {
  let formattedNumber = number.toFixed(20);
  formattedNumber = formattedNumber.replace(/\.?0+$/, '');
  return formattedNumber;
};

const safeParseFloat = (n: number | string | undefined) => {
  return (typeof n === 'string' ? parseFloat(n) : n) || 0;
};

const CATEGORIES = CUT_CATEGORIES.map((x) => ({ value: x, label: capitalize(x) }));

export const CutsByReasonGraph = ({ filters }: { filters: CutOrdersFilters }) => {
  const [category, setCategory] = useQueryParam(
    CUTS_BY_REASON_CATEGORY,
    withDefault(StringParam, DEFAULT_CATEGORY_TAB)
  );

  // TODO: actually send BUs here
  const { loading, data, error } = useQuery(CutReasonsByCategoryDocument, {
    variables: { filters: { category, fiscalCalendarWeeks: filters.fiscalCalendarWeeks } }
  });

  const categoriesForGraph = (data?.cutReasonsByCategory || []).map((x) => ({
    name: x.reason,
    value: x.percentage * 100
  }));

  return (
    <div className={s.wrapper}>
      <h2 className={s.title}>
        <AlloySelect
          value={category}
          onChange={(value) => {
            setCategory(value);
          }}
          variant="borderless"
          dropdownStyle={{ minWidth: '140px', fontWeight: 'bold' }}
          options={CATEGORIES}
          className={s.borderlessSelect}
        />{' '}
        breakdown
      </h2>
      <div style={{ width: '100%' }}>
        <AlloySpin spinning={loading} className={s.spinner}>
          {error ? (
            <div className={s.noCuts}>Can not load data due to an error</div>
          ) : categoriesForGraph.length ? (
            <OtifCutsByReasonGraph categories={categoriesForGraph} />
          ) : (
            !loading && <div className={s.noCuts}>No cuts in this category</div>
          )}
        </AlloySpin>
      </div>
    </div>
  );
};

const graphStyle = {
  userSelect: 'none' as const,
  outline: 'none',
  transition: 'all 0.15s ease'
};

const OTHER_REASONS = 'Other reasons';
const OTHERS_TRESHOLD = 0.5;

export const OtifCutsByReasonGraph = ({
  categories: unsortedCategories
}: {
  categories: { name: string; value: number }[];
}) => {
  const [hovered, setHovered] = useState(-1);
  const [open, setOpen] = useState(false);
  const [categories, rest, hasSmallCategories] = useMemo(() => {
    const sortedCategories = unsortedCategories.sort((a, b) =>
      safeNumberComparator(b.value, a.value)
    );

    const larger = sortedCategories.filter((category) => category.value >= OTHERS_TRESHOLD);
    const smaller = sortedCategories.filter((category) => category.value < OTHERS_TRESHOLD);

    const hasSmallCategories = smaller.length > 0;

    // If there's only 1 small category elements – don't bother with hiding them
    if (smaller.length === 1) {
      return [sortedCategories, [], hasSmallCategories];
    }

    if (smaller.length > 0) {
      const sumOfLessThanOrEqualHalf = smaller.reduce((sum, category) => sum + category.value, 0);
      larger.push({ name: OTHER_REASONS, value: sumOfLessThanOrEqualHalf });
    }

    return [larger, smaller, hasSmallCategories];
  }, [unsortedCategories]);

  // We hide white strokes if there's more than 10 elements of if there are "small" elements
  const shouldShowStrokes = categories.length > 1 && categories.length < 10 && !hasSmallCategories;

  return (
    <div className={s.graphAndLegendWrapper}>
      <div className={s.graphWrapper}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={260} height={260}>
            {/* Represents other part of a circle, so "uncut" */}
            <Pie
              data={categories}
              dataKey="value"
              cx="50%"
              cy="50%"
              outerRadius={130}
              isAnimationActive={false}
              minAngle={0}
              label={(props) => <CustomizedLabelForCuts {...props} data={categories} />}
              labelLine={false}
              strokeWidth={shouldShowStrokes ? 1 : 0}
              style={graphStyle}
              tabIndex={-1}
            >
              {categories.map(({ name }, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={getColor(index).color}
                  opacity={hovered === index || (open && name === OTHER_REASONS) ? 0.5 : 1}
                  onMouseEnter={() => setHovered(index)}
                  onMouseLeave={() => setHovered(-1)}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className={s.labelWrapper}>
        {categories.map(({ name, value }, idx) =>
          name === OTHER_REASONS ? (
            <AlloyPopover
              className={s.collapse}
              key={name}
              placement="bottomRight"
              trigger="click"
              onOpenChange={(open) => setOpen(open)}
              overlayInnerStyle={{
                padding: '4px'
              }}
              content={
                <div className={s.restOverlay}>
                  {rest.map(({ name, value }) => (
                    <div key={name} className={clsx(s.legend)}>
                      <div>{name}</div>
                      <AlloyTooltip title={`${printNumberWithoutScientificNotation(value)}%`}>
                        <div>{safeFormatPercentage(value)}</div>
                      </AlloyTooltip>
                    </div>
                  ))}
                </div>
              }
            >
              <div
                className={clsx(s.legend, { [s.hovered]: hovered === idx || open })}
                onMouseEnter={() => setHovered(idx)}
                onMouseLeave={() => setHovered(-1)}
                style={{
                  cursor: 'pointer'
                }}
              >
                <div className={s.nameWrapper}>
                  <div className={s.name}>
                    {OTHER_REASONS} ({rest.length})
                  </div>
                  <DownOutlined className={s.downOutlined} />
                </div>
                <AlloyTooltip title={`${printNumberWithoutScientificNotation(value)}%`}>
                  <div>{safeFormatPercentage(value)}</div>
                </AlloyTooltip>
                <div
                  style={{
                    width: '16px',
                    height: '16px',
                    borderRadius: '2px',
                    backgroundColor: getColor(idx).color
                  }}
                />
              </div>
            </AlloyPopover>
          ) : (
            <div
              key={name}
              className={clsx(s.legend, { [s.hovered]: hovered === idx })}
              onMouseEnter={() => setHovered(idx)}
              onMouseLeave={() => setHovered(-1)}
            >
              <div>{name}</div>
              <AlloyTooltip title={`${printNumberWithoutScientificNotation(value)}%`}>
                <div>{safeFormatPercentage(value)}</div>
              </AlloyTooltip>
              <div
                style={{
                  width: '16px',
                  height: '16px',
                  borderRadius: '2px',
                  backgroundColor: getColor(idx).color
                }}
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

const RADIAN = Math.PI / 180;

const CustomizedLabelForCuts = ({
  cx: rawCx,
  cy: rawCy,
  midAngle: rawMidAngle,
  innerRadius: rawInnerRadius,
  outerRadius: rawOuterRadius,
  name,
  index: rawIndex,
  data
}: PieLabelRenderProps & {
  data: [
    {
      name: string;
      value: number;
    }
  ];
}) => {
  const textRef = useRef<SVGTSpanElement>(null);
  const [shortName, setShortName] = useState(name);
  const showOnlyOne = data.length === 1;

  // Special magic function to truncate text;
  useEffect(() => {
    const el = textRef.current;
    const width = showOnlyOne ? 260 : 112;

    if (!el) {
      setShortName(name);
      return;
    }

    let maxLength = name.length;

    while (el.getSubStringLength(0, maxLength) > width) {
      maxLength--;
    }

    if (maxLength < name.length) {
      setShortName(
        truncate(name, {
          omission: '…',
          length: maxLength
        })
      );
    } else {
      setShortName(name);
    }
  }, [name, showOnlyOne, textRef]);

  if (name.toLowerCase() === '') return <></>;

  const cx = safeParseFloat(rawCx);
  const cy = safeParseFloat(rawCy);
  const innerRadius = safeParseFloat(rawInnerRadius);
  const outerRadius = safeParseFloat(rawOuterRadius);
  const midAngle = safeParseFloat(rawMidAngle);
  const index = safeParseFloat(rawIndex);

  const radius = innerRadius + (outerRadius - innerRadius) - 8;

  const x = showOnlyOne ? cx : cx + radius * Math.cos(-midAngle * RADIAN);
  const y = showOnlyOne ? cy : cy + radius * Math.sin(-midAngle * RADIAN);

  const textInverted = midAngle >= 90 && midAngle <= 270;
  const rotation = `rotate(${-midAngle + (textInverted ? 180 : 0)}, ${x}, ${y})`;

  return Math.abs(data[index].value) >= 10 ? (
    <text
      x={x}
      y={y}
      fill={getColor(index).text || 'white'}
      textAnchor={showOnlyOne ? 'middle' : textInverted ? 'start' : 'end'}
      dominantBaseline="middle"
      style={{
        pointerEvents: 'none'
      }}
      transform={rotation}
    >
      <tspan x={x} dy="0" className={s.cutsLabel} ref={textRef}>
        {shortName}
      </tspan>
      <tspan x={x} dy="14px" className={s.cutsLabel}>
        {safeFormatPercentage(data[index].value)}
      </tspan>
    </text>
  ) : (
    <></>
  );
};
