import React from 'react';
import { useQuery } from '@apollo/client';
import {
  convertWeekToPeriodWeek,
  convertWeekToPeriodWeekString
} from 'common/helpers/fiscalCalendar';
import { getPreviousWeekAndYear } from 'common/helpers/periodHelper';
import { ExecutiveReportingFilters } from 'graphql/__generated__/types';
import { ExecutiveIntelligenceSummaryDocument } from './gql/__generated__/executiveIntelligenceSummary.query';
import { NumberAndText, StatTile } from '../StatTile/StatTile';
import { EMPTY } from 'common/constants';
import s from './Summary.module.scss';
import { calculateChange } from 'pages/ExecutiveIntelligence/helpers';
import { getCurrentPeriod } from 'components/PeriodCalendar/helpers';

const formatPercentage = (value: any) => {
  const parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue)) return EMPTY;
  // TODO: WHY it's 1 HERE??
  return `${(parsedValue * 100).toFixed(1)}`;
};

const currentPeriod = getCurrentPeriod(true);

// We want to compare PREVIOUS week to PREVIOUS PREVIOUS week, since CURRENT week has no data
const actualPeriod = getPreviousWeekAndYear(currentPeriod.week, currentPeriod.year);
const comparisonPeriod = getPreviousWeekAndYear(actualPeriod.week, actualPeriod.year);
const areWeeksDifferent = actualPeriod.week !== comparisonPeriod.week;

export const Summary = ({
  executiveReportingFilters,
  shouldShowComparison
}: {
  executiveReportingFilters: ExecutiveReportingFilters;
  shouldShowComparison: boolean;
}) => {
  const totalsData = useQuery(ExecutiveIntelligenceSummaryDocument, {
    variables: {
      filters: executiveReportingFilters,
      actualFilters: {
        ...executiveReportingFilters,
        fiscalCalendarWeeks: [
          {
            ...actualPeriod,
            period: convertWeekToPeriodWeek(actualPeriod.week).period
          }
        ]
      },
      comparisonFilters: {
        ...executiveReportingFilters,
        fiscalCalendarWeeks: [
          {
            ...comparisonPeriod,
            period: convertWeekToPeriodWeek(comparisonPeriod.week).period
          }
        ]
      }
    }
  });

  const otifChange = calculateChange(
    totalsData.data?.actualPurchaseOrdersTotalsReport?.otif,
    totalsData.data?.comparisonPeriodPurchaseOrdersTotalsReport?.otif,
    shouldShowComparison && areWeeksDifferent,
    true,
    (value) => `${formatPercentage(value)}%`
  );
  const sfrChange = calculateChange(
    totalsData.data?.actualPurchaseOrdersTotalsReport?.submittedFillRate,
    totalsData.data?.comparisonPeriodPurchaseOrdersTotalsReport?.submittedFillRate,
    shouldShowComparison && areWeeksDifferent,
    true,
    (value) => `${formatPercentage(value)}%`
  );

  return (
    <>
      <div className={s.stats}>
        <StatTile
          title="OTIF"
          value={
            <NumberAndText
              number={formatPercentage(totalsData.data?.otifPurchaseOrdersTotalsReport?.otif)}
              text="%"
            />
          }
          tooltip="On Time In Full"
          loading={totalsData.loading}
          change={otifChange}
          changeText="than prev. period"
          changeTooltip={`In ${convertWeekToPeriodWeekString(
            actualPeriod.week
          )} compared to ${convertWeekToPeriodWeekString(comparisonPeriod.week)}`}
        />
        <StatTile
          title="SFR"
          value={
            <NumberAndText
              number={formatPercentage(
                totalsData.data?.otifPurchaseOrdersTotalsReport?.submittedFillRate
              )}
              text="%"
            />
          }
          tooltip="Submitted Fill Rate"
          loading={totalsData.loading}
          change={sfrChange}
          changeText="than prev. period"
          changeTooltip={`In ${convertWeekToPeriodWeekString(
            actualPeriod.week
          )} compared to ${convertWeekToPeriodWeekString(comparisonPeriod.week)}`}
        />
      </div>
    </>
  );
};
