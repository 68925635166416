import React from 'react';
import {
  DistributionCenterCode,
  DistributionCenterSiblings,
  DistributionCenterFullData
} from '../../DistributionCenterViewDrawer';
import { ViewRow } from 'components/Common/ViewMode/ViewRow/ViewRow';
import { ViewField } from 'components/Common/ViewMode/ViewField/ViewField';
import s from './GeneralSettingsViewTab.module.scss';
import moment from 'moment';
import currency from 'currency.js';
import { ViewTable } from 'components/Common/ViewMode/ViewTable/ViewTable';

export const GeneralSettingsViewTab = ({
  distributionCenter,
  isDCFacilityManagementEnabled
}: {
  distributionCenter: DistributionCenterFullData;
  isDCFacilityManagementEnabled: boolean | undefined;
}) => {
  const generalSettings = (
    <>
      <div className={s.row}>
        <ViewField type="INPUT" title="Code" value={distributionCenter.code} />
        <ViewField type="INPUT" title="Name" value={distributionCenter.name} />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="Source" value={distributionCenter.source} />
        {isDCFacilityManagementEnabled && (
          <ViewField
            type="INPUT"
            title="Facility"
            value={distributionCenter.distributionCenterFacility?.name}
          />
        )}
      </div>
    </>
  );

  const truckCapacity = (
    <div className={s.row}>
      <ViewField type="INPUT" title="Pallets" value={distributionCenter.truckCapacity?.pallets} />
      <ViewField
        type="INPUT"
        title="Weight (lb)"
        value={distributionCenter.truckCapacity?.weight}
      />
    </div>
  );

  const addressSettings = (
    <>
      <ViewField type="INPUT" title="Care Of" value={distributionCenter.careOf} />
      <div className={s.row}>
        <ViewField type="INPUT" title="Address" value={distributionCenter.address} />
        <ViewField type="INPUT" title="City" value={distributionCenter.city} />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="State" value={distributionCenter.state} />
        <ViewField type="INPUT" title="Zip" value={distributionCenter.zip} />
      </div>
      <div className={s.row}>
        <ViewField type="INPUT" title="Latitude" value={distributionCenter.lat} />
        <ViewField type="INPUT" title="Longitude" value={distributionCenter.lng} />
      </div>
      <ViewField type="INPUT" title="Phone" value={distributionCenter.phone} />
      <ViewField
        type="LIST"
        title="Emails"
        value={distributionCenter.emails?.filter((email): email is string => !!email) || []}
      />
    </>
  );

  //TODO: delete this old view below after schip_dc_facility_management FF removed
  const identifiersAndSourcesOld = (
    <div className={s.identifiers_and_sources}>
      <ViewTable<DistributionCenterCode>
        data={distributionCenter.codes}
        columns={[
          {
            title: 'Code',
            dataIndex: 'code',
            classname: s.code_column_old,
            withTooltip: true
          },
          {
            title: 'Source',
            dataIndex: 'source',
            classname: s.source_column_old,
            withTooltip: true
          },
          {
            title: 'Dummy SAP Sold To',
            dataIndex: 'dummySapSoldTo',
            classname: s.dummy_sap_sold_to_column,
            withTooltip: true
          }
        ]}
        lastRowBottomBorder={false}
      />
    </div>
  );

  // this is the new view table for the facility management FF
  const identifiersAndSources = (
    <div className={s.identifiers_and_sources}>
      <ViewTable<DistributionCenterSiblings>
        data={distributionCenter.siblings}
        columns={[
          {
            title: 'Code',
            dataIndex: 'code',
            classname: s.code_column,
            withTooltip: true
          },
          {
            title: 'Source',
            dataIndex: 'source',
            classname: s.source_column,
            withTooltip: true
          }
        ]}
        lastRowBottomBorder={false}
      />
    </div>
  );

  const costAndCapacity = (
    <>
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Current Pallet Capacity"
          value={distributionCenter.costAndCapacity?.currentPalletCapacity || 0}
        />
        <ViewField
          type="INPUT"
          title="Max Pallet Capacity"
          value={distributionCenter.costAndCapacity?.maxPalletCapacity || 0}
        />
      </div>
      <ViewField
        type="INPUT"
        title="Monthly Holding Cost Per Pallet Position"
        value={currency(
          parseFloat(distributionCenter.costAndCapacity?.monthlyHoldingCostPerPalletPosition) || 0
        ).format()}
      />
      <ViewField
        type="INPUT"
        title="Monthly Lease Cost"
        value={currency(
          parseFloat(distributionCenter.costAndCapacity?.monthlyLeaseCost) || 0
        ).format()}
      />
      <div className={s.row}>
        <ViewField
          type="INPUT"
          title="Operating Cost Per Hour"
          value={currency(
            parseFloat(distributionCenter.costAndCapacity?.operatingCostPerHour) || 0
          ).format()}
        />
        <ViewField
          type="INPUT"
          title="Operating Cost Per Case"
          value={currency(
            parseFloat(distributionCenter.costAndCapacity?.operatingCostPerCase) || 0
          ).format()}
        />
      </div>
    </>
  );

  const wmsConfig = distributionCenter.wmsConfig ? (
    <ViewField type="INPUT" title="External ID" value={distributionCenter.wmsConfig.externalId} />
  ) : (
    <div className={s.disabled_container}>
      <ViewField type="SWITCH" title="Disabled" value={false} />
    </div>
  );

  const additionalSettings = (
    <>
      <div className={s.row}>
        {/* //TODO: delete this after schip_dc_facility_management FF removed? Or leave it in this section? */}
        <ViewField
          type="INPUT"
          title="Dummy SAP Sold To"
          value={distributionCenter.dummySapSoldTo}
        />
        <ViewField
          type="INPUT"
          title="Inventory Source"
          value={distributionCenter.inventorySource}
        />
      </div>
      <ViewField type="INPUT" title="Transaver Reference" value={distributionCenter.transaverRef} />
    </>
  );

  const blackouts = (
    <ViewField
      type="LIST"
      title="Blackouts"
      value={distributionCenter.blackouts
        ?.sort((blackout1, blackout2) => {
          if (blackout1?.dayOfWeek && blackout2?.dayOfWeek) {
            return (
              moment.weekdays().findIndex((day) => blackout1?.dayOfWeek === day.toUpperCase()) -
              moment.weekdays().findIndex((day) => blackout2?.dayOfWeek === day.toUpperCase())
            );
          } else if (blackout1?.date && blackout2?.date) {
            return moment(blackout2?.date).diff(moment(blackout1?.date));
          } else {
            return blackout1?.dayOfWeek ? -1 : 1;
          }
        })
        .map((blackout) => blackout?.dayOfWeek || blackout?.date)}
    />
  );

  return (
    <>
      <ViewRow title="General Settings" content={generalSettings} bordered={true} />
      <ViewRow title="Address" content={addressSettings} bordered={true} />
      <ViewRow
        title="Identifiers and Sources"
        content={isDCFacilityManagementEnabled ? identifiersAndSources : identifiersAndSourcesOld}
        bordered={true}
      />
      <ViewRow title="Truck capacity" content={truckCapacity} bordered={true} />
      <ViewRow title="Cost & Capacity" content={costAndCapacity} bordered={true} />
      <ViewRow title="WMS config" content={wmsConfig} bordered={true} />
      <ViewRow title="Additional settings" content={additionalSettings} bordered={true} />
      <ViewRow title="Blackouts" content={blackouts} bordered={false} />
    </>
  );
};
