import React, { useMemo } from 'react';
import s from './CutsGraph.module.scss';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { CutOrdersFilters } from 'graphql/__generated__/types';
import { useQuery } from '@apollo/client';
import { EMPTY } from 'common/constants';
import { CalendarValue } from 'components/PeriodCalendar/types';
import { CutOrdersPercentagesDocument } from './gql/__generated__/cutOrdersPercentages.query';
import { safeNumberComparator } from 'common/helpers/comparators';
import { stringifyCalendarValue } from 'components/PeriodCalendar/helpers';
import { Cell, Pie, PieChart, PieLabelRenderProps, ResponsiveContainer } from 'recharts';
import { CUTS_BY_REASON_CATEGORY, DEFAULT_CATEGORY_TAB } from '../../types';
import { useQueryParam, withDefault, StringParam } from 'use-query-params';
import clsx from 'clsx';

const formatPercentage = (value: number | undefined) => {
  if (Number.isNaN(value) || value === undefined) return EMPTY;
  const fixedAmount = value < 0.1 ? 2 : 1;
  return value.toFixed(fixedAmount) + '%';
};

const safeParseFloat = (n: number | string | undefined) => {
  return (typeof n === 'string' ? parseFloat(n) : n) || 0;
};

const MIN_ANGLE_TOTAL_CUT = 20; // 5.5% of graph, smaller is absolutely unreadable.
const MIN_ANGLE_SINGLE_CUT = MIN_ANGLE_TOTAL_CUT / 5;

type ChartData = [{ name: string; value: number }];

type CutsByCategoryType = {
  customerCutsPercent: number;
  planningCutsPercent: number;
  totalCutsPercent: number;
  transportCutsPercent: number;
  uncategorizedCutsPercent: number;
  warehouseCutsPercent: number;
};

export const CutsGraph = ({
  filters,
  period
}: {
  filters: CutOrdersFilters;
  period: CalendarValue;
}) => {
  const [cutsByCategory, setCutsByCategory] = useQueryParam(
    CUTS_BY_REASON_CATEGORY,
    withDefault(StringParam, DEFAULT_CATEGORY_TAB)
  );

  // TODO: actually send BUs here
  const { loading, data, error } = useQuery(CutOrdersPercentagesDocument, {
    variables: { filters: { fiscalCalendarWeeks: filters.fiscalCalendarWeeks } }
  });

  const parsedCuts = useMemo(() => {
    const cuts = data?.cutOrdersPercentages[0];

    return cuts
      ? {
          customerCutsPercent: safeParseFloat(cuts?.customerCutsPercent),
          planningCutsPercent: safeParseFloat(cuts?.planningCutsPercent),
          totalCutsPercent: safeParseFloat(cuts?.totalCutsPercent),
          transportCutsPercent: safeParseFloat(cuts?.transportCutsPercent),
          uncategorizedCutsPercent: safeParseFloat(cuts?.uncategorizedCutsPercent),
          warehouseCutsPercent: safeParseFloat(cuts?.warehouseCutsPercent)
        }
      : undefined;
  }, [data?.cutOrdersPercentages]);

  const areMultipleYearsSelected = period.years.length > 1;
  const years = useMemo(
    () => [...period.years].sort(safeNumberComparator).join(', ') + ' ',
    [period]
  );
  return (
    <div className={s.wrapper}>
      <h2 className={s.title}>
        Cuts {`(${areMultipleYearsSelected ? years : ''}${stringifyCalendarValue(period)})`}
      </h2>
      <AlloySpin spinning={loading}>
        {error ? (
          <>Can not load data due to an error</>
        ) : parsedCuts ? (
          <OtifCutsGraph
            cutOrdersPercentages={parsedCuts}
            cutsByCategory={cutsByCategory}
            setCutsByCategory={setCutsByCategory}
          />
        ) : (
          <>{loading ? '...' : 'No cuts data'}</>
        )}
      </AlloySpin>
    </div>
  );
};

const graphStyle = {
  userSelect: 'none' as const,
  outline: 'none'
};

export const OtifCutsGraph = ({
  cutOrdersPercentages,
  cutsByCategory,
  setCutsByCategory
}: {
  cutOrdersPercentages: CutsByCategoryType;
  cutsByCategory: string;
  setCutsByCategory: (category: string) => void;
}) => {
  const totals = useMemo(() => {
    const totalCuts = safeParseFloat(cutOrdersPercentages?.totalCutsPercent) * 100;

    const minCutsSlice =
      totalCuts === 0 ? 0 : Math.max(MIN_ANGLE_TOTAL_CUT / 360, totalCuts / 100) * 100;
    const fillSlice = 100 - minCutsSlice;
    const firstSliceAngle = Math.max(MIN_ANGLE_TOTAL_CUT, (totalCuts / 100) * 360);
    const endAngle = -0.25;
    const startAngle = endAngle + firstSliceAngle + 0.5;

    return {
      startAngle,
      endAngle,
      firstSliceAngle,
      data: [
        { name: 'Cuts', value: minCutsSlice, actualValue: totalCuts },
        { name: 'Fulfilled', value: fillSlice, actualValue: 100 - totalCuts }
      ]
    };
  }, [cutOrdersPercentages]);

  const cuts = useMemo(() => {
    const cuts = cutOrdersPercentages;
    return {
      data: [
        { name: 'Customer', value: safeParseFloat(cuts?.customerCutsPercent) * 100 },
        { name: 'Planning', value: safeParseFloat(cuts?.planningCutsPercent) * 100 },
        { name: 'Warehouse', value: safeParseFloat(cuts?.warehouseCutsPercent) * 100 },
        { name: 'Transportation', value: safeParseFloat(cuts?.transportCutsPercent) * 100 },
        { name: 'Uncategorized', value: safeParseFloat(cuts?.uncategorizedCutsPercent) * 100 }
      ]
    };
  }, [cutOrdersPercentages]);

  return (
    <div style={{ width: 500, height: 500 }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={500} height={500}>
          {/* Represents other part of a circle, so "uncut" */}
          <Pie
            data={totals.data}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={130}
            isAnimationActive={false}
            minAngle={0}
            label={(props) => <CustomizedLabelForCuts {...props} data={totals.data} />}
            labelLine={false}
            strokeWidth={0}
            style={graphStyle}
            tabIndex={-1}
          >
            {totals.data.map((_, index) => (
              <Cell
                key={`cell-${index}`}
                fill={index === 0 ? '#5D99EB' : '#162F80'}
                style={{
                  outline: 'none'
                }}
              />
            ))}
          </Pie>
          <Pie
            data={cuts.data}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={250}
            innerRadius={132}
            isAnimationActive={false}
            startAngle={totals.startAngle}
            endAngle={totals.endAngle}
            label={(props) => (
              <CustomizedLabelForCutDetails
                {...props}
                data={cuts.data}
                cutsByCategory={cutsByCategory}
              />
            )}
            labelLine={false}
            strokeWidth={2}
            minAngle={MIN_ANGLE_SINGLE_CUT}
            style={graphStyle}
            tabIndex={-1}
          >
            {cuts.data.map(({ name }, index) => {
              const isSelected = name.toLowerCase() === cutsByCategory;
              return (
                <Cell
                  key={`cell-${index}-${name}`}
                  fill="#78E2EC"
                  opacity={isSelected ? '1' : '0.3'}
                  onClick={() => setCutsByCategory(name.toLowerCase())}
                  cursor={isSelected ? 'default' : 'pointer'}
                  style={{
                    outline: 'none'
                  }}
                />
              );
            })}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

const RADIAN = Math.PI / 180;

const CustomizedLabelForCuts = ({
  cx: rawCx,
  cy: rawCy,
  midAngle: rawMidAngle,
  innerRadius: rawInnerRadius,
  outerRadius: rawOuterRadius,
  name,
  index: rawIndex,
  data
}: PieLabelRenderProps & {
  data: [
    {
      name: string;
      value: number;
      actualValue: number;
    }
  ];
}) => {
  if (name.toLowerCase() === '') return <></>;
  const cx = safeParseFloat(rawCx);
  const cy = safeParseFloat(rawCy);
  const innerRadius = safeParseFloat(rawInnerRadius);
  const outerRadius = safeParseFloat(rawOuterRadius);
  const midAngle = safeParseFloat(rawMidAngle);
  const index = safeParseFloat(rawIndex);

  const actualValue = data[index].actualValue;
  if (actualValue === 0) return <></>;

  const BREAKPOINT = 11;

  const shouldShowInOneRow = actualValue < BREAKPOINT;

  // Different distance to the edge of the graph based on value
  const radiusModificator = shouldShowInOneRow ? 0.97 : actualValue < 15 ? 0.72 : 0.5;

  const radius = innerRadius + (outerRadius - innerRadius) * radiusModificator;

  const yModificator = shouldShowInOneRow ? 0 : midAngle > 180 ? radius * 0.2 : radius * 0.15;

  // actualValue === 100 is an edge case
  const x = actualValue === 100 ? cx : cx + radius * Math.cos(-midAngle * RADIAN);
  const y =
    actualValue === 100
      ? cy - yModificator
      : cy + radius * Math.sin(-midAngle * RADIAN) - yModificator;

  const rotation = shouldShowInOneRow ? `rotate(${-midAngle}, ${x}, ${y})` : '';

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={shouldShowInOneRow ? 'end' : 'middle'}
      dominantBaseline="central"
      transform={rotation}
      style={graphStyle}
      tabIndex={-1}
    >
      {shouldShowInOneRow ? (
        <tspan x={x} dy="0" className={clsx(s.cutsLabel, s.cutsLabelSmall)}>
          {name} {formatPercentage(actualValue)}
        </tspan>
      ) : (
        <>
          <tspan x={x} dy="0" className={clsx(s.cutsLabel)}>
            {name}
          </tspan>
          <tspan x={x} dy="24px" className={s.cutsPercentage}>
            {formatPercentage(actualValue)}
          </tspan>
        </>
      )}
    </text>
  );
};

const CustomizedLabelForCutDetails = ({
  cx: rawCx,
  cy: rawCy,
  midAngle: rawMidAngle,
  innerRadius: rawInnerRadius,
  outerRadius: rawOuterRadius,
  name,
  index: rawIndex,
  data,
  cutsByCategory
}: PieLabelRenderProps & { data: ChartData; cutsByCategory: string }) => {
  if (name.toLowerCase() === '') return <></>;

  const isSelected = name.toLowerCase() === cutsByCategory;

  const cx = safeParseFloat(rawCx);
  const cy = safeParseFloat(rawCy);
  const innerRadius = safeParseFloat(rawInnerRadius);
  const outerRadius = safeParseFloat(rawOuterRadius);
  const midAngle = safeParseFloat(rawMidAngle);
  const index = safeParseFloat(rawIndex);

  if (data[index].value === 0) return <></>;

  const radius = innerRadius + (outerRadius - innerRadius) - 5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  const textInverted = midAngle >= 90 && midAngle <= 270;
  const rotation = `rotate(${-midAngle + (textInverted ? 180 : 0)}, ${x}, ${y})`;

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={textInverted ? 'start' : 'end'}
      dominantBaseline="central"
      style={{ opacity: isSelected ? '1' : '0.3', pointerEvents: 'none' }}
      transform={rotation}
    >
      <tspan x={x} dy="0" className={s.cutsDetailsLabel}>
        {name} {formatPercentage(data[index].value)}
      </tspan>
    </text>
  );
};
