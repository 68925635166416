import React, { useContext, useState } from 'react';
import s from './OrdersListToolbar.module.scss';
import { WarningOutlined } from '@ant-design/icons';
import { LocationUpdate } from 'common/interfaces';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  ToggleExportModal,
  ExportType
} from 'pages/OrdersPage/components/ExportItemsModal/ExportItemsModal';
import Notification from 'components/Common/Notification';
import ConfirmationModal, {
  ConfirmationModalProps
} from 'components/Modals/ConfirmationModal/ConfirmationModal';
import BulkProcessSelect from 'pages/OrdersPage/components/BulkProcessSelect/BulkProcessSelect';
import { UpcSelect } from '../UpcManagement/UpcSelect/UpcSelect';
import { AdvancedFilters } from 'pages/OrdersPage/components/AdvancedFilters/AdvancedFilters';
import { ImportSelect } from './components/ImportSelect';
import { DisplayMode } from './types';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { UserContext } from 'context/userContext';
import { AppHistoryContext } from 'context/AppHistoryContext';
import { ExportOrdersEmailDocument } from './gql/__generated__/exportOrdersEmail.mutation';
import { DownloadCustomizationsModal } from './components/DownloadCustomizationsModal/DownloadCustomizationsModal';
import { ifEcommInSelectedRows } from 'components/PurchaseOrders/utils';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';
import { App } from 'ant5';
import { AdvancedFiltersForNewSearch } from '../AdvancedFilters/AdvancedFiltersForNewSearch';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';

interface OrdersListToolbarProps {
  toggleExportModal: ToggleExportModal;
  toggleExportSoItemsModal: () => void;
  toggleShareEmailModal: () => void;
  toggleBulkDownloadFileModal: () => void;
  selectedRows: {
    id: string;
    deliveryType: string;
    customerPo: string;
    availableActions: string[];
    primaryStatus: string;
    salesOrderIds: string[];
    shipTo?: {
      id: string;
      name: string;
      externalId: string;
    };
    shipToExternalId?: string;
    labelIds: string[];
  }[];
  handleOrdersFilter: (
    filterName: string,
    filterValue: string | { start: string; end: string } | string[]
  ) => void;
  onApplyAdvancedFilter: (update: LocationUpdate, name?: string) => void;
  clearSelectedOrders: () => void;
  toggleExportSeedDataModal: () => void;
  isUseNewSearch: boolean;
  advancedFiltersLoading: boolean;
}

const hasReleasableRows = (selectedRows: { id: string; availableActions: string[] }[]) => {
  return !!selectedRows.find((po) => po.availableActions.includes('RELEASE_PO'));
};

const OrdersListToolbar = ({
  toggleExportModal,
  toggleExportSoItemsModal,
  toggleShareEmailModal,
  selectedRows,
  handleOrdersFilter,
  toggleBulkDownloadFileModal,
  onApplyAdvancedFilter,
  clearSelectedOrders,
  toggleExportSeedDataModal,
  isUseNewSearch,
  advancedFiltersLoading
}: OrdersListToolbarProps) => {
  const { notification: AntNotification } = App.useApp();
  const { isAdmin, isOperations, user } = useContext(UserContext);
  const { getLink } = useContext(AppHistoryContext);

  const [showMultipleUpcSelect, setShowMultipleUpcSelect] = useState(false);
  const { Option } = AlloySelect;
  const history = useHistory();
  const [notification, setNotification] = useState('');
  const [showDownloadCustomizationsModal, setShowDownloadCustomizationsModal] = useState(false);

  // Purchase Orders / Error Visibility page toggle state
  const DEFAULT_TAB: DisplayMode = 'purchaseOrders';
  const [mode, setMode] = useQueryParam('mode', withDefault(StringParam, DEFAULT_TAB));

  const [sendExportEmail] = useMutation(ExportOrdersEmailDocument, {
    refetchQueries: ['purchaseOrdersForPurchaseOrdersPage', 'purchaseOrderCounts']
  });

  const [confirmModalState, setConfirmModalState] = useState<ConfirmationModalProps>({
    visible: false,
    message: '',
    confirmed: (result: boolean) => {}
  });

  /***********************************  Export Dropdown logic  **************************************/
  const exportAction = (type: string) => {
    const erroredPOs: string[] = [];
    selectedRows.map((row) => {
      if (!row.shipTo && row.customerPo) {
        erroredPOs.push(row.customerPo);
      }
      return null;
    });

    const details =
      (erroredPOs.length && (
        <div className={s.dummy_warning}>
          <div className={s.dummy_message}>
            <WarningOutlined className={s.warning_icon} />
            <span>
              The following purchase orders will not be sent, because they are missing a valid
              Ship-to destination.
            </span>
          </div>
          <div className={s.dummy_rows}>
            {erroredPOs.map((poId) => (
              <div key={poId}>{poId}</div>
            ))}
          </div>
        </div>
      )) ||
      undefined;

    if (type === 'PRECISION') {
      toggleBulkDownloadFileModal();
    } else if (type === 'DUMMY') {
      if (ifEcommInSelectedRows(selectedRows)) {
        AntNotification.warning({
          message: 'Unselect any ECOMM purchase orders',
          description: 'Creating dummy order functionality does not currently support ECOMM orders'
        });
      } else {
        setConfirmModalState({
          visible: true,
          message: `Create dummy orders for the following PO(s)`,
          details: (
            <>
              <div data-testid="confirmation-po" className={s.confirmation_po}>
                {selectedRows.map((row) => row.customerPo).join(', ')}
              </div>
              {details}
            </>
          ),
          confirmed: async (ok: boolean) => {
            if (ok) {
              setConfirmModalState({ visible: false, message: '', confirmed: () => {} });
              setShowMultipleUpcSelect(true);
            } else {
              setConfirmModalState({ visible: false, message: '', confirmed: () => {} });
            }
          }
        });
      }
    } else if (type === 'SO_FULL') {
      toggleExportSoItemsModal();
    } else if (type === 'DOWN_CONFIRM') {
      if (ifEcommInSelectedRows(selectedRows)) {
        AntNotification.warning({
          message: 'Unselect any ECOMM purchase orders',
          description: 'Down confirm order functionality does not currently support ECOMM orders'
        });
      }
    } else if (type === 'DOWNLOAD_SELECTED') {
      toggleExportSeedDataModal();
    } else if (type === 'CUSTOMIZATIONS') {
      setShowDownloadCustomizationsModal(!showDownloadCustomizationsModal);
    } else {
      toggleExportModal(type as ExportType);
    }
  };
  /**************************************************************************************************/

  /*****************************&   Create Dummy Order logic  ***************************************/
  const createDummyOrder = async () => {
    if (showMultipleUpcSelect) setShowMultipleUpcSelect(false);

    AntNotification.info({
      message: 'Attempting to create a dummy order'
    });

    try {
      const result = await sendExportEmail({
        variables: { input: { salesOrderIds: selectedRows.flatMap((po) => po.salesOrderIds) } }
      });
      if (
        result?.data?.exportOrdersEmail?.salesOrders?.length ===
        selectedRows.flatMap((po) => po.salesOrderIds).length
      ) {
        setTimeout(() => {
          AntNotification.success({
            message: 'Success',
            description: 'A dummy order was successfully created and emailed'
          });
        }, 250);
      } else if (result?.data?.exportOrdersEmail?.salesOrders?.length === 0) {
        setTimeout(() => {
          AntNotification.error({
            message: 'Failed',
            description: 'An unknown error occurred and a dummy order could not be created'
          });
        }, 250);
      } else {
        setTimeout(() => {
          AntNotification.warning({
            message: 'Warning',
            description: `Not all dummy orders could be created. An unknown error occurred for (${
              selectedRows.flatMap((po) => po.salesOrderIds).length -
              (result?.data?.exportOrdersEmail?.salesOrders?.length || 0)
            }) orders`
          });
        }, 250);
      }
    } catch (error) {
      AntNotification.error({
        message: 'Failed to send',
        description: error.message
      });
    }
  };
  /**************************************************************************************************/

  /********************  Purchase Orders / Error Visibility Page toggle logic   *********************/
  const allModes: { value: DisplayMode; label: string }[] = [
    { value: 'purchaseOrders', label: 'Purchase Orders' },
    { value: 'errorVisibility', label: 'Error Visibility' }
  ];

  const availableModes =
    isAdmin() || isOperations()
      ? allModes
      : allModes.filter((x) => !['purchaseOrders', 'errorVisibility'].includes(x.value));
  /**************************************************************************************************/

  return (
    <>
      <Notification message={notification} onComplete={() => setNotification('')}></Notification>
      <AlloyRow className={s.header}>
        <AlloyCol data-testid="page-title" className={s.page_title}>
          <PageHeader className={s.page_title}>Purchase Orders</PageHeader>
        </AlloyCol>
        <AlloyCol className={s.header_buttons}>
          {/* multiUpcEditOpen */}
          <BulkProcessSelect
            size="large"
            purchaseOrders={selectedRows}
            clearSelection={clearSelectedOrders}
          />

          <ImportSelect size="large" />

          <AlloySelect
            data-testid="po-page-export-select"
            value="Export"
            disabled={selectedRows.length < 1}
            onSelect={exportAction}
            popupMatchSelectWidth={false}
            size="large"
          >
            {user?.availableActions.includes('CREATE_DUMMY_ORDER') && (
              <Option value="DUMMY" data-testid="export-select-dummy">
                Create dummy order
              </Option>
            )}

            {user?.availableActions.includes('FULL_ITEM_LIST') && (
              <Option value="FULL" data-testid="export-select-full">
                PO full item list
              </Option>
            )}

            {user?.availableActions.includes('INVALID_ITEM_LIST') && (
              <Option value="INVALID" data-testid="export-select-invalid">
                PO invalid item list
              </Option>
            )}

            <Option value="PO" data-testid="export-select-po">
              PO list
            </Option>

            {user?.availableActions.includes('FULL_ITEM_LIST') && (
              <Option value="SO_FULL" data-testid="export-select-so-full">
                SO full item list
              </Option>
            )}

            {user?.availableActions.includes('PRECISION_ORDER') && (
              <Option
                value="PRECISION"
                disabled={
                  selectedRows.filter((row) => row.deliveryType === 'DSD').length < 1 ||
                  selectedRows.length < 1
                }
              >
                Precision Orders
              </Option>
            )}

            <Option value="CUSTOMIZATIONS">Download Customization Files</Option>

            {user?.availableActions.includes('EXPORT_SEED_DATA') && (
              <Option
                value="DOWNLOAD_SELECTED"
                disabled={selectedRows.length < 1}
                data-testid="export-select-download-selected"
              >
                Download selected
              </Option>
            )}
          </AlloySelect>
          {user?.availableActions.includes('RELEASE_PO') && (
            <AlloyButton
              size="large"
              data-testid="release-button"
              type="primary"
              disabled={selectedRows.length < 1}
              onClick={() => {
                if (hasReleasableRows(selectedRows)) {
                  toggleShareEmailModal();
                } else {
                  AntNotification.warning({
                    message: 'Unable To Release',
                    description: 'Release is not an available action for the selected order(s)'
                  });
                }
              }}
            >
              Release
            </AlloyButton>
          )}
          {history.location.search &&
            (isUseNewSearch ? (
              <AlloySpin spinning={advancedFiltersLoading}>
                <AdvancedFiltersForNewSearch
                  size="large"
                  data-testid="advanced-filters-button"
                  onApplyAdvancedFilter={onApplyAdvancedFilter}
                  handleOrdersFilter={handleOrdersFilter}
                />
              </AlloySpin>
            ) : (
              <AlloySpin spinning={advancedFiltersLoading}>
                <AdvancedFilters
                  size="large"
                  data-testid="advanced-filters-button"
                  onApplyAdvancedFilter={onApplyAdvancedFilter}
                  handleOrdersFilter={handleOrdersFilter}
                />
              </AlloySpin>
            ))}
        </AlloyCol>
      </AlloyRow>
      {/* toggle to error visibility page */}
      <AlloyRow className={s.page_toggle}>
        <Link to={getLink('/errors')}>
          <AlloySegmented
            options={availableModes}
            value={mode}
            onChange={(value) => setMode(value)}
          />
        </Link>
      </AlloyRow>

      <DownloadCustomizationsModal
        purchaseOrderIds={selectedRows.map((po) => po.id)}
        open={showDownloadCustomizationsModal}
        toggleModal={() => setShowDownloadCustomizationsModal(!showDownloadCustomizationsModal)}
      />

      <ConfirmationModal {...confirmModalState} />
      {!!selectedRows.length && showMultipleUpcSelect && (
        <UpcSelect
          purchaseOrderIds={selectedRows.map((po) => po.id)}
          onContinue={() => {
            createDummyOrder();
          }}
          onCancel={() => {
            setShowMultipleUpcSelect(false);
          }}
        />
      )}
    </>
  );
};

export default OrdersListToolbar;
