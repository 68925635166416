import React from 'react';
import { useHistory } from 'react-router-dom';
import s from './OrdersSummary.module.scss';
import clsx from 'clsx';
import {
  PO_STATUS_NEW,
  PO_STATUS_EXPORTED,
  PO_STATUS_SCRUBBED,
  PO_STATUS_SUBMITTED,
  PO_STATUS_ACKNOWLEDGED,
  PO_STATUS_READY_FOR_DELIVERY,
  PO_STATUS_DELIVERED,
  PO_STATUS_INVOICED,
  PO_STATUS_CANCELLED
} from 'common/constants';
import filterIcon from 'assets/icons/filter_icon.svg';
import filterIconFilled from 'assets/icons/filter_icon_filled.svg';
import { PurchaseOrderSearchCounts, PurchaseOrderStatus } from 'graphql/__generated__/types';
import { notEmpty } from 'common/helpers/notEmpty';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { searchStringToQueryFilterForSearchList } from '../AdvancedFilters/AdvancedFiltersForNewSearch';
import { PurchaseOrderSearchCountsQuery } from './gql/__generated__/purchaseOrderSearchCounts.query';

type CountFields = keyof Omit<
  NonNullable<PurchaseOrderSearchCountsQuery['purchaseOrderSearchCounts']>,
  '__typename'
>;

interface Filter {
  name: string;
  fieldName: CountFields;
  status: PurchaseOrderStatus;
}

const filterName = 'operativeStatuses';

// Doing it the "obvious" way, w/o string replacements, mostly because we can. Maybe we can uppercase + pascal case while preserving types?
const filters: Filter[] = [
  { name: PO_STATUS_NEW, fieldName: 'new', status: 'NEW' },
  { name: PO_STATUS_EXPORTED, fieldName: 'exported', status: 'EXPORTED' },
  { name: PO_STATUS_SCRUBBED, fieldName: 'scrubbed', status: 'SCRUBBED' },
  { name: PO_STATUS_SUBMITTED, fieldName: 'submitted', status: 'SUBMITTED' },
  { name: PO_STATUS_ACKNOWLEDGED, fieldName: 'acknowledged', status: 'ACKNOWLEDGED' },
  {
    name: PO_STATUS_READY_FOR_DELIVERY,
    fieldName: 'readyForDelivery',
    status: 'READY_FOR_DELIVERY'
  },
  { name: PO_STATUS_DELIVERED, fieldName: 'delivered', status: 'DELIVERED' },
  { name: PO_STATUS_INVOICED, fieldName: 'invoiced', status: 'INVOICED' },
  { name: PO_STATUS_CANCELLED, fieldName: 'cancelled', status: 'CANCELLED' }
];

interface OrdersSummaryProps {
  handleOrdersFilter: (
    filterName: string,
    filterValue: string | { start: string; end: string } | string[]
  ) => void;
  loading: boolean;
  purchaseOrderSearchCounts?: PurchaseOrderSearchCounts | null;
}

export const OrdersSummaryForNewSearch = ({
  handleOrdersFilter,
  loading,
  purchaseOrderSearchCounts
}: OrdersSummaryProps) => {
  const history = useHistory();

  const getSummaryItem = (
    index: number,
    { name, fieldName, status }: Filter,
    count: number | undefined,
    selected = false
  ) => {
    if (!count) return null;

    return (
      <div
        key={index}
        data-testid={`orders_summary_${fieldName.toLowerCase()}_tile_wrapper`}
        className={s.tile_container}
      >
        <AlloySpin spinning={loading}>
          <button
            className={clsx(selected ? s.tile && s.selected_tile : '', s.tile)}
            onClick={() => {
              const queryOrderFilters = searchStringToQueryFilterForSearchList(
                history.location.search
              ).filterData;
              const previousFilterValue = queryOrderFilters[filterName];
              if (selected) {
                handleOrdersFilter(
                  filterName,
                  previousFilterValue
                    ? previousFilterValue.filter((f) => f !== status).filter(notEmpty)
                    : []
                );
              } else {
                handleOrdersFilter(
                  filterName,
                  previousFilterValue ? [...previousFilterValue, status].filter(notEmpty) : status
                );
              }
            }}
          >
            <div className={s.name_container}>
              <p className={s.tile_name}>{name}</p>
            </div>
            <img className={s.tile_icon} src={selected ? filterIconFilled : filterIcon} alt="" />
            <p
              className={clsx(s.count, {
                [s.big_value]: count && count > 999999
              })}
            >
              {!loading ? count.toLocaleString() : ''}
            </p>
          </button>
        </AlloySpin>
      </div>
    );
  };

  const isSelected = (filterName: 'operativeStatuses', status: PurchaseOrderStatus): boolean => {
    const queryOrderFilters = searchStringToQueryFilterForSearchList(history.location.search);
    return !!(
      !!queryOrderFilters.filterData[filterName] &&
      queryOrderFilters.filterData[filterName]?.includes(status)
    );
  };

  const calculateCount = (fieldName: CountFields): number | undefined => {
    return purchaseOrderSearchCounts ? purchaseOrderSearchCounts[fieldName] : undefined;
  };

  return (
    <div className={s.orders_summary_container}>
      <div className={s.tile_group}>
        {filters.map((f, index) => {
          const count = calculateCount(f.fieldName);
          return getSummaryItem(index, f, count, isSelected(filterName, f.status));
        })}
      </div>
    </div>
  );
};
