import React, { useMemo } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';
import { useQueryParam, withDefault, ArrayParam, StringParam } from 'use-query-params';
import s from './ForecastPlanning.module.scss';
import { getValueWithAll, setArrayWithAll } from 'common/helpers/selectAll';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import bookmarkAddIcon from 'assets/icons/bookmark_add.svg';
import clsx from 'clsx';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { SearchOutlined } from '@ant-design/icons';
import { AlloyInput } from 'components/ui/AlloyInput/AlloyInput';
import { ProductCard } from './components/ProductCard/ProductCard';
import { ProductDetails } from './components/ProductDetails/ProductDetails';
import { ForecastTable } from './components/ForecastTable/ForecastTable';

const mockProductDetails = {
  orderTrendForecast4weeks: 2.45,
  orderTrendForecast8weeks: 1.91,
  scanTrendForecast4weeks: 2.62,
  scanTrendForecast8weeks: 2.54,
  fillRateTrend5week: 0.75,
  bwInv: 1000,
  ewInv: 1067,
  endingWoc: 1.07,
  orderTrend: 406,
  scanTrend: 203,
  salesForecast: 0.75
};

const mockProducts = [
  {
    upc: '012345678901',
    asin: 'B00001A123',
    description: 'PEPSI COLA 12ZCN12PK'
  },
  {
    upc: '012345678902',
    asin: 'B00001A124',
    description: 'PEPSI DIET 12ZCN12PK'
  },
  {
    upc: '012345678903',
    asin: 'B00001A125',
    description: 'PEPSI MAX 12ZCN12PK'
  },
  {
    upc: '012345678904',
    asin: 'B00001A126',
    description: 'PEPSI WILD CHERRY 12ZCN12PK'
  },
  {
    upc: '012345678905',
    asin: 'B00001A127',
    description: 'PEPSI VANILLA 12ZCN12PK'
  },
  {
    upc: '012345678906',
    asin: 'B00001A128',
    description: 'PEPSI ZERO SUGAR 12ZCN12PK'
  },
  {
    upc: '012345678907',
    asin: 'B00001A129',
    description: 'PEPSI CAFFEINE FREE 12ZCN12PK'
  },
  {
    upc: '012345678908',
    asin: 'B00001A130',
    description: 'PEPSI MANGO 12ZCN12PK'
  },
  {
    upc: '012345678909',
    asin: 'B00001A131',
    description: 'PEPSI LIME 12ZCN12PK'
  },
  {
    upc: '012345678910',
    asin: 'B00001A132',
    description: 'PEPSI BLUE 12ZCN12PK'
  },
  {
    upc: '012345678911',
    asin: 'B00001A133',
    description: 'PEPSI BLACK CHERRY 12ZCN12PK'
  },
  {
    upc: '012345678912',
    asin: 'B00001A134',
    description: 'PEPSI GINGER 12ZCN12PK'
  },
  {
    upc: '012345678913',
    asin: 'B00001A135',
    description: 'PEPSI CRYSTAL 12ZCN12PK'
  },
  {
    upc: '012345678914',
    asin: 'B00001A136',
    description: 'PEPSI STRAWBERRY 12ZCN12PK'
  },
  {
    upc: '012345678915',
    asin: 'B00001A137',
    description: 'PEPSI GRAPE 12ZCN12PK'
  },
  {
    upc: '012345678916',
    asin: 'B00001A138',
    description: 'PEPSI ORANGE 12ZCN12PK'
  },
  {
    upc: '012345678917',
    asin: 'B00001A139',
    description: 'PEPSI WATERMELON 12ZCN12PK'
  },
  {
    upc: '012345678918',
    asin: 'B00001A140',
    description: 'PEPSI LEMON 12ZCN12PK'
  },
  {
    upc: '012345678919',
    asin: 'B00001A141',
    description: 'PEPSI FRUIT PUNCH 12ZCN12PK'
  },
  {
    upc: '012345678920',
    asin: 'B00001A142',
    description: 'PEPSI RASPBERRY 12ZCN12PK'
  }
].map((x) => ({ ...x, ...mockProductDetails }));

export type Product = (typeof mockProducts)[number];

const mockBusinessUnitsOptions = [
  { name: 'FLNA', code: 'FLNA' },
  { name: 'PBC', code: 'PBC' }
];
const mockVendorCodeOptions = [
  { name: 'Vendor code 1', code: '1' },
  { name: 'Vendor code 2', code: '2' }
];
const mockBrandOptions = [
  { name: 'Brand 1', code: '1' },
  { name: 'Brand 2', code: '2' }
];
const sortingOptions = ['By Risk Score', 'By Fill Rate', 'By WoC', 'By Promotional Products'];

export const ForecastPlanning = () => {
  const [bus, setBus] = useQueryParam('bus', withDefault(ArrayParam, ['all']));
  const [vendorCodes, setVendorCodes] = useQueryParam(
    'vendorCodes',
    withDefault(ArrayParam, ['all'])
  );
  const [brands, setBrands] = useQueryParam('brands', withDefault(ArrayParam, ['all']));
  const [searchTerm, setSearchTerm] = useQueryParam('search', withDefault(StringParam, ''));
  const nonEmptySearch = searchTerm.trim();
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, sortingOptions[0]));
  // TODO: select 1st automatically?
  const [selected, setSelected] = useQueryParam('selected', withDefault(StringParam, 'B00001A123'));

  const selectedProduct = useMemo(() => mockProducts.find((x) => x.asin === selected), [selected]);

  const _businessUnitCodes = useDeepCompareMemo(
    () =>
      getValueWithAll(
        bus as string[],
        mockBusinessUnitsOptions.map((x) => x.code)
      ),
    [bus]
  );
  const _vendorCodeCodes = useDeepCompareMemo(
    () =>
      getValueWithAll(
        vendorCodes as string[],
        mockVendorCodeOptions.map((x) => x.code)
      ),
    [vendorCodes]
  );
  const _brandCodes = useDeepCompareMemo(
    () =>
      getValueWithAll(
        brands as string[],
        mockBrandOptions.map((x) => x.code)
      ),
    [brands]
  );

  return (
    <div>
      <div className={s.header}>
        <PageHeader className={s.title}>Forecast</PageHeader>
        <div className={s.page_filters}>
          <AlloySelect
            loading={false}
            className={clsx(s.select, bus.length > 1 && s.moreThanOne)}
            showSearch={false}
            value={bus}
            mode="multiple"
            onChange={(value) => {
              setArrayWithAll(value as string[], bus as string[], setBus);
            }}
            popupMatchSelectWidth
            maxTagCount={1}
            options={[
              { label: 'ALL BUs', value: 'all' },
              ...mockBusinessUnitsOptions.map((bu) => ({
                label: bu.name,
                value: bu.code
              }))
            ]}
          />{' '}
          <AlloySelect
            loading={false}
            className={clsx(s.select, vendorCodes.length > 1 && s.moreThanOne)}
            showSearch={false}
            value={vendorCodes}
            mode="multiple"
            onChange={(value) => {
              setArrayWithAll(value as string[], vendorCodes as string[], setVendorCodes);
            }}
            popupMatchSelectWidth
            maxTagCount={1}
            options={[
              { label: 'ALL Vendor Codes', value: 'all' },
              ...mockVendorCodeOptions.map((vendorCode) => ({
                label: vendorCode.name,
                value: vendorCode.code
              }))
            ]}
          />{' '}
          <AlloySelect
            loading={false}
            className={clsx(s.select, brands.length > 1 && s.moreThanOne)}
            showSearch={false}
            value={brands}
            mode="multiple"
            onChange={(value) => {
              setArrayWithAll(value as string[], brands as string[], setBrands);
            }}
            popupMatchSelectWidth
            maxTagCount={1}
            options={[
              { label: 'All Brands', value: 'all' },
              ...mockBrandOptions.map((brand) => ({
                label: brand.name,
                value: brand.code
              }))
            ]}
          />
          <AlloyButton size="large" icon={<img src={bookmarkAddIcon} alt="Add bookmark" />} />
          <AlloyButton size="large" type="secondary">
            Export
          </AlloyButton>
        </div>
      </div>
      <div className={s.main}>
        <div className={s.product_list_wrapper}>
          <div className={s.inputs_wrapper}>
            <AlloyInput
              placeholder="Search"
              value={nonEmptySearch}
              onChange={(event) => setSearchTerm(event.target.value)}
              allowClear={true}
              prefix={<SearchOutlined width="16px" height="16px" />}
            />
            <div className={s.sorting_container}>
              <AlloySelect
                options={sortingOptions.map((label) => ({ label, value: label }))}
                value={sort}
                onChange={(value) => setSort(value)}
                popupMatchSelectWidth={190}
              />
              <div className={s.amount}>{mockProducts.length}</div>
            </div>
          </div>
          <div className={s.product_list}>
            {mockProducts.map((x) => (
              <ProductCard
                asin={x.asin}
                description={x.description}
                upc={x.upc}
                isSelected={selected === x.asin}
                setSelected={setSelected}
              />
            ))}
          </div>
        </div>
        <div className={s.details}>
          {selectedProduct && <ProductDetails product={selectedProduct} />}
          {selectedProduct && <ForecastTable />}
        </div>
      </div>
    </div>
  );
};
