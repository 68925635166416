import React from 'react';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Col, Row, Switch, Table, Button, Collapse } from 'antd';
import { useEnumValue } from 'common/useEnumValue';
import { AvailableCarrierCodes } from 'common/constants';
import { FinalFormInput } from 'components/Common/fields/FinalFormInput/FinalFormInput';
import { FinalFormSelect } from 'components/Common/fields/FinalFormSelect/FinalFormSelect';
import { FinalFormSwitch } from 'components/Common/fields/FinalFormSwitch/FinalFormSwitch';
import {
  composeValidators,
  parseIntValue,
  validateEmail,
  validateEmails,
  validateRequired
} from 'components/Common/fields/Utils';
import { PasswordField } from 'components/PasswordField/PasswordField';
import { ChannelAdvisorProfilesDocument } from 'pages/TradingPartnersPage/gql/__generated__/channelAdvisorProfiles.query';
import { ChannelAdvisorProfile } from 'graphql/__generated__/types';
import { useForm } from 'react-final-form';
import s from './IntegrationsTab.module.scss';
import { Condition } from '../../../../AssortmentPage/components/EditTradingPartnerAssortment/components/FormFieldComponent/FormFieldComponent';
import { DeleteOutlined } from '@ant-design/icons';
import { FieldArray } from 'react-final-form-arrays';
import { TradingPartnerDefaultConfigsDocument } from 'pages/TradingPartnersPage/gql/__generated__/tradingPartnerDefaultConfigs.query';
import { FormApi } from 'final-form';
import { TradingPartner } from '../../TradingPartnerViewDrawer/TradingPartnerViewDrawer';

interface IntegrationsTabProps {
  tradingPartner?: TradingPartner;
  setUseConfig: (integrationName: string | undefined, value: boolean, form: FormApi) => void;
  upserting: boolean;
}

const columns = [
  {
    title: 'Type',
    dataIndex: 'title'
  },
  Table.EXPAND_COLUMN
];

export const IntegrationsTab = ({
  tradingPartner,
  setUseConfig,
  upserting
}: IntegrationsTabProps) => {
  const { Panel } = Collapse;
  const { data, loading } = useQuery(ChannelAdvisorProfilesDocument, {});

  const { data: defaultConfigsData } = useQuery(TradingPartnerDefaultConfigsDocument);

  const form = useForm();
  const { enumValues: ochConfigRegionValues, loading: ochConfigRegionValuesLoading } =
    useEnumValue('OchRegion');

  const { enumValues: unitOfMeasureValues, loading: unitOfMeasureValuesLoading } =
    useEnumValue('UnitOfMeasure');

  const { enumValues: productIdQualifierValues, loading: productIdQualifierValuesLoading } =
    useEnumValue('ProductIdQualifier');

  const {
    enumValues: internalProductIdQualifierValues,
    loading: internalProductIdQualifierValuesLoading
  } = useEnumValue('InternalProductIdQualifier');

  const {
    enumValues: ctt01TargetSegmentCountValues,
    loading: ctt01TargetSegmentCountValuesLoading
  } = useEnumValue('Edi856Ctt01TargetSegmentCountOptions');

  const {
    enumValues: isaInterchangeUsageIndicatorValues,
    loading: isaInterchangeUsageIndicatorValuesLoading
  } = useEnumValue('IsaInterchangeUsageIndicator');

  const { enumValues: ediFormatValues, loading: ediFormatValuesLoading } =
    useEnumValue('EdiFormat');

  const { enumValues: shippingSlaValues, loading: shippingSlaValuesLoading } =
    useEnumValue('ShippingSla');

  const {
    enumValues: invoiceConfigTermsOfSaleBasisDateCodeValues,
    loading: invoiceConfigTermsOfSaleBasisDateCodeValuesLoading
  } = useEnumValue('InvoiceTermsOfSaleBasisDateCode');

  const {
    enumValues: invoiceConfigTermsOfSalePaymentTypeValues,
    loading: invoiceConfigTermsOfSalePaymentTypeValuesLoading
  } = useEnumValue('InvoiceTermsOfSalePaymentType');

  const [integrations, setIntegration] = useState<{
    [name: string]: {
      description: string;
      show: boolean;
      children?: { [name: string]: { description: string; show: boolean } };
    };
  }>({
    channelAdvisorConfig: {
      description: 'Channel Advisor Config',
      show: !!tradingPartner?.channelAdvisorConfig
    },
    ochConfig: {
      description: 'OCH Config',
      show: !!tradingPartner?.ochConfig
    },
    pepdirectConfig: {
      description: 'PEPDirect Config',
      show: !!tradingPartner?.pepdirectConfig
    },
    shippingConfig: {
      description: 'Shipping Config',
      show: !!tradingPartner?.shippingConfig
    },
    ediMessageConfiguration: {
      description: 'EDI message config',
      show: !!tradingPartner?.ediSelfServiceConfig?.ediGlobalConfig,
      children: {
        purchaseOrderCustomization850: {
          description: 'Purchase Order (850)',
          show: !!tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi850Config
        },
        purchaseOrderAckCustomization855: {
          description: 'Purchase Order Acknowledgements (855)',
          show: !!tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi855Config
        },
        purchaseOrderCustomization856: {
          description: 'ASN (856)',
          show: !!tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi856Config
        },
        purchaseOrderCustomization810: {
          description: 'Invoice (810)',
          show: !!tradingPartner?.ediSelfServiceConfig?.ediMessageConfig?.edi810Config
        }
      }
    },
    ediConfigExternal: {
      description: 'EDI Config External',
      show: !!tradingPartner?.ediConfigExternal
    },
    ediConfigInternal: {
      description: 'EDI Config Internal ',
      show: !!tradingPartner?.ediConfigInternal
    },
    emailConfigSalesOrderExport: {
      description: 'Email Config Sales Order Export',
      show: !!tradingPartner?.emailConfigSalesOrderExport
    },
    emailConfigSalesOrderRelease: {
      description: 'Email Config Sales Order Release',
      show: !!tradingPartner?.emailConfigSalesOrderRelease
    },
    internalAlertConfig: {
      description: 'Internal Alert Config',
      show: !!tradingPartner?.internalAlertConfig
    },
    internalAlertEmailConfig: {
      description: 'Internal Alert Email Config',
      show: !!tradingPartner?.internalAlertEmailConfig
    },
    amazonSellingPartnerConfig: {
      description: 'Amazon Selling Partner Config',
      show: !!tradingPartner?.amazonSellingPartnerConfig
    },
    samsClubConfig: {
      description: "Sam's Club Config",
      show: !!tradingPartner?.samsClubConfig
    },
    invoiceConfig: {
      description: 'Invoice Config',
      show: !!tradingPartner?.invoiceConfig
    }
  });

  const shippingConfigForm = (
    <Row gutter={8}>
      <Col span={12}>
        <FinalFormSwitch name="shippingConfigRoutingEnabled" title="Routing Enabled" />
        <FinalFormInput
          name="shippingConfigMinimalPickupDays"
          title="Minimal Pickup Days"
          type="number"
          parse={parseIntValue}
          required
          validate={validateRequired}
        />
      </Col>
      <Col span={12}>
        <FinalFormSwitch name="shippingConfigTenderingEnabled" title="Tendering Enabled" />
        <FinalFormInput
          name="shippingConfigMaximalPickupDays"
          title="Maximal Pickup Days"
          type="number"
          parse={parseIntValue}
          required
          validate={validateRequired}
        />
      </Col>
      <Col span={12}>
        <FinalFormSelect
          loading={shippingSlaValuesLoading}
          name="shippingConfigSla"
          title="Shipping SLA"
          options={(shippingSlaValues || []).map((sla) => ({
            value: sla,
            label: sla
          }))}
          allowClear
        />
      </Col>
      <Col span={24}>
        <FinalFormSelect
          name="shippingConfigAvailableCarrierCodes"
          title="Available Carrier Codes"
          options={Object.keys(AvailableCarrierCodes).map((code) => ({
            value: code,
            label: code
          }))}
          mode="tags"
        />
      </Col>
    </Row>
  );

  const validateIfSelected = (integrationName: string, validator: any) => {
    return integrations[integrationName]?.show && !upserting ? validator : undefined;
  };

  const ochConfigForm = (
    <Row gutter={8}>
      <Col span={12}>
        <FinalFormInput
          name="ochConfigScope"
          title="Scope"
          required
          validate={validateIfSelected('ochConfig', validateRequired)}
        />
      </Col>
      <Col span={12}>
        <FinalFormInput
          name="ochConfigSourceSystemId"
          title="Source System ID"
          type="number"
          parse={parseIntValue}
        />
      </Col>
      <Col span={12}>
        <FinalFormSelect
          loading={ochConfigRegionValuesLoading}
          name="ochConfigRegion"
          title="Region"
          options={(ochConfigRegionValues || []).map((region: string) => ({
            value: region,
            label: region
          }))}
          allowClear
        />
      </Col>
    </Row>
  );

  const channelAdvisorConfigForm = (
    <Row gutter={8}>
      <Col span={12}>
        <FinalFormInput
          name="channelAdvisorConfigSiteId"
          title="Site ID"
          required
          validate={validateIfSelected('channelAdvisorConfig', validateRequired)}
          type="number"
          parse={parseIntValue}
        />
        <FinalFormSelect
          loading={loading}
          name="channelAdvisorConfigProfileId"
          title="Profile ID"
          options={(data?.channelAdvisorProfiles || []).map(({ accountName, profileId }) => ({
            value: String(profileId),
            label: String(accountName)
          }))}
          required
          validate={validateIfSelected('channelAdvisorConfig', validateRequired)}
        />
        <FinalFormInput
          name="channelAdvisorConfigSpecialInstructionsPrefix"
          title="Special Instructions Prefix"
        />
      </Col>
      <Col span={12}>
        <FinalFormInput
          name="channelAdvisorConfigSiteName"
          title="Site Name"
          required
          validate={validateIfSelected('channelAdvisorConfig', validateRequired)}
        />
        <FinalFormInput name="channelAdvisorConfigPrivateNotes" title="Private Notes" />
        <FinalFormInput
          name="channelAdvisorConfigSpecialInstructionsPostfix"
          title="Special Instructions Postfix"
        />
      </Col>
    </Row>
  );

  const pepdirectConfigForm = (
    <div>
      <FinalFormInput
        name="pepdirectConfigAppId"
        title="App ID"
        required
        validate={validateIfSelected('pepdirectConfig', validateRequired)}
      />
    </div>
  );

  const emailSettings = (configName: string) => (
    <Row gutter={8}>
      <Col span={24}>
        <FinalFormSwitch name={`${configName}Active`} title="Active" />
      </Col>
      <Col span={12}>
        <FinalFormInput
          name={`${configName}EmailFromAddress`}
          title="Email From Address"
          required
          validate={validateIfSelected(
            configName,
            composeValidators([validateRequired, validateEmail])
          )}
        />
      </Col>
      <Col span={12}>
        <FinalFormInput name={`${configName}EmailFromName`} title="Email From Name" />
      </Col>
      <Col span={24}>
        <FinalFormSelect
          name={`${configName}EmailToList`}
          title="Email To List"
          mode="tags"
          options={[]}
          hideDropdown
          validate={validateEmails}
        />
      </Col>
    </Row>
  );

  const emailConfigSalesOrderRelease = emailSettings('emailConfigSalesOrderRelease');

  const emailConfigSalesOrderExport = emailSettings('emailConfigSalesOrderExport');

  const conditionalFields = (integrationName: string, prefix: string) => {
    const customSelectList: { value: number; label: string }[] = [];

    for (let i = 1; i <= 20; i++) {
      customSelectList.push({
        value: i,
        label: `${prefix}${i}`
      });
    }

    const disabledOptions = customSelectList
      .filter((element2) =>
        (form.getFieldState(integrationName)?.value || [])
          .map(
            (customElement: { position: number; inputTextNull: boolean; inputText: string }) =>
              customElement?.position
          )
          .some((element1: number) => element2.value === element1)
      )
      .map((element) => element.value);

    return (
      <FieldArray name={`${integrationName}`}>
        {({ fields }) => (
          <>
            <Col span={12}>
              <Button
                className={s.integration_edi_custom_button}
                onClick={() => fields.push(undefined)}
                disabled={form.getFieldState(`${integrationName}Switch`)?.value === true}
              >
                Add Option
              </Button>
            </Col>
            <Condition when={`${integrationName}Switch`} is={false}>
              <Col span={24}>
                {fields.map((name, index) => (
                  <Row key={index} gutter={10}>
                    <Col span={10}>
                      <FinalFormSelect
                        loading={loading}
                        name={`${name}.position`}
                        title="Position"
                        disabledOptionsList={disabledOptions}
                        options={customSelectList}
                      />
                    </Col>
                    <Col span={10}>
                      <FinalFormInput
                        key={
                          `${name}.inputText` + form.getFieldState(`${name}.inputTextNull`)?.value
                        }
                        name={`${name}.inputText`}
                        disabled={
                          !form.getFieldState(`${name}.position`)?.value ||
                          form.getFieldState(`${name}.inputTextNull`)?.value
                        }
                        title="Input"
                        required={!form.getFieldState(`${name}.inputTextNull`)?.value}
                      />
                    </Col>
                    <Col span={2}>
                      <FinalFormSwitch
                        name={`${name}.inputTextNull`}
                        classname={s.integration_edi_custom_checkbox}
                        title="Empty"
                        checkbox
                        disabled={!form.getFieldState(`${name}.position`)?.value}
                      />
                    </Col>
                    <Col span={2}>
                      <Button
                        className={s.integration_edi_custom_button}
                        icon={<DeleteOutlined />}
                        onClick={() => fields.remove(index)}
                      />
                    </Col>
                  </Row>
                ))}
              </Col>
            </Condition>
          </>
        )}
      </FieldArray>
    );
  };

  const ediMsgConfig = () => {
    const renderChildren = (integration: string) => {
      switch (integration) {
        case 'purchaseOrderCustomization850':
          return integrations.ediMessageConfiguration.children &&
            integrations.ediMessageConfiguration.children['purchaseOrderCustomization850'].show
            ? purchaseOrderCustomization850Config
            : null;
        case 'purchaseOrderAckCustomization855':
          return integrations.ediMessageConfiguration.children &&
            integrations.ediMessageConfiguration?.children['purchaseOrderAckCustomization855']?.show
            ? purchaseOrderAckCustomization855Config
            : null;
        case 'purchaseOrderCustomization856':
          return integrations.ediMessageConfiguration.children &&
            integrations.ediMessageConfiguration?.children['purchaseOrderCustomization856']?.show
            ? purchaseOrderCustomization856Config
            : null;
        case 'purchaseOrderCustomization810':
          return integrations.ediMessageConfiguration.children &&
            integrations.ediMessageConfiguration?.children['purchaseOrderCustomization810']?.show
            ? purchaseOrderCustomization810Config
            : null;
        default:
          return null;
      }
    };

    const integrationChildrenKeys =
      integrations?.ediMessageConfiguration?.children &&
      Object.keys(integrations?.ediMessageConfiguration?.children);

    return (
      <>
        <Row className={s.title_row}>Global settings</Row>
        <Row gutter={8}>
          <Col span={12}>
            <FinalFormSelect
              loading={productIdQualifierValuesLoading}
              name={'ediMessageConfigurationExternalProductId'}
              data-testid={'ediGlobalConfigExtQualifier'}
              title="External Product ID qualifier"
              options={(productIdQualifierValues || []).map((qualifier: string) => ({
                value: qualifier,
                label: qualifier
              }))}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <FinalFormInput
              name={'ediMessageConfigurationGsLocalCode'}
              data-testid={'ediGlobalConfigGsLocalCode'}
              title="GS Local Code"
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <FinalFormInput
              name={'ediMessageConfigurationIsaLocalId'}
              data-testid={'ediGlobalConfigIsaLocalId'}
              title="ISA Local ID"
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <FinalFormInput
              name={'ediMessageConfigurationIsaRemoteId'}
              data-testid={'ediGlobalConfigIsaRemoteId'}
              title="ISA Remote ID"
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <FinalFormInput
              name={'ediMessageConfigurationIsaComponentElementSeparator'}
              data-testid={'ediGlobalConfigIsaComponentSeparator'}
              title="ISA Component Element Separator"
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <FinalFormInput
              name={'ediMessageConfigurationIsaRepetitionSeparator'}
              data-testid={'ediGlobalConfigIsaRepetitionSeparator'}
              title="ISA Repetition Separator"
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <FinalFormInput
              name={'ediMessageConfigurationSupplierId'}
              data-testid={'ediGlobalConfigSupplierId'}
              title="Supplier ID"
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <FinalFormSelect
              loading={ediFormatValuesLoading}
              name={'ediMessageConfigurationFormat'}
              data-testid={'ediGlobalConfigFormat'}
              title="Format"
              options={(ediFormatValues || []).map((format) => ({
                value: format,
                label: format
              }))}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
          </Col>
          <Col span={12}>
            <FinalFormSelect
              loading={internalProductIdQualifierValuesLoading}
              name={'ediMessageConfigurationInternalProductId'}
              data-testid={'ediGlobalConfigIntQualifier'}
              title="Internal Product ID qualifier"
              options={(internalProductIdQualifierValues || []).map((qualifier) => ({
                value: qualifier,
                label: qualifier
              }))}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <FinalFormInput
              name={'ediMessageConfigurationGsRemoteCode'}
              data-testid={'ediGlobalConfigGsRemoteCode'}
              title="GS Remote Code"
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <FinalFormInput
              name={'ediMessageConfigurationIsaLocalQualifier'}
              data-testid={'ediGlobalConfigIsaLocalQualifier'}
              title="ISA Local Qualifier"
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <FinalFormInput
              name={'ediMessageConfigurationIsaRemoteQualifier'}
              data-testid={'ediGlobalConfigIsaRemoteQualifier'}
              title="ISA Remote Qualifier"
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <FinalFormInput
              name={'ediMessageConfigurationIsaInterchangeControlVersion'}
              data-testid={'ediGlobalConfigIsaIntControlVer'}
              title="ISA Interchange Control Version Number"
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <FinalFormSelect
              loading={isaInterchangeUsageIndicatorValuesLoading}
              name={'ediMessageConfigurationIsaInterchangeUsageIndicator'}
              data-testid={'ediGlobalConfigIsaIntUsageInd'}
              title="Interchange Usage Indicator"
              options={(isaInterchangeUsageIndicatorValues || []).map((indicator) => ({
                value: indicator,
                label: indicator
              }))}
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <FinalFormInput
              name={'ediMessageConfigurationSupplierIdPath'}
              data-testid={'ediGlobalConfigSupplierIdPath'}
              title="Supplier ID Path"
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
            <FinalFormInput
              name={'ediMessageConfigurationWebhookEndpoint'}
              data-testid={'ediGlobalConfigWebhookEndpoint'}
              title="Webhook Endpoint"
              required
              validate={validateIfSelected('ediMessageConfiguration', validateRequired)}
            />
          </Col>
        </Row>
        {integrationChildrenKeys ? (
          <Collapse
            bordered={false}
            expandIconPosition={'right'}
            defaultActiveKey={integrationChildrenKeys.filter(
              (integrationName) =>
                integrations.ediMessageConfiguration.children &&
                integrations.ediMessageConfiguration.children[integrationName].show
            )}
            expandIcon={({ isActive, header }) => (
              <Switch
                checked={isActive}
                onChange={(checked) => {
                  const targetKey =
                    integrationChildrenKeys.find(
                      (integrationName) =>
                        integrations.ediMessageConfiguration.children &&
                        integrations.ediMessageConfiguration.children[integrationName]
                          .description === header
                    ) || '';
                  integrations.ediMessageConfiguration.children &&
                    setIntegration({
                      ...integrations,
                      ediMessageConfiguration: {
                        ...integrations.ediMessageConfiguration,
                        children: {
                          ...integrations.ediMessageConfiguration?.children,
                          [targetKey]: {
                            ...integrations.ediMessageConfiguration?.children[targetKey],
                            show: checked
                          }
                        }
                      }
                    });
                  setUseConfig(targetKey, checked, form);
                }}
              />
            )}
          >
            {integrationChildrenKeys &&
              integrationChildrenKeys.map((integration) => (
                <Panel
                  key={integration}
                  header={
                    integrations.ediMessageConfiguration?.children &&
                    integrations.ediMessageConfiguration.children[integration].description
                  }
                >
                  {renderChildren(integration)}
                </Panel>
              ))}
          </Collapse>
        ) : null}
      </>
    );
  };

  const purchaseOrderCustomization850Config = (
    <>
      <Row className={s.title_row}>EDI customizations</Row>
      <Row gutter={8}>
        <Col span={12}>
          <FinalFormInput
            name={'purchaseOrderCustomization850ConfigShipTo'}
            data-testid={'edi850ConfigShipTo'}
            title="Ship-To/Supplier info segment"
          />
        </Col>
        <Col span={12}>
          <FinalFormSelect
            loading={unitOfMeasureValuesLoading}
            name={'purchaseOrderCustomization850ConfigProductUnit'}
            data-testid={'edi850ConfigProductUnitMeasurement'}
            title="Product unit of measurement"
            allowClear
            options={(unitOfMeasureValues || []).map((unit: string) => ({
              value: unit,
              label: unit
            }))}
            required
            validate={validateIfSelected('purchaseOrderCustomization850', validateRequired)}
          />
        </Col>
      </Row>
      <Row className={s.title_row}>Business logic</Row>
      <Row gutter={8}>
        <Col span={12}>
          <FinalFormSelect
            loading={loading}
            name={'purchaseOrderCustomization850ConfigPassUpc'}
            data-testid={'edi850ConfigPassUpcToBu'}
            title="Pass UPC through to BU"
            options={[
              { value: false, label: 'No' },
              { value: true, label: 'Yes' }
            ]}
            required
            validate={validateIfSelected('purchaseOrderCustomization850', validateRequired)}
          />
        </Col>
      </Row>
    </>
  );

  const purchaseOrderAckCustomization855Config = (
    <>
      <Row className={s.title_row}>EDI customizations</Row>
      <Row gutter={8}>
        <Col span={12}>
          <FinalFormSelect
            loading={loading}
            name={'purchaseOrderAckCustomization855ConfigCustomSwitch'}
            data-testid={'edi855ConfigAdditionalItemsSwitch'}
            title="PO1 - Additional item(s) added"
            options={[
              { value: true, label: 'Ignore' },
              { value: false, label: 'Custom' }
            ]}
          />
        </Col>
        {conditionalFields('purchaseOrderAckCustomization855ConfigCustom', 'PO10')}

        <Col span={12}>
          <FinalFormInput
            name="purchaseOrderAckCustomization855ConfigItemNotAvailInput"
            data-testid={'edi855ConfigItemNotAvailable'}
            title="MTX02 Item not available"
          />
        </Col>
        <Col span={12}>
          <FinalFormInput
            name="purchaseOrderAckCustomization855ConfigItemInvalidInput"
            data-testid={'edi855ConfigItemInvalid'}
            title="MTX02 Item invalid/rejected"
          />
        </Col>
      </Row>
      <Row className={s.title_row}>Business Logic</Row>
      <Row>
        <Col span={12}>
          <FinalFormSelect
            loading={loading}
            name="purchaseOrderAckCustomization855ConfigMatch"
            data-testid={'edi855ConfigMatchOrig850'}
            title="Match PO101 sequence to original 850"
            options={[
              { value: false, label: 'No' },
              { value: true, label: 'Yes' }
            ]}
            required
            validate={validateIfSelected('purchaseOrderAckCustomization855', validateRequired)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FinalFormSwitch
            name="purchaseOrderAckCustomization855ConfigReleaseDocument"
            title="Release document to Trading Partner"
            checkbox
            inline
          />
        </Col>
      </Row>
    </>
  );

  const purchaseOrderCustomization856Config = (
    <>
      <Row className={s.title_row}>EDI customizations</Row>
      <Row gutter={8}>
        <Col span={12}>
          <FinalFormSelect
            loading={loading}
            name={'purchaseOrderCustomization856ConfigCustomSwitch'}
            data-testid={'edi856ConfigAdditionalItemsSwitch'}
            title="LIN - Additional item(s) added"
            options={[
              { value: true, label: 'Ignore' },
              { value: false, label: 'Custom' }
            ]}
          />
        </Col>

        {conditionalFields('purchaseOrderCustomization856ConfigCustom', 'LIN0')}
      </Row>

      <Row className={s.title_row}>Business Logic</Row>
      <Row gutter={8}>
        <Col span={12}>
          <FinalFormSelect
            loading={loading}
            name="purchaseOrderCustomization856ConfigMatch"
            data-testid={'edi856ConfigMatchToOrigBu'}
            title="Match LIN01 Sequence to original 850"
            options={[
              { value: false, label: 'No' },
              { value: true, label: 'Yes' }
            ]}
            required
            validate={validateIfSelected('purchaseOrderCustomization856', validateRequired)}
          />
        </Col>
        <Col span={12}>
          <FinalFormSelect
            loading={loading}
            name="purchaseOrderCustomization856ConfigPassShipTo"
            data-testid={'edi856ConfigPassShipToFromBu'}
            title="Pass through Ship-to from BU"
            options={[
              { value: false, label: 'No' },
              { value: true, label: 'Yes' }
            ]}
            required
            validate={validateIfSelected('purchaseOrderCustomization856', validateRequired)}
          />
        </Col>
        <Col span={24}>
          <FinalFormSwitch
            name="purchaseOrderCustomization856ConfigReleaseDocument"
            title="Release document to Trading Partner"
            checkbox
            inline
          />
        </Col>
        <Col span={12}>
          <FinalFormSelect
            loading={ctt01TargetSegmentCountValuesLoading}
            name="purchaseOrderCustomization856ConfigCttCountMatch"
            data-testid={'edi856ConfigPassShipToFromBu'}
            title="CTT01 Number of Line Items Count Match"
            options={(ctt01TargetSegmentCountValues || []).map((segment) => ({
              value: segment,
              label: segment
            }))}
            required
            validate={validateIfSelected('purchaseOrderCustomization856', validateRequired)}
          />
        </Col>
      </Row>
    </>
  );

  const purchaseOrderCustomization810Config = (
    <>
      <Row className={s.title_row}>EDI customizations</Row>
      <Row gutter={8}>
        <Col span={12}>
          <FinalFormInput
            key={
              'purchaseOrderCustomization810ConfigResName' +
              !form.getFieldState('purchaseOrderCustomization810ConfigPassRemAddress')?.value
            }
            name="purchaseOrderCustomization810ConfigResName"
            data-testid={'edi810ConfigRecName'}
            title="N1 Remittance: Recipient Name"
            required={
              !form.getFieldState('purchaseOrderCustomization810ConfigPassRemAddress')?.value
            }
          />
        </Col>
        <Col span={12}>
          <FinalFormInput
            key={
              'purchaseOrderCustomization810ConfigResAddress' +
              !form.getFieldState('purchaseOrderCustomization810ConfigPassRemAddress')?.value
            }
            name="purchaseOrderCustomization810ConfigResAddress"
            data-testid={'edi810ConfigAddress'}
            title="N3 Remittance: Address"
            required={
              !form.getFieldState('purchaseOrderCustomization810ConfigPassRemAddress')?.value
            }
          />
        </Col>
        <Col span={12}>
          <FinalFormInput
            key={
              'purchaseOrderCustomization810ConfigResCityZip' +
              !form.getFieldState('purchaseOrderCustomization810ConfigPassRemAddress')?.value
            }
            name="purchaseOrderCustomization810ConfigResCityZip"
            data-testid={'edi810ConfigCityZipCountry'}
            title="N4 Remittance: City/State/Zip/Country"
            required={
              !form.getFieldState('purchaseOrderCustomization810ConfigPassRemAddress')?.value
            }
          />
        </Col>
      </Row>
      <Row gutter={8}>
        <Col span={12}>
          <FinalFormSelect
            loading={loading}
            name={'purchaseOrderCustomization810ConfigCustomSwitch'}
            data-testid={'edi810ConfigAdditionalItemsSwitch'}
            title="IT1 - Additional Item(s) added"
            options={[
              { value: true, label: 'Ignore' },
              { value: false, label: 'Custom' }
            ]}
          />
        </Col>

        {conditionalFields('purchaseOrderCustomization810ConfigCustom', 'IT10')}
      </Row>

      <Row gutter={8}>
        <Col span={12}>
          <FinalFormSelect
            loading={loading}
            name="purchaseOrderCustomization810ConfigMatch"
            data-testid={'edi810ConfigMatchOrig850'}
            title="Match IT101 Sequence to original 850"
            options={[
              { value: false, label: 'No' },
              { value: true, label: 'Yes' }
            ]}
            required
            validate={validateIfSelected('purchaseOrderCustomization810', validateRequired)}
          />
        </Col>
        <Col span={12}>
          <FinalFormSelect
            loading={loading}
            name="purchaseOrderCustomization810ConfigPassRemAddress"
            data-testid={'edi810ConfigPassAddressFromBu'}
            title="Pass remittance address from BU"
            options={[
              { value: false, label: 'No' },
              { value: true, label: 'Yes' }
            ]}
            required
            validate={validateIfSelected('purchaseOrderCustomization810', validateRequired)}
          />
        </Col>
        <Col span={12}>
          <FinalFormSelect
            loading={loading}
            name="purchaseOrderCustomization810ConfigPassBillToAddress"
            data-testid={'edi810ConfigPassBillToFromBu'}
            title="Pass through bill-to address from BU"
            options={[
              { value: false, label: 'No' },
              { value: true, label: 'Yes' }
            ]}
            required
            validate={validateIfSelected('purchaseOrderCustomization810', validateRequired)}
          />
          {!form.getFieldState('purchaseOrderCustomization810ConfigPassBillToAddress')?.value ? (
            <div className={s.subfield_text}>Ship-to config address will be used</div>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FinalFormSwitch
            name="purchaseOrderCustomization810ConfigReleaseDocument"
            title="Release document to Trading Partner"
            checkbox
            inline
          />
        </Col>
      </Row>
    </>
  );

  const ediConfig = (configName: string) => (
    <Row gutter={8}>
      <Col span={12}>
        <FinalFormInput
          name={`${configName}GsLocalCode`}
          title="GS Local Code"
          required
          validate={validateIfSelected(configName, validateRequired)}
        />
        <FinalFormInput
          name={`${configName}IsaLocalId`}
          title="ISA Local ID"
          required
          validate={validateIfSelected(configName, validateRequired)}
        />
        <FinalFormInput
          name={`${configName}IsaRemoteId`}
          title="ISA Remote ID"
          required
          validate={validateIfSelected(configName, validateRequired)}
        />
        <FinalFormInput
          name={`${configName}IsaComponentElementSeparator`}
          title="ISA Component Element Separator"
        />
        <FinalFormInput
          name={`${configName}IsaRepetitionSeparator`}
          title="ISA Repetition Separator"
        />
        <FinalFormInput name={`${configName}SupplierId`} title="Supplier ID" />
        <FinalFormInput name={`${configName}Format`} title="Format" />
      </Col>
      <Col span={12}>
        <FinalFormInput
          name={`${configName}GsRemoteCode`}
          title="GS Remote Code"
          required
          validate={validateIfSelected(configName, validateRequired)}
        />
        <FinalFormInput
          name={`${configName}IsaLocalQualifier`}
          title="ISA Local Qualifier"
          required
          validate={validateIfSelected(configName, validateRequired)}
        />
        <FinalFormInput
          name={`${configName}IsaRemoteQualifier`}
          title="ISA Remote Qualifier"
          required
          validate={validateIfSelected(configName, validateRequired)}
        />
        <FinalFormInput
          name={`${configName}IsaInterchangeControlVersionNumber`}
          title="ISA Interchange Control Version Number"
        />
        <FinalFormInput
          name={`${configName}InterchangeUsageIndicator`}
          title="Interchange Usage Indicator"
        />
        <FinalFormInput name={`${configName}SupplierIdPath`} title="Supplier ID Path" />
        <FinalFormInput name={`${configName}WebhookEndpoint`} title="Webhook Endpoint" />
      </Col>
    </Row>
  );

  const ediConfigExternal = ediConfig('ediConfigExternal');

  const ediConfigInternal = ediConfig('ediConfigInternal');

  const internalAlertEmailConfig = emailSettings('internalAlertEmailConfig');

  const internalAlertConfig = (
    <Row>
      <Col span={24}>
        <FinalFormSwitch
          name="internalAlertConfigSendDuplicateOrderItemsAlert"
          title="Send Duplicate Order Items Alert"
        />
        <FinalFormSwitch
          name="internalAlertConfigSendInvalidOrderItemsAlert"
          title="Send Invalid Order Items Alert"
        />
        <FinalFormSwitch
          name="internalAlertConfigSendLateOrdersAlert"
          title="Send Late Orders Alert"
        />
      </Col>
    </Row>
  );

  const amazonSellingPartnerConfig = (
    <Row gutter={8}>
      <Col span={24}>
        <FinalFormInput
          name="amazonSellingPartnerConfigAccessKeyId"
          title="Access Key ID"
          placeholder={
            defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
              ?.accessKeyId || undefined
          }
        />
        <FinalFormInput
          name="amazonSellingPartnerConfigClientId"
          title="Client ID"
          placeholder={
            defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
              ?.clientId || undefined
          }
        />
        <FinalFormInput
          name="amazonSellingPartnerConfigRegion"
          title="Region"
          placeholder={
            defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
              ?.region || undefined
          }
        />
        <FinalFormInput
          name="amazonSellingPartnerConfigWriteApiSite"
          title="Write API Site"
          placeholder={
            defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
              ?.writeApiSite || undefined
          }
        />
        <FinalFormInput
          name="amazonSellingPartnerConfigReadApiSite"
          title="Read API Site"
          placeholder={
            defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
              ?.readApiSite || undefined
          }
        />
        <FinalFormInput
          name="amazonSellingPartnerConfigAccessTokenSite"
          title="Access Token Site"
          placeholder={
            defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
              ?.accessTokenSite || undefined
          }
        />
        <PasswordField
          title="Client Secret"
          fieldName="amazonSellingPartnerConfigClientSecret"
          editFieldName="amazonSellingPartnerConfigEditClientSecret"
          upserting={upserting}
          defaultValue={
            defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
              ?.clientSecret
          }
        />
        <PasswordField
          title="Refresh Token"
          fieldName="amazonSellingPartnerConfigRefreshToken"
          editFieldName="amazonSellingPartnerConfigEditRefreshToken"
          upserting={upserting}
          defaultValue={
            defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
              ?.refreshToken
          }
        />
        <PasswordField
          title="Secret Access Key"
          fieldName="amazonSellingPartnerConfigSecretAccessKey"
          editFieldName="amazonSellingPartnerConfigEditSecretAccessKey"
          upserting={upserting}
          defaultValue={
            defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultAmazonSellingPartnerConfig
              ?.secretAccessKey
          }
        />
      </Col>
    </Row>
  );

  const samsClubConfig = (
    <Row gutter={8}>
      <Col span={24}>
        <FinalFormInput
          name="samsClubConfigClientId"
          title="Client ID"
          placeholder={
            defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultSamsClubConfig?.clientId ||
            undefined
          }
        />
        <PasswordField
          title="Client Secret"
          fieldName="samsClubConfigClientSecret"
          editFieldName="samsClubConfigEditClientSecret"
          upserting={upserting}
          defaultValue={
            defaultConfigsData?.tradingPartnerDefaultConfigs?.defaultSamsClubConfig?.clientSecret
          }
        />
      </Col>
    </Row>
  );

  const invoiceConfig = (
    <Row gutter={8}>
      {/* Bill To */}
      <Col span={12}>
        <FinalFormInput
          name="invoiceConfigBillToName"
          title="Bill To Name"
          // required
          // validate={validateRequired}
        />
        <FinalFormInput
          name="invoiceConfigBillToAddress"
          title="Bill To Address"
          // required
          // validate={validateRequired}
        />
      </Col>
      <Col span={12}>
        <FinalFormInput
          name="invoiceConfigBillToPartyId"
          title="Bill To Party ID"
          // required
          // validate={validateRequired}
        />
        <FinalFormInput
          name="invoiceConfigBillToCityName"
          title="Bill To City Name"
          // required
          // validate={validateRequired}
        />
      </Col>
      <Col span={12}>
        <FinalFormInput
          name="invoiceConfigBillToState"
          title="Bill To State"
          // required
          // validate={validateRequired}
        />
        <FinalFormInput
          name="invoiceConfigBillToCountryCode"
          title="Bill To Country Code"
          // required
          // validate={validateRequired}
        />
      </Col>
      <Col span={12}>
        <FinalFormInput
          name="invoiceConfigBillToPostalCode"
          title="Bill To Postal Code"
          // required
          // validate={validateRequired}
        />
        <FinalFormInput
          name="invoiceConfigBillToTaxRegistrationNumber"
          title="Bill To Tax Registration Number"
          // required
          // validate={validateRequired}
        />
      </Col>

      {/* Remittance */}
      <Col span={12}>
        <FinalFormInput
          name="invoiceConfigRemittanceName"
          title="Remittance Name"
          // required
          // validate={validateRequired}
        />
        <FinalFormInput
          name="invoiceConfigRemittanceAddress"
          title="Remittance Address"
          // required
          // validate={validateRequired}
        />
      </Col>
      <Col span={12}>
        <FinalFormInput
          name="invoiceConfigRemittancePartyId"
          title="Remittance Party ID"
          // required
          // validate={validateRequired}
        />
        <FinalFormInput
          name="invoiceConfigRemittanceCityName"
          title="Remittance City Name"
          // required
          // validate={validateRequired}
        />
      </Col>
      <Col span={12}>
        <FinalFormInput
          name="invoiceConfigRemittanceState"
          title="Remittance State"
          // required
          // validate={validateRequired}
        />
        <FinalFormInput
          name="invoiceConfigRemittanceCountryCode"
          title="Remittance Country Code"
          // required
          // validate={validateRequired}
        />
      </Col>
      <Col span={12}>
        <FinalFormInput
          name="invoiceConfigRemittancePostalCode"
          title="Remittance Postal Code"
          // required
          // validate={validateRequired}
        />
        <FinalFormInput
          name="invoiceConfigRemittanceTaxRegistrationNumber"
          title="Remittance Tax Registration Number"
          // required
          // validate={validateRequired}
        />
      </Col>

      {/* Terms of Sale */}
      <Col span={12}>
        <FinalFormSelect
          name="invoiceConfigTermsOfSaleBasisDateCode"
          title="Terms of Sale Basis Date Code"
          // required
          // validate={validateRequired}
          loading={invoiceConfigTermsOfSaleBasisDateCodeValuesLoading}
          options={(invoiceConfigTermsOfSaleBasisDateCodeValues || []).map(
            (termsOfSaleBasisDateCode) => ({
              value: termsOfSaleBasisDateCode,
              label: termsOfSaleBasisDateCode
            })
          )}
          allowClear
        />
        <FinalFormInput
          name="invoiceConfigTermsOfSaleDiscountPercent"
          title="Terms of Sale Discount Percent"
          type="number"
          // required
          // validate={validateRequired}
        />
      </Col>
      <Col span={12}>
        <FinalFormInput
          name="invoiceConfigTermsOfSaleDiscountDaysDue"
          title="Terms of Sale Discount Days Due"
          type="number"
          // required
          // validate={validateRequired}
        />
        <FinalFormInput
          name="invoiceConfigTermsOfSaleNetDays"
          title="Terms of Sale Net Days"
          type="number"
          // required
          // validate={validateRequired}
        />
      </Col>
      <Col span={12}>
        <FinalFormSelect
          name="invoiceConfigTermsOfSalePaymentType"
          title="Terms of Sale Payment Type"
          // required
          // validate={validateRequired}
          loading={invoiceConfigTermsOfSalePaymentTypeValuesLoading}
          options={(invoiceConfigTermsOfSalePaymentTypeValues || []).map(
            (termsOfSalePaymentType) => ({
              value: termsOfSalePaymentType,
              label: termsOfSalePaymentType
            })
          )}
          allowClear
        />
      </Col>
    </Row>
  );

  return (
    <>
      <Table
        rowKey={(row) => row.name}
        columns={columns}
        dataSource={Object.keys(integrations).map((integration) => ({
          title: integrations[integration].description,
          name: integration
        }))}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => {
            switch (record.name) {
              case 'channelAdvisorConfig':
                return integrations['channelAdvisorConfig'].show ? channelAdvisorConfigForm : null;
              case 'ochConfig':
                return integrations['ochConfig'].show ? ochConfigForm : null;
              case 'pepdirectConfig':
                return integrations['pepdirectConfig'].show ? pepdirectConfigForm : null;
              case 'shippingConfig':
                return integrations['shippingConfig'].show ? shippingConfigForm : null;
              case 'ediMessageConfiguration':
                return integrations['ediMessageConfiguration'].show ? ediMsgConfig() : null;
              case 'ediConfigInternal':
                return integrations[record.name].show ? ediConfigInternal : null;
              case 'ediConfigExternal':
                return integrations[record.name].show ? ediConfigExternal : null;
              case 'emailConfigSalesOrderRelease':
                return integrations[record.name].show ? emailConfigSalesOrderRelease : null;
              case 'emailConfigSalesOrderExport':
                return integrations[record.name].show ? emailConfigSalesOrderExport : null;
              case 'internalAlertEmailConfig':
                return integrations[record.name].show ? internalAlertEmailConfig : null;
              case 'internalAlertConfig':
                return integrations[record.name].show ? internalAlertConfig : null;
              case 'amazonSellingPartnerConfig':
                return integrations[record.name].show ? amazonSellingPartnerConfig : null;
              case 'samsClubConfig':
                return integrations[record.name].show ? samsClubConfig : null;
              case 'invoiceConfig':
                return integrations['invoiceConfig'].show ? invoiceConfig : null;
              default:
                return null;
            }
          },
          expandedRowKeys: Object.keys(integrations).reduce<string[]>((result, integration) => {
            if (integrations[integration].show) result.push(integration);
            return result;
          }, []),
          expandIcon: ({ expanded, record }) => (
            <Switch
              checked={expanded}
              onChange={(checked) => {
                setIntegration({
                  ...integrations,
                  [record.name]: { ...integrations[record.name], show: checked }
                });
                setUseConfig(record.name, checked, form);
              }}
            />
          )
        }}
      />
    </>
  );
};
