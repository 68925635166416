import React, { useMemo, useRef } from 'react';
import { CSVLink } from 'react-csv';
import s from './ExportSoItemsModal.module.scss';
import { dateFormat } from 'common/helpers/date';
import { useQuery } from '@apollo/client';
import LoaderSpinner from 'components/LoaderSpinner';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyTable, ColumnsType } from 'components/ui/AlloyTable/AlloyTable';
import { GetSalesOrdersForExportDocument } from './gql/__generated__/getSalesOrdersForExport.query';
import { SoExportItem } from 'graphql/__generated__/types';
import ErrorDisplay from 'components/Common/ErrorDisplay';
import { safeLocaleCompare } from 'common/helpers/comparators';

interface ExportSoItemsModalProps {
  visibility: boolean;
  toggleModal: () => void;
  selectedRowIds: string[];
}

const newSOItemHeaders: { label: string; key: keyof SoExportItem }[] = [
  { label: 'Line #', key: 'line' },
  { label: 'Sold To #', key: 'soldTo' },
  { label: 'Ship-To #', key: 'shipTo' },
  { label: 'RDD', key: 'rdd' },
  { label: 'Truck #', key: 'truck' },
  { label: 'SAP Mat #', key: 'sapMaterialId' },
  { label: 'Allocated Qty', key: 'allocatedQty' },
  { label: 'PO #', key: 'customerPo' },
  { label: 'Product External ID', key: 'externalId' },
  { label: 'UPC', key: 'upc' },
  { label: 'Description', key: 'description' },
  { label: 'Weight', key: 'weight' },
  { label: 'Warehouse', key: 'dcCode' },
  { label: 'Price', key: 'price' },
  { label: 'Currency', key: 'currency' }
];

const ExportSoItemsModal = ({
  visibility,
  toggleModal,
  selectedRowIds
}: ExportSoItemsModalProps) => {
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

  const {
    error: exportSOError,
    loading: exportSOLoading,
    data: exportSOData
  } = useQuery(GetSalesOrdersForExportDocument, {
    variables: {
      purchaseOrderIds: selectedRowIds
    },
    skip: !visibility
  });

  const newCsvData: SoExportItem[] = useMemo(() => {
    if (exportSOData) {
      let newMappedData: SoExportItem[] = [];

      exportSOData.soExportItems?.items.forEach((soToExport) => {
        newMappedData.push(soToExport);
      });

      newMappedData = newMappedData.sort((a, b) => safeLocaleCompare(a.customerPo, b.customerPo));

      const formattedData = [...newMappedData].map((item) => ({
        ...item,
        rdd: dateFormat(item.rdd),
        // for TPs who don't have External IDs and use UPCs instead
        externalId: item.externalId ?? item.upc
        // price: Number(item.price).toFixed(2)
      }));

      return formattedData;
    } else {
      return [];
    }
  }, [exportSOData]);

  if (!exportSOLoading && !newCsvData && !exportSOError) return null;
  if (exportSOLoading) return <LoaderSpinner />;
  if (exportSOError)
    return (
      <div>
        <ErrorDisplay error={exportSOError} />
      </div>
    );

  const newColumns: ColumnsType<SoExportItem> = [
    {
      title: 'Line #',
      dataIndex: 'line',
      align: 'center'
    },
    {
      title: 'Sold To #',
      dataIndex: 'soldTo',
      align: 'center'
    },
    {
      title: 'Ship-To #',
      dataIndex: 'shipTo',
      align: 'center'
    },
    {
      title: 'RDD',
      key: 'rdd',
      align: 'center',
      render: (_, soItem) => dateFormat(soItem.rdd) || ''
    },
    {
      title: 'Truck #',
      dataIndex: 'truck',
      align: 'center'
    },
    {
      title: 'SAP Mat #',
      dataIndex: 'sapMaterialId',
      align: 'center'
    },
    {
      title: 'Allocated Qty',
      dataIndex: 'allocatedQty',
      align: 'center'
    },
    {
      title: 'PO #',
      dataIndex: 'customerPo',
      align: 'center'
    },
    {
      title: 'Product External ID',
      dataIndex: 'externalId',
      align: 'center'
    },
    {
      title: 'UPC',
      dataIndex: 'upc',
      align: 'center'
    },
    {
      title: 'Description',
      dataIndex: 'description'
    },
    {
      title: 'Weight (lbs)',
      dataIndex: 'weight',
      align: 'center'
    },
    {
      title: 'Warehouse',
      dataIndex: 'dcCode',
      align: 'center'
    },
    {
      title: 'Price',
      key: 'price',
      render: (_, soItem) => Number(soItem.price).toFixed(2)
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency'
    }
  ];

  const controls = [
    <AlloyButton key="cancel" className="filled_grey_btn" onClick={() => toggleModal()}>
      Cancel
    </AlloyButton>,
    <AlloyButton
      key="export"
      className="filled_blue_btn"
      onClick={() => {
        csvLinkRef.current?.link.click();
        toggleModal();
      }}
      disabled={!(newCsvData.length > 0)}
      type="primary"
    >
      Download csv
    </AlloyButton>,
    <CSVLink
      key="Sales-Order-Item-List"
      ref={csvLinkRef}
      filename={'Sales-Order-Item-List.csv'}
      headers={newSOItemHeaders}
      data={newCsvData}
    />
  ];

  return (
    <AlloyModal
      data-testid="export_so_items_modal"
      className={s.export_so_items_modal}
      title={
        <div className={s.header}>
          Export Sales Order Items
          {!exportSOLoading && <div className={s.controls}>{controls}</div>}
        </div>
      }
      closable={false}
      open={visibility}
      onCancel={() => toggleModal()}
      width={1400}
      footer={!exportSOLoading && controls}
      classNames={{
        footer: s.footer
      }}
    >
      {exportSOLoading && <LoaderSpinner />}
      {!exportSOLoading && (
        <AlloyTable
          data-testid="so_items_table legacy_borderless_bordered"
          rowKey={(item: SoExportItem) => item.id}
          pagination={false}
          dataSource={newCsvData}
          columns={newColumns}
          scroll={{ x: '100%' }}
          bordered
        />
      )}
    </AlloyModal>
  );
};

export default ExportSoItemsModal;
