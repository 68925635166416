import React, { useState, useContext, useMemo } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import './styles.scss';
import { CancelPurchaseOrdersDocument } from 'components/PurchaseOrders/gql/__generated__/cancelPurchaseOrders.mutation';
import * as Sentry from '@sentry/browser';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ConfirmationModal } from './components/ConfirmationModal/ConfirmationModal';
import { DownConfirmationModal } from './components/DownConfirmation/DownConfirmationModal/DownConfirmationModal';
import EditPoTagsModal from 'components/PurchaseOrders/PoLabelsModal/EditPoTagsModal';
import { intersectionBy } from 'lodash-es';
import { ifEcommInSelectedRows } from 'components/PurchaseOrders/utils';
import { MultipleUpcEdit } from '../UpcManagement/MultipleUpcEdit';
import BulkProcessCancelPOsModal from './components/BulkProcessCancelPOsModal/BulkProcessCancelPOsModal';
import ReattemptReleaseOrdersModal from './components/ReattemptReleaseOrders/ReattemptReleaseOrdersModal';
import { ReattemptPurchaseOrdersDocument } from './components/ReattemptReleaseOrders/gql/__generated__/reattemptPurchaseOrders.mutation';
import ReprocessOrdersModal from './components/ReprocessOrders/ReprocessOrdersModal';
import { ReprocessPurchaseOrdersDocument } from './components/ReprocessOrders/gql/__generated__/reprocessPurchaseOrders.mutation';
import ResetOrdersModal from './components/ResetOrders/ResetOrdersModal';
import { ResetPurchaseOrdersToIngestedDocument } from './components/ResetOrders/gql/__generated__/resetPurchaseOrdersToIngested.mutation';
import { EditPoDestination } from './components/EditPoDestination/EditPoDestination';
import { DispatchActions } from 'graphql/__generated__/types';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import {
  GetPosForProcessingDocument,
  GetPosForProcessingQuery
} from './gql/__generated__/getPosForProcessing.query';
import { SplitPurchaseOrdersDocument } from './gql/__generated__/splitPurchaseOrders.mutation';
import { PurchaseOrder } from 'pages/OrdersPage/types';
import { UserContext } from 'context/userContext';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { App } from 'ant5';
import { AlloyMenu, AlloyMenuItem, AlloySubMenu } from 'components/ui/AlloyMenu/AlloyMenu';
import { SizeType } from 'ant5/lib/config-provider/SizeContext';
import { ResendCustomizationsModal } from './components/ResendCustomizationsModal/ResendCustomizationsModal';
import { CoastFlipsModal } from '@/src/pages/OrdersPage/components/BulkProcessSelect/components/CoastFlipsModal/CoastFlipsModal';
import { useFeatureFlags } from '@/src/common/useFeatureFlags/useFeatureFlags';
import { FE_PRE_SOURCE } from 'common/useFeatureFlags/featureFlags';

const { Option } = AlloySelect;

const BULK_ADD_TAG = 'addTag';
const AUTOSCRUB_ORDERS = 'Autoscrub orders';
const BULK_VIEW_MULTI_UPCS = 'viewMultiUpcs';
const BULK_PROCESS_SPLIT = 'split';
const BULK_PROCESS_REPROCESS = 'reprocess';
const BULK_PROCESS_CANCEL_POS = 'cancelPurchaseOrders';
const BULK_PROCESS_INITIAL_CONFIRMATION = 'confirmOrderAcceptance';
const BULK_PROCESS_DOWN_CONFIRMATION = 'downConfirmOrderAcceptance';
const BULK_PROCESS_RESET = 'reset';
const BULK_EDIT_PO_DESTINATION = 'editPoDestination';
const BULK_EDIT_PRE_SOURCING = 'preSourcing';
export const BULK_PROCESS_REATTEMPT_PO = 'reattemptPurchaseOrders';
const BULK_PROCESS_RESEND_CUSTOMIZATIONS = 'resendCustomizations';

const BulkProcesses = new Map<string, string>([
  [BULK_ADD_TAG, 'Add Tag'],
  [AUTOSCRUB_ORDERS, 'Autoscrub orders'],
  [BULK_PROCESS_CANCEL_POS, 'Cancel'],
  [BULK_PROCESS_DOWN_CONFIRMATION, 'Down Confirmation'],
  [BULK_EDIT_PO_DESTINATION, 'Edit Ship-To'],
  [BULK_PROCESS_INITIAL_CONFIRMATION, 'Initial confirmation'],
  [BULK_EDIT_PRE_SOURCING, 'Pre-Sourcing'],
  [BULK_PROCESS_REPROCESS, 'Reprocess'],
  [BULK_PROCESS_RESET, 'Reset'],
  [BULK_PROCESS_SPLIT, 'Split'],
  [BULK_VIEW_MULTI_UPCS, 'View multi-UPCs'],
  [BULK_PROCESS_REATTEMPT_PO, 'Reattempt'],
  [BULK_PROCESS_RESEND_CUSTOMIZATIONS, 'Resend Customizations']
]);

//this map corresponds with user availableActions or DispatchActions type and is used to display only actions the user is allowed to perform
const controlledBulkProcesses: { [index: string]: DispatchActions } = {};
controlledBulkProcesses[BULK_ADD_TAG] = 'ADD_TAG';
controlledBulkProcesses[AUTOSCRUB_ORDERS] = 'AUTOSCRUB';
controlledBulkProcesses[BULK_PROCESS_CANCEL_POS] = 'CANCEL_PO';
controlledBulkProcesses[BULK_PROCESS_DOWN_CONFIRMATION] = 'DOWN_CONFIRM';
controlledBulkProcesses[BULK_EDIT_PO_DESTINATION] = 'EDIT_SHIP_TO';
controlledBulkProcesses[BULK_PROCESS_INITIAL_CONFIRMATION] = 'INITAL_CONFIRMATION'; //spelling error
controlledBulkProcesses[BULK_PROCESS_REPROCESS] = 'REPROCESS_PO';
controlledBulkProcesses[BULK_PROCESS_RESET] = 'RESET_PO';
controlledBulkProcesses[BULK_PROCESS_SPLIT] = 'AUTO_TRUCK_SPLIT';
controlledBulkProcesses[BULK_VIEW_MULTI_UPCS] = 'VIEW_MULTI_UPC';
controlledBulkProcesses[BULK_PROCESS_REATTEMPT_PO] = 'REATTEMPT_PO';

interface BulkProcessSelectProps {
  purchaseOrders: {
    id: string;
    deliveryType: string;
    customerPo: string;
    primaryStatus: string;
    shipTo?: {
      id: string;
      name: string;
      externalId: string;
    };
    shipToExternalId?: string;
    labelIds: string[];
  }[];
  clearSelection: () => void;
  size?: SizeType;
}

type PurchaseOrderWithActions = InferNodeType<GetPosForProcessingQuery, 'purchaseOrders'>;
// PurchaseOrder is partial since we join PurchaseOrder to PurchaseOrderWithActions, but it's possible that it will be empty
export type JoinedPo = PurchaseOrderWithActions & Partial<PurchaseOrder>;

export interface AvailableActionSort {
  allowed: JoinedPo[];
  disallowed: JoinedPo[];
}

const BulkProcessSelect = ({ purchaseOrders, clearSelection, size }: BulkProcessSelectProps) => {
  const { notification, modal: antModal } = App.useApp();
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDownConfirmationModal, setShowDownConfirmationModal] = useState(false);
  const [editPoTagsModalVisibility, setEditPoTagsModalVisibility] = useState(false);
  const [multiUpcEditOpen, setMultiUpcEditOpen] = useState(false);
  const [showCancelPOsModal, setShowCancelPOsModal] = useState(false);
  const [showReprocessModal, setShowReprocessModal] = useState<boolean>(false);
  const [showReattemptModal, setShowReattemptModal] = useState<boolean>(false);
  const [showResetModal, setShowResetModal] = useState<boolean>(false);
  const [showResendCustomizationsModal, setShowResendCustomizationsModal] =
    useState<boolean>(false);
  const [showCoastFlipsModal, setShowCoastFlipsModal] = useState<boolean>(false);

  const {
    featureFlags: [{ enabled: isPreSourceEnabled }]
  } = useFeatureFlags([{ name: FE_PRE_SOURCE }]);

  const allowedBulkProcesses = useMemo(() => {
    const allowedActions = new Map();

    for (const process of BulkProcesses.keys()) {
      if (
        !controlledBulkProcesses[process] ||
        (!!user?.availableActions &&
          user?.availableActions.includes(controlledBulkProcesses[process]))
      ) {
        allowedActions.set(process, BulkProcesses.get(process));
      }
    }

    return allowedActions;
  }, [user]);

  const [splitPOs] = useMutation(SplitPurchaseOrdersDocument, {
    refetchQueries: ['purchaseOrdersForPurchaseOrdersPage', 'purchaseOrderCounts']
  });

  const purchaseOrderIds = purchaseOrders.map((purchaseOrder) => purchaseOrder.id);

  const [getPOs, getPOsResult] = useLazyQuery(GetPosForProcessingDocument, {
    variables: {
      ids: purchaseOrderIds,
      first: purchaseOrderIds.length
    },

    onError: (error) => {
      Sentry.captureException(error);
      notification.error({
        message: 'Failed to fetch purchase orders',
        description: 'Something went wrong while fetching purchase orders. Please try again'
      });
    }
  });

  const joinedPOs = getNodesFromEdges(getPOsResult.data?.purchaseOrders).map((po) => ({
    ...po,
    ...purchaseOrders.find((fullPo) => fullPo.id === po.id)
  }));

  const cancellablePOs: AvailableActionSort = {
    allowed: joinedPOs.filter((po) => po.availableActions.includes('CANCEL_PO')),
    disallowed: joinedPOs.filter((po) => !po.availableActions.includes('CANCEL_PO'))
  };

  const reattemptPOsList: AvailableActionSort = {
    allowed: joinedPOs.filter((po) => po.availableActions.includes('REATTEMPT_PO')),
    disallowed: joinedPOs.filter((po) => !po.availableActions.includes('REATTEMPT_PO'))
  };

  const reprocessPOsList: AvailableActionSort = {
    allowed: joinedPOs.filter((po) => po.availableActions.includes('REPROCESS_PO')),
    disallowed: joinedPOs.filter((po) => !po.availableActions.includes('REPROCESS_PO'))
  };

  const resetPOsList: AvailableActionSort = {
    allowed: joinedPOs.filter((po) => po.availableActions.includes('RESET_PO')),
    disallowed: joinedPOs.filter((po) => !po.availableActions.includes('RESET_PO'))
  };

  /*******************   CANCEL POS MUTATION  ********************************************/

  const [cancelPo, { loading: isCancelLoading }] = useMutation(CancelPurchaseOrdersDocument, {
    refetchQueries: ['purchaseOrdersForPurchaseOrdersPage', 'purchaseOrderCounts'],
    variables: { input: { purchaseOrderIds: cancellablePOs.allowed.map((po) => po.id) } },
    onCompleted: (data) => {
      const length = data.cancelPurchaseOrders?.purchaseOrders?.length || 0;
      notification.success({
        message: `${length} purchase order${length > 1 ? 's' : ''} successfully cancelled`,
        props: {
          'data-testid': 'bulk-process-po-cancel-success-notification'
        }
      });
      clearSelection();
    },
    onError: (error) => {
      notification.error({
        message: 'An error occurred while cancelling purchase order(s)',
        description: error.message
      });
      clearSelection();
    }
  });
  /************************************************************************************ */

  /****************************  REPROCESS POS MUTATION  **********************************/

  const [reprocessPo, { loading: isReprocessLoading }] = useMutation(
    ReprocessPurchaseOrdersDocument,
    {
      refetchQueries: ['purchaseOrdersForPurchaseOrdersPage', 'purchaseOrderCounts'],
      variables: { input: { purchaseOrderIds: reprocessPOsList.allowed.map((po) => po.id) } },
      onCompleted: (data) => {
        const length = data.reprocessPurchaseOrders?.purchaseOrders?.length || 0;
        notification.info({
          message: `${length} purchase order${length > 1 ? 's' : ''} successfully reprocessed`
        });
        clearSelection();
      },
      onError: (error) => {
        console.error(error);
        notification.error({
          message: 'An error occurred while reprocessing purchase order(s)',
          description: error.message
        });
        clearSelection();
      }
    }
  );

  /********************************************************************************** */

  /****************************  REATTEMPT POS MUTATION  **********************************/

  const [reattemptPo, { loading: isReattemptLoading }] = useMutation(
    ReattemptPurchaseOrdersDocument,
    {
      refetchQueries: ['purchaseOrdersForPurchaseOrdersPage', 'purchaseOrderCounts'],
      variables: { input: { purchaseOrderIds: reattemptPOsList.allowed.map((po) => po.id) } },
      onCompleted: (data) => {
        const length =
          data.reattemptPurchaseOrders?.purchaseOrdersEnqueuedForReattempt?.length || 0;
        notification.info({
          message: `${length} purchase order${length > 1 ? 's' : ''} queued for reattempt.`
        });
        clearSelection();
      },
      onError: (error) => {
        console.error(error);
        notification.error({
          message: 'An error occurred while reattempting release of purchase order(s)',
          description: error.message
        });
        clearSelection();
      }
    }
  );

  /********************************************************************************** */

  /****************************  RESET POS MUTATION  **********************************/

  const [resetPo, { loading: isResetLoading }] = useMutation(
    ResetPurchaseOrdersToIngestedDocument,
    {
      refetchQueries: ['purchaseOrdersForPurchaseOrdersPage', 'purchaseOrderCounts'],
      variables: { input: { purchaseOrderIds: resetPOsList.allowed.map((po) => po.id) } },
      onCompleted: (data) => {
        const length = data.resetPurchaseOrdersToIngested?.purchaseOrdersReset?.length || 0;
        notification.info({
          message: `${length} purchase order${length > 1 ? 's' : ''} queued for reset.`
        });
        clearSelection();
      },
      onError: (error) => {
        console.error(error);
        notification.error({
          message: 'An error occurred while resetting purchase order(s)',
          description: error.message
        });
        clearSelection();
      }
    }
  );

  /********************************************************************************** */

  const onConfirm = (action: string) => {
    setLoading(true);
    switch (action) {
      case BULK_PROCESS_REPROCESS:
        setLoading(false); // this is here because we are no longer setting loading in reprocessPo function
        return reprocessPo();
      case BULK_PROCESS_SPLIT:
        return split();
      case BULK_PROCESS_RESET:
        setLoading(false); // this is here because we are no longer setting loading in the reset function
        return resetPo();
      case BULK_PROCESS_CANCEL_POS:
        setLoading(false); // this is here because we are no longer setting loading in the cancelPo function
        return cancelPo();
      case BULK_PROCESS_REATTEMPT_PO:
        setLoading(false); // this is here because we are no longer setting loading in reattempt function
        return reattemptPo();
    }
  };

  const toggleEditPoTagsModal = () => setEditPoTagsModalVisibility(!editPoTagsModalVisibility);

  /************ SELECT FUNCTIONALITY  *************/
  function onSelect(action: string) {
    if (action === BULK_PROCESS_INITIAL_CONFIRMATION) {
      setShowConfirmationModal(true);
    } else if (action === BULK_PROCESS_DOWN_CONFIRMATION) {
      setShowDownConfirmationModal(true);
    } else if (action === BULK_PROCESS_CANCEL_POS) {
      getPOs();
      setShowCancelPOsModal(true);
    } else if (action === BULK_ADD_TAG) {
      toggleEditPoTagsModal();
    } else if (action === BULK_PROCESS_REPROCESS) {
      getPOs();
      setShowReprocessModal(true);
    } else if (action === BULK_PROCESS_REATTEMPT_PO) {
      getPOs();
      setShowReattemptModal(true);
    } else if (action === BULK_PROCESS_RESET) {
      getPOs();
      setShowResetModal(true);
    } else if (action === 'coastFlips') {
      setShowCoastFlipsModal(true);
    } else if (action === AUTOSCRUB_ORDERS) {
      window.open(
        `${
          import.meta.env.REACT_APP_API_URL
        }/supply_chain/autoscrub/start?customer_pos=${purchaseOrders
          .map((row) => row.customerPo)
          .join(',')}`,
        '_blank',
        'noopener,noreferrer'
      );
    } else if (action === BULK_VIEW_MULTI_UPCS) {
      if (!ifEcommInSelectedRows(purchaseOrders)) {
        setMultiUpcEditOpen(true);
      } else {
        notification.warning({
          message: 'Unselect any ECOMM purchase orders',
          description: 'Multiple UPCs functionality does not currently support ECOMM orders'
        });
      }
    } else if (action === BULK_PROCESS_RESEND_CUSTOMIZATIONS) {
      setShowResendCustomizationsModal(true);
    } else {
      // dynamically rendered modal title
      let userText = BulkProcesses.get(action);

      let title;
      title = `${userText} the following`;

      if (purchaseOrders.length > 1) {
        title += ` (${purchaseOrders.length}) purchase orders`;
      } else {
        title += ` purchase order`;
      }

      let titleNode = <span data-testid="bulk-confirmation-modal-title">{title}</span>;

      antModal.confirm({
        title: titleNode,
        icon: null,
        content: (
          <div data-testid="bulk-confirmation-modal" className="bulk-confirmation-modal">
            {action === BULK_PROCESS_SPLIT && (
              <div className="warning">
                <ExclamationCircleOutlined
                  style={{
                    fontSize: 30,
                    color: '#FFFFFF',
                    background: '#1991EB',
                    borderRadius: '50%'
                  }}
                />
                <div className="note">
                  NOTE: If you are going to create dummy orders, do not split until after scrubbing.
                </div>
              </div>
            )}
            <div data-testid="confirm-list" className="confirm_list">
              {[...purchaseOrders].map((po) => {
                return (
                  <AlloyRow key={po.id} data-testid={`${po.id}-row`} className="po_row">
                    <AlloyCol span={9} className="poId" data-testid={`${po.id}-col`}>
                      {po.customerPo}
                    </AlloyCol>
                    <AlloyCol span={15} className="rdd" data-testid="col-rdd">
                      {po.shipTo
                        ? `${po.shipTo.name} - ${po.shipTo.externalId}`
                        : po.shipToExternalId}
                    </AlloyCol>
                  </AlloyRow>
                );
              })}
            </div>
          </div>
        ),
        okButtonProps: {
          //@ts-ignore
          'data-testid': 'yes-continue-button',
          disabled: !purchaseOrders.length
        },
        okText: <span data-testid="yes-continue-button-text">Yes, Continue</span>,
        onOk: () => onConfirm(action),
        cancelButtonProps: {
          //@ts-ignore
          'data-testid': 'cancel-button'
        },
        cancelText: <span data-testid="cancel-button-text">Cancel</span>
      });
    }
  }
  /****************************************************** */

  const split = async () => {
    const notSplittingOrders = purchaseOrders.find(
      (purchaseOrder) => purchaseOrder.deliveryType === 'ECOMM'
    );
    if (notSplittingOrders) {
      setLoading(false);
      return notification.warning({
        message: 'Unselect any ECOMM purchase orders',
        description: 'Split functionality does not currently support ECOMM orders'
      });
    }

    try {
      await splitPOs({ variables: { input: { purchaseOrderIds } } }).then((res) => {
        if ((res as { errors: string[] }).errors) throw (res as { errors: string[] }).errors[0];
      });
      notification.success({
        message: `${purchaseOrders.length} purchase orders successfully split`
      });
    } catch (error) {
      Sentry.captureException(error);
      notification.error({
        message: 'An error occurred while splitting the requested purchase orders',
        description: error.message
      });
    }
    setLoading(false);
  };

  return (
    <div className="bulk-process-select">
      <AlloySelect
        size={size}
        data-testid="po-page-bulk-process-select"
        value="Bulk Process"
        popupMatchSelectWidth={false}
        disabled={!purchaseOrders.length}
        loading={loading || isReattemptLoading || isReprocessLoading}
        dropdownRender={() => {
          return (
            <>
              <AlloyMenu
                style={{ width: 256 }}
                mode="vertical"
                theme="light"
                selectable={false}
                className="bulk_process_select_content"
              >
                {Array.from(allowedBulkProcesses.keys()).map((bulkProcess, index) =>
                  bulkProcess !== BULK_EDIT_PO_DESTINATION &&
                  bulkProcess !== BULK_EDIT_PRE_SOURCING ? (
                    <AlloyMenuItem
                      key={index}
                      title={allowedBulkProcesses.get(bulkProcess)}
                      onClick={() => onSelect(bulkProcess)}
                      data-testid={`bulk-process-select-${bulkProcess}`}
                    >
                      {allowedBulkProcesses.get(bulkProcess)}
                    </AlloyMenuItem>
                  ) : bulkProcess !== BULK_EDIT_PO_DESTINATION ? (
                    isPreSourceEnabled ? (
                      <AlloySubMenu key="pre-source-submenu" title="Pre-Source">
                        <AlloyMenuItem key="coast-flips" onClick={() => onSelect('coastFlips')}>
                          Coast Flips
                        </AlloyMenuItem>
                      </AlloySubMenu>
                    ) : null
                  ) : (
                    <EditPoDestination
                      key={index}
                      purchaseOrders={purchaseOrders}
                      disabled={false}
                      style={{ marginRight: '15px' }}
                      className="edit_ship_to_submenu"
                      asSubMenu
                    />
                  )
                )}
              </AlloyMenu>
            </>
          );
        }}
        onSelect={onSelect}
      >
        {Array.from(allowedBulkProcesses.keys()).map((bulkProcess, index) => (
          <Option
            key={bulkProcess}
            data-testid={`bulk-${allowedBulkProcesses
              .get(bulkProcess)
              ?.replace(/\s+/g, '-')
              .toLowerCase()}`}
            value={bulkProcess}
          >
            {allowedBulkProcesses.get(bulkProcess)}
          </Option>
        ))}
      </AlloySelect>
      {showCancelPOsModal && (
        <BulkProcessCancelPOsModal
          loading={isCancelLoading}
          onConfirm={() => onConfirm(BULK_PROCESS_CANCEL_POS)}
          cancellablePOs={cancellablePOs}
          setShowCancelPOsModal={setShowCancelPOsModal}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          purchaseOrderIds={purchaseOrders.map((po) => po.id)}
          setShowConfirmationModal={setShowConfirmationModal}
        />
      )}
      {showDownConfirmationModal && (
        <DownConfirmationModal
          purchaseOrderIds={purchaseOrders.map((po) => po.id)}
          setShowDownConfirmationModal={setShowDownConfirmationModal}
        />
      )}
      <EditPoTagsModal
        visibility={editPoTagsModalVisibility}
        toggleEditPoTagsModal={toggleEditPoTagsModal}
        poTagsList={intersectionBy(
          purchaseOrders.map((po) => po.labelIds),
          'id'
        ).flat()}
        orderId={purchaseOrders.map((po) => po.id)}
        bulkForm={true}
      />
      {multiUpcEditOpen && (
        <MultipleUpcEdit
          purchaseOrderIds={purchaseOrders.map((po) => po.id)}
          onClose={() => setMultiUpcEditOpen(false)}
        />
      )}
      {showReprocessModal && (
        <ReprocessOrdersModal
          loading={isReprocessLoading}
          onConfirm={() => onConfirm(BULK_PROCESS_REPROCESS)}
          reprocessPOsList={reprocessPOsList}
          setShowReprocessModal={setShowReprocessModal}
        />
      )}
      {showReattemptModal && (
        <ReattemptReleaseOrdersModal
          loading={isReattemptLoading}
          onConfirm={() => onConfirm(BULK_PROCESS_REATTEMPT_PO)}
          reattemptPOsList={reattemptPOsList}
          setShowReattemptModal={setShowReattemptModal}
        />
      )}
      {showResetModal && (
        <ResetOrdersModal
          loading={isResetLoading}
          onConfirm={() => onConfirm(BULK_PROCESS_RESET)}
          resetPOsList={resetPOsList}
          setShowResetModal={setShowResetModal}
        />
      )}
      {showResendCustomizationsModal && (
        <ResendCustomizationsModal
          open={showResendCustomizationsModal}
          purchaseOrderIds={purchaseOrders.map((po) => po.id)}
          close={() => setShowResendCustomizationsModal(false)}
        />
      )}
      {showCoastFlipsModal && (
        <CoastFlipsModal
          open={showCoastFlipsModal}
          poNumbers={purchaseOrders.map(({ customerPo }) => customerPo)}
          close={() => setShowCoastFlipsModal(false)}
        />
      )}
    </div>
  );
};

export default BulkProcessSelect;
