import clsx from 'clsx';
import React from 'react';
import s from './ViewField.module.scss';
import checkboxViewNegative from 'assets/icons/editing/checkbox_view_negative.svg';
import checkboxViewPositive from 'assets/icons/editing/checkbox_view_positive.svg';
import { AlloyTooltip } from 'components/ui/AlloyTooltip/AlloyTooltip';
import { AlloyTag } from 'components/ui/AlloyTag/AlloyTag';

export type ViewFieldType = 'SWITCH' | 'CHECKBOX' | 'INPUT' | 'LIST';

const isStringArray = (
  value: string | number | boolean | string[] | null | undefined
): value is string[] =>
  !!value && typeof value !== 'boolean' && typeof value !== 'string' && typeof value !== 'number';

export const ViewField = ({
  type,
  title,
  value,
  className,
  'data-testid': dataTestId
}: {
  type: ViewFieldType;
  title: string;
  value: string | number | boolean | string[] | null | undefined;
  className?: string;
  'data-testid'?: string;
}) => {
  switch (type) {
    case 'SWITCH':
      return (
        <div className={clsx(s.switch, className)} data-testid={dataTestId}>
          <div className={clsx(s.switch_icon, { [s.positive]: value })} />
          <div className={clsx(s.switch_title, { [s.negative]: !value })}>{title}</div>
        </div>
      );
    case 'CHECKBOX':
      return (
        <div className={clsx(s.checkbox, className)} data-testid={dataTestId}>
          <img alt="" src={value ? checkboxViewPositive : checkboxViewNegative} />
          <div className={clsx(s.checkbox_title, { [s.negative]: !value })}>{title}</div>
        </div>
      );
    case 'INPUT':
      const isEmpty = value === null || value === undefined || value === '';
      return (
        <div className={clsx(s.input, className)} data-testid={dataTestId}>
          <div className={s.title}>{title}</div>
          <AlloyTooltip
            placement="topLeft"
            title={isEmpty || value.toString().length < 6 ? undefined : value}
            overlayStyle={{ maxWidth: '300px' }}
          >
            <div className={clsx(s.input_value, { [s.empty]: isEmpty })}>
              {!isEmpty ? value : 'Empty'}
            </div>
          </AlloyTooltip>
        </div>
      );
    case 'LIST':
      return (
        <div className={clsx(s.list, className)} data-testid={dataTestId}>
          <div className={s.title}>{title}</div>
          <div className={s.list_container}>
            {value && isStringArray(value) && value.length > 0 ? (
              value.map((listItem) => (
                <AlloyTag key={listItem} closable={false}>
                  {listItem}
                </AlloyTag>
              ))
            ) : (
              <span
                className={clsx({
                  [s.empty]: !value || !isStringArray(value) || value.length === 0
                })}
              >
                Empty
              </span>
            )}
          </div>
        </div>
      );
    default:
      return null;
  }
};
