import React from 'react';
import { useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { DeliveryDestination } from '../../EditTradingPartnerAssortment';
import { AlloyFormField } from 'components/ui/formFields/AlloyFormField/AlloyFormField';

interface ShipToFieldProps {
  deliveryDestinations: DeliveryDestination[];
}

export const ShipToField = ({ deliveryDestinations }: ShipToFieldProps) => {
  const form = useForm();

  return (
    <>
      <AlloyFormField
        data-testid="aa-edit-modal-ship-to-select" //TODO: QA-555 fails on this data-testid - fix this
        component="select"
        name="shipTo"
        required
        fieldProps={{
          title: 'Ship-To',
          options: [
            {
              value: 'all',
              label: 'All Ship-To'
            },
            ...deliveryDestinations
              .filter((rdd) => !rdd.specialAssortment)
              ?.map(({ id, name, externalId }) => ({ value: id, label: name || externalId || '' }))
          ],
          mode: 'multiple',
          allowClear: true //NOTE: adding allowClear here because it's a real pain to have to delete one by one after selecting "All Ship-to"
        }}
      />
      <OnChange name="shipTo">
        {(values: string[], previous: string[]) => {
          if (values.includes('all')) {
            const allIds = [
              'all',
              ...deliveryDestinations.filter((rdd) => !rdd.specialAssortment)?.map(({ id }) => id)
            ];
            if (values.length < previous.length) {
              form.mutators.setShipTos([...values].filter((id) => id !== 'all'));
            } else if (values.length < allIds.length) {
              form.mutators.setShipTos([
                'all',
                ...deliveryDestinations.filter((rdd) => !rdd.specialAssortment)?.map(({ id }) => id)
              ]);
            }
          }
        }}
      </OnChange>
    </>
  );
};
