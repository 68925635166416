import React, { useState } from 'react';
import { AlloyTabs } from 'components/ui/AlloyTabs/AlloyTabs';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { EntityDrawer } from 'components/Common/ViewMode/EntityDrawer/EntityDrawer';
import s from './EditEventDrawer.module.scss';
import { EditEventDetails } from 'pages/EventTracker/components/EditEventDetails/EditEventDetails';
import { EditEventWatchlist } from 'pages/EventTracker/components/EditEventWatchlist/EditEventWatchlist';
import { Form } from 'react-final-form';
import { FileOutlined, DeleteOutlined } from '@ant-design/icons';

interface EditEventDrawerProps {
  show: boolean;
  event: any;
  onClose: () => void;
}

export const EditEventDrawer = ({ show, event, onClose }: EditEventDrawerProps) => {
  if (!event) return null;
  const [editMode, setEditMode] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentTab, setCurrentTab] = useState('details');

  const Details = () => {
    if (!editMode) {
      return (
        <>
          <div className={s.value_group}>
            <div className={s.label}>Event name</div>
            <div className={s.value}>{event.name}</div>
          </div>
          <div className={s.value_group}>
            <div className={s.label}>Date range of hypercare</div>
            <div className={s.value}>
              {event.startDate} - {event.endDate}
            </div>
          </div>
          <div className={s.value_group}>
            <div className={s.label}>Customer</div>
            <div className={s.value}>{event.customer}</div>
          </div>
          <div className={s.value_group}>
            <div className={s.label}>Business Unit</div>
            <div className={s.value}>{event.businessUnit}</div>
          </div>
        </>
      );
    } else {
      return (
        <div className={s.drawer_display}>
          <EditEventDetails event={event} />
          <div className={s.footer}>
            <AlloyButton type="primary">Save</AlloyButton>
            <AlloyButton type="secondary" onClick={() => setEditMode(false)}>
              Cancel
            </AlloyButton>
          </div>
        </div>
      );
    }
  };

  const Watchlist = () => {
    if (!editMode) {
      return (
        <div className={s.filelist}>
          <div className={s.header}>
            <div>File name</div>
          </div>

          <div className={s.file}>
            <div className={s.left}>
              <div className={s.icon}>
                <FileOutlined />
              </div>
              <div className={s.name_group}>
                <div className={s.name}>myfile.csv</div>
                <div className={s.date}>Updated: 6/12/24</div>
              </div>
            </div>
            <div className={s.right}>
              <div className={s.download_link}>Download</div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={s.drawer_display}>
          {!showConfirmation && (
            <>
              <EditEventWatchlist event={event} />
              <div className={s.footer}>
                <AlloyButton type="primary" onClick={() => setShowConfirmation(true)}>
                  Next
                </AlloyButton>
                <AlloyButton type="secondary" onClick={() => setEditMode(false)}>
                  Cancel
                </AlloyButton>
              </div>
            </>
          )}
          {showConfirmation && (
            <>
              <div className={s.confirm_details}>
                <div>Found orders and external IDs to be added:</div>
                <ul>
                  <li>10 purchase orders</li>
                  <li>25 external IDs</li>
                </ul>
              </div>
              <div className={s.footer}>
                <AlloyButton type="primary" onClick={() => setShowConfirmation(true)}>
                  Confirm and Add
                </AlloyButton>
                <AlloyButton type="secondary" onClick={() => setShowConfirmation(false)}>
                  Back
                </AlloyButton>
              </div>
            </>
          )}
        </div>
      );
    }
  };

  const CurrentTab = () => {
    if (currentTab === 'watchlist') {
      return <Watchlist />;
    } else {
      return <Details />;
    }
  };

  return (
    <Form
      onSubmit={() => {}}
      render={() => {
        return (
          <EntityDrawer
            startEditing={() => setEditMode(!editMode)}
            loading={false}
            viewMode={
              <div className={s.edit}>
                <div className={s.view}>
                  <AlloyTabs
                    className={s.tabs}
                    activeKey={currentTab}
                    onChange={(target) => setCurrentTab(target)}
                    items={[
                      {
                        label: 'Event details',
                        key: 'details'
                      },
                      {
                        label: 'Event watchlist',
                        key: 'watchlist'
                      }
                    ]}
                  />

                  <CurrentTab />
                </div>
              </div>
            }
            title={event.name}
            onClose={() => onClose()}
            open={show}
          />
        );
      }}
    />
  );
};
