import * as Types from '../../../../graphql/__generated__/types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AvailableDemandVisbilityFiltersQueryVariables = Types.Exact<{
  countryCode?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AvailableDemandVisbilityFiltersQuery = { __typename?: 'RootQueryType', demandVisibilityAvailableFilters: { __typename?: 'ExecutiveReportingAvailableFilters', fiscalYears: Array<number>, businessUnits: Array<{ __typename?: 'BusinessUnit', code: string, id: string, name: string }>, countries: Array<{ __typename?: 'Country', code: string, name: string }>, vendorMarkets: Array<{ __typename?: 'VendorMarket', countryCode: string, currencyCode: string, externalId: string, id: string, name: string }> } };


export const AvailableDemandVisbilityFiltersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"availableDemandVisbilityFilters"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"countryCode"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"demandVisibilityAvailableFilters"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"countryCode"},"value":{"kind":"Variable","name":{"kind":"Name","value":"countryCode"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"businessUnits"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"countries"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"fiscalYears"}},{"kind":"Field","name":{"kind":"Name","value":"vendorMarkets"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"countryCode"}},{"kind":"Field","name":{"kind":"Name","value":"currencyCode"}},{"kind":"Field","name":{"kind":"Name","value":"externalId"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode<AvailableDemandVisbilityFiltersQuery, AvailableDemandVisbilityFiltersQueryVariables>;