import {
  CalendarValue,
  isPeriodWeekSpeicific,
  isPeriodWeekRange,
  isPeriod
} from 'components/PeriodCalendar/types';
import { range, random } from 'lodash-es';
import { convertWeekToPeriodWeekString, getWeeksInFinancialYear } from './fiscalCalendar';
import { safeNumberComparator } from './comparators';
import { getCurrentPeriod } from 'components/PeriodCalendar/helpers';

/**
 * A simple function to tell if array of numbers has "holes" in it.
 */
export const isContinuous = (numbers: number[]): boolean => {
  numbers.sort((a, b) => a - b);

  for (let i = 1; i < numbers.length; i++) {
    if (numbers[i] !== numbers[i - 1] + 1) {
      return false;
    }
  }

  return true;
};

export const isCalendarValueContinuous = (value: CalendarValue): boolean => {
  if (value.years.length > 1) {
    const maxYearWeek = Math.max(...value.years.map((x) => getWeeksInFinancialYear(x)));
    if (isPeriodWeekSpeicific(value)) {
      return value.weeks.length === maxYearWeek;
    }
    if (isPeriodWeekRange(value)) {
      return value.start === 1 && value.end === maxYearWeek;
    }
    if (isPeriod(value)) {
      return value.periods.length === 13;
    }
    return false;
  } else {
    if (isPeriodWeekSpeicific(value)) {
      return isContinuous(value.weeks);
    } else if (isPeriodWeekRange(value)) {
      return true;
    } else if (isPeriod(value)) {
      return isContinuous(value.periods);
    }
    return false;
  }
};

export const getLineChartLabels = (value: CalendarValue): string[] => {
  let labels: string[] = [];

  if (isPeriodWeekSpeicific(value)) {
    labels = value.weeks.map((x) => convertWeekToPeriodWeekString(x));
  } else if (isPeriodWeekRange(value)) {
    labels = range(value.start, value.end + 1).map((x) => convertWeekToPeriodWeekString(x));
  } else if (isPeriod(value)) {
    labels = value.periods.sort(safeNumberComparator).map((x) => `P${x}`);
  }

  return labels;
};

export const getDataSetColor = (year: number) => {
  const colors = [
    '#0597F2', // Current year
    '#88B441', // Prev year
    '#FDB515',
    '#CF1322',
    '#88B441',
    '#FFA59C',
    '#ca8a04',
    '#22d3ee',
    '#701a75',
    '#831843',
    '#be123c'
  ];
  const CURRENT_YEAR = new Date().getFullYear();
  return colors[CURRENT_YEAR - year] || '#881337'; // catch all color;
};

// TODO: we shouldn't rely on that. Used for testing purposes only.
export const generateFakeDataSets = (
  value: CalendarValue,
  randomRange: [number, number] = [60, 100]
) => {
  const { week, year: currentYear, period } = getCurrentPeriod(true);

  return value.years.map((year) => {
    let data: number[] = [];

    if (isPeriodWeekSpeicific(value)) {
      data = value.weeks
        .filter((x) => (year === currentYear ? x <= week : true))
        .map((_x) => random(randomRange[0], randomRange[1]));
    } else if (isPeriodWeekRange(value)) {
      data = range(value.start, value.end + 1)
        .filter((x) => (year === currentYear ? x <= week : true))
        .map((_x) => random(randomRange[0], randomRange[1]));
    } else if (isPeriod(value)) {
      data = value.periods
        .filter((x) => (year === currentYear ? x <= period : true))
        .map((_x) => random(randomRange[0], randomRange[1]));
    }

    return {
      label: `${year}`,
      backgroundColor: getDataSetColor(year),
      borderColor: getDataSetColor(year),
      data,
      pointStyle:
        data.length === 1 || !isCalendarValueContinuous(value)
          ? ('circle' as const)
          : (false as const)
    };
  });
};
