export enum AuditTrailAction {
  ADDITION = 'ADDITION',
  CHANGE = 'CHANGE',
  REMOVAL = 'REMOVAL'
}

export enum AuditTrailActor {
  SYSTEM = 'SYSTEM',
  USER = 'USER'
}

export enum AuthMethod {
  SAML = 'SAML',
  USER_PASS = 'USER_PASS'
}

export enum DispatchActions {
  ADD_TAG = 'ADD_TAG',
  AUTO_TRUCK_SPLIT = 'AUTO_TRUCK_SPLIT',
  AUTOSCRUB = 'AUTOSCRUB',
  CANCEL_PO = 'CANCEL_PO',
  CHANNEL_ADVISOR_SYNC = 'CHANNEL_ADVISOR_SYNC',
  CREATE_DUMMY_ORDER = 'CREATE_DUMMY_ORDER',
  DOWN_CONFIRM = 'DOWN_CONFIRM',
  EDIT_SHIP_TO = 'EDIT_SHIP_TO',
  EXPORT_SEED_DATA = 'EXPORT_SEED_DATA',
  FULL_ITEM_LIST = 'FULL_ITEM_LIST',
  IMPORT_SEED_DATA = 'IMPORT_SEED_DATA',
  INITAL_CONFIRMATION = 'INITAL_CONFIRMATION',
  INVALID_ITEM_LIST = 'INVALID_ITEM_LIST',
  MOVE_SO_ITEM = 'MOVE_SO_ITEM',
  MUTATE_CHARGEBACKS = 'MUTATE_CHARGEBACKS',
  NEW_SO = 'NEW_SO',
  PRECISION_ORDER = 'PRECISION_ORDER',
  PREPARE_PO = 'PREPARE_PO',
  REATTEMPT_PO = 'REATTEMPT_PO',
  RELEASE_PO = 'RELEASE_PO',
  RELEASE_SO = 'RELEASE_SO',
  REPROCESS_PO = 'REPROCESS_PO',
  RESET_PO = 'RESET_PO',
  ROUTE_TO_OPTIMAL_DC = 'ROUTE_TO_OPTIMAL_DC',
  UPLOAD_SHORT_MONITOR = 'UPLOAD_SHORT_MONITOR',
  VIEW_MULTI_UPC = 'VIEW_MULTI_UPC'
}

export enum Role {
  ADMIN = 'ADMIN',
  CATALOG = 'CATALOG',
  CDP = 'CDP',
  CONTENT = 'CONTENT',
  DATA = 'DATA',
  FINANCE = 'FINANCE',
  MARKETING = 'MARKETING',
  OPERATIONS = 'OPERATIONS',
  SALES = 'SALES',
  SUPERADMIN = 'SUPERADMIN',
  SUPPLY_CHAIN = 'SUPPLY_CHAIN'
}

export enum AlgorithmConfigType {
  WEIGHTED_PARAMETERS = 'WEIGHTED_PARAMETERS'
}

export enum WeightType {
  DISTANCE = 'DISTANCE',
  RATES = 'RATES',
  SHIP_TOGETHERNESS = 'SHIP_TOGETHERNESS',
  TRANSIT_DAYS = 'TRANSIT_DAYS'
}

export enum CatalogItemType {
  GTIN12 = 'GTIN12',
  GTIN14 = 'GTIN14',
  ORACLE = 'ORACLE',
  SAP = 'SAP'
}

export enum AssortmentConfigFieldSlug {
  CASES_PER_LAYER = 'CASES_PER_LAYER',
  CASES_PER_PALLET = 'CASES_PER_PALLET',
  CURRENCY = 'CURRENCY',
  DEPTH = 'DEPTH',
  DISTRIBUTION_CENTER_IDS = 'DISTRIBUTION_CENTER_IDS',
  EXTERNAL_ID = 'EXTERNAL_ID',
  GROSS_WEIGHT = 'GROSS_WEIGHT',
  GTIN14 = 'GTIN14',
  HEIGHT = 'HEIGHT',
  INVENTORY_RESERVE = 'INVENTORY_RESERVE',
  LAYERS_PER_PALLET = 'LAYERS_PER_PALLET',
  MOQ_MINIMUM = 'MOQ_MINIMUM',
  MOQ_UNIT_OF_MEASURE = 'MOQ_UNIT_OF_MEASURE',
  NAME = 'NAME',
  ORACLE_INVEN_ID = 'ORACLE_INVEN_ID',
  PRICE = 'PRICE',
  PRODUCT_TYPE = 'PRODUCT_TYPE',
  REQUIRED_ORDER_UNIT_OF_MEASURE = 'REQUIRED_ORDER_UNIT_OF_MEASURE',
  SAP_MATERIAL_ID = 'SAP_MATERIAL_ID',
  SHIPS_IN_OWN_CONTAINER = 'SHIPS_IN_OWN_CONTAINER',
  TO_CASE_QUANTITY = 'TO_CASE_QUANTITY',
  UPC = 'UPC',
  WIDTH = 'WIDTH'
}

export enum AssortmentConfigValidationType {
  NUMERIC = 'NUMERIC',
  TEXT = 'TEXT'
}

export enum CutSummaryType {
  INVALID_PRODUCT_CUT = 'INVALID_PRODUCT_CUT',
  MANUAL_CUT = 'MANUAL_CUT',
  PALLET_ROUNDING_CUT = 'PALLET_ROUNDING_CUT',
  SCRUB_CUT = 'SCRUB_CUT'
}

export enum CatalogCountryCodeType {
  AE = 'AE',
  CA = 'CA',
  DE = 'DE',
  GB = 'GB',
  PL = 'PL',
  US = 'US'
}

export enum RetailerProductExternalIdType {
  ASIN = 'ASIN',
  DOORDASH_ID = 'DOORDASH_ID',
  SAP_MATERIAL_ID = 'SAP_MATERIAL_ID',
  WALMART_ITEM_NUMBER = 'WALMART_ITEM_NUMBER'
}

export enum CatalogSourceType {
  CATALOG = 'CATALOG',
  DE = 'DE',
  GM = 'GM',
  OREX = 'OREX',
  SCHIP = 'SCHIP'
}

export enum CatalogUnitOfMeasureType {
  BAGS = 'BAGS',
  CASES = 'CASES',
  DISPLAY_SHIPPERS = 'DISPLAY_SHIPPERS',
  DISPLAYS = 'DISPLAYS',
  EACHES = 'EACHES',
  MIXED_MOD_PALLETS = 'MIXED_MOD_PALLETS',
  PACKS = 'PACKS',
  PAILS = 'PAILS',
  PALLETS = 'PALLETS',
  POUNDS = 'POUNDS'
}

export enum UnitOfMeasure {
  CA = 'CA',
  EA = 'EA',
  PC = 'PC',
  UN = 'UN'
}

export enum InventorySource {
  CHANNELADVISOR = 'CHANNELADVISOR',
  EIV = 'EIV',
  MOCK = 'MOCK',
  NETSUITE = 'NETSUITE',
  SAP = 'SAP',
  WMS = 'WMS'
}

export enum TradingPartnerActiveAssortmentMoqUnitOfMeasure {
  LAYER = 'LAYER',
  PALLET = 'PALLET'
}

export enum TradingPartnerActiveAssortmentRequiredOrderUnitOfMeasure {
  CASE = 'CASE',
  EACH = 'EACH',
  PAK = 'PAK',
  RU = 'RU'
}

export enum PurchaseOrderItemError {
  DISTRIBUTION_CENTER_NOT_FOUND = 'DISTRIBUTION_CENTER_NOT_FOUND',
  DUPLICATE_ITEM = 'DUPLICATE_ITEM',
  FAILED_TO_SEND_INVOICE = 'FAILED_TO_SEND_INVOICE',
  INACTIVE_ASSORTMENT = 'INACTIVE_ASSORTMENT',
  MISSING_ASSORTMENT = 'MISSING_ASSORTMENT',
  MISSING_SALES_ORDERS = 'MISSING_SALES_ORDERS',
  NO_DC_ON_SALES_ORDER = 'NO_DC_ON_SALES_ORDER',
  PRICE_MISMATCH = 'PRICE_MISMATCH',
  PRODUCT_NOT_AVAILABLE_FOR_SALE = 'PRODUCT_NOT_AVAILABLE_FOR_SALE',
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  RELEASE_ERROR = 'RELEASE_ERROR',
  SHIP_TO_NOT_FOUND = 'SHIP_TO_NOT_FOUND',
  UNDER_MOQ = 'UNDER_MOQ',
  WRONG_UOM = 'WRONG_UOM'
}

export enum PurchaseOrderItemWarning {
  DUPLICATE_ITEM = 'DUPLICATE_ITEM',
  PRICE_MISMATCH = 'PRICE_MISMATCH',
  UNDER_MOQ = 'UNDER_MOQ',
  WRONG_UOM = 'WRONG_UOM'
}

export enum SalesOrderSource {
  CHANNEL_ADVISOR = 'CHANNEL_ADVISOR',
  ORACLE = 'ORACLE',
  OREX = 'OREX',
  SAP = 'SAP'
}

/** The current sales' order statuses. */
export enum SalesOrderStatus {
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
  EXPORTED = 'EXPORTED',
  INVOICED = 'INVOICED',
  NEW = 'NEW',
  OPEN = 'OPEN',
  PACKED = 'PACKED',
  PICKED = 'PICKED',
  READY_FOR_DELIVERY = 'READY_FOR_DELIVERY',
  SCRUBBED = 'SCRUBBED',
  SHIPPED = 'SHIPPED',
  SUBMITTED = 'SUBMITTED'
}

/** The current product's order statuses. */
export enum PurchaseOrderStatus {
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
  EXPORTED = 'EXPORTED',
  INVOICED = 'INVOICED',
  NEW = 'NEW',
  OPEN = 'OPEN',
  READY_FOR_DELIVERY = 'READY_FOR_DELIVERY',
  SCRUBBED = 'SCRUBBED',
  SUBMITTED = 'SUBMITTED'
}

export enum ForecastAlgorithm {
  P50 = 'P50',
  P90 = 'P90',
  SALES_AVERAGE_28_DAYS = 'SALES_AVERAGE_28_DAYS',
  SALES_AVERAGE_30_DAYS = 'SALES_AVERAGE_30_DAYS',
  SMARTR = 'SMARTR'
}

/** One of the 7 days of the week */
export enum DayOfWeek {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY'
}

export enum ReleaseMethod {
  CHANNEL_ADVISOR = 'CHANNEL_ADVISOR',
  D2C_OCH = 'D2C_OCH',
  EDI = 'EDI',
  EMAIL = 'EMAIL',
  FEDEX_PARCEL = 'FEDEX_PARCEL',
  OCH_NON_LIVE = 'OCH_NON_LIVE',
  OCH_WITH_INVALID_ITEMS = 'OCH_WITH_INVALID_ITEMS',
  ODFL = 'ODFL',
  ODFL_RATE_SHOPPING = 'ODFL_RATE_SHOPPING',
  ONE_RAIL = 'ONE_RAIL',
  REST = 'REST',
  UNTENDERED_UPS = 'UNTENDERED_UPS'
}

export enum Rounding {
  CEILING = 'CEILING',
  FLOOR = 'FLOOR'
}

export enum PurchaseOrderActionType {
  ADD_SALES_ORDER = 'ADD_SALES_ORDER',
  AUTOSCRUBBED = 'AUTOSCRUBBED',
  CANCEL_PURCHASE_ORDER = 'CANCEL_PURCHASE_ORDER',
  CONFIRMED_SALES_ORDER_EMAIL_DELIVERY = 'CONFIRMED_SALES_ORDER_EMAIL_DELIVERY',
  CREATE = 'CREATE',
  CREATE_DUMMY_ORDER = 'CREATE_DUMMY_ORDER',
  CREATE_SHIPMENT = 'CREATE_SHIPMENT',
  DELETE_CUT_SUMMARIES = 'DELETE_CUT_SUMMARIES',
  ERROR_ADDED = 'ERROR_ADDED',
  ERROR_CLEARED = 'ERROR_CLEARED',
  EXTERNAL_SHIPMENT_RELEASE = 'EXTERNAL_SHIPMENT_RELEASE',
  FULFILLMENT_SYNCED = 'FULFILLMENT_SYNCED',
  GENERATED_SALES_ORDER_CUSTOMIZATION = 'GENERATED_SALES_ORDER_CUSTOMIZATION',
  INGEST = 'INGEST',
  OCH_ACK_FAILED = 'OCH_ACK_FAILED',
  OPENED_SALES_ORDER_EMAIL = 'OPENED_SALES_ORDER_EMAIL',
  PREPARE = 'PREPARE',
  PUBLISH_ORDER_TO_CHANNEL_ADVISOR = 'PUBLISH_ORDER_TO_CHANNEL_ADVISOR',
  REATTEMPT = 'REATTEMPT',
  RECEIVED_ASN = 'RECEIVED_ASN',
  RECEIVED_EXCHANGE_RETURN = 'RECEIVED_EXCHANGE_RETURN',
  RECEIVED_EXTERNAL_997_FOR_810 = 'RECEIVED_EXTERNAL_997_FOR_810',
  RECEIVED_EXTERNAL_997_FOR_855 = 'RECEIVED_EXTERNAL_997_FOR_855',
  RECEIVED_EXTERNAL_997_FOR_856 = 'RECEIVED_EXTERNAL_997_FOR_856',
  RECEIVED_EXTERNAL_997_WITH_NO_DOCUMENT_TYPE = 'RECEIVED_EXTERNAL_997_WITH_NO_DOCUMENT_TYPE',
  RECEIVED_EXTERNAL_PURCHASE_ORDER = 'RECEIVED_EXTERNAL_PURCHASE_ORDER',
  RECEIVED_INTERNAL_810 = 'RECEIVED_INTERNAL_810',
  RECEIVED_INTERNAL_855 = 'RECEIVED_INTERNAL_855',
  RECEIVED_INTERNAL_856 = 'RECEIVED_INTERNAL_856',
  RECEIVED_INTERNAL_894 = 'RECEIVED_INTERNAL_894',
  RECEIVED_INTERNAL_997_FOR_SALES_ORDER = 'RECEIVED_INTERNAL_997_FOR_SALES_ORDER',
  RECEIVED_OCH_ACKNOWLEDGMENT = 'RECEIVED_OCH_ACKNOWLEDGMENT',
  RELEASE = 'RELEASE',
  RELEASE_EXTERNAL_ACKNOWLEDGEMENT = 'RELEASE_EXTERNAL_ACKNOWLEDGEMENT',
  RELEASE_EXTERNAL_EXCHANGE_RETURN = 'RELEASE_EXTERNAL_EXCHANGE_RETURN',
  RELEASE_EXTERNAL_SHIPMENT = 'RELEASE_EXTERNAL_SHIPMENT',
  RELEASE_INVOICE = 'RELEASE_INVOICE',
  REMOVE_SALES_ORDER = 'REMOVE_SALES_ORDER',
  REPROCESS = 'REPROCESS',
  RESCRUB = 'RESCRUB',
  RESET_PURCHASE_ORDER = 'RESET_PURCHASE_ORDER',
  REVERT_STATE = 'REVERT_STATE',
  ROUTE_TO_OPTIMAL_DC = 'ROUTE_TO_OPTIMAL_DC',
  SALES_ORDER_DIVERSION = 'SALES_ORDER_DIVERSION',
  SALES_ORDER_UPDATED = 'SALES_ORDER_UPDATED',
  SCRUB = 'SCRUB',
  SCRUBBED = 'SCRUBBED',
  SEND_DOWN_CONFIRM_ACK = 'SEND_DOWN_CONFIRM_ACK',
  SEND_ORDER_ACCEPTED_ACK = 'SEND_ORDER_ACCEPTED_ACK',
  SEND_SHIPMENT_CONFIRMATION_ACK = 'SEND_SHIPMENT_CONFIRMATION_ACK',
  SENT_CUSTOMIZATION_PER_SALES_ORDER = 'SENT_CUSTOMIZATION_PER_SALES_ORDER',
  SENT_EXTERNAL_810 = 'SENT_EXTERNAL_810',
  SENT_EXTERNAL_855_FOR_855 = 'SENT_EXTERNAL_855_FOR_855',
  SENT_EXTERNAL_855_FOR_PURCHASE_ORDER = 'SENT_EXTERNAL_855_FOR_PURCHASE_ORDER',
  SENT_EXTERNAL_856 = 'SENT_EXTERNAL_856',
  SENT_EXTERNAL_997_FOR_PURCHASE_ORDER = 'SENT_EXTERNAL_997_FOR_PURCHASE_ORDER',
  SENT_INTERNAL_997_FOR_810 = 'SENT_INTERNAL_997_FOR_810',
  SENT_INTERNAL_997_FOR_855 = 'SENT_INTERNAL_997_FOR_855',
  SENT_INTERNAL_997_FOR_856 = 'SENT_INTERNAL_997_FOR_856',
  SENT_INTERNAL_SALES_ORDER = 'SENT_INTERNAL_SALES_ORDER',
  SENT_OCH_DUMMY_ORDER = 'SENT_OCH_DUMMY_ORDER',
  SENT_OCH_ORDER = 'SENT_OCH_ORDER',
  SPLIT = 'SPLIT',
  UPDATE = 'UPDATE',
  UPDATE_ARRIVE_BY_DATE = 'UPDATE_ARRIVE_BY_DATE',
  UPDATE_DESTINATION = 'UPDATE_DESTINATION',
  UPDATE_SALES_ORDER = 'UPDATE_SALES_ORDER',
  UPDATE_SHIPMENT = 'UPDATE_SHIPMENT'
}

export enum HistorySourceType {
  ORDER_STATUS_SLA_AUDIT = 'ORDER_STATUS_SLA_AUDIT',
  SYSTEM = 'SYSTEM',
  SYSTEM_SIDE_EFFECT = 'SYSTEM_SIDE_EFFECT',
  UNKNOWN = 'UNKNOWN',
  USER = 'USER',
  USER_SIDE_EFFECT = 'USER_SIDE_EFFECT'
}

export enum UpdateType {
  AMAZON = 'AMAZON',
  AUTOMATED = 'AUTOMATED',
  HUMAN = 'HUMAN',
  ORDER_STATUS_SLA_AUDIT = 'ORDER_STATUS_SLA_AUDIT',
  SYSTEM = 'SYSTEM',
  SYSTEM_SIDE_EFFECT = 'SYSTEM_SIDE_EFFECT',
  UNKNOWN = 'UNKNOWN',
  USER = 'USER',
  USER_SIDE_EFFECT = 'USER_SIDE_EFFECT'
}

export enum ConfirmationStatus {
  FAILURE = 'FAILURE',
  GENERATED = 'GENERATED',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS'
}

export enum ConfirmationType {
  DOWN_CONFIRM = 'DOWN_CONFIRM',
  ORDER_ACCEPTED = 'ORDER_ACCEPTED',
  SHIPMENT_CONFIRMATION = 'SHIPMENT_CONFIRMATION'
}

export enum PurchaseOrderError {
  DISTRIBUTION_CENTER_NOT_FOUND = 'DISTRIBUTION_CENTER_NOT_FOUND',
  DUPLICATE_ITEM = 'DUPLICATE_ITEM',
  FAILED_TO_SEND_INVOICE = 'FAILED_TO_SEND_INVOICE',
  INACTIVE_ASSORTMENT = 'INACTIVE_ASSORTMENT',
  MISSING_ASSORTMENT = 'MISSING_ASSORTMENT',
  MISSING_SALES_ORDERS = 'MISSING_SALES_ORDERS',
  NO_DC_ON_SALES_ORDER = 'NO_DC_ON_SALES_ORDER',
  PRICE_MISMATCH = 'PRICE_MISMATCH',
  PRODUCT_NOT_AVAILABLE_FOR_SALE = 'PRODUCT_NOT_AVAILABLE_FOR_SALE',
  PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND',
  RELEASE_ERROR = 'RELEASE_ERROR',
  SHIP_TO_NOT_FOUND = 'SHIP_TO_NOT_FOUND',
  UNDER_MOQ = 'UNDER_MOQ',
  WRONG_UOM = 'WRONG_UOM'
}

export enum PurchaseOrderWarning {
  DUPLICATE_ITEM = 'DUPLICATE_ITEM',
  PRICE_MISMATCH = 'PRICE_MISMATCH',
  UNDER_MOQ = 'UNDER_MOQ',
  WRONG_UOM = 'WRONG_UOM'
}

export enum ReportableErrorAction {
  CANCEL_PO = 'CANCEL_PO',
  CREATE_PO = 'CREATE_PO',
  CREATE_SHIPMENT = 'CREATE_SHIPMENT',
  CREATE_SO = 'CREATE_SO',
  DISPATCH_TP = 'DISPATCH_TP',
  EDI_PROCESSING = 'EDI_PROCESSING',
  FORECAST = 'FORECAST',
  FULFILLMENT_SYNC = 'FULFILLMENT_SYNC',
  GENERIC_ACTION = 'GENERIC_ACTION',
  INGEST_INVENTORY = 'INGEST_INVENTORY',
  INGEST_OCH_PAYLOAD = 'INGEST_OCH_PAYLOAD',
  INGEST_ORDER = 'INGEST_ORDER',
  MAP_PRODUCTS = 'MAP_PRODUCTS',
  PREPARE_PO = 'PREPARE_PO',
  RELEASE_ACKNOWLEDGEMENT_EXTERNALLY = 'RELEASE_ACKNOWLEDGEMENT_EXTERNALLY',
  RELEASE_EXCHANGE_RETURN_EXTERNALLY = 'RELEASE_EXCHANGE_RETURN_EXTERNALLY',
  RELEASE_INVENTORY_EXTERNALLY = 'RELEASE_INVENTORY_EXTERNALLY',
  RELEASE_INVOICE_EXTERNALLY = 'RELEASE_INVOICE_EXTERNALLY',
  RELEASE_SHIPMENT_EXTERNALLY = 'RELEASE_SHIPMENT_EXTERNALLY',
  RELEASE_SO = 'RELEASE_SO',
  REPROCESS_PO = 'REPROCESS_PO',
  RESET_PO = 'RESET_PO',
  ROUTE_TO_OPTIMAL_DC = 'ROUTE_TO_OPTIMAL_DC',
  SYNC_SOS = 'SYNC_SOS',
  UPDATE_DESTINATION = 'UPDATE_DESTINATION',
  UPDATE_MABD = 'UPDATE_MABD',
  UPDATE_PO = 'UPDATE_PO',
  UPDATE_SHIPMENT = 'UPDATE_SHIPMENT'
}

export enum ReportableErrorCategory {
  GENERIC_CATEGORY = 'GENERIC_CATEGORY',
  OPERATIONAL = 'OPERATIONAL',
  TRANSACTIONAL = 'TRANSACTIONAL',
  VALIDATION = 'VALIDATION'
}

export enum ReportableErrorLevel {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING'
}

export enum ReportableErrorStatus {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED'
}

export enum ReportableErrorTrigger {
  GENERIC_TRIGGER_TYPE = 'GENERIC_TRIGGER_TYPE',
  SYSTEM = 'SYSTEM',
  THIRD_PARTY = 'THIRD_PARTY',
  USER = 'USER'
}

export enum ReportableErrorType {
  ACKNOWLEDGEMENT_EXTERNAL_RELEASE_FAILED = 'ACKNOWLEDGEMENT_EXTERNAL_RELEASE_FAILED',
  ASN_PO_MISMATCH = 'ASN_PO_MISMATCH',
  CANCEL_PO_FAILED = 'CANCEL_PO_FAILED',
  CREATE_PO_FAILED = 'CREATE_PO_FAILED',
  CREATE_SHIPMENT_FAILED = 'CREATE_SHIPMENT_FAILED',
  CREATE_SO_FAILED = 'CREATE_SO_FAILED',
  DELIVERY_COST_DATA_NO_SHIPMENT_FOUND = 'DELIVERY_COST_DATA_NO_SHIPMENT_FOUND',
  DISPATCH_TP_FAILED = 'DISPATCH_TP_FAILED',
  DISTRIBUTION_CENTER_NOT_FOUND = 'DISTRIBUTION_CENTER_NOT_FOUND',
  EXCHANGE_RETURN_EXTERNAL_RELEASE_FAILED = 'EXCHANGE_RETURN_EXTERNAL_RELEASE_FAILED',
  FORECAST_FAILED = 'FORECAST_FAILED',
  FULFILLMENT_SYNC_FAILED = 'FULFILLMENT_SYNC_FAILED',
  GENERIC_TYPE = 'GENERIC_TYPE',
  INCOMPLETELY_SOURCED_ORDER = 'INCOMPLETELY_SOURCED_ORDER',
  INGEST_FAILED = 'INGEST_FAILED',
  INVALID_S3_CONFIG = 'INVALID_S3_CONFIG',
  INVALID_SFTP_CONFIG = 'INVALID_SFTP_CONFIG',
  INVENTORY_EXTERNAL_RELEASE_FAILED = 'INVENTORY_EXTERNAL_RELEASE_FAILED',
  INVENTORY_INGESTION_FAILED = 'INVENTORY_INGESTION_FAILED',
  INVOICE_EXTERNAL_RELEASE_FAILED = 'INVOICE_EXTERNAL_RELEASE_FAILED',
  MAP_PRODUCTS_FAILED = 'MAP_PRODUCTS_FAILED',
  NO_CUSTOMIZATION_DATA = 'NO_CUSTOMIZATION_DATA',
  NO_CUSTOMIZATION_HEADERS_FOR_DISTRIBUTION_CENTER = 'NO_CUSTOMIZATION_HEADERS_FOR_DISTRIBUTION_CENTER',
  OCH_ACK_FAILED = 'OCH_ACK_FAILED',
  OCH_INGESTION_FAILED = 'OCH_INGESTION_FAILED',
  PREPARE_PO_FAILED = 'PREPARE_PO_FAILED',
  PRODUCT_UPC_MISMATCH = 'PRODUCT_UPC_MISMATCH',
  REJECTED_ACKNOWLEDGMENT = 'REJECTED_ACKNOWLEDGMENT',
  RELEASE_FAILED = 'RELEASE_FAILED',
  REPROCESS_PO_FAILED = 'REPROCESS_PO_FAILED',
  RESET_PO_FAILED = 'RESET_PO_FAILED',
  ROUTE_TO_OPTIMAL_DC_FAILED = 'ROUTE_TO_OPTIMAL_DC_FAILED',
  SENT_CUSTOMIZATION_NOT_ALLOWED = 'SENT_CUSTOMIZATION_NOT_ALLOWED',
  SHIPMENT_EXTERNAL_RELEASE_FAILED = 'SHIPMENT_EXTERNAL_RELEASE_FAILED',
  SHIPMENT_TENDERING_FAILED = 'SHIPMENT_TENDERING_FAILED',
  SYNC_SOS_FAILED = 'SYNC_SOS_FAILED',
  UPDATE_DESTINATION_FAILED = 'UPDATE_DESTINATION_FAILED',
  UPDATE_MABD_FAILED = 'UPDATE_MABD_FAILED',
  UPDATE_PO_FAILED = 'UPDATE_PO_FAILED',
  UPDATE_SHIPMENT_FAILED = 'UPDATE_SHIPMENT_FAILED'
}

export enum DeliveryType {
  DSD = 'DSD',
  ECOMM = 'ECOMM',
  WHD = 'WHD'
}

export enum EdiParserConfigExternalIdIdentifier {
  VENDOR_ITEM_NUMBER = 'VENDOR_ITEM_NUMBER'
}

export enum ProductIdQualifier {
  BP = 'BP',
  EN = 'EN',
  IB = 'IB',
  SK = 'SK',
  UK = 'UK',
  UP = 'UP',
  VN = 'VN'
}

export enum EdiFormat {
  X12 = 'X12',
  XML = 'XML'
}

export enum InternalProductIdQualifier {
  ORACLE_INVEN_ID = 'ORACLE_INVEN_ID',
  SAP_MATERIAL_ID = 'SAP_MATERIAL_ID',
  UPC = 'UPC'
}

export enum IsaInterchangeUsageIndicator {
  PRODUCTION = 'PRODUCTION',
  TESTING = 'TESTING'
}

export enum Edi810Ctt01TargetSegmentCountOptions {
  IT1 = 'IT1'
}

export enum EdiSegment {
  N1 = 'N1'
}

export enum Edi855Ctt01TargetSegmentCountOptions {
  PO1 = 'PO1'
}

export enum Edi856Ctt01TargetSegmentCountOptions {
  HL = 'HL',
  LIN = 'LIN'
}

export enum InvoiceTermsOfSaleBasisDateCode {
  INVOICE_DATE = 'INVOICE_DATE'
}

export enum InvoiceTermsOfSalePaymentType {
  BASIC = 'BASIC'
}

export enum MeasurementSystem {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC'
}

export enum OchRegion {
  ESSA = 'ESSA',
  NA = 'NA'
}

export enum OrderItemRounding {
  NONE = 'NONE',
  PALLET = 'PALLET'
}

export enum ValidationLevel {
  ERROR = 'ERROR',
  OFF = 'OFF',
  WARNING = 'WARNING'
}

export enum ProcessingFlowCancelPo {
  CANCEL_INTERNALLY = 'CANCEL_INTERNALLY',
  CANCEL_OCH_ORDER = 'CANCEL_OCH_ORDER',
  NOOP = 'NOOP'
}

export enum ProcessingFlowFulfillmentSyncType {
  INGEST_CHANNEL_ADVISOR_ORDER_SHIPPED_UPDATE = 'INGEST_CHANNEL_ADVISOR_ORDER_SHIPPED_UPDATE',
  UPDATE_WAREHOUSE_STATUS = 'UPDATE_WAREHOUSE_STATUS',
  UPSERT_INTELLIGENCE_SALES_ORDER = 'UPSERT_INTELLIGENCE_SALES_ORDER',
  UPSERT_SALES_ORDERS_WITH_SHIPMENTS = 'UPSERT_SALES_ORDERS_WITH_SHIPMENTS'
}

export enum ProcessingFlowIngestPo {
  INGEST = 'INGEST',
  /** @deprecated deprecated */
  INGEST_NEW_PO = 'INGEST_NEW_PO',
  INTELLIGENCE = 'INTELLIGENCE'
}

export enum ProcessingFlowPreparePo {
  /** Action for preparing an acknowledged purchase order event in our system */
  PREPARE_ACKNOWLEDGED = 'PREPARE_ACKNOWLEDGED',
  /** Publish Order to default Channel Advisor DC for breakdown of bundles into constituent parts. Suggested for all PepDirect orders. */
  PREPARE_DROP_SHIP = 'PREPARE_DROP_SHIP',
  /** Drop Ship Observation is currently a no-op prepare action */
  PREPARE_DROP_SHIP_OBSERVATION_ONLY = 'PREPARE_DROP_SHIP_OBSERVATION_ONLY',
  /** Same as `prepare_drop_ship` except it downloads any customizations and stores them in a local s3 bucket. */
  PREPARE_DROP_SHIP_WITH_CUSTOMIZATIONS = 'PREPARE_DROP_SHIP_WITH_CUSTOMIZATIONS',
  /** Action for updating UOM from eaches to cases and preparing an acknowledged purchase order event in our system. */
  PREPARE_EACHES_TO_CASES_ACKNOWLEDGED = 'PREPARE_EACHES_TO_CASES_ACKNOWLEDGED',
  /** Takes actions to handle returns */
  PREPARE_EXCHANGE = 'PREPARE_EXCHANGE',
  /**
   * Action for new forecasted purchase order event in our system using given ingestable payload received
   *       from any external system via any source channel (e.g. queue, REST API).
   */
  PREPARE_FORECASTED = 'PREPARE_FORECASTED',
  /** Action for non-live orders preparation. Involves updating UOMs to match the ones defined in the Active Assortment. */
  PREPARE_NON_LIVE_ORDER = 'PREPARE_NON_LIVE_ORDER'
}

export enum ProcessingFlowReleaseExternalAcknowledgementType {
  NOOP = 'NOOP',
  RELEASE_ACK_TO_PEPDIRECT = 'RELEASE_ACK_TO_PEPDIRECT',
  RELEASE_EDI_855_ACK = 'RELEASE_EDI_855_ACK'
}

export enum ProcessingFlowReleaseExternalExchangeReturnType {
  NOOP = 'NOOP',
  RELEASE_EXCHANGE_RETURN_TO_PEPDIRECT = 'RELEASE_EXCHANGE_RETURN_TO_PEPDIRECT'
}

export enum ProcessingFlowReleaseInventoryType {
  NOOP = 'NOOP',
  RELEASE_EDI_846_INVENTORY = 'RELEASE_EDI_846_INVENTORY',
  RELEASE_INVENTORY_TO_PEPDIRECT = 'RELEASE_INVENTORY_TO_PEPDIRECT'
}

export enum ProcessingFlowReleaseInvoiceType {
  NOOP = 'NOOP',
  RELEASE_EDI_810_INVOICE = 'RELEASE_EDI_810_INVOICE'
}

export enum ProcessingFlowReleaseExternalShipmentType {
  NOOP = 'NOOP',
  RELEASE_ASN_TO_AMAZON = 'RELEASE_ASN_TO_AMAZON',
  RELEASE_ASN_TO_PEPDIRECT = 'RELEASE_ASN_TO_PEPDIRECT',
  RELEASE_EDI_856_ASN = 'RELEASE_EDI_856_ASN'
}

export enum ProcessingFlowResetPo {
  DELETE_SALES_ORDERS_AND_CONFIRMATIONS = 'DELETE_SALES_ORDERS_AND_CONFIRMATIONS',
  RESET_PURCHASE_ORDER_AND_SUBMITTED_UNITS = 'RESET_PURCHASE_ORDER_AND_SUBMITTED_UNITS'
}

export enum ProcessingFlowRouteToOptimalDc {
  /** Route purchase order items to DCs based on shipping rate cost and availability */
  ROUTE_BY_COST_AND_AVAILABILITY = 'ROUTE_BY_COST_AND_AVAILABILITY',
  /** Routes a prepared purchase order's sales order item's to DCs based on a DC's ranking and on hand product inventory. */
  ROUTE_BY_DC_PRIORITY_AND_AVAILABILITY = 'ROUTE_BY_DC_PRIORITY_AND_AVAILABILITY',
  /** Place holder for call to future Routing Engine. */
  ROUTE_BY_ENGINE = 'ROUTE_BY_ENGINE',
  /** Temporary method of routing orders only fulfilled out of Joliet via one rail. */
  ROUTE_BY_ONE_RAIL_JOLIET_ONLY = 'ROUTE_BY_ONE_RAIL_JOLIET_ONLY',
  /**
   * Route orders by always trying to ship the most amount of items together and selecting the closest
   * DC in case more than one can fulfill the order.
   */
  ROUTE_BY_SHIP_TOGETHER_AND_DISTANCE = 'ROUTE_BY_SHIP_TOGETHER_AND_DISTANCE',
  /**
   * Route orders by always trying to ship the most amount of items together and selecting the cheapest
   * DC in case more than one can fulfill the order.
   */
  ROUTE_BY_SHIP_TOGETHER_AND_RATES = 'ROUTE_BY_SHIP_TOGETHER_AND_RATES',
  /**
   * Routes the Sales Order by simply copying the TradingPartner release_method.
   *       No DC is selected, release is just done to Business Unit.
   */
  ROUTE_BY_TRADING_PARTNER_RELEASE_METHOD = 'ROUTE_BY_TRADING_PARTNER_RELEASE_METHOD',
  /**
   * Routes the Sales Order by simply copying the TradingPartner release_method.
   *   Sets the DC to the dummy DC
   */
  ROUTE_BY_TRADING_PARTNER_RELEASE_METHOD_WITH_DC = 'ROUTE_BY_TRADING_PARTNER_RELEASE_METHOD_WITH_DC',
  /**
   * Route orders by selecting a DC or DCs based on multiple weighted parameters, while minimizing the
   * cost function (not cost as money).
   */
  ROUTE_BY_WEIGHTED_PARAMETERS = 'ROUTE_BY_WEIGHTED_PARAMETERS',
  /**
   * For read only POs (such as Sam's Club) used for reporting only.
   *     Syncs the fulfilments from Channel Advisor to our system as SalesOrders.
   */
  ROUTE_FROM_CA_INPUT = 'ROUTE_FROM_CA_INPUT',
  /**
   * Action for new multi-DC (often warehouse) where routing/sourcing
   *     per DC is based on warehouse availability of products, but not inventory-on-hand
   */
  ROUTE_MULTI_DC = 'ROUTE_MULTI_DC',
  /**
   * Routing flow to route POIs to single allowed DC of RDD that is set on PO ingestion step.
   *
   *       In this case we expect that PO is externally fulfilled and we should fully rely on what TP
   *       needs / requires from us (as an example - Instacart TP).
   *
   *       As an outcome single SO with SOIs and single shipment with shipment items will be created.
   */
  ROUTE_TO_SINGLE_ALLOWED_DC = 'ROUTE_TO_SINGLE_ALLOWED_DC'
}

export enum ProcessingFlowUpdatePo {
  UPDATE_DROP_SHIP = 'UPDATE_DROP_SHIP',
  UPDATE_DROP_SHIP_OBSERVATION_ONLY = 'UPDATE_DROP_SHIP_OBSERVATION_ONLY',
  UPDATE_EXISTING_MULTI_DC = 'UPDATE_EXISTING_MULTI_DC',
  UPDATE_NOOP = 'UPDATE_NOOP',
  UPDATE_ONE_RAIL_ORDER = 'UPDATE_ONE_RAIL_ORDER'
}

export enum PurchaseOrderAcknowledgementTransport {
  AMAZON_SELLING_PARTNER_API = 'AMAZON_SELLING_PARTNER_API',
  NONE = 'NONE'
}

export enum ShippingSla {
  BASED_ON_FULFILLMENT_SHIP_DATE = 'BASED_ON_FULFILLMENT_SHIP_DATE',
  FIVE_BUSINESS_DAYS = 'FIVE_BUSINESS_DAYS',
  IN_BY_2PM_OUT_SAME_DAY = 'IN_BY_2PM_OUT_SAME_DAY'
}

export enum ShipToReleaseIdentifier {
  CIS_ID = 'CIS_ID',
  COF_ID = 'COF_ID',
  DUNS = 'DUNS',
  EXTERNAL_ID = 'EXTERNAL_ID',
  EXTERNAL_STORE_NUMBER = 'EXTERNAL_STORE_NUMBER',
  INTERNAL_ID = 'INTERNAL_ID',
  SAN_CODE = 'SAN_CODE',
  SAP_CUSTOMER_ID = 'SAP_CUSTOMER_ID',
  SOLD_TO_SAP_CUSTOMER_ID = 'SOLD_TO_SAP_CUSTOMER_ID'
}

export enum DistributionCenterSource {
  CHANNELADVISOR = 'CHANNELADVISOR',
  INSTACART = 'INSTACART',
  LOGIWA = 'LOGIWA',
  MOCK = 'MOCK',
  ORACLE = 'ORACLE',
  QUALPAC = 'QUALPAC',
  SAP = 'SAP',
  WARREN = 'WARREN'
}

export enum CustomizationHeaderFieldType {
  TEXT = 'TEXT',
  URL = 'URL'
}

export enum StoreType {
  NEW = 'NEW',
  RENNOVATED = 'RENNOVATED'
}

export enum OrderRefDateType {
  DELIVERY_DATE = 'DELIVERY_DATE',
  PURCHASE_ORDER_DATE = 'PURCHASE_ORDER_DATE',
  SHIPMENT_DATE = 'SHIPMENT_DATE'
}

export enum ChargebackDimension {
  BUSINESS_UNIT = 'BUSINESS_UNIT',
  DISTRIBUTION_CENTER = 'DISTRIBUTION_CENTER',
  ISSUE_TYPE = 'ISSUE_TYPE',
  RETAILER_CHANNEL = 'RETAILER_CHANNEL',
  SHIP_TO = 'SHIP_TO',
  STATUS = 'STATUS',
  SUB_TYPE_OF_THE_NON_COMPLIANCE = 'SUB_TYPE_OF_THE_NON_COMPLIANCE',
  TRADING_PARTNER = 'TRADING_PARTNER'
}

export enum CutOrdersSortColumn {
  CATEGORY = 'CATEGORY',
  CUT_REASON = 'CUT_REASON',
  CUT_UNITS = 'CUT_UNITS',
  UPC = 'UPC'
}

export enum SortOrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum DemandVisibilitySortColumn {
  BRAND = 'BRAND',
  DEMAND_IN_CASES = 'DEMAND_IN_CASES',
  GROSS_VALUE = 'GROSS_VALUE'
}

export enum DistributionCenterSortColumn {
  CODE = 'CODE',
  FACILITY_NAME = 'FACILITY_NAME',
  NAME = 'NAME',
  SOURCE = 'SOURCE',
  UPDATED_AT = 'UPDATED_AT'
}

export enum ReportType {
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_LOCATION = 'CUSTOMER_LOCATION',
  SOURCE_LOCATION = 'SOURCE_LOCATION'
}

export enum OnTimeRateTrendAggregation {
  PERIOD = 'PERIOD',
  WEEK = 'WEEK'
}

export enum ExportSeedDataKind {
  PURCHASE_ORDERS_WITH_ALL_REFS = 'PURCHASE_ORDERS_WITH_ALL_REFS',
  PURCHASE_ORDERS_WITH_DIRECT_REFS = 'PURCHASE_ORDERS_WITH_DIRECT_REFS'
}

export enum FulfillmentSandboxAlgorithmType {
  COST_AND_AVAILABILITY = 'COST_AND_AVAILABILITY',
  SHIP_TOGETHER_AND_DISTANCE = 'SHIP_TOGETHER_AND_DISTANCE',
  SHIP_TOGETHER_AND_RATES = 'SHIP_TOGETHER_AND_RATES',
  WEIGHTED_PARAMS = 'WEIGHTED_PARAMS'
}

export enum FulfillmentSandboxWeightType {
  DISTANCE = 'DISTANCE',
  RATES = 'RATES',
  SHIP_TOGETHERNESS = 'SHIP_TOGETHERNESS',
  TRANSIT_DAYS = 'TRANSIT_DAYS'
}

export enum FulfillmentSandboxPipelineStatus {
  NOT_TRIGGERED = 'NOT_TRIGGERED',
  PICKED_UP = 'PICKED_UP',
  TRIGGERED = 'TRIGGERED'
}

export enum InventoryDiscrepancyReason {
  AWAITING_ORDER_SHIPMENT_CONFIRMATION = 'AWAITING_ORDER_SHIPMENT_CONFIRMATION',
  AWAITING_STO_RECEIPT_CONFIRMATION = 'AWAITING_STO_RECEIPT_CONFIRMATION',
  DAMAGE = 'DAMAGE',
  DELAYED_ORDER_SHIPMENT_UPDATE = 'DELAYED_ORDER_SHIPMENT_UPDATE',
  DELAYED_STO_RECEIPT_UPDATE = 'DELAYED_STO_RECEIPT_UPDATE',
  OTHER_ADJUSTMENTS = 'OTHER_ADJUSTMENTS',
  SAMPLE = 'SAMPLE',
  SHIPMENT_QUANTITY = 'SHIPMENT_QUANTITY',
  STALE = 'STALE',
  STO_QUANTITY = 'STO_QUANTITY',
  SWAP = 'SWAP'
}

export enum UnitOfMeasureType {
  CS = 'CS',
  EA = 'EA',
  PAK = 'PAK'
}

export enum RepackOptimizerStage {
  MAIN = 'MAIN',
  SECOND = 'SECOND'
}

export enum RepackItemType {
  NON_PRODUCED = 'NON_PRODUCED',
  PRODUCED = 'PRODUCED'
}

export enum StoLateness {
  AT_RISK = 'AT_RISK',
  DELIVERED = 'DELIVERED',
  HIGH_RISK = 'HIGH_RISK',
  ON_TIME = 'ON_TIME',
  STALLED = 'STALLED',
  UNKNOWN = 'UNKNOWN'
}

export enum StoSapShippingStatus {
  CHKIN = 'CHKIN',
  LDEND = 'LDEND',
  LDSRT = 'LDSRT',
  PLND = 'PLND',
  SHCOMP = 'SHCOMP',
  SHEND = 'SHEND',
  SHSRT = 'SHSRT'
}

export enum LateStoSortColumn {
  DELIVERY_DATE = 'DELIVERY_DATE',
  DESTINATION_CODE = 'DESTINATION_CODE',
  ORDER_NUMBER = 'ORDER_NUMBER',
  ORIGIN_CODE = 'ORIGIN_CODE',
  REQUESTED_DELIVERY_DATE = 'REQUESTED_DELIVERY_DATE',
  REQUESTED_SHIP_DATE = 'REQUESTED_SHIP_DATE',
  SHIP_DATE = 'SHIP_DATE',
  SHIPMENT_STATUS = 'SHIPMENT_STATUS',
  USER_GPID = 'USER_GPID'
}

export enum CondOperator {
  EXCLUDE = 'EXCLUDE',
  INCLUDE = 'INCLUDE'
}

export enum UserSortColumn {
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum ProductsFillRateSortColumn {
  UPC = 'UPC'
}

export enum CutSource {
  MIXED = 'MIXED',
  SAP = 'SAP',
  SCHIP = 'SCHIP'
}

export enum ReportMetricsSortColumn {
  ACCEPTED_FILL_RATE = 'ACCEPTED_FILL_RATE',
  DELIVERED_QUANTITY = 'DELIVERED_QUANTITY',
  ORDER_QUANTITY_VARIANCE = 'ORDER_QUANTITY_VARIANCE',
  PRODUCT_DESCRIPTION = 'PRODUCT_DESCRIPTION',
  PRODUCT_EXTERNAL_ID = 'PRODUCT_EXTERNAL_ID',
  SAP_MATERIAL_ID = 'SAP_MATERIAL_ID',
  SUBMITTED_FILL_RATE = 'SUBMITTED_FILL_RATE',
  TOTAL_CUSTOMER_ACCEPTED_QUANTITY = 'TOTAL_CUSTOMER_ACCEPTED_QUANTITY',
  TOTAL_CUSTOMER_ORIGINAL_QUANTITY = 'TOTAL_CUSTOMER_ORIGINAL_QUANTITY',
  TOTAL_QUANTITY = 'TOTAL_QUANTITY',
  TOTAL_REMAINING_TO_SHIP = 'TOTAL_REMAINING_TO_SHIP',
  UPC = 'UPC',
  VARIANCE_IMPACT = 'VARIANCE_IMPACT',
  WINDOW_END_ALERT = 'WINDOW_END_ALERT'
}

/** The sort to use for purchase orders */
export enum PurchaseOrderSort {
  BUSINESS_UNIT = 'BUSINESS_UNIT',
  CUSTOMER_PO = 'CUSTOMER_PO',
  DELIVERY_WINDOW_END = 'DELIVERY_WINDOW_END',
  ORDER_DATE = 'ORDER_DATE',
  RETAILER_ADDRESS = 'RETAILER_ADDRESS',
  STATUS = 'STATUS',
  TRADING_PARTNER_CODE = 'TRADING_PARTNER_CODE',
  UPDATED_AT = 'UPDATED_AT',
  VENDOR_MARKET = 'VENDOR_MARKET'
}

export enum PoOperativeStatus {
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
  EXPORTED = 'EXPORTED',
  INVOICED = 'INVOICED',
  NEW = 'NEW',
  OPEN = 'OPEN',
  READY_FOR_DELIVERY = 'READY_FOR_DELIVERY',
  SCRUBBED = 'SCRUBBED',
  SUBMITTED = 'SUBMITTED'
}

export enum PurchaseOrderSearchSort {
  BUSINESS_UNIT_CODE = 'BUSINESS_UNIT_CODE',
  CUSTOMER_PO = 'CUSTOMER_PO',
  LAST_UPDATED_AT = 'LAST_UPDATED_AT',
  ORDER_DATE = 'ORDER_DATE',
  PO_DELIVERY_WINDOW_END = 'PO_DELIVERY_WINDOW_END',
  PRIMARY_OPERATIVE_STATUS_INDEX = 'PRIMARY_OPERATIVE_STATUS_INDEX',
  RETAILER_CHANNEL_EXTERNAL_ID = 'RETAILER_CHANNEL_EXTERNAL_ID',
  SHIP_TO_EXTERNAL_ID = 'SHIP_TO_EXTERNAL_ID',
  TRADING_PARTNER_EXTERNAL_ID = 'TRADING_PARTNER_EXTERNAL_ID'
}

export enum OrderStatusSlaAuditType {
  CONFIRMED_SALES_ORDER_EMAIL_DELIVERY = 'CONFIRMED_SALES_ORDER_EMAIL_DELIVERY',
  OPENED_SALES_ORDER_EMAIL = 'OPENED_SALES_ORDER_EMAIL',
  RECEIVED_EXTERNAL_997_FOR_810 = 'RECEIVED_EXTERNAL_997_FOR_810',
  RECEIVED_EXTERNAL_997_FOR_855 = 'RECEIVED_EXTERNAL_997_FOR_855',
  RECEIVED_EXTERNAL_997_FOR_856 = 'RECEIVED_EXTERNAL_997_FOR_856',
  RECEIVED_EXTERNAL_PURCHASE_ORDER = 'RECEIVED_EXTERNAL_PURCHASE_ORDER',
  RECEIVED_INTERNAL_810 = 'RECEIVED_INTERNAL_810',
  RECEIVED_INTERNAL_855 = 'RECEIVED_INTERNAL_855',
  RECEIVED_INTERNAL_856 = 'RECEIVED_INTERNAL_856',
  RECEIVED_INTERNAL_894 = 'RECEIVED_INTERNAL_894',
  RECEIVED_INTERNAL_997_FOR_SALES_ORDER = 'RECEIVED_INTERNAL_997_FOR_SALES_ORDER',
  RECEIVED_OCH_ACKNOWLEDGMENT = 'RECEIVED_OCH_ACKNOWLEDGMENT',
  SENT_EXTERNAL_810 = 'SENT_EXTERNAL_810',
  SENT_EXTERNAL_855_FOR_855 = 'SENT_EXTERNAL_855_FOR_855',
  SENT_EXTERNAL_855_FOR_PURCHASE_ORDER = 'SENT_EXTERNAL_855_FOR_PURCHASE_ORDER',
  SENT_EXTERNAL_856 = 'SENT_EXTERNAL_856',
  SENT_EXTERNAL_997_FOR_PURCHASE_ORDER = 'SENT_EXTERNAL_997_FOR_PURCHASE_ORDER',
  SENT_INTERNAL_997_FOR_810 = 'SENT_INTERNAL_997_FOR_810',
  SENT_INTERNAL_997_FOR_855 = 'SENT_INTERNAL_997_FOR_855',
  SENT_INTERNAL_997_FOR_856 = 'SENT_INTERNAL_997_FOR_856',
  SENT_INTERNAL_SALES_ORDER = 'SENT_INTERNAL_SALES_ORDER',
  SENT_OCH_DUMMY_ORDER = 'SENT_OCH_DUMMY_ORDER',
  SENT_OCH_ORDER = 'SENT_OCH_ORDER'
}

export enum RepackUserAction {
  CUT = 'CUT',
  MOVED_TO_FULFILL = 'MOVED_TO_FULFILL'
}

export enum OptimizerStatus {
  FAILED = 'FAILED',
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  SUCCESS = 'SUCCESS'
}

/** The sort to use for reportable errors */
export enum ReportableErrorSort {
  CUSTOMER_PO = 'CUSTOMER_PO',
  INSERTED_AT = 'INSERTED_AT',
  MESSAGE = 'MESSAGE',
  TRADING_PARTNER_EXTERNAL_ID = 'TRADING_PARTNER_EXTERNAL_ID',
  TYPE = 'TYPE'
}

export enum RddSortColumn {
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT',
  VENDOR_MARKET_NAME = 'VENDOR_MARKET_NAME'
}

export enum CalculationField {
  FOH_CAPACITY_EACH = 'FOH_CAPACITY_EACH',
  FOH_CAPACITY_PACK = 'FOH_CAPACITY_PACK',
  FOH_FILL_PERCENTAGE = 'FOH_FILL_PERCENTAGE',
  FORECAST1_EACH = 'FORECAST1_EACH',
  FORECAST1_PACK = 'FORECAST1_PACK',
  FORECAST2_EACH = 'FORECAST2_EACH',
  FORECAST2_PACK = 'FORECAST2_PACK',
  ON_HAND_INVENTORY_EACH = 'ON_HAND_INVENTORY_EACH',
  ON_HAND_INVENTORY_PACK = 'ON_HAND_INVENTORY_PACK',
  ROUNDING = 'ROUNDING',
  TO_CASE_QUANTITY_EACH = 'TO_CASE_QUANTITY_EACH',
  TO_CASE_QUANTITY_PACK = 'TO_CASE_QUANTITY_PACK'
}

export enum TradingPartnerActiveAssortmentSortColumn {
  UPDATED_AT = 'UPDATED_AT'
}

export enum ProductType {
  REPACK = 'REPACK',
  RETAIL = 'RETAIL'
}

export enum TradingPartnerSortColumn {
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT',
  VENDOR_MARKET_NAME = 'VENDOR_MARKET_NAME'
}

export enum VendorMarketSortColumn {
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT',
  VENDOR_NAME = 'VENDOR_NAME'
}

export enum VendorSortColumn {
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export enum OrderSortColumn {
  CUSTOMER_PO = 'CUSTOMER_PO',
  DISCREPANCY = 'DISCREPANCY',
  INVENTORY = 'INVENTORY',
  MATERIAL_DESCRIPTION = 'MATERIAL_DESCRIPTION',
  MATERIAL_NUMBER = 'MATERIAL_NUMBER',
  ORDER_NUMBER = 'ORDER_NUMBER',
  RDD = 'RDD',
  REQ_DELIVERY_DATE = 'REQ_DELIVERY_DATE',
  SHIP_TO_NAME = 'SHIP_TO_NAME',
  STATUS = 'STATUS',
  UNITS_ORDERED = 'UNITS_ORDERED',
  WEIGHT = 'WEIGHT'
}

export enum CustomizationSendingStatus {
  CUSTOMIZATION_NOT_SENT = 'CUSTOMIZATION_NOT_SENT',
  NO_CUSTOMIZATION_DATA = 'NO_CUSTOMIZATION_DATA',
  NO_CUSTOMIZATION_HEADERS_FOR_DISTRIBUTION_CENTER = 'NO_CUSTOMIZATION_HEADERS_FOR_DISTRIBUTION_CENTER',
  SENT = 'SENT',
  SENT_CUSTOMIZATION_NOT_ALLOWED = 'SENT_CUSTOMIZATION_NOT_ALLOWED'
}

export enum SeedImportStatus {
  DELETED = 'DELETED',
  PROCESSED = 'PROCESSED'
}

export enum ActiveAssortmentProductType {
  DIRECT_STORE_DELIVERY = 'DIRECT_STORE_DELIVERY',
  ECOMM_DELIVERY = 'ECOMM_DELIVERY',
  WAREHOUSE_DELIVERY = 'WAREHOUSE_DELIVERY'
}
