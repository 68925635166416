import { ConfirmationType, DeliveryType, PO_ERROR_FILTER_MAP } from 'common/constants';
import React, { useMemo } from 'react';
import { useState } from 'react';
import { Filter, FilterData, LocationUpdate, NewFilterName } from 'common/interfaces';
import { useLazyQuery } from '@apollo/client';
import {
  AdvancedFilterComponent,
  getInitialFilters
} from 'components/AdvancedFilter/AdvancedFilterComponent';
import { EXCLUDE_PREFIX, filtersToLocalUpdate } from 'common/helpers/advancedFiltersHelper';
import { parse, stringify } from 'query-string';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import {
  getNodesFromEdges,
  queryStringToArrayOrUndefined,
  locationPropertyToStringOrNull
} from 'common/helpers/mappingHelper';
import {
  PurchaseOrderStatus,
  ConfirmationType as ConfirmationTypeGenerated,
  PurchaseOrderError,
  PurchaseOrderSearchAdvancedFilters,
  PurchaseOrderSearchFilters,
  PurchaseOrderSearchErrorOrWarningCondition,
  PurchaseOrderSearchShipToIdCondition,
  PurchaseOrderSearchLabelIdCondition
} from 'graphql/__generated__/types';
import { notEmpty } from 'common/helpers/notEmpty';
import { LabelsDocument } from './gql/__generated__/labels.query';
import {
  BusinessUnitsOrdersPageDocument,
  BusinessUnitsOrdersPageQueryVariables
} from './gql/__generated__/businessUnitsOrdersPage.query';
import { safeLocaleCompare } from 'common/helpers/comparators';
import {
  VendorMarketsAdvancedFiltersOrdersPageDocument,
  VendorMarketsAdvancedFiltersOrdersPageQueryVariables
} from './gql/__generated__/vendorMarketsAdvancedFiltersOrdersPage.query';
import {
  RddAdvancedFiltersDocument,
  RddAdvancedFiltersQueryVariables
} from './gql/__generated__/rddAdvancedFilters.query';
import {
  TradingPartnersAdvancedFiltersDocument,
  TradingPartnersAdvancedFiltersQueryVariables
} from './gql/__generated__/tradingPartnersAdvancedFiltersOrdersPage.query';
import {
  DistributionCentersAdvancedFiltersDocument,
  DistributionCentersAdvancedFiltersQueryVariables
} from './gql/__generated__/distributionCentersAdvancedFilters.query';
import { SizeType } from 'ant5/es/config-provider/SizeContext';
import { CondOperator } from 'graphql/__generated__/enums';

export const NEW_SEARCH_LOCAL_STORAGE_FILTERS_NAME = 'newSearchFilters';

interface AdvancedFiltersProps {
  onApplyAdvancedFilter: (update: LocationUpdate, name?: string) => void;
  handleOrdersFilter: (
    filterName: string,
    filterValue: string | { start: string; end: string } | string[]
  ) => void;
  size?: SizeType;
}

interface OrdersFilterData {
  filterData: PurchaseOrderSearchFilters;
  advancedFilterData: PurchaseOrderSearchAdvancedFilters;
}

// for NEW search
//map query string to queryFilter object to capture the current filter state
export const searchStringToQueryFilterForSearchList = (
  search: string
): {
  filterData: PurchaseOrderSearchFilters;
  advancedFilterData: PurchaseOrderSearchAdvancedFilters;
} => {
  const locationSearchProps = parse(search);

  //add new filter const here
  const {
    operativeStatuses,
    retailerChannelIds,
    businessUnitIds,
    deliveryDestinationIds,
    tradingPartnerIds,
    purchaseOrderErrors,
    labels,
    dateReceived_start,
    dateReceived_end,
    mustArriveByDate_start,
    mustArriveByDate_end,
    salesOrderMustArriveByDate_start,
    salesOrderMustArriveByDate_end,
    deliveryWindow_start,
    deliveryWindow_end,
    deliveryType,
    searchIds,
    purchaseOrderConfirmation,
    distributionCenterIds,
    withCuts,
    shipmentsRange_max,
    shipmentsRange_min
  } = locationSearchProps;

  // TODO: Proper string parsing with ZOD?
  const purchaseOrderErrorConditions = [] as PurchaseOrderSearchErrorOrWarningCondition[];
  const { includePurchaseOrderErrors, excludePurchaseOrderErrors } = (
    queryStringToArrayOrUndefined(purchaseOrderErrors) || []
  ).reduce(
    (
      result: {
        includePurchaseOrderErrors: PurchaseOrderError[];
        excludePurchaseOrderErrors: PurchaseOrderError[];
      },
      value: string
    ) => {
      value.startsWith(EXCLUDE_PREFIX)
        ? result.excludePurchaseOrderErrors.push(value.substr(7) as PurchaseOrderError)
        : result.includePurchaseOrderErrors.push(value as PurchaseOrderError);
      return result;
    },
    {
      includePurchaseOrderErrors: [] as PurchaseOrderError[],
      excludePurchaseOrderErrors: [] as PurchaseOrderError[]
    }
  );
  if (includePurchaseOrderErrors.length > 0) {
    purchaseOrderErrorConditions.push({
      operator: 'INCLUDE',
      value: includePurchaseOrderErrors
    });
  }
  if (excludePurchaseOrderErrors.length > 0) {
    purchaseOrderErrorConditions.push({
      operator: 'EXCLUDE',
      value: excludePurchaseOrderErrors
    });
  }

  const labelsConditions = [] as PurchaseOrderSearchLabelIdCondition[];
  const { includePurchaseOrderLabels, excludePurchaseOrderLabels } = (
    queryStringToArrayOrUndefined(labels) || []
  ).reduce(
    (
      result: { includePurchaseOrderLabels: string[]; excludePurchaseOrderLabels: string[] },
      value: string
    ) => {
      value.startsWith(EXCLUDE_PREFIX)
        ? result.excludePurchaseOrderLabels.push(value.substr(7))
        : result.includePurchaseOrderLabels.push(value);
      return result;
    },
    { includePurchaseOrderLabels: [] as string[], excludePurchaseOrderLabels: [] as string[] }
  );
  if (includePurchaseOrderLabels.length > 0) {
    labelsConditions.push({
      operator: 'INCLUDE',
      value: includePurchaseOrderLabels
    });
  }
  if (excludePurchaseOrderLabels.length > 0) {
    labelsConditions.push({
      operator: 'EXCLUDE',
      value: excludePurchaseOrderLabels
    });
  }

  const shipToIdConditions = [] as PurchaseOrderSearchShipToIdCondition[];
  const { includePurchaseOrderRddIds, excludePurchaseOrderRddIds } = (
    queryStringToArrayOrUndefined(deliveryDestinationIds) || []
  ).reduce(
    (
      result: {
        includePurchaseOrderRddIds: string[];
        excludePurchaseOrderRddIds: string[];
      },
      value: string
    ) => {
      value.startsWith(EXCLUDE_PREFIX)
        ? result.excludePurchaseOrderRddIds.push(value.substr(7))
        : result.includePurchaseOrderRddIds.push(value);
      return result;
    },
    {
      includePurchaseOrderRddIds: [] as string[],
      excludePurchaseOrderRddIds: [] as string[]
    }
  );
  if (includePurchaseOrderRddIds.length > 0) {
    shipToIdConditions.push({
      operator: 'INCLUDE',
      value: includePurchaseOrderRddIds
    });
  }
  if (excludePurchaseOrderRddIds.length > 0) {
    shipToIdConditions.push({
      operator: 'EXCLUDE',
      value: excludePurchaseOrderRddIds
    });
  }

  //return new filter variable here with value
  return {
    filterData: {
      // TODO: instead of as validate with ZOD?
      operativeStatuses: queryStringToArrayOrUndefined(operativeStatuses) as PurchaseOrderStatus[],
      retailerChannelIds: queryStringToArrayOrUndefined(retailerChannelIds),
      businessUnitIds: queryStringToArrayOrUndefined(businessUnitIds),
      tradingPartnerIds: queryStringToArrayOrUndefined(tradingPartnerIds),
      deliveryTypes: queryStringToArrayOrUndefined(deliveryType) as DeliveryType[],
      searchableIds: queryStringToArrayOrUndefined(searchIds),
      distributionCenterIds: queryStringToArrayOrUndefined(distributionCenterIds),
      withOperativeCuts:
        withCuts === 'posWithCuts' ? true : withCuts === 'posWithoutCuts' ? false : null,
      confirmation: purchaseOrderConfirmation
        ? {
            type: locationPropertyToStringOrNull(
              purchaseOrderConfirmation
            ) as ConfirmationTypeGenerated,
            status: 'GENERATED'
          }
        : null,
      orderDateRange: dateReceived_start
        ? {
            start: dateReceived_start,
            end: dateReceived_end
          }
        : null,
      poMabdRange: mustArriveByDate_start
        ? {
            start: mustArriveByDate_start,
            end: mustArriveByDate_end
          }
        : null,
      soMabdRange: salesOrderMustArriveByDate_start
        ? {
            start: salesOrderMustArriveByDate_start,
            end: salesOrderMustArriveByDate_end
          }
        : null,
      soDeliveryWindow: deliveryWindow_start
        ? {
            start: deliveryWindow_start,
            end: deliveryWindow_end
          }
        : null,
      totalShipmentsRange: {
        lower:
          shipmentsRange_min && shipmentsRange_min.length > 0
            ? typeof shipmentsRange_min === 'string'
              ? parseInt(shipmentsRange_min)
              : parseInt(shipmentsRange_min[0])
            : undefined,
        upper:
          shipmentsRange_max && shipmentsRange_max.length > 0
            ? typeof shipmentsRange_max === 'string'
              ? parseInt(shipmentsRange_max)
              : parseInt(shipmentsRange_max[0])
            : undefined
      }
    },
    advancedFilterData: {
      errorOrWarningConditions: purchaseOrderErrorConditions,
      shipToIdConditions: shipToIdConditions,
      labelIdConditions: labelsConditions
    }
  };
};

//add new query filter conditional here
//map current queryFilter object to filter object which is editable
export const queryFilterToFilters = (queryFilter: OrdersFilterData) => {
  //when adding new filter, check for it in queryFilter here

  const filters = [] as Filter<NewFilterName>[];
  if (queryFilter.filterData.distributionCenterIds)
    filters.push({
      name: 'distributionCenterIds',
      value: queryFilter.filterData.distributionCenterIds.filter(notEmpty)
    });
  if (queryFilter.filterData.tradingPartnerIds)
    filters.push({
      name: 'tradingPartnerIds',
      value: queryFilter.filterData.tradingPartnerIds.filter(notEmpty)
    });
  if (queryFilter.filterData.retailerChannelIds)
    filters.push({
      name: 'retailerChannelIds',
      value: queryFilter.filterData.retailerChannelIds.filter(notEmpty)
    });
  if (queryFilter.filterData.businessUnitIds)
    filters.push({
      name: 'businessUnitIds',
      value: queryFilter.filterData.businessUnitIds.filter(notEmpty)
    });
  if (queryFilter.filterData.deliveryTypes)
    filters.push({
      name: 'deliveryType',
      value: queryFilter.filterData.deliveryTypes.filter(notEmpty)
    });
  if (queryFilter.filterData.confirmation)
    filters.push({
      name: 'purchaseOrderConfirmation',
      value: queryFilter.filterData.confirmation.type
    });
  if (queryFilter.filterData.poMabdRange)
    filters.push({ name: 'mustArriveByDate', value: queryFilter.filterData.poMabdRange });
  if (queryFilter.filterData.soMabdRange)
    filters.push({
      name: 'salesOrderMustArriveByDate',
      value: queryFilter.filterData.soMabdRange
    });
  if (queryFilter.filterData.orderDateRange)
    filters.push({ name: 'dateReceived', value: queryFilter.filterData.orderDateRange });
  if (queryFilter.filterData.soDeliveryWindow)
    filters.push({ name: 'deliveryWindow', value: queryFilter.filterData.soDeliveryWindow });
  if (queryFilter.filterData.searchableIds)
    filters.push({
      name: 'searchIds',
      value: queryFilter.filterData.searchableIds.filter(notEmpty)
    });
  if (typeof queryFilter.filterData.withOperativeCuts === 'boolean')
    filters.push({
      name: 'withCuts',
      value: queryFilter.filterData.withOperativeCuts ? 'posWithCuts' : 'posWithoutCuts'
    });

  if (
    queryFilter.filterData.totalShipmentsRange?.upper !== undefined ||
    queryFilter.filterData.totalShipmentsRange?.lower !== undefined
  ) {
    filters.push({
      name: 'shipmentsRange',
      value: {
        min: queryFilter.filterData.totalShipmentsRange.lower ?? null,
        max: queryFilter.filterData.totalShipmentsRange.upper ?? null
      }
    });
  }

  const errorsMap = new Map<string, string[]>();
  queryFilter.advancedFilterData.errorOrWarningConditions?.forEach((condition) => {
    if (!condition?.operator) return;
    const optionValue = errorsMap.get(condition.operator);
    const value = [...(optionValue || []), ...(condition?.value || []).filter(notEmpty)];
    errorsMap.set(condition.operator, value);
  });
  errorsMap.forEach((value, key) => {
    filters.push({
      name: 'purchaseOrderErrors',
      value: value,
      option: key
    });
  });

  const labelsMap = new Map<string, string[]>();
  queryFilter.advancedFilterData.labelIdConditions?.forEach((condition) => {
    if (!condition?.operator) return;
    const optionValue = labelsMap.get(condition.operator);
    const value = [...(optionValue || []), ...(condition?.value || []).filter(notEmpty)];
    labelsMap.set(condition.operator, value);
  });
  labelsMap.forEach((value, key) => {
    filters.push({
      name: 'labels',
      value: value,
      option: key
    });
  });

  const deliveryDestinationsMap = new Map<string, string[]>();
  queryFilter.advancedFilterData.shipToIdConditions?.forEach((condition) => {
    if (!condition?.operator) return;
    const optionValue = deliveryDestinationsMap.get(condition?.operator);
    const value = [...(optionValue || []), ...(condition?.value || []).filter(notEmpty)];
    deliveryDestinationsMap.set(condition.operator, value);
  });
  deliveryDestinationsMap.forEach((value, key) => {
    filters.push({
      name: 'deliveryDestinationIds',
      value: value,
      option: key
    });
  });

  return filters;
};

export const AdvancedFiltersForNewSearch = ({
  onApplyAdvancedFilter,
  handleOrdersFilter,
  size
}: AdvancedFiltersProps) => {
  const history = useHistory();
  const [currentFilters, setCurrentFilters] = useState<Filter<NewFilterName>[]>(
    getInitialFilters(
      searchStringToQueryFilterForSearchList(history.location.search),
      queryFilterToFilters,
      'searchIds'
    )
  );
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  const [loadVendorMarkets, { loading: vendorMarketsLoading, data: vendorMarketsData }] =
    useLazyQuery(VendorMarketsAdvancedFiltersOrdersPageDocument, {
      fetchPolicy: 'network-only'
    });
  const vendorMarketList = useMemo(
    () =>
      vendorMarketsData
        ? getNodesFromEdges(vendorMarketsData?.vendorMarkets).sort((a, b) =>
            safeLocaleCompare(a.name, b.name)
          )
        : undefined,
    [vendorMarketsData]
  );

  const [loadTradingPartners, { loading: tradingPartnersLoading, data: tradingPartnersData }] =
    useLazyQuery(TradingPartnersAdvancedFiltersDocument, {
      fetchPolicy: 'network-only'
    });

  const tradingPartnerList = useMemo(
    () =>
      tradingPartnersData
        ? getNodesFromEdges(tradingPartnersData?.tradingPartners).sort((a, b) =>
            safeLocaleCompare(a.name, b.name)
          )
        : undefined,
    [tradingPartnersData]
  );

  const [
    loadDistributionCenters,
    { loading: distributionCentersLoading, data: distributionCentersData }
  ] = useLazyQuery(DistributionCentersAdvancedFiltersDocument, {
    fetchPolicy: 'network-only'
  });

  const distributionCentersList = useMemo(
    () =>
      distributionCentersData
        ? getNodesFromEdges(distributionCentersData?.distributionCenters).sort((a, b) =>
            safeLocaleCompare(a?.name, b?.name)
          )
        : undefined,
    [distributionCentersData]
  );

  const [
    loadDeliveryDestinations,
    { loading: deliveryDestinationsLoading, data: deliveryDestinationsData }
  ] = useLazyQuery(RddAdvancedFiltersDocument, {
    fetchPolicy: 'network-only'
  });

  const deliveryDestinationsList = useMemo(
    () =>
      deliveryDestinationsData
        ? getNodesFromEdges(deliveryDestinationsData?.retailerDeliveryDestinations)
            .filter(
              (rdd) =>
                rdd.active ||
                (
                  currentFilters.find((f) => f.name === 'deliveryDestinationIds')?.value as string[]
                )?.includes(rdd.id)
            )
            .sort((a, b) => safeLocaleCompare(a?.name, b?.name))
        : undefined,
    [currentFilters, deliveryDestinationsData]
  );

  const [loadBusinessUnits, { loading: businessUnitsLoading, data: businessUnitsData }] =
    useLazyQuery(BusinessUnitsOrdersPageDocument, {
      fetchPolicy: 'network-only'
    });

  const businessUnitsList = useMemo(
    () =>
      businessUnitsData
        ? getNodesFromEdges(businessUnitsData?.businessUnits)
            .filter(notEmpty)
            .sort((a, b) => safeLocaleCompare(a.code, b.code))
        : undefined,
    [businessUnitsData]
  );

  const [loadLabelsList, { loading: labelsListLoading, data: labelsListData }] = useLazyQuery(
    LabelsDocument,
    {
      variables: {
        first: 1000
      },
      fetchPolicy: 'network-only'
    }
  );

  const labelsList = useMemo(
    () => (labelsListData ? getNodesFromEdges(labelsListData?.labels) : undefined),
    [labelsListData]
  );

  const loading = useMemo(() => {
    const result = [];
    if (tradingPartnersLoading) {
      result.push('tradingPartnerIds');
    }
    if (distributionCentersLoading) {
      result.push('distributionCenterIds');
    }
    if (deliveryDestinationsLoading) {
      result.push('deliveryDestinationIds');
    }
    if (vendorMarketsLoading) {
      result.push('retailerChannelIds');
    }
    if (businessUnitsLoading) {
      result.push('businessUnitIds');
    }
    return result;
  }, [
    businessUnitsLoading,
    deliveryDestinationsLoading,
    distributionCentersLoading,
    tradingPartnersLoading,
    vendorMarketsLoading
  ]);

  //add filter UI object, attributes and values here
  //Drop down values
  const filters: FilterData<NewFilterName>[] = useMemo(
    () => [
      {
        name: 'deliveryType',
        title: 'Order Type',
        type: 'string',
        multiple: true,
        values: Object.keys(DeliveryType)
          .map((value) => ({ name: value, title: value }))
          .filter((deliveryType) => {
            const currentTpFilter = currentFilters.find(
              (filter) => filter.name === 'tradingPartnerIds'
            );
            const currentShipTosFilter = currentFilters.find(
              (filter) => filter.name === 'deliveryDestinationIds'
            );
            if (
              (!tradingPartnerList ||
                !currentTpFilter ||
                !currentTpFilter.value ||
                (currentTpFilter.value as string[]).length === 0) &&
              (!deliveryDestinationsList ||
                !currentShipTosFilter ||
                !currentShipTosFilter.value ||
                (currentShipTosFilter.value as string[]).length === 0)
            )
              return true;
            const existInTradingPartnersDeliveryTypes = currentTpFilter
              ? (queryStringToArrayOrUndefined(currentFilters) || []).map(
                  (tpId) => tradingPartnerList?.find((tp) => tp.externalId === tpId)?.deliveryType
                )
              : Object.keys(DeliveryType);
            const existInShipTosDeliveryTypes = currentShipTosFilter
              ? (queryStringToArrayOrUndefined(currentShipTosFilter) || []).reduce(
                  (result, current) => {
                    const shipTo = deliveryDestinationsList?.find(
                      (dd) => dd?.externalId === current
                    );
                    if (shipTo && shipTo.tradingPartners) {
                      result = result.concat(
                        shipTo?.tradingPartners?.map((tp) => tp.deliveryType).filter(notEmpty)
                      );
                    }
                    return result;
                  },
                  [] as string[]
                )
              : Object.keys(DeliveryType);
            return (
              existInTradingPartnersDeliveryTypes.includes(deliveryType.name as DeliveryType) &&
              existInShipTosDeliveryTypes.includes(deliveryType.name)
            );
          }),
        lazyLoading: false,
        dependsOn: ['tradingPartners', 'deliveryDestinations']
      },
      {
        name: 'tradingPartnerIds',
        title: 'Trading Partners',
        type: 'string',
        multiple: true,
        values: (tradingPartnerList || []).map((tradingPartner) => ({
          name: tradingPartner?.id,
          title: tradingPartner?.name
        })),
        lazyLoading: true,
        dependsOn: [
          'deliveryType',
          'businessUnitIds',
          'deliveryDestinationIds',
          'retailerChannelIds',
          'distributionCenterIds'
        ]
      },
      {
        name: 'distributionCenterIds',
        title: 'Distribution Centers',
        type: 'string',
        multiple: true,
        values: (distributionCentersList || []).map((distributionCenter) => ({
          name: distributionCenter?.id,
          title: `${distributionCenter?.name} - ${distributionCenter?.code}`
        })),
        lazyLoading: true,
        dependsOn: [
          'deliveryType',
          'businessUnitIds',
          'deliveryDestinationIds',
          'retailerChannelIds',
          'tradingPartnerIds'
        ]
      },
      {
        name: 'retailerChannelIds',
        title: 'Customers',
        type: 'string',
        multiple: true,
        values: (vendorMarketList || []).map((vendorMarket) => ({
          name: vendorMarket.id,
          title: vendorMarket.name,
          id: vendorMarket.id
        })),
        lazyLoading: true,
        dependsOn: [
          'tradingPartnerIds',
          'deliveryDestinationIds',
          'deliveryType',
          'businessUnitIds'
        ]
      },
      {
        name: 'deliveryDestinationIds',
        title: 'Ship-To',
        type: 'string',
        multiple: true,
        additionalOptions: [CondOperator.EXCLUDE, CondOperator.INCLUDE],
        values: (deliveryDestinationsList || []).map((deliveryDestination) => ({
          name: deliveryDestination?.id,
          title: deliveryDestination?.name,
          id: deliveryDestination?.id
        })),
        lazyLoading: true,
        dependsOn: ['tradingPartnerIds', 'retailerChannelIds', 'deliveryType', 'businessUnitIds']
      },
      { name: 'mustArriveByDate', title: 'MABD', type: 'dateRange', lazyLoading: false },
      {
        name: 'salesOrderMustArriveByDate',
        title: 'Sales Order MABD',
        type: 'dateRange',
        lazyLoading: false
      },
      { name: 'dateReceived', title: 'Order Date', type: 'dateRange', lazyLoading: false },
      { name: 'deliveryWindow', title: 'Delivery Window', type: 'dateRange', lazyLoading: false },
      {
        name: 'businessUnitIds',
        title: 'BU',
        type: 'string',
        multiple: true,
        values: (businessUnitsList || []).map((businessUnit) => ({
          name: businessUnit.id,
          title: businessUnit.code
        })),
        lazyLoading: true,
        dependsOn: [
          'tradingPartnerIds',
          'retailerChannelIds',
          'deliveryType',
          'deliveryDestinationIds'
        ]
      },
      {
        name: 'purchaseOrderErrors',
        title: 'Error Type',
        additionalOptions: [CondOperator.EXCLUDE, CondOperator.INCLUDE],
        type: 'string',
        multiple: true,
        values: Object.entries(PO_ERROR_FILTER_MAP).map((error) => ({
          name: error[0],
          title: error[1]
        })),
        lazyLoading: false
      },
      {
        name: 'purchaseOrderConfirmation',
        title: 'Confirmation',
        type: 'string',
        multiple: false,
        values: ConfirmationType.map((confirmationType) => ({
          name: confirmationType.name,
          title: confirmationType.description
        })),
        lazyLoading: false
      },
      {
        name: 'labels',
        title: 'Tags',
        additionalOptions: [CondOperator.EXCLUDE, CondOperator.INCLUDE],
        type: 'string',
        multiple: true,
        values: (labelsList || []).map((label) => ({
          name: label.id,
          title: label.text
        })),
        lazyLoading: true
      },
      {
        name: 'withCuts',
        title: 'PO Cuts',
        type: 'string',
        multiple: false,
        values: [
          {
            name: 'posWithCuts',
            title: 'POs with cuts'
          }
        ],
        lazyLoading: false
      },
      { name: 'shipmentsRange', title: 'Total Shipments', type: 'numberRange', lazyLoading: false }
    ],
    [
      businessUnitsList,
      currentFilters,
      deliveryDestinationsList,
      distributionCentersList,
      labelsList,
      tradingPartnerList,
      vendorMarketList
    ]
  );

  //lazy loading of drop down options is down on user focus for each select
  const onFilterValuesFocus = (
    fieldName: NewFilterName,
    currentFilters: Filter<NewFilterName>[]
  ) => {
    const filterData = searchStringToQueryFilterForSearchList(
      stringify(filtersToLocalUpdate(currentFilters))
    );
    if (fieldName === 'tradingPartnerIds' && !tradingPartnersLoading) {
      loadTradingPartners({
        variables: getTradingPartnersVariables(filterData)
      });
    }

    if (fieldName === 'distributionCenterIds' && !distributionCentersLoading) {
      loadDistributionCenters({
        variables: getDistributionCentersVariables(filterData)
      });
    }
    if (fieldName === 'retailerChannelIds' && !vendorMarketsLoading) {
      loadVendorMarkets({
        variables: getVendorMarketsVariables(filterData)
      });
    }
    if (fieldName === 'deliveryDestinationIds' && !deliveryDestinationsLoading) {
      loadDeliveryDestinations({
        variables: getDeliveryDestinationsVariables(filterData)
      });
    }
    // don't depends on other fields, so don't load if data was already loaded
    if (fieldName === 'businessUnitIds' && !businessUnitsLoading) {
      loadBusinessUnits({
        variables: getBusinessUnitsVariables(filterData)
      });
    }
    if (fieldName === 'labels' && !labelsListLoading && !labelsListData) {
      loadLabelsList();
    }
  };

  const loadLazyLoadingData = useCallback(
    async (
      previous: Filter<NewFilterName>[],
      newCurrentFilters: Filter<NewFilterName>[],
      reloadAll?: boolean,
      manualReload?: Filter<NewFilterName>[]
    ) => {
      const filterData = searchStringToQueryFilterForSearchList(
        stringify(filtersToLocalUpdate(newCurrentFilters))
      );

      const reload: string[] = [];

      manualReload?.forEach((filter) => {
        if (filter.name && !reload.includes(filter.name)) reload.push(filter.name);
      });

      if (previous.length < newCurrentFilters.length) {
        const addedFilter = newCurrentFilters.filter(
          (filter) => !previous.find((prevFilter) => prevFilter.name === filter.name)
        );
        addedFilter.forEach((filter) => {
          if (filter.name && filter.value) {
            reload.push(filter.name);
          }
        });
      } else if (previous.length > newCurrentFilters.length) {
        const deletedFilter = previous.find(
          (filter) => !newCurrentFilters.find((newFilter) => newFilter.name === filter.name)
        );
        if (!deletedFilter || !deletedFilter?.value || !deletedFilter?.name) return;

        filters.forEach((filter) => {
          if (filter.lazyLoading && filter.dependsOn) {
            if (
              deletedFilter &&
              deletedFilter.name &&
              deletedFilter.name !== filter.name &&
              newCurrentFilters.find((currentFilter) => currentFilter.name === filter.name) &&
              filter.dependsOn?.includes(deletedFilter.name)
            ) {
              reload.push(filter.name);
            }
          }
        });
      } else {
        const editedFilter = newCurrentFilters.find((newFilter) =>
          previous.find(
            (prevFilter) =>
              newFilter.name === prevFilter.name &&
              !isEqual(
                newFilter.value
                  ? typeof newFilter.value === 'string'
                    ? [newFilter.value]
                    : Array.isArray(newFilter.value)
                    ? newFilter.value.sort()
                    : newFilter.value
                  : [],
                prevFilter.value
                  ? typeof prevFilter.value === 'string'
                    ? [prevFilter.value]
                    : Array.isArray(prevFilter.value)
                    ? prevFilter.value.sort()
                    : prevFilter.value
                  : []
              )
          )
        );
        if (editedFilter && editedFilter.name) {
          // edited
          filters.forEach((filter) => {
            if (filter.lazyLoading && filter.dependsOn) {
              if (
                editedFilter &&
                editedFilter.name &&
                editedFilter.name !== filter.name &&
                newCurrentFilters.find((currentFilter) => currentFilter.name === filter.name) &&
                filter.dependsOn?.includes(editedFilter.name)
              ) {
                reload.push(filter.name);
              }
            }
          });
        }
      }

      if (
        newCurrentFilters.find((filter) => filter.name === 'labels') &&
        !labelsListData &&
        !labelsListLoading
      ) {
        reload.push('labels');
      }

      if (reload.includes('distributionCenterIds') || reloadAll) {
        loadDistributionCenters({
          variables: getDistributionCentersVariables(filterData)
        });
      }

      if (reload.includes('tradingPartnerIds') || reloadAll) {
        loadTradingPartners({
          variables: getTradingPartnersVariables(filterData)
        });
      }

      if (reload.includes('retailerChannelIds') || reloadAll) {
        loadVendorMarkets({
          variables: getVendorMarketsVariables(filterData)
        });
      }

      if (reload.includes('deliveryDestinationIds') || reloadAll) {
        loadDeliveryDestinations({
          variables: getDeliveryDestinationsVariables(filterData)
        });
      }

      if (reload.includes('labels') || reloadAll) {
        loadLabelsList();
      }

      if (reload.includes('businessUnitIds') || reloadAll) {
        loadBusinessUnits({
          variables: getBusinessUnitsVariables(filterData)
        });
      }
    },
    [
      filters,
      labelsListData,
      labelsListLoading,
      loadBusinessUnits,
      loadDeliveryDestinations,
      loadLabelsList,
      loadTradingPartners,
      loadDistributionCenters,
      loadVendorMarkets
    ]
  );

  return (
    <AdvancedFilterComponent<NewFilterName>
      searchFieldName="searchIds"
      size={size}
      filters={filters}
      onApplyAdvancedFilter={onApplyAdvancedFilter}
      onFilterValuesFocus={onFilterValuesFocus}
      loading={loading}
      searchStringToQueryFilter={searchStringToQueryFilterForSearchList}
      saveToLocalStorageFieldName={NEW_SEARCH_LOCAL_STORAGE_FILTERS_NAME}
      queryFilterToFilters={queryFilterToFilters}
      handleSearch={handleOrdersFilter}
      loadLazyLoadingData={loadLazyLoadingData}
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
      setShowAdvancedFilters={setShowAdvancedFilters}
      showAdvancedFilters={showAdvancedFilters}
    />
  );
};

const getVendorMarketsVariables = (
  filterData: OrdersFilterData
): VendorMarketsAdvancedFiltersOrdersPageQueryVariables => {
  const { businessUnitIds, deliveryTypes, tradingPartnerIds, distributionCenterIds } =
    filterData.filterData;
  const { shipToIdConditions } = filterData.advancedFilterData;
  const deliveryDestinationIds = shipToIdConditions?.find(
    (condition) => condition?.operator === 'INCLUDE'
  )?.value;

  return {
    filter: {
      tradingPartnerIds,
      businessUnitIds,
      deliveryTypes,
      deliveryDestinationIds,
      distributionCenterIds
    }
  };
};

const getTradingPartnersVariables = (
  filterData: OrdersFilterData
): TradingPartnersAdvancedFiltersQueryVariables => {
  const { businessUnitIds, deliveryTypes, retailerChannelIds, distributionCenterIds } =
    filterData.filterData;
  const { shipToIdConditions } = filterData.advancedFilterData;
  const deliveryDestinationIds = shipToIdConditions?.find(
    (condition) => condition?.operator === 'INCLUDE'
  )?.value;

  return {
    filter: {
      businessUnitIds,
      deliveryTypes,
      deliveryDestinationIds,
      vendorMarketIds: retailerChannelIds,
      distributionCenterIds
    }
  };
};

const getBusinessUnitsVariables = (
  filterData: OrdersFilterData
): BusinessUnitsOrdersPageQueryVariables => {
  const { deliveryTypes, tradingPartnerIds, retailerChannelIds, distributionCenterIds } =
    filterData.filterData;
  const { shipToIdConditions } = filterData.advancedFilterData;
  const deliveryDestinationIds = shipToIdConditions?.find(
    (condition) => condition?.operator === 'INCLUDE'
  )?.value;

  return {
    filter: {
      tradingPartnerIds,
      vendorMarketIds: retailerChannelIds,
      deliveryTypes,
      deliveryDestinationIds,
      distributionCenterIds
    }
  };
};

const getDistributionCentersVariables = (
  filterData: OrdersFilterData
): DistributionCentersAdvancedFiltersQueryVariables => {
  const { tradingPartnerIds, retailerChannelIds, businessUnitIds, deliveryTypes } =
    filterData.filterData;
  const { shipToIdConditions } = filterData.advancedFilterData;
  const deliveryDestinationIds = shipToIdConditions?.find(
    (condition) => condition?.operator === 'INCLUDE'
  )?.value;
  return {
    filter: {
      deliveryTypes,
      tradingPartnerIds,
      vendorMarketIds: retailerChannelIds,
      businessUnitIds,
      deliveryDestinationIds
    }
  };
};

const getDeliveryDestinationsVariables = (
  filterData: OrdersFilterData
): RddAdvancedFiltersQueryVariables => {
  const {
    tradingPartnerIds,
    retailerChannelIds,
    businessUnitIds,
    deliveryTypes,
    distributionCenterIds
  } = filterData.filterData;

  return {
    filter: {
      tradingPartnerIds,
      vendorMarketIds: retailerChannelIds,
      businessUnitIds,
      deliveryTypes: deliveryTypes,
      distributionCenterIds
    }
  };
};
