import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  FiscalCalendarWeekInputWithWeekOnly,
  getCurrentReferenceDate
} from 'components/PeriodCalendar/helpers';
import { convertToMM } from 'common/helpers/convertToMM';
import { TotalWithGraph } from '../TotalWithGraph/TotalWithGraph';
import { convertWeekToPeriodWeekString, getFinancialInfo } from 'common/helpers/fiscalCalendar';
import { isContinuous } from 'common/helpers/graph';
import { getPreviousWeekAndYear, yearWeekSorter } from 'common/helpers/periodHelper';
import {
  ExecutiveIntelligenceServiceOtifPurchaseOrdersTotalsReportDocument,
  ExecutiveIntelligenceServiceOtifPurchaseOrdersTotalsReportQuery
} from './gql/__generated__/executiveIntelligenceServiceOtifPurchaseOrdersTotalsReport.query';
import {
  ExecutiveIntelligenceServiceOtifPurchaseOrdersReportsByPeriodDocument,
  ExecutiveIntelligenceServiceOtifPurchaseOrdersReportsByPeriodQuery
} from './gql/__generated__/executiveIntelligenceServiceOtifPurchaseOrdersReportsByPeriod.query';
import { EMPTY } from 'common/constants';
import { calculateChange } from 'pages/ExecutiveIntelligence/helpers';
import { ExecutiveIntelligenceServiceOtifSalesOrdersTotalsReportDocument } from './gql/__generated__/executiveIntelligenceServiceOtifSalesOrdersTotalsReport.query';
import { ExecutiveIntelligenceServiceOtifSalesOrdersReportsByPeriodDocument } from './gql/__generated__/executiveIntelligenceServiceOtifSalesOrdersReportsByPeriod.query';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';
import { ExecutiveReportingFilters } from 'graphql/__generated__/types';
import s from './Service.module.scss';

const formatPercentage = (value: any) => {
  const parsedValue = parseFloat(value);
  if (Number.isNaN(parsedValue)) return EMPTY;
  return `${(parsedValue * 100).toFixed(1)}%`;
};

const formatPercentageFromNumber = (value: number) => {
  if (Number.isNaN(value)) return EMPTY;
  return `${(value * 100).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3
  })}%`;
};

type OtifByPeriodData =
  ExecutiveIntelligenceServiceOtifPurchaseOrdersReportsByPeriodQuery['periodReport'][number];
type Tiles = keyof NonNullable<
  ExecutiveIntelligenceServiceOtifPurchaseOrdersTotalsReportQuery['totalsReport']
>;
// Backend name

const currentPeriod = getFinancialInfo(getCurrentReferenceDate());

// We want to compare PREVIOUS week to PREVIOUS PREVIOUS week, since CURRENT week has no data
const actualPeriod = getPreviousWeekAndYear(currentPeriod.week, currentPeriod.year);
const comparisonPeriod = getPreviousWeekAndYear(actualPeriod.week, actualPeriod.year);

const shouldShowChange = actualPeriod.week !== comparisonPeriod.week;

// TODO: create a generic function for that?
export const mapDataWithPeriods = (
  data: OtifByPeriodData[],
  periods: FiscalCalendarWeekInputWithWeekOnly[]
) => {
  const sortedData = [...periods].sort((a, b) => yearWeekSorter(a, b));

  const dataWithFilledHoles = (sortedData || []).map((fiscalCalendarWeek) => {
    const found = data.find(
      (x) =>
        x.fiscalCalendarWeek.week === fiscalCalendarWeek.week &&
        fiscalCalendarWeek.year === x.fiscalCalendarWeek.year
    );
    return {
      fiscalCalendarWeek,
      ...found
    };
  });

  return { data: dataWithFilledHoles };
};

export const Service = ({
  fiscalCalendarWeeks,
  executiveReportingFilters
}: {
  fiscalCalendarWeeks: FiscalCalendarWeekInputWithWeekOnly[];
  executiveReportingFilters: ExecutiveReportingFilters;
}) => {
  const [mode, setMode] = useState<'po' | 'so'>('po');
  const totals = useQuery(
    mode === 'po'
      ? ExecutiveIntelligenceServiceOtifPurchaseOrdersTotalsReportDocument
      : ExecutiveIntelligenceServiceOtifSalesOrdersTotalsReportDocument,
    {
      variables: {
        filters: executiveReportingFilters
      }
    }
  );

  const period = useQuery(
    mode === 'po'
      ? ExecutiveIntelligenceServiceOtifPurchaseOrdersReportsByPeriodDocument
      : ExecutiveIntelligenceServiceOtifSalesOrdersReportsByPeriodDocument,
    {
      variables: {
        filters: {
          countryCode: 'US',
          fiscalCalendarWeeks: fiscalCalendarWeeks
        }
      }
    }
  );

  const tiles: {
    field: Tiles;
    title: React.ReactNode;
    valueFormatter: (value: string) => string;
    moreIsBetter?: boolean;
    valueTooltipFormatter?: (value: number) => string;
  }[] = [
    {
      field: 'otif',
      title: (
        <span>
          OTIF BY{' '}
          <AlloySelect
            value={mode}
            onChange={(value) => {
              setMode(value);
            }}
            variant="borderless"
            dropdownStyle={{ minWidth: '30px', fontWeight: 'bold' }}
            options={[
              {
                value: 'po',
                label: 'PO'
              },
              {
                value: 'so',
                label: 'SO'
              }
            ]}
            className={s.borderlessSelect}
          />
        </span>
      ),
      valueFormatter: (value: string) => formatPercentage(value),
      moreIsBetter: true,
      valueTooltipFormatter: (value) => formatPercentageFromNumber(value)
    },
    {
      field: 'onTime',
      title: 'ON-TIME',
      valueFormatter: (value: string) => formatPercentage(value),
      moreIsBetter: true,
      valueTooltipFormatter: (value) => formatPercentageFromNumber(value)
    },
    {
      field: 'inFull',
      title: 'IN-FULL',
      valueFormatter: (value: string) => formatPercentage(value),
      moreIsBetter: true,
      valueTooltipFormatter: (value) => formatPercentageFromNumber(value)
    },
    {
      field: 'rsvValue',
      title: 'RSV',
      valueFormatter: (value) => convertToMM(value),
      moreIsBetter: true,
      valueTooltipFormatter: (value) => `$${value.toLocaleString('en-US')}`
    },
    {
      field: 'submittedFillRate',
      title: 'SFR',
      valueFormatter: (value: string) => formatPercentage(value),
      moreIsBetter: true,
      valueTooltipFormatter: (value) => formatPercentageFromNumber(value)
    },
    {
      field: 'acceptedFillRate',
      title: 'AFR',
      valueFormatter: (value: string) => formatPercentage(value),
      moreIsBetter: true,
      valueTooltipFormatter: (value) => formatPercentageFromNumber(value)
    },
    {
      field: 'approximateLostOpportunity',
      title: 'APPROX. LOST OPPORTUNITY',
      valueFormatter: (value) => convertToMM(value),
      moreIsBetter: false,
      valueTooltipFormatter: (value) => `$${value.toLocaleString('en-US')}`
    }
  ];

  if (totals.error)
    return (
      <div style={{ gridColumn: 'span 12' }}>Could not load service data due to a server error</div>
    );

  return (
    <>
      {tiles.map(({ title, valueFormatter, field, moreIsBetter, valueTooltipFormatter }) => {
        const relatedData = mapDataWithPeriods(
          period.data?.periodReport || [],
          fiscalCalendarWeeks
        ).data;

        const isContinious = isContinuous(relatedData.map((x) => x.fiscalCalendarWeek.week));

        const actualPeriodValue = relatedData.find(
          (x) =>
            x.fiscalCalendarWeek.week === actualPeriod.week &&
            x.fiscalCalendarWeek.year === actualPeriod.year
        )?.[field];
        const comparisonPeriodValue = relatedData.find(
          (x) =>
            x.fiscalCalendarWeek.week === comparisonPeriod.week &&
            x.fiscalCalendarWeek.year === comparisonPeriod.year
        )?.[field];

        const change = calculateChange(
          actualPeriodValue,
          comparisonPeriodValue,
          shouldShowChange,
          !!moreIsBetter,
          valueFormatter
        );

        return (
          <TotalWithGraph
            key={field}
            change={change}
            changeText="than prev. period"
            changeTooltip={`In ${convertWeekToPeriodWeekString(
              actualPeriod.week
            )} compared to ${convertWeekToPeriodWeekString(comparisonPeriod.week)}`}
            title={title}
            totalPosition={field === 'otif' ? 'left' : 'top'}
            width={field === 'otif' ? 12 : 4}
            loading={totals.loading || period.loading}
            total={valueFormatter(totals.data?.totalsReport?.[field])}
            graph={{
              loading: period.loading,
              data: relatedData.map((x) => ({
                value: isNaN(parseFloat(x[field])) ? undefined : parseFloat(x[field]),
                label: convertWeekToPeriodWeekString(x.fiscalCalendarWeek.week)
              })),
              isContinious,
              valueTickFormatter: (value) => valueFormatter(value.toString()),
              valueTooltipFormatter: valueTooltipFormatter
            }}
          />
        );
      })}
    </>
  );
};
