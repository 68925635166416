import React, { useMemo, useState } from 'react';
import s from './Chargebacks.module.scss';
import { PeriodCalendar } from 'components/PeriodCalendar/PeriodCalendar';
import {
  getCurrentPeriod,
  transformCalendarValueToPeriodWeekYear
} from 'components/PeriodCalendar/helpers';
import { CalendarValue, Mode } from 'components/PeriodCalendar/types';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { groupBy, range } from 'lodash-es';
import { useQueryParam, withDefault, JsonParam, StringParam } from 'use-query-params';
import { PageHeader } from 'components/ui/PageHeader/PageHeader';
import { useQuery } from '@apollo/client';
import {
  ChargebacksReportDetailsByPeriodForGraphDocument,
  ChargebacksReportDetailsByPeriodForGraphQuery
} from './gql/__generated__/chargebacksReportDetailsByPeriodForGraph.query';
import { useDeepCompareMemo } from 'use-deep-compare';
import { convertWeekToPeriodWeekString } from 'common/helpers/fiscalCalendar';
import currency from 'currency.js';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Tooltip,
  CartesianGrid,
  LegendProps,
  TooltipProps
} from 'recharts';
import { AlloySegmented } from 'components/ui/AlloySegmented/AlloySegmented';
import { AlloySpin } from 'components/ui/AlloySpin/AlloySpin';
import { getColorWithApproximation } from 'common/helpers/palette';
import { notEmpty } from 'common/helpers/notEmpty';
import { EMPTY } from 'common/constants';
import clsx from 'clsx';
import { ChargebackDimension } from 'graphql/__generated__/types';
import { AlloySelect } from 'components/ui/AlloySelect/AlloySelect';

type ChargebackReportByPeriod =
  ChargebacksReportDetailsByPeriodForGraphQuery['chargebackDetailsReportsByPeriod'][number];

const CURRENT_YEAR = new Date().getFullYear();
const STARTING_YEAR = 2024;

const stringifyYearWeek = ({ week, year }: { week: number; year: number }, withYear: boolean) => {
  return `${withYear ? year : ''}${convertWeekToPeriodWeekString(week)}`.trim();
};

const stringifyReason = (reason: string | undefined) => {
  return (reason || '').replaceAll('_', ' ').replace('Prep ', 'Prep - ');
};

const formatCurrency = (value: any, precision = 2) => {
  if (Number.isNaN(value) || value === undefined) return EMPTY;
  const money = currency(value, { symbol: '$', precision, separator: ',', decimal: '.' });
  return money.format();
};

const getDefaultPeriod = () => {
  const { week } = getCurrentPeriod(true);
  const end = week;
  return { start: 1, end };
};
const defaultPeriod = getDefaultPeriod();

const DEFAULT_PERIOD_STATE: CalendarValue = {
  mode: Mode.PERIOD_WEEK,
  isRange: true,
  years: [CURRENT_YEAR],
  ...defaultPeriod
};

// TODO: fetch from BE?
const DEFAULT_BUS = ['FLNA', 'Gatorade', 'PBC', 'Quaker'];

const AXIS_COLOR = '#B1B1B1';
const __name__ = '__name__';

const dimensionToField: { [K in ChargebackDimension]?: keyof ChargebackReportByPeriod } = {
  DISTRIBUTION_CENTER: 'distributionCenter',
  ISSUE_TYPE: 'issueType',
  STATUS: 'status'
} as const;

const dimensions: { label: string; value: ChargebackDimension }[] = [
  {
    label: 'Distribution Center',
    value: 'DISTRIBUTION_CENTER'
  },
  {
    label: 'Issue Type',
    value: 'ISSUE_TYPE'
  },
  {
    label: 'Status',
    value: 'STATUS'
  }
] as const;

export const Chargebacks = () => {
  const [disabledSeries, setDisabledSeries] = useState<string[]>([]);

  const handleLegendClick = (dataKey: string) => {
    if (disabledSeries.includes(dataKey)) {
      setDisabledSeries(disabledSeries.filter((el) => el !== dataKey));
    } else {
      setDisabledSeries((prev) => [...prev, dataKey]);
    }
  };

  const [period, setPeriod] = useQueryParam<CalendarValue>(
    'period',
    withDefault(JsonParam, DEFAULT_PERIOD_STATE)
  );

  const fiscalCalendarWeeks = useDeepCompareMemo(() => {
    return transformCalendarValueToPeriodWeekYear(period, true);
  }, [period]);

  const [dimension, setDimension] = useQueryParam(
    'graph_dimension',
    withDefault(StringParam, 'ISSUE_TYPE')
  );
  const selectedField = useMemo(
    () => dimensionToField[dimension as ChargebackDimension] || 'issueType',
    [dimension]
  );

  const chargebacksGraphs = useQuery(ChargebacksReportDetailsByPeriodForGraphDocument, {
    variables: {
      dimensions: ['BUSINESS_UNIT', dimension as ChargebackDimension],
      filters: {
        fiscalCalendarWeeks,
        countryCode: 'US'
      }
    }
  });

  const [bu, setBu] = useQueryParam('graph_bu', withDefault(StringParam, 'all'));

  const allBusSelected = bu === 'all';

  const { graphData, series, areMultipleYearsSelected } = useMemo(() => {
    const fiscalData = chargebacksGraphs.data?.chargebackDetailsReportsByPeriod || [];

    const areMultipleYearsSelected = (fiscalCalendarWeeks || []).some(
      (x) => x.year !== fiscalCalendarWeeks?.[0]?.year
    );

    const filteredData = fiscalData.filter(
      (x) => allBusSelected || (x.businessUnit || '').toLocaleLowerCase() === bu.toLocaleLowerCase()
    );

    const allReasons = [...new Set(fiscalData.map((x) => x[selectedField]).filter(notEmpty))];

    const legendColors: { [key: string]: string } = {};
    allReasons.forEach((x, idx) => {
      legendColors[x] = getColorWithApproximation(idx, allReasons.length).color;
    });

    const series = [...new Set(filteredData.map((x) => x[selectedField]).filter(notEmpty))].map(
      (name: string) => ({
        name,
        color: legendColors[name]
      })
    );

    const groupedData = groupBy(filteredData, (x) =>
      allBusSelected
        ? x.businessUnit
        : stringifyYearWeek(x.fiscalCalendarWeek, areMultipleYearsSelected)
    );

    const calculateGraphData = (name: string, items: ChargebackReportByPeriod[]) => {
      const result: { [key: string]: number } = {};
      items.forEach((item) => {
        const field = item[selectedField];
        if (field) {
          result[field] = currency(result[field] || 0).add(item.financialCharge).value;
        }
      });
      return { ...result, [__name__]: name };
    };

    const graphData =
      bu === 'all'
        ? DEFAULT_BUS.map((unit) => calculateGraphData(unit, groupedData[unit] || []))
        : fiscalCalendarWeeks.map((weekYear) => {
            const name = stringifyYearWeek(weekYear, areMultipleYearsSelected);
            return calculateGraphData(name, groupedData[name] || []);
          });

    return { graphData, series, areMultipleYearsSelected };
  }, [
    chargebacksGraphs.data?.chargebackDetailsReportsByPeriod,
    fiscalCalendarWeeks,
    bu,
    allBusSelected,
    selectedField
  ]);

  return (
    <div>
      <div className={s.header}>
        <div className={s.page_title}>
          <PageHeader className={s.title}>Chargebacks</PageHeader>
        </div>
        <div className={s.page_filters}>
          <PeriodCalendar
            value={period}
            onChange={setPeriod}
            years={
              STARTING_YEAR === CURRENT_YEAR ? undefined : range(STARTING_YEAR, CURRENT_YEAR + 1)
            }
            // If we have more years, we want to allow to select prev periods, else nope
            pastOnly={STARTING_YEAR === CURRENT_YEAR}
          />
          <AlloyButton size="large" type="secondary">
            Export
          </AlloyButton>
        </div>
      </div>
      <div className={s.graph_top}>
        <h2 className={s.title}>
          Chargebacks by{' '}
          <AlloySelect
            value={dimension}
            onChange={(value) => {
              setDimension(value);
            }}
            variant="borderless"
            dropdownStyle={{ minWidth: '150px', fontWeight: 'bold' }}
            options={dimensions}
            className={s.borderlessSelect}
          />
        </h2>
        <div className={s.selector}>
          <AlloySegmented
            value={bu}
            onChange={(value) => setBu(value)}
            options={[
              {
                label: 'All BUs',
                value: 'all'
              },
              ...DEFAULT_BUS.map((bu) => ({
                label: bu,
                value: bu
              }))
            ]}
          />
        </div>
      </div>
      <AlloySpin spinning={chargebacksGraphs.loading}>
        <div style={{ width: '100%', height: '360px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={graphData}
              margin={{
                top: 0,
                right: 0,
                left: 20,
                bottom: allBusSelected ? 5 : areMultipleYearsSelected ? 75 : 25
              }}
            >
              <CartesianGrid horizontal={true} vertical={false} stroke="#dcdcdc" strokeWidth={1} />
              <XAxis
                dataKey={__name__}
                angle={allBusSelected ? 0 : -90}
                textAnchor={allBusSelected ? 'middle' : 'end'}
                dx={allBusSelected ? 0 : -5}
                tickLine={false}
                stroke={AXIS_COLOR}
                tick={{ fill: 'black' }}
              />
              <YAxis
                stroke={AXIS_COLOR}
                tickLine={false}
                tickFormatter={(label) => formatCurrency(label, 0)}
              />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                verticalAlign="top"
                content={<CustomLegend handleLegendClick={handleLegendClick} />}
              />
              {series.map(({ name, color }) => (
                <Bar
                  key={name}
                  dataKey={name}
                  stackId="a"
                  fill={color}
                  isAnimationActive={false}
                  hide={disabledSeries.includes(name)}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </AlloySpin>
    </div>
  );
};

const CustomLegend = (props: LegendProps & { handleLegendClick: (name: string) => void }) => {
  const { payload, handleLegendClick } = props;
  if (!payload) return <></>;

  return (
    <div className={s.legend}>
      {payload.map((entry, index) => (
        <button
          key={`item-${index}`}
          className={clsx(s.item, { [s.not_active]: entry.inactive })}
          onClick={() => handleLegendClick(entry.value)}
        >
          <div className={s.color} style={{ backgroundColor: entry.color }} />
          {stringifyReason(entry.value)}
        </button>
      ))}
    </div>
  );
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<number, '__name__'>) => {
  if (!(active && payload && payload.length)) return null;

  return (
    <div className={s.tooltip}>
      <div className={s.value}>{label}</div>
      {/* We reverse the array so order matches the "barchart" order */}
      {[...payload].reverse().map((entry) => (
        <div key={`item-${entry.name}`} className={s.item}>
          <div className={s.color} style={{ backgroundColor: entry.color }} />
          {stringifyReason(entry.name)}:{' '}
          <span className={s.value}>{formatCurrency(entry.value)}</span>
        </div>
      ))}
    </div>
  );
};
