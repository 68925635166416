import React from 'react';
import s from './ReleaseOrdersModal.module.scss';
import { useMutation, useQuery } from '@apollo/client';
import { ReleaseSalesOrdersModalDocument } from './gql/__generated__/releaseSalesOrdersModal.mutation';
import {
  PurchaseOrdersForReleaseQuery,
  PurchaseOrdersForReleaseDocument
} from './gql/__generated__/purchaseOrdersForRelease.query';
import { InferNodeType, getNodesFromEdges } from 'common/helpers/mappingHelper';
import LoaderSpinner from 'components/LoaderSpinner';
import { AlloyRow } from 'components/ui/AlloyRow/AlloyRow';
import { AlloyCol } from 'components/ui/AlloyCol/AlloyCol';
import { AlloyModal } from 'components/ui/AlloyModal/AlloyModal';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { App } from 'ant5';
import { PurchaseOrderSearchCountsDocument } from '../OrdersSummary/gql/__generated__/purchaseOrderSearchCounts.query';
import { PurchaseOrderSearchListDocument } from 'pages/OrdersPage/gql/__generated__/purchaseOrderSearchList.query';

interface ReleaseOrdersModalProps {
  visibility: boolean;
  toggleModal: () => void;
  selectedRowIds: string[];
  clearSelection: () => void;
}

type PurchaseOrderWithActions = InferNodeType<PurchaseOrdersForReleaseQuery, 'purchaseOrders'>;

export interface AvailableActionSort {
  allowed: PurchaseOrderWithActions[];
  disallowed: PurchaseOrderWithActions[];
}

const ReleaseOrdersModal = ({
  visibility,
  toggleModal,
  selectedRowIds,
  clearSelection
}: ReleaseOrdersModalProps) => {
  const [releaseSalesOrdersMutation, { loading: processing }] = useMutation(
    ReleaseSalesOrdersModalDocument,
    {
      refetchQueries: [
        'purchaseOrders',
        'purchaseOrderCounts',
        PurchaseOrderSearchCountsDocument,
        PurchaseOrderSearchListDocument
      ]
    }
  );

  const { notification } = App.useApp();

  const { data, loading } = useQuery(PurchaseOrdersForReleaseDocument, {
    variables: {
      ids: selectedRowIds,
      first: selectedRowIds.length
    },

    skip: !visibility || !selectedRowIds.length,
    onError: (error) => {
      notification.error({
        message: 'Failed to fetch purchase orders',
        description: error.message
      });
    }
  });

  const joinedPOs = getNodesFromEdges(data?.purchaseOrders);

  const releasablePOsList: AvailableActionSort = {
    allowed: joinedPOs?.filter((po) => po?.availableActions.includes('RELEASE_PO')),
    disallowed: joinedPOs?.filter((po) => !po?.availableActions.includes('RELEASE_PO'))
  };

  const releaseSalesOrdersAction = async () => {
    try {
      const response = await releaseSalesOrdersMutation({
        variables: {
          input: {
            salesOrderIds: releasablePOsList.allowed.reduce((result, current) => {
              if (current.salesOrders.length > 0) {
                result = result.concat(
                  current.salesOrders.filter((so) => so.status !== 'SUBMITTED').map((so) => so.id)
                );
              }
              return result;
            }, [] as string[])
          }
        }
      });

      //do this actions before notification showing to prevent re-rendering notification
      clearSelection();
      toggleModal();
      if ((response?.data?.releaseSalesOrders?.salesOrders || []).length > 0) {
        notification.success({
          message: `Released ${
            (response?.data?.releaseSalesOrders?.salesOrders || []).length
          } Sales Order${
            (response?.data?.releaseSalesOrders?.salesOrders || []).length > 1 ? 's' : ''
          }`,
          duration: 0
        });
      } else {
        notification.error({
          message: 'Something went wrong, please, try again.',
          duration: 0
        });
      }
    } catch (error) {
      clearSelection();
      toggleModal();
      notification.error({
        message: error.message,
        duration: 0
      });
    }
  };

  const hasAllowedPOs = releasablePOsList.allowed.length > 0;
  const DisplayPOs = () => {
    return (
      <>
        {hasAllowedPOs ? (
          <>
            <div data-testid="accepted-release-pos-title" className={s.title}>
              {`The following (${releasablePOsList.allowed.length}) purchase orders will be released:`}
            </div>
            <div data-testid="accepted-release-pos-list" className={s.table}>
              {releasablePOsList.allowed?.map((po) => {
                return (
                  <AlloyRow key={po.id} data-testid={`${po.id}-row`} className={s.row}>
                    <AlloyCol span={9} data-testid={`${po.id}-col`} className={s.col_po}>
                      {po.customerPo}
                    </AlloyCol>
                    <AlloyCol
                      span={15}
                      data-testid={`${po.retailerDeliveryDestination?.id}-col`} // added conditional to accommodate for no RDD in testing
                      className={s.col_rdd}
                    >
                      {po.retailerDeliveryDestination
                        ? `${po.retailerDeliveryDestination.name} - ${po.retailerDeliveryDestination.externalId}`
                        : po.retailerDeliveryDestinationExternalId}
                    </AlloyCol>
                  </AlloyRow>
                );
              })}
            </div>
          </>
        ) : null}

        {releasablePOsList.disallowed.length ? (
          <>
            <div data-testid="rejected-release-pos-title" className={s.title_rejected_pos}>
              {`The following (${releasablePOsList.disallowed.length}) purchase orders will NOT be released:`}
            </div>
            <div data-testid="rejected-release-pos-list" className={s.table}>
              {releasablePOsList.disallowed?.map((po) => {
                return (
                  <AlloyRow key={po.id} data-testid={`${po.id}-row`} className={s.row}>
                    <AlloyCol span={9} data-testid={`${po.id}-col`} className={s.col_po}>
                      {po.customerPo}
                    </AlloyCol>
                    <AlloyCol
                      span={15}
                      data-testid={`${po.retailerDeliveryDestination?.id}-col`} // added conditional to accommodate for no RDD in testing
                      className={s.col_rdd}
                    >
                      {po.retailerDeliveryDestination
                        ? `${po.retailerDeliveryDestination.name} - ${po.retailerDeliveryDestination.externalId}`
                        : po.retailerDeliveryDestinationExternalId}
                    </AlloyCol>
                  </AlloyRow>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      <AlloyModal
        title="Confirm release"
        open={visibility}
        onCancel={toggleModal}
        width={700}
        footer={[
          <AlloyButton
            data-testid="release-cancel-button"
            className="filled_grey_btn"
            key="cancel"
            onClick={toggleModal}
          >
            Cancel
          </AlloyButton>,
          <AlloyButton
            data-testid="release-confirm-button"
            className="filled_blue_btn"
            key="export"
            loading={processing || loading}
            disabled={processing || loading}
            onClick={releaseSalesOrdersAction}
          >
            Yes, Continue
          </AlloyButton>
        ]}
      >
        {loading || processing ? (
          <LoaderSpinner loadingText={processing ? 'Processing...' : 'Loading...'} />
        ) : (
          <DisplayPOs />
        )}
      </AlloyModal>
    </>
  );
};

export default ReleaseOrdersModal;
