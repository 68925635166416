export const MockData = [
  {
    id: '123',
    name: 'Walmart 2024 - Copy 2',
    createdBy: 'Jamie L.',
    lastUpdated: '2024-05-17',
    customer: 'Walmart.com',
    businessUnit: 'FLNA',
    startDate: '5/1/24',
    endDate: '7/7/24',
    private: true
  },
  {
    id: '134',
    name: 'Walmart 2024 - Copy',
    createdBy: 'Gowri H.',
    lastUpdated: '2024-05-17',
    customer: 'Walmart.com',
    businessUnit: 'FLNA',
    startDate: '5/1/24',
    endDate: '7/7/24',
    private: false
  },
  {
    id: '145',
    name: 'Walmart 2024',
    createdBy: 'Brian G.',
    lastUpdated: '2024-05-17',
    customer: 'Walmart.com',
    businessUnit: 'FLNA',
    startDate: '5/1/24',
    endDate: '7/7/24',
    private: false
  },
  {
    id: '156',
    name: 'Walmart 2023',
    createdBy: 'Chelsy S.',
    lastUpdated: '2024-05-17',
    customer: 'Walmart.com',
    businessUnit: 'FLNA',
    startDate: '5/1/24',
    endDate: '7/7/24',
    private: false
  }
];
