import React from 'react';
import s from './EditEventWatchlist.module.scss';
import { AlloyButton } from 'components/ui/AlloyButton/AlloyButton';
import { FileOutlined, DeleteOutlined } from '@ant-design/icons';

interface EditEventWatchlistProps {
  event: any;
}

export const EditEventWatchlist = ({ event }: EditEventWatchlistProps) => {
  return (
    <div className={s.event_watchlist}>
      <div className={s.upload_prompt}>
        <div className={s.label}>
          Add <b>PO List</b> and/or <b>External ID</b> list
        </div>
        <AlloyButton type="primary">PO list</AlloyButton>
        <AlloyButton type="primary">External ID</AlloyButton>
      </div>

      <div className={s.filelist}>
        <div className={s.header}>
          <div>File name</div>
          <div>Remove</div>
        </div>

        <div className={s.file}>
          <div className={s.left}>
            <div className={s.icon}>
              <FileOutlined />
            </div>
            <div className={s.name_group}>
              <div className={s.name}>myfile.csv</div>
              <div className={s.date}>Updated: 6/12/24</div>
            </div>
          </div>
          <div className={s.right}>
            <div className={s.download_link}>Download</div>
            <div className={s.icon}>
              <DeleteOutlined />
            </div>
          </div>
        </div>
      </div>

      <div className={s.template_wrapper}>
        <div>Don't have a template?</div>
        <div className={s.links}>
          <div className={s.file}>
            <FileOutlined className={s.icon} />
            <span>PO template</span>
          </div>
          <div className={s.file}>
            <FileOutlined className={s.icon} />
            <span>External ID template</span>
          </div>
        </div>
      </div>
    </div>
  );
};
